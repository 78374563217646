import {FC} from 'react';
import moment from 'moment';
import FormItem from '../../FormItem';
import {DatePickerWrapper} from './DateComponent.styles';
import {IDateComponentProps} from './DateComponent.types';

const DateComponent: FC<IDateComponentProps> = ({
  name,
  label,
  value,
  required,
  handleValueChange,
}: IDateComponentProps) => (
  <FormItem label={label} required={required}>
    <DatePickerWrapper
      format='DD/MM/YYYY'
      name={name}
      placeholder={label}
      defaultValue={value ? moment(value, 'DD/MM/YYYY') : undefined}
      onChange={(dateValue, dateString) =>
        handleValueChange({keyName: name, value: dateString})
      }
    />
  </FormItem>
);

export default DateComponent;
