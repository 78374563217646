import {
  HouseCircle,
  NationalIDCircle,
  DocumentCircle,
} from '../../../../static/images';

// eslint-disable-next-line import/prefer-default-export
export const defineIcon = (documentType: string) => {
  switch (documentType) {
    case 'national_id':
      return <NationalIDCircle />;
    case 'business_documents':
      return <DocumentCircle />;
    case 'proof_of_address':
      return <HouseCircle />;
    default:
      return <DocumentCircle />;
  }
};
