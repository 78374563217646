/* eslint-disable */
import {FC, ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {setIsAuthenticated} from '../../../state';
import WithNotification from '../../HOC/WithNotification';
import LayoutComponent from './LayoutComponent';

const UnauthenticatedLayout: FC<{children: ReactNode}> = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [mobileNavVisibility, setMobileNavVisibility] =
    useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const firstStepAuthStepper = localStorage.getItem('firstStepAuthStepper');

  useEffect(() => {
    dispatch(setIsAuthenticated(false));
  }, []);

  const showHomePage = (): void => history.push('/home');
  const closeMobileNav = (): void => setMobileNavVisibility(false);
  const showMobileNav = (): void => {
    setMobileNavVisibility(!mobileNavVisibility);
  };

  const defineCurrentStep = (currentPath: string): number => {
    // 0 index - first step, 1 index - second step, 2 index - third step
    const checkBalance = urlSearchParams.get('check_balance') || '';

    const secondStep = currentPath.includes('/payment-method');

    // Conditions related to third (last) step
    const loginPaymentStep = currentPath === '/login-payment';
    const sufficientBalance = checkBalance === '1';
    const loginWithSufficientBalance = loginPaymentStep && sufficientBalance;
    const afterPaymentStep = currentPath === '/incomplite';
    const thirdStep = loginWithSufficientBalance || afterPaymentStep;

    if (secondStep) return 1;

    if (thirdStep) return 2;

    // First step
    return 0;
  };

  const checkStepsView = (currentPath: string): boolean =>
    currentPath === '/register-payment' ||
    currentPath.includes('/payment-method') ||
    currentPath === '/login-payment' ||
    currentPath === '/incomplite';

  const checkHeaderView = (): boolean => {
    const authLinks: string[] = [
      '/login',
      '/register',
      '/forgot-password',
      '/register-payment',
      '/payment-method',
      '/login-payment',
      '/incomplite',
    ];
    return (
      authLinks.includes(location.pathname) ||
      location.pathname.includes('/payment-method') ||
      location.pathname.includes('/verification-email') ||
      location.pathname.includes('/auth')
    );
  };

  // eslint-disable-next-line arrow-body-style
  const checkAuthPaymentLayout = (): boolean => {
    return (
      location.pathname.includes('/register-payment') ||
      location.pathname.includes('/payment-method') ||
      location.pathname.includes('/login-payment') ||
      location.pathname.includes('/incomplite')
    );
  };

  const defineHeaderBgColor = (path: string) => {
    switch (path) {
      case '/about': {
        return {
          backgroundColor: '#F6F5FF',
        };
      }
      case '/security': {
        return {
          backgroundColor: '#ECFDF5',
        };
      }
      case '/partner': {
        return {
          backgroundColor: '#FEF2F2',
        };
      }
      case '/contact': {
        return {
          backgroundColor: '#FFF7ED',
        };
      }
      default: {
        return {
          backgroundColor: 'transparent',
        };
      }
    }
  };

  const headerBgColor = defineHeaderBgColor(location.pathname);
  const isShowAuthPaymentLayout = checkAuthPaymentLayout();

  return (
    /*
    <LayoutComponent
      headerBgColor={headerBgColor}
      checkHeaderView={checkHeaderView}
      showHomePage={showHomePage}
      mobileNavVisibility={mobileNavVisibility}
      showMobileNav={showMobileNav}
      closeMobileNav={closeMobileNav}
      checkStepsView={checkStepsView}
      defineCurrentStep={defineCurrentStep}
      firstStepAuthStepper={firstStepAuthStepper}
      isShowAuthPaymentLayout={isShowAuthPaymentLayout}
    >
      {children}
    </LayoutComponent>
    */
    <>{children}</>
  );
};

export default WithNotification(UnauthenticatedLayout);
