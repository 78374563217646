import {FC} from "react";
// eslint-disable-next-line import/prefer-default-export
export const GoogleIcon: FC = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.8145 10.2159C19.8145 9.54228 19.754 8.89455 19.6417 8.27274H10.6945V11.9475H15.8072C15.587 13.135 14.9176 14.1411 13.9115 14.8148V17.1984H16.9817C18.7781 15.5446 19.8145 13.1091 19.8145 10.2159Z" fill="#4285F4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6944 19.5C13.2594 19.5 15.4098 18.6493 16.9817 17.1984L13.9114 14.8148C13.0607 15.3848 11.9726 15.7216 10.6944 15.7216C8.22006 15.7216 6.12574 14.0504 5.3787 11.805H2.20483V14.2663C3.76802 17.3711 6.98074 19.5 10.6944 19.5Z" fill="#34A853"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.37878 11.805C5.18878 11.235 5.08082 10.6261 5.08082 10C5.08082 9.37387 5.18878 8.76501 5.37878 8.19501V5.73364H2.20491C1.5615 7.01614 1.19446 8.46705 1.19446 10C1.19446 11.533 1.5615 12.9839 2.20491 14.2664L5.37878 11.805Z" fill="#FBBC05"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6944 4.27841C12.0892 4.27841 13.3414 4.75773 14.326 5.69909L17.0507 2.97432C15.4055 1.44136 13.2551 0.5 10.6944 0.5C6.98074 0.5 3.76802 2.62886 2.20483 5.73364L5.3787 8.195C6.12574 5.94955 8.22006 4.27841 10.6944 4.27841Z" fill="#EA4335"/>
    </svg>
)
