import {FC, useEffect} from 'react';
import {Spin} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import EmptyVoucher from './EmptyVoucher';
import FilledVoucher from './FilledVoucher';
import VerifyAccount from '../../../components/VerifyAccount';
import {RootState} from '../../../../state/store';
import {
  getVoucherDataRequest,
  setPageTitleAction,
  getBadgeCountRequest,
} from '../../../../state';
import {
  IListItem,
  IListItemData,
} from '../../../../state/Vouchers/vouchers.types';

const VouchersList: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {timestampReload} = useSelector((state: RootState) => state.appReducer);
  const {loading, error, data} = useSelector(
    (state: RootState) => state.vouchersReducer,
  );

  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  useEffect(() => {
    if (data) {
      dispatch(setPageTitleAction(data.pageTitle));
    }
    dispatch(setPageTitleAction('Voucher'));
  }, [data]);

  useEffect(() => {
    dispatch(getVoucherDataRequest());
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  if (error) return <div>{error}</div>;

  if (loading || !data)
    return <Spin tip='Loading...' spinning={loading || !data} size='large' />;

  return (
    <VouchersWrapper>
      {userNotVerified ? (
        <VerifyAccount />
      ) : (
        <>
          {data && data.list && data.list.length > 0 ? (
            data.list.map((listItem: IListItem) => {
              const availableBalance = listItem.data.find(
                (itm: IListItemData) =>
                  itm.title.toLowerCase() === 'available balance',
              );

              const accountNumber = listItem.data.find(
                (itm: IListItemData) =>
                  itm.title.toLowerCase() === 'acc number',
              );

              const currency = listItem.data.find(
                (itm: IListItemData) =>
                  itm.title.toLowerCase() === 'currencies',
              );

              const voucherId = listItem.data.find(
                (itm: IListItemData) => itm.title.toLowerCase() === 'id',
              );

              const isVoucherFull =
                availableBalance &&
                availableBalance.value &&
                !!+availableBalance.value;

              if (isVoucherFull) {
                return (
                  <FilledVoucher
                    key={listItem.id}
                    voucherId={voucherId?.value}
                    balance={availableBalance?.value}
                    currency={currency?.value}
                    accountNumber={accountNumber?.value}
                  />
                );
              }

              return (
                <EmptyVoucher key={listItem.id} voucherId={voucherId?.value} />
              );
            })
          ) : (
            <VerifyAccount />
          )}
        </>
      )}
    </VouchersWrapper>
  );
};

const VouchersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default VouchersList;
