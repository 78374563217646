import {
  destroyStandardDataItemRequestAction,
  destroyStandardDataItemSuccessAction,
  destroyStandardDataItemFailureAction,
} from '../actions/delete.actions';
import {destroyStandardDataItem} from '../../../infra/standardized.service';
import {ThunkActionType} from '../../store';
import {getStandardDataRequest} from './read.requests';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  formFilteredUrl,
  handleErrorNotification,
  handleResponseExtraAction,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const destroyStandardDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch, getState) => {
    dispatch(destroyStandardDataItemRequestAction());

    const {currentPageTable, pageSizeTable, filterDataMap} =
      getState().standardizedReducer;

    const slugFilter = formFilteredUrl({
      slugUrl: slug,
      currentPageTable,
      pageSizeTable,
      filterDataMap,
    });

    return destroyStandardDataItem(slug)
      .then((response) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: destroyStandardDataItemSuccessAction,
          failureAction: destroyStandardDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugFilter,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: destroyStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
