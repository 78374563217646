import styled from 'styled-components';
import {Button} from 'antd';

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 485px;
  margin-left: 65px;

  @media (max-width: 950px) {
    max-width: 350px;
  }

  @media (max-width: 840px) {
    margin: 0 auto;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  @media (max-width: 370px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled(Button)<{$borderRadius?: string}>`
  height: 48px;
  padding: 12px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
  background-color: ${(p) => p.theme.main.colors.lightPurple};

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  @media (max-width: 370px) {
    margin-right: 0;
    margin-bottom: 16px;

    &:first-child {
      margin-right: 0;
    }
  }
`;
