interface ITypographyProps {
  fontSize: string;
  fontWeight?: number;
  color?: string;
  letterSpacing?: string;
  fontFamily?: string;
}

export interface ITypography {
  [propName: string]: string | ITypographyProps;
}

export const typography: ITypography = {
  title: {
    fontFamily: 'NotoSans SemiBold, sans-serif',
    fontSize: '36px',
  },
  h2: {
    fontFamily: 'NotoSans SemiBold, sans-serif',
    fontSize: '36px',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontFamily: 'NotoSans SemiBold, sans-serif',
    fontSize: '24px',
  },
  semiHeading: {
    fontFamily: 'NotoSans SemiBold, sans-serif',
    fontSize: '20px',
  },
  heading: {
    fontFamily: 'NotoSans Regular, sans-serif',
    fontSize: '18px',
  },
  subHeading: {
    fontFamily: 'NotoSans SemiBold, sans-serif',
    fontSize: '16px',
  },
  paragraph: {
    fontFamily: 'NotoSans Regular, sans-serif',
    fontSize: '16px',
  },
  body: {
    fontFamily: 'NotoSans Regular, sans-serif',
    fontSize: '14px',
  },
  bodyMedium: {
    fontFamily: 'NotoSans Medium, sans-serif',
    fontSize: '14px',
  },
  caption: {
    fontFamily: 'NotoSans Regular, sans-serif',
    fontSize: '12px',
  },
  captionMedium: {
    fontFamily: 'NotoSans Medium, sans-serif',
    fontSize: '12px',
  },
  micro: {
    fontFamily: 'NotoSans Regular, sans-serif',
    fontSize: '10px',
  },
  microMedium: {
    fontFamily: 'NotoSans Medium, sans-serif',
    fontSize: '10px',
  },
  passwordSettingsEmail: {
    fontFamily: 'PasswordDots',
    fontSize: '14px',
  },
};
