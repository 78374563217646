import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {
  AcceptTerms,
  ColoredText,
  DepositAmount,
  GreyText,
  Heading,
  Subtitle,
  Input,
  InputCurrencySign,
  InputWrapper,
} from './AmountInput.styles';
import {IAmountInputProps} from './AmountInput.types';

const AmountInput: FC<IAmountInputProps> = ({
  name,
  value,
  description,
  placeholder,
  handleChange,
  currentCurrency,
}: IAmountInputProps) => {
  const history = useHistory();

  return (
    <DepositAmount>
      <Heading>Deposit Amount</Heading>
      <Subtitle>{description}</Subtitle>
      <InputWrapper>
        <Input
          type='text'
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <InputCurrencySign>
          {value.length ? currentCurrency : ''}
        </InputCurrencySign>
      </InputWrapper>
      <AcceptTerms>
        <GreyText>By purchasing, you accept the</GreyText>
        <ColoredText onClick={() => history.push('/voucher-terms')}>
          {' '}
          Terms and Conditions{' '}
        </ColoredText>
        <GreyText>and acknowledge reading the</GreyText>
        <ColoredText onClick={() => history.push('/privacy-policy')}>
          {' '}
          Privacy Policy.
        </ColoredText>
      </AcceptTerms>
    </DepositAmount>
  );
};

export default AmountInput;
