import {FC} from 'react';
import {useParams} from 'react-router-dom';
import {IDeleteFileArgs} from '../../../../../state/Verification/verification.types';
import {CloseIcon, DocumentIcon} from '../../../../static/images';
import {
  Button,
  Container,
  Section,
  FileChip,
  FileName,
  FileLink,
  Paragraph,
  IconWrapper,
} from './FileChips.styles';
import {IFileChipsProps} from './FileChips.types';

const FileChips: FC<IFileChipsProps> = ({
  documentType,
  uploadedFiles,
  deleteUploadedFile,
}: IFileChipsProps) => {
  const {id} = useParams<{id: string}>();

  // eslint-disable-next-line arrow-body-style
  const handleDeleteUploadedFile = (deleteFileArgs: IDeleteFileArgs) => {
    return () => deleteUploadedFile(deleteFileArgs);
  };

  return (
    <Container>
      {uploadedFiles.length > 0 ? (
        <div>
          <Paragraph>Uploaded files</Paragraph>
          <Section>
            {uploadedFiles.map((itm) => (
              <FileChip key={itm.label}>
                <IconWrapper>
                  <DocumentIcon fillColor='#192b3b' />
                </IconWrapper>
                <FileLink href={itm.url} target='_blank' rel='noreferrer'>
                  <FileName>{itm.label}</FileName>
                </FileLink>
                <Button
                  onClick={handleDeleteUploadedFile({
                    user_id: id,
                    file: itm.label,
                    group: documentType,
                  })}
                >
                  <CloseIcon />
                </Button>
              </FileChip>
            ))}
          </Section>
        </div>
      ) : null}
    </Container>
  );
};

export default FileChips;
