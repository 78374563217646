import {FC, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Spin, Modal} from 'antd';
import {RootState} from '../../../../../state/store';
import {
  TypeView,
  IFormFields,
  ISendFormArgs,
} from '../../../../../state/FormConstructor/formConstructor.types';
import {
  postRequest,
  deleteRequest,
  clearSendFormRes,
  clearAllFormRes,
} from '../../../../../state';
import {useFormHandler} from '../../../../hooks';
import {IFormDialogProps} from './FormDialog.types';
import FormCollection from '../FormCollection';
import {createFormItems} from '../../../../../utils';

const FormDialog: FC<IFormDialogProps> = ({
  visible,
  onClose,
}: IFormDialogProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {error, formViewMap, apiUrl, sendFormRes, loading} = useSelector(
    (state: RootState) => state.formConstructorReducer,
  );

  const [formFields, setFormFields] = useState<IFormFields>({
    fields: [],
    buttons: [],
  });

  const sendFormAction = ({slugUrl, body}: ISendFormArgs): void => {
    dispatch(postRequest({slugUrl, body}));
  };

  const deleteRequestAction = ({slugUrl, body}: ISendFormArgs): void => {
    dispatch(deleteRequest({slugUrl, body}));
  };

  const formHook = useFormHandler({
    formViewMap,
    apiUrl,
    actions: {sendFormAction, deleteRequestAction},
  });

  const handleCancel = (): void => {
    dispatch(clearAllFormRes());
    onClose();
  };

  useEffect(() => {
    if (!sendFormRes) return;
    handleCancel();
  }, [sendFormRes]);

  useEffect(() => {
    if (!formViewMap) return;
    setFormFields(
      createFormItems({formItems: formViewMap.data, typeView: TypeView.Popup}),
    );
  }, [formViewMap]);

  useEffect(() => {
    if (!sendFormRes) return;
    if (!sendFormRes.type) return;

    if (sendFormRes.type === 'file') {
      window.open(sendFormRes.fileUrl);
      dispatch(clearSendFormRes());
      return;
    }

    if (sendFormRes.type === 'standardized') {
      history.push(`/s/${sendFormRes.name}`);
      dispatch(clearAllFormRes());
      return;
    }

    if (sendFormRes.type === 'redirect_inner') {
      history.push(sendFormRes.url);
      dispatch(clearAllFormRes());
      return;
    }

    if (sendFormRes.type === 'redirect_outer') {
      window.open(sendFormRes.url);
      dispatch(clearSendFormRes());
    }
  }, [sendFormRes]);

  if (error) return <div>{error}</div>;

  if (loading || !formViewMap) {
    return (
      <Spin tip='Loading...' spinning={loading || !formViewMap} size='large' />
    );
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      wrapClassName={
        formFields.fields.length === 1 ? '' : 'form-constructor-popup'
      }
    >
      <FormCollection
        typeView={TypeView.Popup}
        formFields={formFields}
        formValues={formHook.formValues}
        formHasError={formHook.formHasError}
        submitForm={formHook.submitForm}
        handleValueChange={formHook.handleValueChange}
        handleCancelButton={handleCancel}
      />
    </Modal>
  );
};

export default FormDialog;
