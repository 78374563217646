import {AxiosResponse} from 'axios';
import {
  getFormView,
  postService,
  deleteService,
} from '../../infra/formConstructor.service';
import {ThunkActionType} from '../store';
import {IGetFormViewRequestArgs, ISendFormArgs} from './formConstructor.types';
import {
  getFormViewRequestAction,
  getFormViewSuccessAction,
  getFormViewFailureAction,
  sendFormRequestAction,
  sendFormSuccessAction,
  sendFormFailureAction,
} from './formConstructor.actions';
import {getStandardDataRequest} from '../Standardized/requests/read.requests';
import {setNotificationState} from '../Notification/notification.actions';
import {
  formFilteredUrl,
  handleErrorNotification,
  handleResponseExtraAction,
  handleResponseFailNotification,
  handleResponseSuccessAndFailNotification,
} from '../../utils';

export const getFormViewRequest =
  (args: IGetFormViewRequestArgs): ThunkActionType =>
  (dispatch) => {
    const {slugUrl, apiUrl} = args;
    dispatch(getFormViewRequestAction(apiUrl));
    localStorage.setItem('formConstructorUrl', slugUrl);

    return getFormView(slugUrl)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getFormViewSuccessAction,
          failureAction: getFormViewFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getFormViewFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const postRequest =
  (args: ISendFormArgs): ThunkActionType =>
  (dispatch) => {
    dispatch(sendFormRequestAction());

    return postService(args)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: sendFormSuccessAction,
          failureAction: sendFormFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendFormFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const deleteRequest =
  (args: ISendFormArgs): ThunkActionType =>
  (dispatch, getState) => {
    dispatch(sendFormRequestAction());

    const {currentPageTable, pageSizeTable, filterDataMap} =
      getState().standardizedReducer;

    const slugFilter = formFilteredUrl({
      slugUrl: args.slugUrl,
      currentPageTable,
      pageSizeTable,
      filterDataMap,
    });

    return deleteService(args)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: sendFormSuccessAction,
          failureAction: sendFormFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugFilter,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendFormFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
