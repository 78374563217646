import styled from 'styled-components';

export const PanelInner = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 677px) {
    flex-direction: row;
  }

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const LeftPanelPart = styled.div`
  display: flex;
  align-items: center;
`;

export const TextPartPanel = styled.div`
  margin-left: 10px;
`;

export const Title = styled.p`
  ${(p) => p.theme.main.typography.subHeading};
  margin-bottom: 4px;
  color: #192b3b;
`;
export const Description = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: #74828a;
`;

export const RightPanelPart = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    justify-content: space-between;
    align-items: baseline;
  }
`;

export const ActionsListPaymentWrapper = styled.div`
  margin-right: 20px;

  @media (max-width: 375px) {
    margin-right: 14px;
  }
`;

export const EndBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ArrowWrapper = styled.div<{isOpen: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease;
`;
