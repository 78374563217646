import {FC, useState} from 'react';
import {Spin} from 'antd';
import {IVerifyClientProps} from './VerifyClient.types';
import {ButtonWrapper, ButtonInner, IconWrapper} from './VerifyClient.styles';
import PopoverComponent from '../../../../components/PopoverComponent';

const VerifyClient: FC<IVerifyClientProps> = ({
  approveProfile,
  loadingVerifyClient,
}: IVerifyClientProps) => {
  const [isShowPopover, setShowPopover] = useState(false);

  const declineClick = (): void => setShowPopover(false);
  const handleShowPopover = (visible: boolean) => setShowPopover(visible);

  const acceptClick = (): void => {
    approveProfile();
    setShowPopover(false);
  };

  return (
    <PopoverComponent
      visible={isShowPopover}
      questionText='Are you sure you want to approve this profile?'
      acceptText='Approve'
      declineText='Cancel'
      acceptClick={acceptClick}
      declineClick={declineClick}
      handleShowPopover={handleShowPopover}
    >
      <ButtonWrapper>
        <ButtonInner>
          {loadingVerifyClient ? (
            <IconWrapper>
              <Spin size='small' spinning={loadingVerifyClient} />
            </IconWrapper>
          ) : null}
          <div>Approve profile</div>
        </ButtonInner>
      </ButtonWrapper>
    </PopoverComponent>
  );
};

export default VerifyClient;
