import styled from 'styled-components';
import {Select} from 'antd';

export const SelectWrapper = styled.div<{isSelectInFocus: boolean}>`
  height: 48px;
  border-radius: 8px;
  outline: ${({isSelectInFocus}) =>
    isSelectInFocus ? '2px solid #192b3b' : '0'};
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 32px;
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    border-color: ${(p) => p.theme.main.colors.lightGrey} !important;
  }

  .ant-select-selection-placeholder {
    text-transform: capitalize;
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }

  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.main.colors.black};
  }
`;
