import styled from 'styled-components';

export const Container = styled.div`
  width: 275px;
  height: 76px;
  border: 2px dashed #ebebeb;
  border-radius: 8px;
  position: relative;
`;

export const Input = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const TextPart = styled.div`
  margin-left: 16px;
`;

export const Title = styled.p`
  ${(p) => p.theme.main.typography.bodyMedium}
`;

export const ColoredTitle = styled.span`
  color: ${(p) => p.theme.main.colors.brand};
`;

export const BlackTitle = styled.span`
  color: ${(p) => p.theme.main.colors.black};
`;

export const SubTitle = styled.p`
  ${(p) => p.theme.main.typography.caption}
  color: ${(p) => p.theme.main.colors.darkGrey};
`;
