// CLEAR_INCOMPLETE_DATA_ITEM_COPY TYPES & ACTIONS
export const CLEAR_INCOMPLETE_DATA_ITEM_COPY: string =
  'CLEAR_INCOMPLETE_DATA_ITEM_COPY';
export const CLEAR_INCOMPLETE_DATA_ITEM_CREATE: string =
  'CLEAR_INCOMPLETE_DATA_ITEM_COPY';
export const CLEAR_INCOMPLETE_PAYMENT_FIELDS: string =
  'CLEAR_INCOMPLETE_PAYMENT_FIELDS';

export const clearIncompleteDataItemCopy = (): {type: string} => ({
  type: CLEAR_INCOMPLETE_DATA_ITEM_COPY,
});

export const clearIncompleteDataItemCreate = (): {type: string} => ({
  type: CLEAR_INCOMPLETE_DATA_ITEM_CREATE,
});

export const clearIncompletePaymentFields = (): {type: string} => ({
  type: CLEAR_INCOMPLETE_PAYMENT_FIELDS,
});

// CLEAR_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const CLEAR_INCOMPLETE_DATA_ITEM: string = 'CLEAR_INCOMPLETE_DATA_ITEM';

export const clearIncompleteDataItem = (): {type: string} => ({
  type: CLEAR_INCOMPLETE_DATA_ITEM,
});

// SET_CURRENT_SLUG TYPES & ACTIONS
export const SET_CURRENT_SLUG: string = 'SET_CURRENT_SLUG';

export const setCurrentSlug = (
  receivedSlug: string,
): {payload: string; type: string} => ({
  type: SET_CURRENT_SLUG,
  payload: receivedSlug,
});

// CLEAR_PAY_SUCCESS_RES TYPES & ACTIONS
export const CLEAR_PAY_SUCCESS_RES: string = 'CLEAR_PAY_SUCCESS_RES';

export const clearPaySuccessRes = () => ({
  type: CLEAR_PAY_SUCCESS_RES,
});

// CLEAR_ERROR_STATE TYPES & ACTIONS
export const CLEAR_ERROR_STATE: string = 'CLEAR_ERROR_STATE';

export const clearErrorState = () => ({
  type: CLEAR_ERROR_STATE,
});
