const ERRORS_MESSAGES = {
  // REMOTE VALIDATION MESSAGES
  10001: 'Ошибка внутреннего перевода OAuth2',
  10101: 'Токен JWT утверждает, что JSON не может быть проанализирован',
  10111: 'Невозможно преобразовать токен доступа в JSON',
  10112: 'Невозможно преобразовать токен OpenID в JSON',
  10121: 'Исключение улучшения токена OpenID',
  10201: 'Недостаточно полномочий, нет требуемых объемов',
  10202: 'Недостаточные авторитеты, неправильные рамки',
  21004: 'Повторите попытку',
  15001: 'Пользователь с почтой {email} уже существует',
  15002: 'Пользователь с почтой {email} не найден',
  15003: 'Пользователь с почтой {email} уже активирован',
  15004: 'Пользователь с почтой {email} не активирован, но код не требуется',
  15005:
    'Код, указанный для подтверждения, не совпадает с кодом Пользователя с электронной почтой {email}',
  15101: 'Неизвестный объект уведомления',
  21008: 'Необработанное исключение',

  // LOCAL VALIDATION MESSAGES
  EMAIL_EXIST_VALIDATION_MESSAGE: 'Электронная почта обязательна!',
  ACTIVATION_CODE_EXIST_VALIDATION_MESSAGE: 'Требуется код активации!',
  PASSWORD_EXIST_VALIDATION_MESSAGE: 'Необходим пароль!',
  PASSWORD_MATCHING_VALIDATION_MESSAGE: 'Два введенных пароля не совпадают!',
  MIN1_CAPITAL_LETTER_VALIDATION_MESSAGE:
    'Пароль должен состоять минимум из 1 заглавной буквы!',
  MIN1_DIGIT_VALIDATION_MESSAGE: 'Пароль должен состоять минимум из 1 цифры!',
  MIN1_LOWER_LETTER_VALIDATION_MESSAGE:
    'Пароль должен содержать минимум 1 строчную букву!',
  MIN8_CHARACTER_VALIDATION_MESSAGE:
    'Пароль должен состоять минимум из 8 букв!',
  MAX20_CHARACTER_VALIDATION_MESSAGE:
    'Пароль должен состоять максимум из 20 букв!',
  COMPANY_NAME_EXIST_VALIDATION_MESSAGE: 'Требуется название компании!',
  FIELD_EXIST_VALIDATION_MESSAGE: 'Поле обязательно!',
  NEW_EMAIL_CONFIRM_EXIST_VALIDATION_MESSAGE:
    'Необходимо подтверждение новой электронной почты!',
  NEW_EMAIL_MATCHING_VALIDATION_MESSAGE:
    'Две введенные новые почты не совпадают!',
  FIRST_NAME_EXIST_VALIDATION_MESSAGE: 'Имя обязательно!',
  LAST_NAME_EXIST_VALIDATION_MESSAGE: 'Фамилия обязательна!',
  DAY_BIRTH_EXIST_VALIDATION_MESSAGE: 'День рождения обязателен!',
  MONTH_BIRTH_EXIST_VALIDATION_MESSAGE: 'Месяц рождения обязателен!',
  YEAR_BIRTH_EXIST_VALIDATION_MESSAGE: 'Год рождения обязателен!',
  PHONE_NUMBER_EXIST_VALIDATION_MESSAGE: 'Номер телефона обязателен!',
  COUNTRY_EXIST_VALIDATION_MESSAGE: 'Страна обязательна!',
  CITY_EXIST_VALIDATION_MESSAGE: 'Город обязателен!',
  ADDRESS_EXIST_VALIDATION_MESSAGE: 'Адрес обязателен!',
  POSTCODE_EXIST_VALIDATION_MESSAGE: 'Почтовый индекс обязателен!',
};

export default ERRORS_MESSAGES;
