/* eslint-disable */
import React from 'react';
import {useSelector} from 'react-redux';
import {Spin} from 'antd';
import {RootState} from '../../../../../state/store';
import CollapseList from '../CollapseList';
import EmptyCard from '../../Dashboard/EmptyCard';
import {ITransactionsListProps} from './TransactionsList.types';
import {IActivityItem} from '../CollapseList/CollapseList.types';
import {PeriodDivider} from './TransactionsList.styles';

const TransactionsList = ({listItems}: ITransactionsListProps) => {
  const {loading} = useSelector(
    (state: RootState) => state.transactionsReducer,
  );

  if (loading) return <Spin tip='Loading...' spinning={loading} size='large' />;

  return (
    <>
      {listItems.length > 0 ? (
        listItems.map((listItem) => (
          <div key={listItem.id}>
            <PeriodDivider>{listItem.period}</PeriodDivider>
            <div>
              {listItem.activitiesList.map((activityItem: IActivityItem) => (
                <CollapseList
                  key={activityItem.id}
                  id={activityItem.id}
                  currency={
                    activityItem.currency
                      ? activityItem.currency.toUpperCase()
                      : null
                  }
                  contractorInfo={activityItem.contractorInfo}
                  paymentPeriod={activityItem.paymentPeriod}
                  paymentAmount={activityItem.paymentAmount}
                  transactionId={activityItem.transactionId}
                  paymentMethod={activityItem.paymentMethod}
                  paymentStatus={activityItem.paymentStatus}
                  isPaymentReceived={activityItem.isPaymentReceived}
                />
              ))}
            </div>
          </div>
        ))
      ) : (
        <EmptyCard />
      )}
    </>
  );
};

export default TransactionsList;
