import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Spin} from 'antd';
import ListTreatBar from './ListTreatBar';
import {RootState} from '../../../../state/store';
import {
  getTransactionsDataRequest,
  setPageTitleAction,
  getBadgeCountRequest,
  clearValuesTransactionsMap,
  clearFilterTransactionsMap,
} from '../../../../state';
import {IListItem} from './CollapseList/CollapseList.types';
import TransactionsList from './TransactionsList';

const Transactions: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {timestampReload} = useSelector((state: RootState) => state.appReducer);
  const {error, data} = useSelector(
    (state: RootState) => state.transactionsReducer,
  );

  useEffect(() => {
    if (data) {
      dispatch(setPageTitleAction(data.pageTitle));
    }
    dispatch(setPageTitleAction('Transactions'));
  }, [data]);

  useEffect(() => {
    dispatch(getTransactionsDataRequest());
    dispatch(getBadgeCountRequest());
    dispatch(clearValuesTransactionsMap());
    dispatch(clearFilterTransactionsMap());
  }, [timestampReload]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearValuesTransactionsMap());
      dispatch(clearFilterTransactionsMap());
    };
  }, []);

  if (error) return <div>{error}</div>;
  if (!data) return <Spin tip='Loading...' spinning={!data} size='large' />;

  const listItems: IListItem[] = data.table
    ? Object.values(data.table.body)
    : [];

  return (
    <>
      <ListTreatBar
        buttons={data.buttons}
        filterTemplate={data.filterTemplate}
      />
      <TransactionsList listItems={listItems} />
    </>
  );
};

export default Transactions;
