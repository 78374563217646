import {AxiosResponse} from 'axios';
import {Dispatch, ActionCreator} from 'redux';
import {handleResponseFirstStep} from './handleResponseFirstStep';

interface IExtraRequest {
  extraRequest: ActionCreator<any>;
  extraRequestArg?: string;
}

interface IHandleResponseExtraActions {
  response: AxiosResponse;
  dispatch: Dispatch;
  successAction: ActionCreator<any>;
  failureAction: ActionCreator<any>;
  notificationAction: ActionCreator<any>;
  extraRequests: IExtraRequest[];
}

// eslint-disable-next-line import/prefer-default-export
export const handleResponseExtraActions = ({
  response,
  dispatch,
  successAction,
  failureAction,
  notificationAction,
  extraRequests,
}: IHandleResponseExtraActions) => {
  handleResponseFirstStep(response, dispatch, successAction, failureAction);

  const typeNotification = 'error' in response.data ? 'error' : 'success';
  const typeMessage = typeNotification === 'error' ? 'Error' : 'Success';

  dispatch(
    notificationAction({
      isShow: true,
      type: typeNotification,
      message: response.data.message || typeMessage,
    }),
  );

  if (typeNotification === 'error') return;

  extraRequests.forEach(({extraRequest, extraRequestArg}) => {
    if (extraRequestArg) {
      dispatch(extraRequest(extraRequestArg));
      return;
    }

    dispatch(extraRequest());
  });
};
