import styled from 'styled-components';
import {Avatar, Menu, Tooltip} from 'antd';
import {UserOutlined} from '@ant-design/icons';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 20px 58px;
  background-color: ${(p) => p.theme.main.colors.white};
  border-bottom: 1px solid #eff0f1;

  @media (max-width: 677px) {
    padding: 20px 40px;
  }
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 677px) {
    display: none;
  }
`;

export const HeaderTitle = styled.h3`
  ${(p) => p.theme.main.typography.h3};
  color: ${(p) => p.theme.main.colors.black};
  text-transform: capitalize;

  @media (max-width: 677px) {
    display: none;
  }
`;

export const AvatarWrapper = styled(Avatar)`
  margin-left: 46px;
  margin-right: 10px;
`;

export const AvatarWrapperDefault = styled.div`
  margin: 0 12px;
`;

export const UserIcon = styled(UserOutlined)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const UserData = styled.section`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserDataDetailsWrapper = styled.section`
  display: flex;
  align-items: center;
  height: 60px;

  @media (max-width: 677px) {
    display: none;
  }
`;

export const UserDataDetails = styled.section`
  display: inherit;
  flex-direction: column;
  align-items: center;
  margin-right: 14px;
`;

export const UserDataDetailsEmail = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const IconWrapper = styled.div<{isOpen: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease;
`;

export const StyledMenu = styled(Menu)`
  border-radius: 8px;

  @media (max-width: 677px) {
    border-radius: 0;
  }
`;

export const UserDetailsDropdownItem = styled(Menu.Item)`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  padding: 18px 20px;
  border-top: ${({title}: {title: string}) =>
    title.toLowerCase() === 'logout' ? '1px solid #ebebeb' : '0'};
`;

export const UserDetailsDropdownItemContact = styled.a`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    color: ${(p) => p.theme.main.colors.darkGrey};
    background-color: #f5f5f5;
  }
`;

export const UserDetailsDropdownItemCopyright = styled(Menu.Item)`
  display: none;
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  padding: 18px 20px;

  @media (max-width: 677px) {
    display: block;
  }
`;

export const MenuItemInner = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItemText = styled.div`
  margin-left: 12px;
`;

export const HeaderLogo = styled.button`
  display: none;

  @media (max-width: 677px) {
    display: block;
  }
`;

export const HeaderBar = styled.div`
  display: flex;
  align-items: center;
`;

export const RefreshButtonDesktop = styled.button`
  display: flex;
  align-items: center;
  margin-left: 18px;

  @media (max-width: 677px) {
    display: none;
  }
`;

export const RefreshButtonMobile = styled.button`
  display: none;
  align-items: center;
  margin-right: 40px;

  @media (max-width: 677px) {
    display: flex;
  }
`;

export const BurgerButton = styled.button`
  display: none;
  width: 16px;
  height: 16px;

  @media (max-width: 677px) {
    display: block;
  }
`;

export const BurgerNav = styled.div<{isOpen: boolean}>`
  width: 100%;
  height: 2px;
  background: ${({isOpen}) => (isOpen ? 'none' : '#192B3B')};
  border-radius: 2px;
  position: relative;

  transition: background 0.2s linear;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${(p) => p.theme.main.colors.black};
    border-radius: 2px;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: transform 0.2s linear;
  }

  &:before {
    bottom: 5px;
    transform-origin: left top;
    transform: ${({isOpen}) =>
      isOpen
        ? 'rotate(45deg) translate3d(1px, -2px, 0)'
        : 'rotate(0) translate3d(0, 0, 0)'};
  }

  &:after {
    top: 5px;
    transform-origin: left bottom;
    transform: ${({isOpen}) =>
      isOpen
        ? 'rotate(-45deg) translate3d(1px, 1.4px, 0)'
        : 'rotate(0) translate3d(0, 0, 0)'};
  }
`;

export const CopyrightMenuItem = styled.div`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
`;

export const StyledTooltip = styled(Tooltip)`
  color: red;
`;
