interface ITelephoneArgs {
  phoneNumber: string;
  phoneCode: string;
}

// eslint-disable-next-line import/prefer-default-export
export const formTelephone = (formTelephoneArgs: ITelephoneArgs): string => {
  const {phoneNumber, phoneCode} = formTelephoneArgs;
  let telephone = '';

  if (
    phoneNumber.startsWith(phoneCode) &&
    phoneNumber.length > phoneCode.length
  ) {
    const phoneNumberWithoutCode = phoneNumber.replace(phoneCode, '');
    telephone = phoneCode + phoneNumberWithoutCode;
  } else if (
    phoneCode.startsWith(phoneNumber) &&
    phoneNumber.length > 0 &&
    phoneNumber.length < phoneCode.length
  ) {
    telephone = '';
  } else if (
    phoneCode !== phoneNumber ||
    phoneNumber.length === 0 ||
    phoneNumber.length === phoneCode.length
  ) {
    telephone = phoneCode;
  }

  return telephone;
};
