// eslint-disable-next-line import/prefer-default-export
export const HouseCircle = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      d='M15 24L17 22M17 22L24 15L31 22M17 22V32C17 32.5523 17.4477 33 18 33H21M31 22L33 24M31 22V32C31 32.5523 30.5523 33 30 33H27M21 33C21.5523 33 22 32.5523 22 32V28C22 27.4477 22.4477 27 23 27H25C25.5523 27 26 27.4477 26 28V32C26 32.5523 26.4477 33 27 33M21 33H27'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
