import styled from 'styled-components';
import {Checkbox, Select} from 'antd';

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }
`;

export const CheckboxBlock = styled.div``;

export const CheckboxBlockTitle = styled.p``;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledCheckbox = styled(Checkbox)``;
