import {FC} from 'react';
import {Close, UserCircle} from '../../../static/images';
import {
  ContainerMessages,
  Message,
  Heading,
  UserPart,
  Avatar,
  Text,
  DateTimeMessage,
  UserName,
} from './MessagesList.styles';
import {IMessagesListProps} from './MessagesList.types';

const MessagesList: FC<IMessagesListProps> = ({
  messagesList,
  formateDateTime,
  removeMessage,
}: IMessagesListProps) => (
  <ContainerMessages>
    {messagesList.map((message) => (
      <Message key={message.id}>
        <Heading>
          <UserPart>
            <Avatar>
              <UserCircle />
            </Avatar>
            <div>
              <DateTimeMessage>
                {formateDateTime(message.created_at)}
              </DateTimeMessage>
              <UserName>{message.user_name}</UserName>
            </div>
          </UserPart>
          <button
            type='button'
            onClick={() => {
              removeMessage({
                message_id: message.id.toString(),
                chat_id: message.chat_id,
              });
            }}
          >
            <Close />
          </button>
        </Heading>
        <Text>{message.body}</Text>
      </Message>
    ))}
  </ContainerMessages>
);

export default MessagesList;
