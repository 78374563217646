import {FC, useState, useEffect, ChangeEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {Select} from 'antd';
import {RootState} from '../../../state/store';
import {getPhoneCodesRequest} from '../../../state';
import {
  FormItem,
  FormItemFullWidth,
  InputWrapper,
} from '../../pages/MemberArea/Auth/Auth.styles';
import {
  StyledSelect,
  FlexWrapper,
  FormInner,
  HalfPart,
  LabelDate,
  FlexItem,
  FlexItemDate,
  FlexItemMonth,
  FlexItemYear,
  PhoneCodeSelectWrapper,
  PhoneCodeSelect,
  CountryCode,
  PhoneNumberInput,
} from './PersonalFormComponent.styles';
import {
  optionsDayBirth,
  optionsMonthBirth,
  optionsYearBirth,
} from './formOptionsList';
import {Down, Search} from '../../static/images';
import {IPersonalFormComponentProps} from './PersonalFormComponent.types';

const {Option} = Select;

const PersonalFormComponent: FC<IPersonalFormComponentProps> = ({
  formRules,
  isSelectInFocus,
  countriesList,
  isDateFieldRequired,
  initialPhoneCode,
  handleSelectInFocus,
}: IPersonalFormComponentProps) => {
  const dispatch = useDispatch();
  const {phoneCodes} = useSelector(
    (state: RootState) => state.phoneCodesReducer,
  );

  const [valSelect, setValSelect] = useState(initialPhoneCode);

  const handlePhoneCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValSelect(e.target.value);
  };

  useEffect(() => {
    dispatch(getPhoneCodesRequest());
  }, []);

  return (
    <FormInner>
      <HalfPart>
        <FormItem
          label='First name'
          name='firstName'
          rules={formRules.firstName}
        >
          <InputWrapper placeholder='First name' $borderRadius='8px' />
        </FormItem>
        <FormItem label='Last name' name='lastName' rules={formRules.lastName}>
          <InputWrapper placeholder='Last name' $borderRadius='8px' />
        </FormItem>
        <div>
          <LabelDate isShowRequiredMark={isDateFieldRequired}>
            Date of birth
          </LabelDate>
          <FlexItem>
            <FlexItemDate>
              <FormItem label='' name='day_birth' rules={formRules.dayBirth}>
                <StyledSelect suffixIcon={<Down />}>
                  {optionsDayBirth.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </StyledSelect>
              </FormItem>
            </FlexItemDate>
            <FlexItemMonth>
              <FormItem
                label=''
                name='month_birth'
                rules={formRules.monthBirth}
              >
                <StyledSelect suffixIcon={<Down />}>
                  {optionsMonthBirth.map((option) => (
                    <Option key={option.key} value={option.key}>
                      {option.value}
                    </Option>
                  ))}
                </StyledSelect>
              </FormItem>
            </FlexItemMonth>
            <FlexItemYear>
              <FormItem label='' name='year_birth' rules={formRules.yearBirth}>
                <StyledSelect suffixIcon={<Down />}>
                  {optionsYearBirth.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </StyledSelect>
              </FormItem>
            </FlexItemYear>
          </FlexItem>
        </div>
        {phoneCodes ? (
          <FlexWrapper>
            <PhoneCodeSelectWrapper>
              <LabelDate isShowRequiredMark={isDateFieldRequired}>
                Phone
              </LabelDate>
              <FormItem label='' name='phone_code' rules={[]}>
                <PhoneCodeSelect onChange={handlePhoneCodeChange}>
                  {phoneCodes.phoneCodeList.map((phoneCode) => (
                    <option key={uuidv4()} value={phoneCode.code}>
                      {phoneCode.label}
                    </option>
                  ))}
                </PhoneCodeSelect>
              </FormItem>
              <CountryCode>
                <div>{phoneCodes.phoneCodeMap[valSelect]}</div>
              </CountryCode>
            </PhoneCodeSelectWrapper>
            <FormItemFullWidth
              label=''
              name='phone_number'
              rules={formRules.phoneNumber}
            >
              <PhoneNumberInput type='number' />
            </FormItemFullWidth>
          </FlexWrapper>
        ) : null}
      </HalfPart>
      <HalfPart>
        <FormItem label='Country' name='country' rules={formRules.country}>
          <StyledSelect
            showSearch
            optionFilterProp='value'
            placeholder='Select your country'
            suffixIcon={isSelectInFocus ? <Search /> : <Down />}
            onFocus={handleSelectInFocus(true)}
            onBlur={handleSelectInFocus(false)}
            filterOption={(input, option) =>
              option?.value.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {countriesList.map((countryName: any) => (
              <Option key={countryName} value={countryName}>
                {countryName}
              </Option>
            ))}
          </StyledSelect>
        </FormItem>
        <FormItem label='City' name='city' rules={formRules.city}>
          <InputWrapper placeholder='City' $borderRadius='8px' />
        </FormItem>
        <FormItem label='Address' name='address' rules={formRules.address}>
          <InputWrapper placeholder='Address' $borderRadius='8px' />
        </FormItem>
        <FormItem
          label='Postal code'
          name='postcode'
          rules={formRules.postCode}
        >
          <InputWrapper placeholder='Postal code' $borderRadius='8px' />
        </FormItem>
      </HalfPart>
    </FormInner>
  );
};

export default PersonalFormComponent;
