import styled from 'styled-components';
import {Button, Select} from 'antd';

export const Container = styled.div`
  max-width: 450px;
  margin: 50px auto;
  padding: 15px 20px;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const Inner = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h3`
  margin: 26px 0 30px;
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
`;

export const TermItem = styled.div<{marginLeft?: string}>`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-left: ${({marginLeft}) => marginLeft || '0'};
`;

export const DefinitionItem = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
`;

export const Content = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 0 40px;
  text-align: left;
`;

export const TextPart = styled.div`
  margin: 0 14px;
`;

export const Group = styled.div`
  margin-bottom: 8px;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled(Button)`
  min-width: 120px;
  height: 48px;
  padding: 12px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.lightPurple};

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }
`;
