import styled from 'styled-components';

export const Button = styled.button`
  width: 120px;
  ${(p) => p.theme.main.typography.bodyMedium};
  background-color: ${(p) => p.theme.main.colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 14px;
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 8px;

  &:disabled {
    background-color: ${(p) => p.theme.main.colors.grey};
  }

  @media (max-width: 380px) {
    padding: 10px;
  }

  @media (max-width: 360px) {
    width: 110px;
  }
`;

export const ButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.white};
  margin-right: 8px;

  @media (max-width: 360px) {
    ${(p) => p.theme.main.typography.captionMedium};
  }
`;
