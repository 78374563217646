import {AnyAction} from 'redux';
import {IProfileState} from './profile.types';
import {
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
} from './email/actions';
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from './password/actions';
import {
  GET_PROFILE_INFO_REQUEST,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from './profile/actions';
import {
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
} from './transactions/actions';

const initialState: IProfileState = {
  loading: false,
  profileData: null,
  changedPasswordResponse: null,
  userData: null,
  error: null,
};

const profileReducer = (
  state: IProfileState = initialState,
  action: AnyAction,
): IProfileState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_PROFILE_INFO_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case CHANGE_EMAIL_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case GET_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload,
        error: null,
      };

    case UPDATE_PROFILE_SUCCESS:
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changedPasswordResponse: action.payload,
        loading: false,
        error: null,
      };

    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        userData: action.payload,
      };

    // FAILURE REQUEST ACTIONS
    case GET_PROFILE_INFO_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case CHANGE_EMAIL_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
