import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  height: 256px;
  padding: 15px 20px;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: relative;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Text = styled.p`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  margin: 24px 0;
`;

export const Button = styled.button`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.white};
  background-color: ${(p) => p.theme.main.colors.brand};
  padding: 14px 24px;
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 8px;
`;
