import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Voucher: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M6 8H8V11H6V8Z' fill={fillColor} />
    <path d='M8 8V6H6V4H9V6H10V8H8Z' fill={fillColor} />
    <path d='M10 9H9V11H10V9Z' fill={fillColor} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V4C16 5.15251 15.0762 5.85628 14.3453 6.10934C13.5609 6.3809 13 7.12624 13 8C13 8.87376 13.5609 9.6191 14.3453 9.89066C15.0762 10.1437 16 10.8475 16 12V14C16 15.1046 15.1046 16 14 16H2C0.895432 16 0 15.1046 0 14V12C0 10.8475 0.923793 10.1437 1.65473 9.89066C2.43909 9.6191 3 8.87376 3 8C3 7.12624 2.43909 6.3809 1.65473 6.10934C0.923794 5.85628 0 5.15251 0 4V2ZM14 2H2V3.99732C2.00263 4.00484 2.01143 4.02257 2.03721 4.05111C2.09031 4.10987 2.18601 4.1768 2.30906 4.2194C3.87398 4.76121 5 6.24781 5 8C5 9.75219 3.87397 11.2388 2.30906 11.7806C2.18601 11.8232 2.09031 11.8901 2.03721 11.9489C2.01143 11.9774 2.00263 11.9952 2 12.0027V14H14V12.0027C13.9974 11.9952 13.9886 11.9774 13.9628 11.9489C13.9097 11.8901 13.814 11.8232 13.6909 11.7806C12.126 11.2388 11 9.75219 11 8C11 6.24781 12.126 4.76121 13.6909 4.2194C13.814 4.1768 13.9097 4.10987 13.9628 4.05111C13.9886 4.02257 13.9974 4.00484 14 3.99732V2ZM14.0009 12.0069C14.0008 12.0069 14.0002 12.0052 14 12.0027L14.0009 12.0069C14.001 12.0069 14.001 12.0075 14.0009 12.0069Z'
      fill={fillColor}
    />
  </svg>
);
