import styled from 'styled-components';
import {Select} from 'antd';

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }
`;

export const OptionIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionIconText = styled.div`
  margin-left: 10px;
`;
