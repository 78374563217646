import api from './config';
import {STANDARD_DATA} from './constants';
import {
  ItemDataCopied,
  ItemDataEdit,
} from '../state/Standardized/standardized.types';

// CREATE
const createStandardDataItem = (slug: string) =>
  api.get(`${STANDARD_DATA}/${slug}`);

const storeStandardDataItem = (slug: string, createData: ItemDataCopied) =>
  api.post(`${STANDARD_DATA}/${slug}`, {...createData});

const storeRolesItem = (slug: string) => api.post(`${STANDARD_DATA}/${slug}`);

// READ
const getStandardData = (tableName: string) =>
  api.get(`${STANDARD_DATA}/${tableName}`);

const getStandardDataItem = (slug: string) =>
  api.get(`${STANDARD_DATA}/${slug}`);

const copyStandardDataItem = (slug: string) =>
  api.get(`${STANDARD_DATA}/${slug}`);

// UPDATE
const editStandardDataItem = (slug: string) =>
  api.get(`${STANDARD_DATA}/${slug}`);

const updateStandardDataItem = (slug: string, updatedData: ItemDataEdit) =>
  api.post(`${STANDARD_DATA}/${slug}`, {...updatedData});

const updateRolesItem = (slug: string) => api.post(`${STANDARD_DATA}/${slug}`);

// DELETE
const destroyStandardDataItem = (slug: string) =>
  api.delete(`${STANDARD_DATA}/${slug}`);

// FILTER
const filterStandardData = (tableName: string) =>
  api.get(`${STANDARD_DATA}/${tableName}`);

const getStandardDataPerPage = (tableName: string, pageConfig: string) =>
  api.get(`${STANDARD_DATA}/${tableName}?${pageConfig}`);

export {
  // CREATE
  createStandardDataItem,
  storeStandardDataItem,
  storeRolesItem,
  // READ
  getStandardData,
  getStandardDataItem,
  copyStandardDataItem,
  // UPDATE
  editStandardDataItem,
  updateStandardDataItem,
  updateRolesItem,
  // DELETE
  destroyStandardDataItem,
  // FILTER
  getStandardDataPerPage,
  filterStandardData,
};
