import {FC} from 'react';
import {ButtonWrapper} from './SubmitButton.styles';
import {ISubmitButtonProps} from './SubmitButton.types';

const SubmitButton: FC<ISubmitButtonProps> = ({
  name,
  label,
  submitForm,
}: ISubmitButtonProps) => (
  <ButtonWrapper name={name} onClick={submitForm}>
    {label}
  </ButtonWrapper>
);

export default SubmitButton;
