import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Print: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill={fillColor}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3.5H12.994C14.102 3.5 15 4.387 15 5.498V9.502C14.9992 10.032 14.7882 10.54 14.4132 10.9146C14.0383 11.2892 13.53 11.4997 13 11.5V13.49C13 14.048 12.544 14.5 12.005 14.5H3.995C3.86314 14.4997 3.73264 14.4733 3.61105 14.4223C3.48947 14.3713 3.37922 14.2966 3.28668 14.2027C3.19414 14.1088 3.12115 13.9974 3.07194 13.8751C3.02273 13.7527 2.99828 13.6218 3 13.49V11.5C2.73741 11.5004 2.47733 11.449 2.23463 11.3488C1.99193 11.2485 1.77138 11.1014 1.58561 10.9158C1.39984 10.7302 1.2525 10.5098 1.15201 10.2672C1.05152 10.0246 0.999868 9.76459 1 9.502V5.498C1 4.395 1.897 3.5 3.006 3.5H4V2.499C4 1.947 4.453 1.5 4.997 1.5H11.003C11.553 1.5 12 1.943 12 2.499V3.5ZM13.5 5.5C13.6326 5.5 13.7598 5.44732 13.8536 5.35355C13.9473 5.25979 14 5.13261 14 5C14 4.86739 13.9473 4.74021 13.8536 4.64645C13.7598 4.55268 13.6326 4.5 13.5 4.5C13.3674 4.5 13.2402 4.55268 13.1464 4.64645C13.0527 4.74021 13 4.86739 13 5C13 5.13261 13.0527 5.25979 13.1464 5.35355C13.2402 5.44732 13.3674 5.5 13.5 5.5ZM4 9.5V13.25C4 13.385 4.111 13.5 4.248 13.5H11.752C11.818 13.4995 11.881 13.4729 11.9275 13.4261C11.9739 13.3792 12 13.316 12 13.25V9.5H4ZM5 3V3.5H11V3C11 2.86894 10.9486 2.7431 10.8567 2.64959C10.7649 2.55608 10.64 2.50236 10.509 2.5H5.491C5.35996 2.50236 5.23508 2.55608 5.14325 2.64959C5.05142 2.7431 4.99998 2.86894 5 3Z'
      fill={fillColor}
    />
  </svg>
);
