import {FC, ChangeEvent, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Moment} from 'moment';
import {List} from 'antd';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import {RootState} from '../../../../../state/store';
import {
  extractFilterTransactionsMap,
  extractValuesTransactionsMap,
  getFilteredTransactionsDataQueryStringRequest,
  getTransactionsDataRequest,
  setFilterTransactionsMap,
  setValuesTransactionsMap,
} from '../../../../../state';
import {useCountdown} from '../../../../hooks';
import CalendarPicker from '../CalendarPicker';
import MultiSelect from '../MultiSelect';
import SingleSelect from '../SingleSelect';
import SearchInput from '../SearchInput';
import {
  ActionItem,
  IFormValues,
  IListTreatBarProps,
} from './ListTreatBar.types';
import {BarContainer, BarItem} from './ListTreatBar.styles';
import {
  buildQueryString,
  defineGridMap,
  formFilterBarData,
  makeMultiSelectValue,
  updateMapValues,
} from './helpers';
import {
  createFormValues,
  decreaseSelectValue,
  decreaseSelectValueKey,
} from '../../../../../utils';
import {TRANSACTIONS} from '../../../../../infra/constants';

const ListTreatBar: FC<IListTreatBarProps> = ({
  buttons,
  filterTemplate,
}: IListTreatBarProps) => {
  const dispatch = useDispatch();

  const {valueMap, filterMap} = useSelector(
    (state: RootState) => state.transactionsReducer,
  );
  const initialFilterValues = filterMap || {};

  const [formValues, setFormValues] = useState<IFormValues>({});
  const [filterValues, setFilterValues] =
    useState<IFormValues>(initialFilterValues);

  const {progressMap, maxProgress, startCountdown, resetProgressMap} =
    useCountdown();

  const addMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    if (formValues[prop] || filterValues[prop]) {
      if (
        typeof formValues[prop] === 'string' ||
        typeof filterValues[prop] === 'string'
      ) {
        setFormValues((prevState) => ({
          ...prevState,
          [prop]: [formValues[prop], option.key],
        }));

        setFilterValues((prevState) => ({
          ...prevState,
          [prop]: [filterValues[prop], option.value],
        }));

        return;
      }

      setFormValues({[prop]: [...formValues[prop], option.key]});
      setFilterValues({[prop]: [...filterValues[prop], option.value]});
      return;
    }

    setFormValues((prevState) => ({...prevState, [prop]: option.key}));
    setFilterValues((prevState) => ({...prevState, [prop]: option.value}));
  };

  const removeMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    setFormValues((prevState) =>
      decreaseSelectValue(prevState, prop, option, 'key'),
    );

    setFilterValues((prevState) =>
      decreaseSelectValueKey(prevState, prop, option),
    );
  };

  const handleSingleSelectChange = (
    prop: string,
    option: OptionData | OptionGroupData,
  ) => {
    setFormValues((prevState) => ({...prevState, [prop]: option.key}));
    setFilterValues((prevState) => ({...prevState, [prop]: option.value}));

    dispatch(setFilterTransactionsMap({[prop]: option.value}));
    dispatch(setValuesTransactionsMap({[prop]: option.key}));

    const finalQueryString = buildQueryString({
      ...formValues,
      [prop]: option.key,
    });

    if (!finalQueryString) {
      dispatch(getTransactionsDataRequest());
      return;
    }

    dispatch(getFilteredTransactionsDataQueryStringRequest(finalQueryString));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    setFilterValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleStartDateChange = (startDate: Moment | null) => {
    setFilterValues((prevState) => ({
      ...prevState,
      calendar: {...prevState.calendar, start: startDate},
    }));
  };

  const handleEndDateChange = (endDate: Moment | null) => {
    setFilterValues((prevState) => ({
      ...prevState,
      calendar: {...prevState.calendar, end: endDate},
    }));
  };

  const filterTransactions = () => {
    dispatch(setFilterTransactionsMap(filterValues));
    dispatch(setValuesTransactionsMap(formValues));

    const finalQueryString = buildQueryString(formValues);

    if (!finalQueryString) {
      dispatch(getTransactionsDataRequest());
      return;
    }

    dispatch(getFilteredTransactionsDataQueryStringRequest(finalQueryString));
  };

  const clearFilter = (name: string) => () => {
    const values = updateMapValues({fieldName: name, formValues, filterValues});

    setFormValues({...values.newFormValues, [name]: ''});
    setFilterValues({...values.newFilterValues, [name]: ''});

    dispatch(extractValuesTransactionsMap(values.newFormValues));
    dispatch(extractFilterTransactionsMap(values.newFilterValues));

    startCountdown(name);
  };

  useEffect(() => {
    const initFormValues = createFormValues(filterTemplate);
    setFormValues({calendar: '', ...initFormValues, ...valueMap});
  }, []);

  useEffect(() => {
    if (filterValues.calendar?.start && filterValues.calendar?.end) {
      const dateRangeString = `${
        TRANSACTIONS.DATE_RANGE_FROM
      }=${filterValues.calendar.start.format('YYYY-MM-DD')}&${
        TRANSACTIONS.DATE_RANGE_TO
      }=${filterValues.calendar.end.format('YYYY-MM-DD')}`;

      setFormValues((prevState) => ({...prevState, calendar: dateRangeString}));
    }
  }, [filterValues]);

  useEffect(() => {
    const isCountdownCompleted = Object.values(progressMap).some(
      (itm) => itm === maxProgress,
    );

    if (isCountdownCompleted) {
      const finalQueryString = buildQueryString(formValues);

      if (!finalQueryString) {
        dispatch(getTransactionsDataRequest());
        resetProgressMap();
        return;
      }

      dispatch(getFilteredTransactionsDataQueryStringRequest(finalQueryString));
      resetProgressMap();
    }
  }, [progressMap]);

  const filterBarData = formFilterBarData(buttons, filterTemplate);

  return (
    <BarContainer>
      <List
        grid={defineGridMap(filterBarData.length)}
        dataSource={filterBarData}
        renderItem={(item: ActionItem): JSX.Element => {
          if (item.type === 'input') {
            return (
              <div style={{display: 'flex'}}>
                <div style={{width: '80%'}}>
                  <BarItem>
                    <SearchInput
                      label={item.label}
                      name={item.name}
                      value={filterValues[item.name] || ''}
                      handleChange={handleInputChange}
                      filterTransactions={filterTransactions}
                    />
                  </BarItem>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <button type='button' onClick={clearFilter(item.name)}>
                    Clear
                  </button>
                  <p style={{marginLeft: 10}}>{progressMap[item.name] || 0}</p>
                </div>
              </div>
            );
          }

          if (item.type === 'select' && item.multi) {
            const initialValue = makeMultiSelectValue(filterValues[item.name]);

            return (
              <div style={{display: 'flex'}}>
                <div style={{width: '80%'}}>
                  <BarItem>
                    <MultiSelect
                      options={item.options}
                      name={item.name}
                      initialValue={initialValue}
                      addMultiSelectValue={addMultiSelectValue}
                      removeMultiSelectValue={removeMultiSelectValue}
                      filterTransactions={filterTransactions}
                    />
                  </BarItem>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <button type='button' onClick={clearFilter(item.name)}>
                    Clear
                  </button>
                  <p style={{marginLeft: 10}}>{progressMap[item.name] || 0}</p>
                </div>
              </div>
            );
          }

          if (item.type === 'select' && !item.multi) {
            return (
              <div style={{display: 'flex'}}>
                <div style={{width: '80%'}}>
                  <BarItem>
                    <SingleSelect
                      options={item.options}
                      name={item.name}
                      value={filterValues[item.name] || 'Filter by'}
                      handleSingleSelectChange={handleSingleSelectChange}
                    />
                  </BarItem>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <button type='button' onClick={clearFilter(item.name)}>
                    Clear
                  </button>
                  <p style={{marginLeft: 10}}>{progressMap[item.name] || 0}</p>
                </div>
              </div>
            );
          }

          return (
            <div style={{display: 'flex'}}>
              <div style={{width: '80%'}}>
                <BarItem>
                  <CalendarPicker
                    startDate={filterValues.calendar?.start || null}
                    endDate={filterValues.calendar?.end || null}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    filterTransactions={filterTransactions}
                  />
                </BarItem>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <button type='button' onClick={clearFilter('calendar')}>
                  Clear
                </button>
                <p style={{marginLeft: 10}}>{progressMap.calendar || 0}</p>
              </div>
            </div>
          );
        }}
      />
    </BarContainer>
  );
};

export default ListTreatBar;
