import {
  IReqSendPaymentField,
  ISuccessPaymentSendPayload,
} from './payment.types';

// FORM_SEND_PAYMENT_REQUEST TYPES & ACTIONS
export const FORM_SEND_PAYMENT_REQUEST: string = 'FORM_SEND_PAYMENT_REQUEST';

export const formSendPaymentRequest = (
  payload: IReqSendPaymentField,
): {type: string; payload: IReqSendPaymentField} => ({
  type: FORM_SEND_PAYMENT_REQUEST,
  payload,
});

// SET_CURRENT_METHOD_KEY TYPES & ACTIONS
export const SET_CURRENT_METHOD_KEY: string = 'SET_CURRENT_METHOD_KEY';

export const setCurrentMethodKey = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_CURRENT_METHOD_KEY,
  payload,
});

// SET_PREFERRED_METHOD TYPES & ACTIONS
export const SET_PREFERRED_METHOD: string = 'SET_PREFERRED_METHOD';

export const setPreferredMethod = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_PREFERRED_METHOD,
  payload,
});

// SEND_PAYMENT TYPES & ACTIONS
export const SEND_PAYMENT_REQUEST: string = 'SEND_PAYMENT_REQUEST';
export const SEND_PAYMENT_SUCCESS: string = 'SEND_PAYMENT_SUCCESS';
export const SEND_PAYMENT_FAILURE: string = 'SEND_PAYMENT_FAILURE';

export const sendPaymentRequestAction = (): {type: string} => ({
  type: SEND_PAYMENT_REQUEST,
});

export const sendPaymentSuccessAction = (
  payload: ISuccessPaymentSendPayload,
) => ({
  type: SEND_PAYMENT_SUCCESS,
  payload,
});

export const sendPaymentFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_PAYMENT_FAILURE,
  payload,
});
