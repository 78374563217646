import {AnyAction} from 'redux';
import {
  GET_BADGE_COUNT_REQUEST,
  GET_BADGE_COUNT_SUCCESS,
  GET_BADGE_COUNT_FAILURE,
  CHANGE_STATUS_NOTIFICATION_REQUEST,
  CHANGE_STATUS_NOTIFICATION_SUCCESS,
  CHANGE_STATUS_NOTIFICATION_FAILURE,
} from './badgeCount.actions';
import {IBadgeCountState} from './badgeCount.types';

const initialState: IBadgeCountState = {
  loading: false,
  badgeCountMap: null,
  changeStatusNotificationRes: null,
  error: null,
};

const badgeCountReducer = (
  state: IBadgeCountState = initialState,
  action: AnyAction,
): IBadgeCountState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_BADGE_COUNT_REQUEST:
    case CHANGE_STATUS_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_BADGE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        badgeCountMap: action.payload,
        error: null,
      };

    case CHANGE_STATUS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        changeStatusNotificationRes: action.payload,
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_BADGE_COUNT_FAILURE:
    case CHANGE_STATUS_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default badgeCountReducer;
