import {render} from 'react-dom';
import {Provider} from 'react-redux';
import IntlProviderWrapper from './IntlProviderWrapper';
import store from './state/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './view/styles/global.css';

render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <App />
    </IntlProviderWrapper>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
