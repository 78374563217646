import {AnyAction} from 'redux';
import {
  GET_FORM_VIEW_REQUEST,
  GET_FORM_VIEW_SUCCESS,
  GET_FORM_VIEW_FAILURE,
  SEND_FORM_REQUEST,
  SEND_FORM_SUCCESS,
  SEND_FORM_FAILURE,
  CLEAR_FORM_VIEW_RES,
  CLEAR_SEND_FORM_RES,
  CLEAR_ALL_FORM_RES,
} from './formConstructor.actions';
import {IFormConstructorState} from './formConstructor.types';

const initialState: IFormConstructorState = {
  loading: false,
  formViewMap: null,
  sendFormRes: null,
  apiUrl: undefined,
  error: null,
};

const formConstructorReducer = (
  state: IFormConstructorState = initialState,
  action: AnyAction,
): IFormConstructorState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_FORM_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        apiUrl: action.payload,
      };

    case SEND_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_FORM_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        formViewMap: action.payload,
        error: null,
      };

    case SEND_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        sendFormRes: action.payload,
        error: null,
      };

    // MISC ACTIONS
    case CLEAR_FORM_VIEW_RES:
      return {
        ...state,
        formViewMap: null,
      };

    case CLEAR_SEND_FORM_RES:
      return {
        ...state,
        sendFormRes: null,
      };

    case CLEAR_ALL_FORM_RES:
      return {
        ...state,
        formViewMap: null,
        sendFormRes: null,
        apiUrl: undefined,
      };

    // FAILURE REQUEST ACTIONS
    case GET_FORM_VIEW_FAILURE:
    case SEND_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default formConstructorReducer;
