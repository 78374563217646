import styled from 'styled-components';
import {Collapse} from 'antd';

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: 0;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 24px !important;
  }

  .ant-collapse-content {
    border-top: 0;
    border-radius: 8px !important;
  }

  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  border: 0;
`;

export const StyledPanel = styled(Collapse.Panel)``;
