import {FC} from 'react';
import {Select} from 'antd';
import {Down} from '../../../../../static/images';
import {
  StyledSelect,
  OptionIconWrapper,
  OptionIconText,
} from './FormSelect.styles';
import {IFormSelectProps} from './FormSelect.types';
import {defineIcon} from '../PaymentProvider/defineIcon';

const {Option} = Select;

const FormSelect: FC<IFormSelectProps> = ({
  keyProp,
  valueSelect,
  placeholder,
  options,
  handleSelectChange,
}: IFormSelectProps) => (
  <StyledSelect
    value={valueSelect}
    placeholder={placeholder}
    optionFilterProp='key'
    suffixIcon={<Down />}
    onSelect={(value, option) => {
      handleSelectChange(keyProp, option);
    }}
  >
    {options.map((option) => (
      <Option key={option[0]} value={option[0]}>
        {keyProp === 'method' ? (
          <OptionIconWrapper>
            {defineIcon(option[1].toLowerCase())}
            <OptionIconText>{option[1]}</OptionIconText>
          </OptionIconWrapper>
        ) : (
          option[1]
        )}
      </Option>
    ))}
  </StyledSelect>
);

export default FormSelect;
