import {
  TypeView,
  IFormFields,
  IFormItem,
} from '../../state/FormConstructor/formConstructor.types';

interface ICreateFormItemsArgs {
  formItems: IFormItem[];
  typeView: TypeView;
}

// eslint-disable-next-line import/prefer-default-export
export const createFormItems = (args: ICreateFormItemsArgs): IFormFields => {
  const {formItems, typeView} = args;
  const buttonsList = formItems.filter((itm) => itm.type === 'button');

  const formFieldsRaw = formItems.reduce((acc: IFormItem[], itm) => {
    if (itm.type !== 'button') acc.push(itm);
    return acc;
  }, []);

  const middleformFieldsRaw = Math.ceil(formFieldsRaw.length / 2);

  const formFiledsList =
    typeView === TypeView.Popup && formFieldsRaw.length >= 4
      ? [
          formFieldsRaw.slice(0, middleformFieldsRaw),
          formFieldsRaw.slice(middleformFieldsRaw),
        ]
      : [formFieldsRaw];

  return {
    fields: formFiledsList,
    buttons: buttonsList,
  };
};
