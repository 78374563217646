import styled from 'styled-components';
import {Button} from 'antd';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1500px) {
    flex-wrap: wrap;
  }
`;

export const PageInner = styled.div`
  width: 100%;

  @media (max-width: 1500px) {
    margin-bottom: 32px;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const MainTitle = styled.h2`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
`;

export const MainContainer = styled.div`
  background-color: ${(p) => p.theme.main.colors.white};
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);

  @media (max-width: 400px) {
    padding: 16px;
  }
`;

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const IconWraper = styled.div`
  width: 48px;
  height: 48px;
`;

export const Content = styled.div`
  margin-left: 54px;

  @media (max-width: 840px) {
    margin-left: 0;
  }
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.subHeading};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 8px;
`;

export const SubTitle = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
`;

export const TextPart = styled.div`
  margin-left: 16px;
`;

export const ButtonWrapper = styled(Button)`
  height: 48px;
  padding: 12px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.lightPurple};

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;

  & .ant-spin-dot-item {
    background-color: ${(p) => p.theme.main.colors.white};
  }
`;
