import {IStandardizedDataItemCopy} from '../standardized.types';

// INITIAL_GET_STANDARD_DATA TYPES & ACTIONS
export const INITIAL_GET_STANDARD_DATA_REQUEST: string =
  'INITIAL_GET_STANDARD_DATA_REQUEST';
export const INITIAL_GET_STANDARD_DATA_SUCCESS: string =
  'INITIAL_GET_STANDARD_DATA_SUCCESS';
export const INITIAL_GET_STANDARD_DATA_FAILURE: string =
  'INITIAL_GET_STANDARD_DATA_FAILURE';

export const initialGetStandardDataRequestAction = (): {type: string} => ({
  type: INITIAL_GET_STANDARD_DATA_REQUEST,
});

export const initialGetStandardDataSuccessAction = (
  payload: IStandardizedDataItemCopy,
) => ({
  type: INITIAL_GET_STANDARD_DATA_SUCCESS,
  payload,
});

export const initialGetStandardDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: INITIAL_GET_STANDARD_DATA_FAILURE,
  payload,
});

// GET_STANDARD_DATA TYPES & ACTIONS
export const GET_STANDARD_DATA_REQUEST: string = 'GET_STANDARD_DATA_REQUEST';
export const GET_STANDARD_DATA_SUCCESS: string = 'GET_STANDARD_DATA_SUCCESS';
export const GET_STANDARD_DATA_FAILURE: string = 'GET_STANDARD_DATA_FAILURE';

export const getStandardDataRequestAction = (): {type: string} => ({
  type: GET_STANDARD_DATA_REQUEST,
});

export const getStandardDataSuccessAction = (
  payload: IStandardizedDataItemCopy,
) => ({
  type: GET_STANDARD_DATA_SUCCESS,
  payload,
});

export const getStandardDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_STANDARD_DATA_FAILURE,
  payload,
});

// GET_STANDARD_DATA_PER_PAGE TYPES & ACTIONS
export const GET_STANDARD_DATA_PER_PAGE_REQUEST: string =
  'GET_STANDARD_DATA_PER_PAGE_REQUEST';
export const GET_STANDARD_DATA_PER_PAGE_SUCCESS: string =
  'GET_STANDARD_DATA_PER_PAGE_SUCCESS';
export const GET_STANDARD_DATA_PER_PAGE_FAILURE: string =
  'GET_STANDARD_DATA_PER_PAGE_FAILURE';

export const getStandardDataPerPageRequestAction = (): {type: string} => ({
  type: GET_STANDARD_DATA_PER_PAGE_REQUEST,
});

export const getStandardDataPerPageSuccessAction = (
  payload: IStandardizedDataItemCopy,
) => ({
  type: GET_STANDARD_DATA_PER_PAGE_SUCCESS,
  payload,
});

export const getStandardDataPerPageFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_STANDARD_DATA_PER_PAGE_FAILURE,
  payload,
});

// GET_STANDARD_DATA_ITEM TYPES & ACTIONS
export const GET_STANDARD_DATA_ITEM_REQUEST: string =
  'GET_STANDARD_DATA_ITEM_REQUEST';
export const GET_STANDARD_DATA_ITEM_SUCCESS: string =
  'GET_STANDARD_DATA_ITEM_SUCCESS';
export const GET_STANDARD_DATA_ITEM_FAILURE: string =
  'GET_STANDARD_DATA_ITEM_FAILURE';

export const getStandardDataItemRequestAction = (): {type: string} => ({
  type: GET_STANDARD_DATA_ITEM_REQUEST,
});

export const getStandardDataItemSuccessAction = (payload: any) => ({
  type: GET_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const getStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_STANDARD_DATA_ITEM_FAILURE,
  payload,
});

// COPY_STANDARD_DATA_ITEM TYPES & ACTIONS
export const COPY_STANDARD_DATA_ITEM_REQUEST: string =
  'COPY_STANDARD_DATA_ITEM_REQUEST';
export const COPY_STANDARD_DATA_ITEM_SUCCESS: string =
  'COPY_STANDARD_DATA_ITEM_SUCCESS';
export const COPY_STANDARD_DATA_ITEM_FAILURE: string =
  'COPY_STANDARD_DATA_ITEM_FAILURE';

export const copyStandardDataItemRequestAction = (): {type: string} => ({
  type: COPY_STANDARD_DATA_ITEM_REQUEST,
});

export const copyStandardDataItemSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: COPY_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const copyStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: COPY_STANDARD_DATA_ITEM_FAILURE,
  payload,
});
