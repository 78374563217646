// GET_USER_DATA TYPES & ACTIONS
export const GET_USER_DATA_REQUEST: string = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS: string = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE: string = 'GET_USER_DATA_FAILURE';

export const getUserDataRequestAction = (): {type: string} => ({
  type: GET_USER_DATA_REQUEST,
});

export const getUserDataSuccessAction = (payload: any) => ({
  type: GET_USER_DATA_SUCCESS,
  payload,
});

export const getUserDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_USER_DATA_FAILURE,
  payload,
});
