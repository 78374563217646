import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Incomplete: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C7.57517 16 7.15742 15.9668 6.74935 15.9027C6.20376 15.8169 5.83098 15.3051 5.91672 14.7595C6.00246 14.214 6.51426 13.8412 7.05984 13.9269C7.36557 13.975 7.67952 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C7.67952 2 7.36557 2.02504 7.05984 2.07308C6.51426 2.15882 6.00246 1.78605 5.91672 1.24046C5.83098 0.694868 6.20376 0.183074 6.74935 0.0973325C7.15742 0.0332028 7.57517 0 8 0Z'
      fill={fillColor}
    />
    <path
      d='M3.29683 1.5279C3.74338 1.20291 4.36882 1.30145 4.69381 1.748C5.0188 2.19454 4.92026 2.81999 4.47371 3.14498C3.96446 3.5156 3.5156 3.96446 3.14498 4.47371C2.81999 4.92025 2.19455 5.0188 1.748 4.69381C1.30146 4.36882 1.20291 3.74337 1.5279 3.29683C2.02138 2.61877 2.61877 2.02138 3.29683 1.5279Z'
      fill={fillColor}
    />
    <path
      d='M0.097332 6.74935C0.183074 6.20376 0.694868 5.83098 1.24046 5.91672C1.78605 6.00246 2.15882 6.51426 2.07308 7.05984C2.02504 7.36557 2 7.67952 2 8C2 8.32048 2.02504 8.63443 2.07308 8.94016C2.15882 9.48574 1.78605 9.99754 1.24046 10.0833C0.694868 10.169 0.183074 9.79624 0.097332 9.25065C0.0332026 8.84258 0 8.42483 0 8C0 7.57517 0.0332022 7.15742 0.097332 6.74935Z'
      fill={fillColor}
    />
    <path
      d='M1.5279 12.7032C1.20291 12.2566 1.30146 11.6312 1.748 11.3062C2.19454 10.9812 2.81999 11.0797 3.14498 11.5263C3.5156 12.0355 3.96446 12.4844 4.47371 12.855C4.92025 13.18 5.0188 13.8055 4.69381 14.252C4.36882 14.6985 3.74337 14.7971 3.29683 14.4721C2.61877 13.9786 2.02138 13.3812 1.5279 12.7032Z'
      fill={fillColor}
    />
  </svg>
);
