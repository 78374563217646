import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../state/store';
import {getBadgeCountRequest, setPageTitleAction} from '../../../../state';
import {
  EnvelopeCircleTransparent,
  ProfileCircle,
  VerificationCircle,
} from '../../../static/images';
import EmailForm from './EmailForm';
import PanelHeader from './PanelHeader';
import PasswordForm from './PasswordForm';
import PersonalForm from './PersonalForm';
import {StyledCollapse, StyledPanel} from './Settings.styles';

const Settings = () => {
  const dispatch = useDispatch();
  const {data} = useSelector((state: RootState) => state.appReducer);
  const {email} = data.navbar.user;
  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  const [collapsedKeysList, setCollapsedKeysList] = useState<string | string[]>(
    [],
  );

  useEffect(() => {
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  useEffect(() => {
    dispatch(setPageTitleAction('Settings'));
  }, []);

  return (
    <div>
      <StyledCollapse key='email' onChange={(key) => setCollapsedKeysList(key)}>
        <StyledPanel
          key='email'
          showArrow={false}
          header={
            <PanelHeader
              title='Email address'
              description={email}
              icon={<EnvelopeCircleTransparent />}
              panelId='email'
              collapsedKeysList={collapsedKeysList}
            />
          }
        >
          <div>
            <EmailForm />
          </div>
        </StyledPanel>
      </StyledCollapse>
      <StyledCollapse
        key='personal'
        onChange={(key) => setCollapsedKeysList(key)}
      >
        <StyledPanel
          key='personal'
          showArrow={false}
          header={
            <PanelHeader
              title='Personal information'
              description='Edit your contact details'
              icon={<ProfileCircle />}
              panelId='personal'
              collapsedKeysList={collapsedKeysList}
            />
          }
        >
          <div>
            <PersonalForm />
          </div>
        </StyledPanel>
      </StyledCollapse>
      <StyledCollapse
        key='password'
        onChange={(key) => setCollapsedKeysList(key)}
      >
        <StyledPanel
          key='password'
          showArrow={false}
          header={
            <PanelHeader
              title='Change password'
              description='*********'
              icon={<VerificationCircle />}
              panelId='password'
              collapsedKeysList={collapsedKeysList}
            />
          }
        >
          <div>
            <PasswordForm />
          </div>
        </StyledPanel>
      </StyledCollapse>
    </div>
  );
};

export default Settings;
