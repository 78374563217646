const VoucherTerms = () => (
  <section className='section'>
    <div className='container-terms'>
      <div>
        <h1 className="main-title terms-title">Voucher Terms</h1>
        <p className="terms-modified-mark">Last modified:  05.10.2021</p>
        <p className="terms-description">Peratera (New Zealand) Limited, a company incorporated in New Zealand (Blixti/ we/ us) provides a software platform (Platform) for customers (you/your/Customer) to purchase Vouchers via the Platform to redeem against Products from participating Suppliers.</p>
        <p className="terms-description">You agree that you shall be bound by these terms and conditions (Voucher Terms) in relation to your purchase and use of Vouchers. Please read these Voucher Terms carefully as they contain important information about your rights and responsibilities when using purchasing and using Vouchers.</p>
        <p className="terms-description">You will be deemed to have accepted and will be bound by these Voucher Terms by when you purchase a Voucher. As the provider of the Platform, we do not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any products or services other than our Services. When you Redeem a Voucher against a Product, you are entering into a contract directly with the Supplier. We do not become a party to or participant in, any contractual relationship between you and the Supplier. We are not acting as an agent in any capacity for you or a Supplier.</p>
        <p className="terms-description">Without limiting the way in which you may become bound by this Agreement, you will be deemed to have accepted and will be bound by the terms and conditions of this Agreement by signing a document agreeing to be bound by this Agreement, by accepting this Agreement by conduct such as by checking the &quot;I agree to the Terms and Conditions&quot; checkbox and/or clicking the &quot;Sign Up&quot; button on your computer screen to electronically indicate your acceptance of this Agreement, or by proceeding with the any use of the Services. For that reason, this Agreement is important, and you should ensure that you read this Agreement carefully and contact us with any questions before you use the Platform and Services.</p>
        <p className="terms-description">You warrant that:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">you are at least 18 years old;</p></li>
          <li className="terms-list__item"><p className="terms-description">you are legally capable of entering into binding contracts;</p></li>
          <li className="terms-list__item"><p className="terms-description">you will abide by the terms and conditions of the Platform as contained in this Agreement;</p></li>
          <li className="terms-list__item"><p className="terms-description">you will not use the Services or the Platform for any unlawful purpose; and</p></li>
          <li className="terms-list__item"><p className="terms-description">all information supplied by you is accurate, true and up to date.</p></li>
        </ul>
        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Definitions</h3> 

          <h4 className="terms-description terms-description--heading">Account</h4>
          <p className="terms-description">means an active an up-to-date account with us.</p>

          <h4 className="terms-description terms-description--heading">Customer</h4>
          <p className="terms-description">means a person who uses the Platform to purchase a Voucher and/or Redeem a Voucher against a Product.</p>

          <h4 className="terms-description terms-description--heading">Platform</h4>
          <p className="terms-description">means the Blixti platform which delivers the Services to you.</p>

          <h4 className="terms-description terms-description--heading">Redeem</h4>
          <p className="terms-description">means your act of redeeming a Voucher against the purchase of a Product.</p>

          <h4 className="terms-description terms-description--heading">Services</h4>
          <p className="terms-description">means our services we offer via our Platform from time-to-time including the purchase of Vouchers and the maintenance of your Account.</p>

          <h4 className="terms-description terms-description--heading">Site</h4>
          <p className="terms-description">means the site that hosts the Platform.</p>

          <h4 className="terms-description terms-description--heading">Supplier</h4>
          <p className="terms-description">means a business that offers Products that you are able to Redeem a Voucher against.</p>

          <h4 className="terms-description terms-description--heading">Products</h4>
          <p className="terms-description">means the products, goods or services, offered by a Supplier to Customers.</p>

          <h4 className="terms-description terms-description--heading">Product Cost</h4>
          <p className="terms-description">means the total value of a Product which you Redeem a Voucher against for the purchase of that Product.</p>

          <h4 className="terms-description terms-description--heading">Product Terms</h4>
          <p className="terms-description">means the terms, conditions and restrictions that are specific to a Product which are stated by the Supplier.</p>

          <h4 className="terms-description terms-description--heading">Voucher</h4>
          <p className="terms-description">means a pre-paid value store which a Customer can apply towards the purchase of a Product.</p>

          <h4 className="terms-description terms-description--heading">Voucher Value</h4>
          <p className="terms-description">means the aggregate of your Vouchers at any particular point in time.</p>
        </section>

        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Our services</h3> 
          <p className="terms-description">Our Vouchers are sold by us or by a network of retail outlets authorized by us.  Our prepaid Vouchers are sold for face value. Our vouchers show an expiry date on the voucher receipt. They allow you to make payments on the websites of our Businesses through the online Blixti payment form.</p>
          <p className="terms-description">Subject to the terms and conditions of this Agreement, the provision of the Services which we supply constitute our only obligation to you.</p>
          <p className="terms-description">You agree that we may modify the Services at any time, and discontinue the Services (or part thereof) at any time. We may also restrict your use of some or all Services.  If you have pre-paid for any Vouchers and we discontinue the Services or terminate your Account, we will refund the balance of your Voucher Value at that time.</p>
          <p className="terms-description">You acknowledge and agree that Blixti does not guarantee that the Platform and Services will always be accurate, reliable, or error-free.</p>
          <p className="terms-description">Other than as expressly provided in this Agreement, Blixti does not accept any responsibility, and disclaims all and any liability for, any dispute between you and a Supplier in connection with the Platform or any related supply of Products or the redemption or otherwise of a Voucher.</p>
        </section>

        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Creating an account with us</h3>
          <p className="terms-description">You are required to create an Account on the Platform in order to use some or all of the Services including the purchase of Vouchers.  Your Account will only be accessible via the Platform.</p>
          <p className="terms-description">When you create an Account with us you agree:</p>
          <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">that the Account will be created using our online sign-up process, or any other method specified by us from time to time;</p></li>
            <li className="terms-list__item"><p className="terms-description">to keep confidential and secure any password used to access the Account;</p></li>
            <li className="terms-list__item"><p className="terms-description">that you warrant that all information provided by you to us in the setup of the Account is true and correct in every detail; and</p></li>
            <li className="terms-list__item"><p className="terms-description">that you will only use the Account for the purposes of using the Services, and for no other purpose.</p></li>
          </ul>
          <p className="terms-description">You may only open an Account if it is legal to do so in your country of residence. By opening an Account you represent and warrant to us that your opening of an Account does not violate any laws or regulations applicable to you. You shall pay us the amount of any losses we incur in connection with your breach of this section.</p>
          <p className="terms-description">During the registration process, you will be asked to select a password for your account. You agree to keep your password confidential at all times and must not disclose it to any third party. </p> 
          <p className="terms-description">You agree to be fully responsible for activities that relate to your account or your password,. If you have reason to believe that your password has been obtained by someone else without your consent, you must:</p>
          <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">reset your password; and</p></li>
            <li className="terms-list__item"><p className="terms-description">inform us immediately to disable your account.</p></li>
          </ul>
          <p className="terms-description">You will be responsible for all usage of your Account or any unauthorised use. Your username and password for your Account are non-transferrable and must be kept confidential.</p>
          <p className="terms-description">If a third party accesses your account because credentials associated with that Account are compromised, we are not liable to you in any way.  If you become aware of your credentials being compromised, you must advise us immediately.  We reserve the right to suspend or terminate your registration with us in the event of your credentials being compromised. Any undue delay in notifying us may not only affect the security of your Account but may result in you being liable for any losses as a result where your failure to notify us is intentional or negligent. If you suspect that your Account was accessed by someone else, you should also contact the police and report the incident.</p>
          <p className="terms-description">We may suspend your Account or otherwise restrict its functionality on reasonable grounds relating to the security of the Account or any of its security features or if we reasonably suspect that an unauthorised or fraudulent use of your Account has occurred or that any of its security features have been compromised. We will notify you of any suspension or restriction and of the reasons for such suspension or restriction in advance or, where we are unable to do so, immediately after the suspension or restriction has been imposed, unless notifying you would be unlawful or compromise our reasonable security interests. We will lift the suspension and/or the restriction as soon as practicable after the reasons for the suspension and/or restriction have ceased to exist.</p>
          <p className="terms-description">During and after the Account sign-up process, we reserve the right to request further information from you about you. After you have submitted an application for an Account to us, we may contact you about it, what changes or additions are required to be made to the application for an Account before we accept it, or if we have rejected your application for an Account. We may reject your application for an Account at any time in our sole and absolute discretion and prohibit you from creating an Account on the Site.</p>
          <p className="terms-description">If you have any intention to use your Account for commercial purposes, you must tell us, even if you use it also for private purposes. If you have stated that you will use your Account for private purposes only, you must tell us immediately before, at any point in the future you use it for commercial purposes by contacting Customer Service. You are using your Account for commercial purposes if you are using Vouchers for payments for or in connection with any business activity. We reserve the right to determine whether, in our reasonable opinion, you are using your Account for commercial purposes. There are other terms that bind you if you use your Account for commercial purposes. If you are in any doubt about whether or not an activity amounts to a commercial activity, you should contact Customer Service.</p>
        </section>

        <section className="terms-section">
           <h3 className="section__middle-title terms-title">Platform licence</h3>
           <p className="terms-description">Blixti grants to you a non-exclusive, non-transferable, revocable licence (Licence) to use the Platform subject to the terms of this Agreement.</p>
          <p className="terms-description">Notwithstanding clause 4.1, we may refuse to grant a Licence to any person, for any reason whatsoever in its sole discretion.  We reserve the right to revoke a licence granted to any person under this clause at any time in accordance with this Agreement.</p>
          <p className="terms-description">You may not sublicense, deliver, transfer or assign the Licence to any other person.</p>
          <p className="terms-description">In addition to your obligations under this Agreement, the Licence is subject to the following terms, where you agree not to:</p>
           <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">interrupt or attempt to interrupt the operation of the Platform or the Site in any way, or use the Platform in a manner that adversely affects the availability of its resources to other suppliers or Customers;</p></li>
            <li className="terms-list__item"><p className="terms-description">use the Platform for any illegal purpose or in any manner that is inconsistent with this Agreement; and</p></li>
            <li className="terms-list__item"><p className="terms-description">modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from or offer for sale any information contained on, or obtained from the Site or the Platform.</p></li>
          </ul>
          <p className="terms-description">By using the Site, you acknowledge and agree that:</p>
           <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">we have the absolute right to change, suspend or remove any Service or other information on the Platform at any time;</p></li>
            <li className="terms-list__item"><p className="terms-description">from time to time, the Platform may be unavailable for maintenance and/or updates, or due to unexpected technical issues; and</p></li>
            <li className="terms-list__item"><p className="terms-description">we have provided no uptime guarantees in relation to the Platform.</p></li>
          </ul>
        </section>

        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Payment for voucher</h3>
          <p className="terms-description">When a sale of a Voucher to you is completed via the Platform, the Voucher will:</p>
           <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">be sold by us you; and</p></li>
            <li className="terms-list__item"><p className="terms-description">Redeemed against Products at which time you and the Supplier will form a legally binding agreement between each other on the terms of the Product Terms.</p></li>
          </ul>
           <p className="terms-description">Payments will be made by you under this Agreement:</p>
           <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">via third-party payment platform or any other means of payment provided by us via the Platform from time to time (Payment Processor); and</p></li>
            <li className="terms-list__item"><p className="terms-description">subject to acceptance of, and in accordance with, any credit account terms and conditions or terms and conditions of use of the Payment Processor.</p></li>
          </ul>
           <p className="terms-description">As a condition of us enabling payment processing services through the Payment Processor, you agree to provide us with accurate and complete information about you, and you authorise us to share such info with the Payment Processor together with transaction information related to your use of the payment processing services provided by the Payment Processor.</p>
          <p className="terms-description">Vouchers are sold in various currencies according to the country where they are distributed. In case you make a payment in a different currency, your Voucher will be debited according to the current exchange rate plus an exchange fee.</p>
          <p className="terms-description">If you purchase a Voucher using a Payment Processor that may be subject to a right to claim funds back (a Chargeback) such as (but not limited to) credit or debit card or direct debit, you declare that you will not exercise such Chargeback other than for unauthorised use of the Payment Processor or for a breach by us of these Terms of Use which would result in you having a right to a refund of the Voucher. Otherwise, you may not Chargeback any Voucher or allow Chargeback of any upload transaction for reasons for which we are not responsible including (but not limited to) disputes with Suppliers for damaged or non-delivered Products or insufficient balance on the Payment Processor account. We reserve the right to charge you fees and expenses we incur in connection with such Chargeback and any action undertaken to challenge the same.</p>
        </section>

        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Voucher terms</h3>
          <p className="terms-description">You may use a Voucher to purchase a Product via a Supplier.  Once you authorise payment, we facilitate the payment or transfer of funds using your Voucher.  The value (or part thereof) of the Product, once Redeemed, will be deducted from the Voucher Value.  If the Voucher Value is not sufficient to pay the entire purchase price, then you will need to pay the balance by other means provided (which may include you purchasing another Voucher for the balance) or not proceed with the purchase of the Product.</p>
          <p className="terms-description">The supply of a Product which is purchased by the Redemption of a Voucher, is always subject to the terms and conditions of the Product Terms.  You should read the Product Terms carefully prior to Redeeming a Voucher against that Product.</p>
          <p className="terms-description">Product Terms may require the Product to be supplied within a specific period of time from the date of Redemption or supplied on a specific date to you (Nominated Date).  If you cannot accept the supply of the Product on the Nominated Date, you should contact the Supplier directly.  A Supplier may:</p>
          <ul className="terms-list">
            <li className="terms-list__item"><p className="terms-description">agree to extend the Nominated Date for the supply of the Product; or</p></li>
            <li className="terms-list__item"><p className="terms-description">agree to cancel the supply of the Service in which case you will be credited the Product Cost.</p></li>
          </ul>
          <p className="terms-description">Where the Product Cost is less that the face value of the Voucher, you will be credited the balance to Redeem against other Products.</p>
          <p className="terms-description">Vouchers are non-refundable in whole or part and cannot be exchanged for cash.  Vouchers do not earn interest.</p>
          <p className="terms-description">Where you purchase a Voucher on behalf of a third party (Voucher Recipient), it is your responsibility to ensure that the particulars of the Voucher Recipient, such as their email address, and the spelling of their name, is correct and accurate.  We are not responsible for the loss of a Voucher where you fail to correctly and accurately provide us information about the Voucher Recipient.</p>
          <p className="terms-description">Where you purchase a Voucher on behalf of a third party (Voucher Recipient), it is your responsibility to ensure that the particulars of the Voucher Recipient, such as their email address, and the spelling of their name, is correct and accurate.  We are not responsible for the loss of a Voucher where you fail to correctly and accurately provide us information about the Voucher Recipient.</p>
        </section>

        <section className="terms-section">
          <h3 className="section__middle-title terms-title">Prohibited voucher uses</h3>
          <p className="terms-description">It is strictly forbidden to use Vouchers as consideration for the sale or supply of: tobacco products, prescription drugs, drugs and drug paraphernalia, weapons (including without limitation, knives, guns, firearms or ammunition), satellite and cable TV descramblers, material which incites violence, hatred, racism or which is considered obscene, government IDs and licences including replicas and novelty items and any counterfeit products, unlicensed or illegal lotteries or gambling services (including without limitation the use of or participation in illegal gambling houses), unregistered charity services, items which encourage or facilitate illegal activities, prepaid debit cards or other stored value cards that are not associated with a particular merchant and are not limited to purchases of particular products or services, third party processing or payment aggregation products or services, multi-level marketing, pyramid selling or ponzi schemes, matrix programmes or other “get rich quick” schemes or high yield investment programmes, goods or services that infringe the intellectual property rights of a third party, un-coded/miscoded gaming, timeshares or property reservation payments. We reserve the right, in our sole discretion, to add categories of prohibited transactions by adding such categories either to these Terms of Service or an acceptable use policy published on the Site.</p>
          <p className="terms-description">It is strictly forbidden to use the Vouchers as payments to persons or entities offering illegal gambling services, including (but not limited to) illegal sports betting, casino games and poker games. We may suspend or terminate your Account at any time or refuse to execute or reverse a Voucher transaction if we believe that you directly or indirectly use or have used your Account for or in connection with illegal gambling transactions. This list is not exhaustive and it is your responsibility to ensure that you do not use our services for transactions that may be considered illegal in your jurisdiction.</p>
          <p className="terms-description">You may not use our Services if you are residing in certain countries. A list of non-serviced countries is available on the Site and updated from time to time. This list is not exhaustive and we may in our sole discretion decide to discontinue or restrict our services in other countries at any time and without prior notice. We reserve the right to suspend or terminate your Account at any time if we reasonably believe to be required to do so by law or in order to comply with recommendations issued by a relevant government authority or recognised body for the prevention of financial crime.</p>
          <p className="terms-description">It is strictly forbidden to use your Account and Vouchers for any illegal purposes including but not limited to fraud and money laundering. We will investigate and report any suspicious activity to the relevant law enforcement agency. We reserve the right to charge you in our sole discretion an administration fee for every investigation we undertake into any such suspicious activity, including where we get notified thereof by any third party whom we partner with. You are prohibited from using your Account in an attempt to abuse, exploit or circumvent the usage restrictions imposed by a Supplier on the Products it provides.</p>
          <p className="terms-description">If you conduct or attempt to conduct any transaction in violation of the prohibitions contained in this clause 7, we reserve the right to: reverse the Voucher transaction; and/or close or suspend your Account; and/or report the transaction to the relevant law enforcement agency; and/or claim damages from you; and charge you an administration fee if we apply any of the above.</p>
          <p className="terms-description">It is your and not our responsibility to ensure that you only send payments to persons or entities for the sale or supply of goods and services that you may provide or receive in compliance with any applicable laws and regulations. The mere fact that a person or entity accepts payments through us is not an indication of the legality of the supply or provision of their goods and services. If you are in doubt as to the legality of a supply or purchase, you should not continue with the payment of the good or service using a Voucher.</p>
         </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Product supply</h3>
            <p className="terms-description">All Products offered by Suppliers are subject to availability and you should enquire about availability before Redeeming a Voucher against a Product.  We do not guarantee that a Product will be available for supply to you at your preferred date and/or time and/or in the quantities you wish to purchase.</p>
            <p className="terms-description">Our Suppliers have their own cancellation policies which you agree to when you Redeem</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Complaints or problems</h3>
            <p className="terms-description">If you have a problem or a complaint when the Supplier supplies your Product to you, you should immediately notify the Supplier to resolve the problem or complaint.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Descriptions of products</h3>
            <p className="terms-description">Suppliers provide the information about their Products. The content or format of some Products may change slightly from time to time. It is the Supplier obligation to provide you with the current details about the Product.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Customer guarantees</h3>
            <p className="terms-description">Nothing in these Voucher Terms  excludes, restricts or modifies any guarantee, right or remedy that under law cannot be excluded, modified or restricted such as your statutory consumer guarantee rights.</p>
            <p className="terms-description">Subject to such legislation:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">Vouchers are sold by us to enable Customers to Redeem the face value of the Voucher/s against the purchase of Products provided by Suppliers.</p></li>
              <li className="terms-list__item"><p className="terms-description">We sell the Voucher only and the fulfilment of the Product is a contract between the Supplier and a Customer directly.  We are not a party to the fulfilment of the supply of a Product.</p></li>
              <li className="terms-list__item"><p className="terms-description">We make no warranty or representation regarding the standard of any Product to be provided to you by the Supplier.</p></li>
              <li className="terms-list__item"><p className="terms-description">We make no guarantees or warranties about the Products that are provided to you by Suppliers.</p></li>
            </ul>
             <p className="terms-description">We do not credit your account the Product Cost or refund you the value of the Voucher or exchange your Product for another Product (after you have Redeemed a Voucher) for a change of mind or errors you make when you Redeem the Voucher.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Suspension and termination</h3>
            <p className="terms-description">This Agreement commences at the earlier of:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">your acceptance of the terms of this Agreement; or</p></li>
              <li className="terms-list__item"><p className="terms-description">your first use of the Services,</p></li>
              <li className="terms-list__item"><p className="terms-description">and will continue until terminated under this clause 12 (Term).</p></li>
            </ul>
            <p className="terms-description">Blixti may terminate this Agreement at any time, and for any reason, by:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">the provision of written notice to you (which may include notice via the Platform); or</p></li>
              <li className="terms-list__item"><p className="terms-description">permanently disabling or deactivating your access to the Platform.</p></li>
            </ul>
            <p className="terms-description">Blixti may suspend your access to the Platform and use of the Services, or any part thereof, at any time, and for any reason.  Blixti will however provide you with written notice (which may include notice provided via the Platform) if it suspends your use of the Platform or Services (or part thereof) and will advise you subsequently if and when such suspension is lifted.</p>
            <p className="terms-description">You may terminate this Agreement at any time, and for any reason, upon the provision of written notice to Blixti, or by cancelling your Account (Termination Notice).  When we receive your Termination Notice we will refund your Voucher Value and terminate your Account.</p>
            <p className="terms-description">Either party may terminate this Agreement immediately (or with effect from any later date that it may nominate) by written notice to the other party if one or more Insolvency Events occurs in relation to that other party. For the purposes of this clause, Insolvency Event means, in respect of a party (other than for the purpose of solvent reconstruction or amalgamation):</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">a receiver, administrator, manager or liquidator is appointed over the party’s undertaking or assets or the party enters into any assignment, composition or arrangement with its creditors; or</p></li>
              <li className="terms-list__item"><p className="terms-description">the party is unable to pay its debts when due or is deemed unable to pay its debts under any law or suspends payment to its creditors.</p></li>
            </ul>
            <p className="terms-description">Upon the termination of this Agreement by you or by Blixti in accordance with this clause 12:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">you will be deactivated from using the Platform;</p></li>
              <li className="terms-list__item"><p className="terms-description">you must cease all use of the Platform;</p></li>
              <li className="terms-list__item"><p className="terms-description">we will pay you any outstanding Voucher Value (subject always to our right to deduct and off-set anything you owe to us).</p></li>
            </ul>
            <p className="terms-description">We reserve the right to carry out any necessary money laundering, terrorism financing, fraud or other illegal activity checks before authorising any withdrawal of your funds, including in relation to returning any funds to you after you have closed your Account.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Prohibited uses - content</h3>
            <p className="terms-description">All content and information on the Platform, including, but not limited to, messages, data, information, text, music, sound, photos, graphics, video, maps, icons, software, code or other material, as well as the infrastructure used to provide such content and information, is owned by Blixti or its third party licensors.  You agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, code, products, or Services obtained from or through the Platform. Additionally, you agree not to:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">use the Platform or its contents for any commercial purpose, other than in your capacity as a Customer;</p></li>
              <li className="terms-list__item"><p className="terms-description">use another person&lsquo;s name, account, identity or password without permission, or use the Platform while impersonating another person;</p></li>
              <li className="terms-list__item"><p className="terms-description">access, monitor or copy any content or information of the Platform using any robot, spider, scraper or other automated means or any manual process for any purpose without Blixti’s express written permission;</p></li>
              <li className="terms-list__item"><p className="terms-description">violate the restrictions in any robot exclusion headers on the Platform or bypass or circumvent other measures employed to prevent or limit access to the Platform;</p></li>
              <li className="terms-list__item"><p className="terms-description">deep-link to any portion of the Platform for any purpose apart from where expressly permitted by this Agreement; or</p></li>
              <li className="terms-list__item"><p className="terms-description">attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Blixti in connection with the Platform or the Services (except to the extent specifically permitted by law).</p></li>
            </ul>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Our intellectual property rights</h3>
            <p className="terms-description">You acknowledge and agree that, as between the parties, we own all interest in and to the Site, Customer Data, our trade names, logos, trademarks, service marks, domain names, social media identifiers, all data collected through or from the Site, all audiovisual content, video recordings, audio recordings, photographs, graphics, artwork, text or any other content created by us or at our direction, or assigned to us, and any materials, software, technology or tools used or provided by us to promote, sell/resell (as may be applicable) or distribute the Vouchers and conduct our business in connection therewith (collectively Blixti IP).</p>
            <p className="terms-description">You shall not use, sell, rent, lease, sublicense, distribute, broadcast, transmit, stream, place shift, transfer, copy, reproduce, download, time shift, display, perform, modify or timeshare the Blixti IP or any portion thereof, or use such Blixti IP as a component of or a base for products or services prepared for commercial use, sale, sublicense, lease, access or distribution.</p>
            <p className="terms-description">You shall not prepare any derivative work based on the Blixti IP or translate, reverse engineer, decompile or disassemble the Blixti IP. You shall not take any action to challenge or object to the validity of our rights in the Blixti IP or our ownership or registration thereof. Except as specifically provided in this Agreement, you and any third party assisting you with your obligations in this Agreement, are not authorized to use Blixti IP in any medium without prior written approval from our authorized representative. </p>
            <p className="terms-description">You shall not include any trade name, trademark, service mark, domain name, social media identifier, of ours or our affiliates, or any variant or misspelling thereof, in any trademark, domain name, email address, social network identifier, metadata or search engine keyword. You shall not use or display any Blixti IP in a manner that could reasonably imply an endorsement, relationship, affiliation with, or sponsorship between you or a third party and us. All rights to the Blixti IP not expressly granted in this Agreement are reserved by us.</p>
            <p className="terms-description">If:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">you provide us or any of our affiliates with feedback, suggestions, reviews, modifications, data, images, text, or other information or content about a product or service or otherwise in connection with this Agreement, any Blixti IP, or your Voucher, (collectively, Feedback); or</p></li>
              <li className="terms-list__item"><p className="terms-description">a Supplier provides us with any Feedback about your Redemption of a Voucher against a Product, then,</p></li>
              <li className="terms-list__item"><p className="terms-description">you irrevocably assigns to us all right, title, and interest in and to such Feedback.</p></li>
            </ul>
            <p className="terms-description">In the event your assignment to us is invalid for any reason, you hereby irrevocably grant us and its affiliates a perpetual, paid-up, royalty-free, nonexclusive, worldwide, irrevocable, freely transferable right and license to:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">use, reproduce, perform, display, and distribute Feedback;</p></li>
              <li className="terms-list__item"><p className="terms-description">adapt, modify, re-format, and create derivative works of Feedback for any purpose and sublicense the foregoing rights to any other person or entity.</p></li>
            </ul>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Confidentiality & privacy</h3>
            <p className="terms-description">Blixti will keep confidential all information provided by you or on your behalf which you designate in writing as confidential, and will only use or disclose such confidential information:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">for the purpose of providing or procuring Services under this Agreement;</p></li>
              <li className="terms-list__item"><p className="terms-description">with your consent;</p></li>
              <li className="terms-list__item"><p className="terms-description">in anonymized and aggregated form; or</p></li>
              <li className="terms-list__item"><p className="terms-description">as may otherwise be required or permitted by law.</p></li>
            </ul>
            <p className="terms-description">You must keep all of our content and processes that form the Platform confidential.</p>
            <p className="terms-description">Both you and Blixti agree to comply with all applicable privacy laws in dealing with any personal information or other identifying information provided by a User or a third party about a User (Personal Information). Both you and Blixti must at all times collect, use, hold, destroy, and disclose Personal Information in accordance with our privacy policy and applicable law.</p>
            <p className="terms-description">By using the Platform or the Services, you agree to:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">the terms of the Blixti privacy policy.  A copy of our privacy policy may be viewed on our website; and</p></li>
              <li className="terms-list__item"><p className="terms-description">if applicable, our standard Data Protection Agreement in relation to our processing of personal information you supply to us.  A copy of our standard Data Protection Agreement may be viewed on our website.</p></li>
            </ul>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Support</h3>
            <p className="terms-description">If you require support in relation to your use of the Platform, or any of our Services, please visit our support page at <a className="terms-link" href="https://www.blixti.com/contact">https://www.blixti.com/contact .</a></p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Compliance with third party licences</h3>
            <p className="terms-description">The Platform may incorporate components licensed to Blixti by third parties, which may be subject to their own End User Licence Agreements (Third Party Licences).</p>
            <p className="terms-description">You agree that the use of the Platform, in addition to this Agreement, will be governed by any terms and conditions specified by any Third Party Licence that applies to the Platform, including but not limited to those appended to this Agreement or separately notified to you.</p>
            <p className="terms-description">You agree to be bound by and observe all terms and conditions of any Third Party Licence and acknowledges that any breach of a Third Party Licence will entitle Blixti to terminate the Licence and exercise its rights under clause 12.</p>
          </section>

           <section className="terms-section">
            <h3 className="section__middle-title terms-title">Your indemnity</h3>
            <p className="terms-description">You indemnify and keep indemnified, Blixti, its agents, employees and officers against all loss, cost, expense or damage which Blixti, its agents, employees or officers suffer or incur, as a direct or indirect result of:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">any breach or alleged breach by you of this Agreement, or the representations and warranties made in this Agreement;</p></li>
              <li className="terms-list__item"><p className="terms-description">any mistake or misrepresentation made by you in connection with your Account application;</p></li>
              <li className="terms-list__item"><p className="terms-description">any claim for your Taxes arising from the redemption of a Voucher against Products;</p></li>
              <li className="terms-list__item"><p className="terms-description">any claim arising out of your negligence, fraud or willful misconduct;</p></li>
              <li className="terms-list__item"><p className="terms-description">any misuse of the Platform by you in breach of this Agreement;</p></li>
              <li className="terms-list__item"><p className="terms-description">your violation of any law or Blixti Intellectual Property Rights or other rights of a third party; or</p></li>
              <li className="terms-list__item"><p className="terms-description">any legal proceedings or any claim made against Blixti by a third party, which arises directly or indirectly from any Product you purchase using a Voucher.</p></li>
            </ul>
            <p className="terms-description">Any amount payable by you under the forgoing indemnity will be reduced to the extent that Blixti caused or contributed to the relevant act or event giving rise to the indemnity.</p>
            <p className="terms-description">We maintain the right to control our own defence and to choose and appoint our own defence counsel, regardless of the presence or absence of a conflict of interest between us and you. Your duty to defend and indemnify us includes the duty to pay our reasonable solicitor’s fees and costs, including any expert fees.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Liability and disclaimer</h3>
              <p className="terms-description">You acknowledge and agree that Blixti has made no representations or warranties that the Services will be error free or that a Supplier will comply with its own Product Terms.  </p>
              <p className="terms-description">You agree that Blixti will not be liable or responsible for any failure in, or delay to, the provision of the Services or in Blixti complying with its obligations under this Agreement where such failure or delay has arisen as a direct or indirect result of:</p>
              <ul className="terms-list">
                <li className="terms-list__item"><p className="terms-description">epidemic, pandemic, fire, earthquake, storm, flood, hurricane, inclement weather or other act of God, war, terrorism, explosion, sabotage, industrial accident or an industrial strike;</p></li>
                <li className="terms-list__item"><p className="terms-description">denial of service attacks, telecommunications failure, hardware failure or the failure of software provided by a third party to function in accordance with its specifications;</p></li>
                <li className="terms-list__item"><p className="terms-description">a significant demand being placed on telecommunications infrastructure, or on Blixti’s services, which is above the usual level of demand and which results in a failure of Blixti’s software and hardware to function correctly or in a timely manner;</p></li>
                <li className="terms-list__item"><p className="terms-description">the failure of any third party to fulfil any obligations to Blixti; or</p></li>
                <li className="terms-list__item"><p className="terms-description">any other circumstances or event similar to the above which is beyond the reasonable control of Blixti.</p></li>
              </ul>
            <p className="terms-description">You acknowledge and agree that Blixti has no liability whatsoever to you in relation to:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">the conduct of Supplier;</p></li>
              <li className="terms-list__item"><p className="terms-description">any negligence of Supplier or other tort committed by a Supplier;</p></li>
              <li className="terms-list__item"><p className="terms-description">any breach of default by a Supplier; or</p></li>
              <li className="terms-list__item"><p className="terms-description">any breach or default of the Product Terms by a Supplier.</p></li>
            </ul>
            <p className="terms-description">In the event any terms, conditions, representations or warranties are implied by statute, common law or equity into this Agreement which cannot be lawfully excluded (Prescribed Terms), such Prescribed Terms will apply, save that Blixti’s liability for breach of any such Prescribed Terms will be limited, to the extent permitted by law, at Blixti’s option, to any one or more of the following:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">in the case of services, the cost of supplying the services again or payment of the cost of having the services supplied again; and</p></li>
              <li className="terms-list__item"><p className="terms-description">in the case of software or other goods, the cost of replacing the goods, supplying equivalent goods or having the goods repaired, or payment of the cost of replacing the goods, supplying equivalent goods or having the goods repaired</p></li>
            </ul>
            <p className="terms-description">If Blixti’s liability for breach of any Prescribed Terms are capable of exclusion, they are hereby excluded to the fullest extent permitted by law</p>
            <p className="terms-description">To the extent permitted by law, and subject to clause 19.4, any conditions, warranties, guarantees, rights, remedies, liabilities and other terms implied or conferred by statute, custom or the general law that impose any liability or obligation on us are excluded under this Agreement.</p>
            <p className="terms-description">Subject to clause 19.4, and to the extent permitted by law, Blixti’s liability to you arising directly or indirectly under or in connection with this Agreement or otherwise in connection with or in any way relating to the Platform or any of the Services, and whether arising under any indemnity, statute, in tort (for negligence or otherwise) or on any other basis in law or equity, is limited as follows:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">Blixti excludes all liability for loss of revenue, loss of goodwill, loss of customers, loss of capital, downtime costs, loss of profit, loss of or damage to reputation, loss under or in relation to any other contract, loss of data, loss of use of data, loss of anticipated savings or benefits, or any indirect, consequential or special loss, damage, cost or expense or other claims for consequential compensation, incurred by or awarded against you under or in any way connected with this Agreement or otherwise in connection with or in any way relating to the Platform or Services; and</p></li>
              <li className="terms-list__item"><p className="terms-description">Blixti’s total aggregate liability under or in any way connected with this Agreement or otherwise in connection with or in any way relating to the Platform or Services, is otherwise limited to the greater of:</p></li>
              <li className="terms-list__item"><p className="terms-description">NZD10.00; and</p></li>
              <li className="terms-list__item"><p className="terms-description">your Voucher Value.</p></li>
            </ul>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">Amendments</h3>
            <p className="terms-description">Blixti reserves the right to revise and amend this Agreement in its discretion, as follows:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">if Blixti considers that the change is likely to benefit you or have a neutral or minor detrimental impact on you, it may make any changes immediately without notifying you except by publishing the amended Agreement (as applicable) on the Platform; and</p></li>
              <li className="terms-list__item"><p className="terms-description">if Blixti considers that the change is likely to have a significant detrimental impact on you, it will make the change after it has notified you of the change (solely by using the email address you have provided) and will display a notice on the Platform describing the change.</p></li>
            </ul>
            <p className="terms-description">Your continued use of the Platform after an amendment will mean that you agree to that amendment. You must stop using the Platform if you do not agree to an amendment.</p>
          </section>

          <section className="terms-section">
            <h3 className="section__middle-title terms-title">General clauses</h3>
            <p className="terms-description">In the interpretation of this Agreement unless the context otherwise requires:</p>
            <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">headings and words in bold type are included for convenience only and do not affect interpretation;</p></li>
              <li className="terms-list__item"><p className="terms-description">the words &quot;includes&quot; or &quot;including&quot; mean &quot;includes without limitation&quot; or &quot;including without limitation&quot;;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a word includes the singular and the plural of the word and vice versa;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a gender includes any gender;</p></li>
              <li className="terms-list__item"><p className="terms-description">if a word or phrase is defined, then other parts of speech and grammatical forms of that word or phrase have a corresponding meaning;</p></li>
              <li className="terms-list__item"><p className="terms-description">a term which refers to a person includes a person in any capacity, a body corporate, an unincorporated body (for example a society or association), a trust, a partnership, a sovereign state, a government or a government department or agency;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a document includes a reference to that document as amended, novated, supplemented, varied or replaced;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a recital, clause, paragraph, schedule, annexure or other part is a reference to an item of that type in this Agreement;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a party is a reference to a party to this Agreement and includes a reference to that party’s successors, personal legal representatives and permitted assigns;</p></li>
              <li className="terms-list__item"><p className="terms-description">a reference to a statute or regulation or a provision of a statute or regulation includes a reference to that statute, regulation or provision as amended or replaced, and a reference to a statute includes all regulations, proclamations, ordinances and by-laws made or issued under that statute; and</p></li>
              <li className="terms-list__item"><p className="terms-description">if an individual party to this Agreement consists of two or more persons, then those persons are bound both jointly and severally.</p></li>
            </ul>
            <p className="terms-description">Any notice given under this Agreement must be in writing and must be signed by the party giving the notice, or alternatively must be given via functionality contained in the Platform. Unless a later time is specified in a notice, the notice takes effect from the time it is received. A notice is taken to be received:</p>
             <ul className="terms-list">
              <li className="terms-list__item"><p className="terms-description">in the case of a notice delivered by hand, when so delivered;</p></li>
              <li className="terms-list__item"><p className="terms-description">in the case of a notice sent by pre-paid express post, on the third clear Business Day after the date of posting;</p></li>
              <li className="terms-list__item"><p className="terms-description">in the case of a notice sent by email, at the time that the email is sent, unless the sender receives a notification that the email was delayed or not received; or</p></li>
              <li className="terms-list__item"><p className="terms-description">in the case of a notice sent via functionality contained in the Platform, at the time the notice was sent,</p></li>
            </ul>
            <p className="terms-description">but if the effect of paragraphs (a) - (d) above is that a notice is taken to have been received before 9:00am or after 5:00pm on a day which is not a Saturday, Sunday or public holiday in New Zealand (Business Day), or on a day which is not a Business Day, then the notice is taken to have been received at 9:00am of the next Business Day.</p>
            <p className="terms-description">Neither party is the partner, agent, employee or representative of any other party and neither party has the power to incur any obligations on behalf of any other party.</p>
            <p className="terms-description">There are no other representations, promises, warranties, covenants or undertakings between the parties and this Agreement supersedes all previous agreements in respect of its subject matter and embodies the entire agreement between the parties.</p>
            <p className="terms-description">A provision of or a right created under this Agreement may not be waived except in writing signed by the party or parties to be bound by the waiver. No single or partial exercise by any party of any right, power or remedy under this Agreement will preclude any other or further exercise of that or any other right, power or remedy. The rights, powers or remedies provided in this Agreement are cumulative with and not exclusive of any rights, powers or remedies provided independently of this Agreement.</p>
            <p className="terms-description">If any provision of this Agreement is judged invalid or unenforceable for any reason whatsoever by a court of competent jurisdiction, such invalidity or unenforceability (unless deletion of such provision would materially adversely affect one of the parties) will not affect the operation or interpretation of any other provision of this Agreement to the extent that the invalid or unenforceable provision will be treated as severed from this Agreement.</p>
            <p className="terms-description">You must not assign or novate any of your rights or obligations under this Agreement. You agree that Blixti may however assign or novate this Agreement at any time, with or without notice to you.</p>
            <p className="terms-description">The parties acknowledge and agree that no rule of construction applies to the disadvantage of a party because that party was responsible for the preparation of this Agreement or part of it.</p>
            <p className="terms-description">This Agreement will be construed in accordance with and will be governed by the laws in force in New Zealand. Each of the parties irrevocably submits to and accepts the exclusive jurisdiction of any of New Zealand.</p>
          </section>
      </div>
    </div>
  </section>
)

export default VoucherTerms;
