import {FC} from 'react';

import ContactForm from './ContactForm';

import '../../../styles/preview.css';

const ContactPage: FC = (): JSX.Element => (
  <>
    <section className='main-section main-section-color-line contact-form contact'>
      <div className='contact-container'>
        <div className='row justify-content-center justify-content-md-between'>
          <div className='col-12 col-md-5 content-block wrapper-content-column'>
            <h3 className='main-title'>Need some help?</h3>
            <p className='subtitle'>Got a question that we haven’t answered?</p>
            <p className='subtitle'>Let’s start the conversation here.</p>
            <ul className='list-questions'>
              <li>
                <p className='section__small-description'>General inquiries:</p>
                <a
                  href='mailto:info@blixti.com'
                  rel='noopener noreferrer'
                  className='standard-description link'
                >
                  info@blixti.com
                </a>
              </li>
              <li>
                <p className='section__small-description'>Customer Support:</p>
                <a
                  href='mailto:support@blixti.com'
                  rel='noopener noreferrer'
                  className='standard-description link'
                >
                  support@blixti.com
                </a>
              </li>
            </ul>
          </div>
          <div className='col-12 col-md-7'>
            <div className='form-container form-container--contact'>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ContactPage;
