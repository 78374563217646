import {AxiosResponse} from 'axios';
import {
  declineIncompleteInvoice,
  payIncompleteInvoiceInit,
  payIncompleteInvoiceSend,
} from '../../../infra/incompleteInvoices.service';
import {ThunkActionType} from '../../store';
import {
  declineIncompleteInvoiceFailureAction,
  declineIncompleteInvoiceRequestAction,
  declineIncompleteInvoiceSuccessAction,
  payIncompleteInvoiceInitFailureAction,
  payIncompleteInvoiceInitRequestAction,
  payIncompleteInvoiceInitSuccessAction,
  payIncompleteInvoiceSendFailureAction,
  payIncompleteInvoiceSendRequestAction,
  payIncompleteInvoiceSendSuccessAction,
} from '../actions/payment.actions';
import {IPaySendMap} from '../incompleteInvoices.types';
import {getIncompleteDataRequest} from './read.requests';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraActions,
} from '../../../utils';
import {getBadgeCountRequest} from '../../BadgeCount/badgeCount.requests';

export const payIncompleteInvoiceInitRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(payIncompleteInvoiceInitRequestAction());
    return payIncompleteInvoiceInit(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: payIncompleteInvoiceInitSuccessAction,
          failureAction: payIncompleteInvoiceInitFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: payIncompleteInvoiceInitFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const payIncompleteInvoiceSendRequest =
  (paymentMap: IPaySendMap): ThunkActionType =>
  (dispatch) => {
    dispatch(payIncompleteInvoiceSendRequestAction());
    return payIncompleteInvoiceSend(paymentMap)
      .then((response: AxiosResponse) => {
        handleResponseExtraActions({
          response,
          dispatch,
          successAction: payIncompleteInvoiceSendSuccessAction,
          failureAction: payIncompleteInvoiceSendFailureAction,
          notificationAction: setNotificationState,
          extraRequests: [
            {extraRequest: getIncompleteDataRequest},
            {extraRequest: getBadgeCountRequest},
          ],
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: payIncompleteInvoiceSendFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const declineIncompleteInvoiceRequest =
  (slug: string, idInvoice: {id: number}): ThunkActionType =>
  (dispatch) => {
    dispatch(declineIncompleteInvoiceRequestAction());
    return declineIncompleteInvoice(slug, idInvoice)
      .then((response: AxiosResponse) => {
        handleResponseExtraActions({
          response,
          dispatch,
          successAction: declineIncompleteInvoiceSuccessAction,
          failureAction: declineIncompleteInvoiceFailureAction,
          notificationAction: setNotificationState,
          extraRequests: [
            {extraRequest: getIncompleteDataRequest},
            {extraRequest: getBadgeCountRequest},
          ],
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: declineIncompleteInvoiceFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
