import colors from './colors';
import shadow from './shadow';
import button from './button';
import {typography, ITypography} from './typography';

interface IStyles {
  [key: string]: string | number;
}

interface IThemesMain {
  typography: ITypography;
  colors: IStyles;
  button: IStyles;
  shadow: IStyles;
}

interface IThemes {
  main: IThemesMain;
  [key: string]: any;
}

const themes: IThemes = {
  main: {
    colors,
    typography,
    button,
    shadow,
  },
};

export default themes;
