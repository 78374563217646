import {AxiosResponse} from 'axios';
import {postProfileInformation} from '../../../infra/profile.service';
import {ThunkActionType} from '../../store';
import {getAppDataRequest} from '../../App/app.requests';
import {IProfileMap} from '../profile.types';
import {
  changeEmailSuccessAction,
  changeEmailFailureAction,
  changeEmailRequestAction,
} from './actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseExtraAction,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const changeEmailRequest =
  (updatedProfile: IProfileMap): ThunkActionType =>
  (dispatch) => {
    dispatch(changeEmailRequestAction());

    return postProfileInformation(updatedProfile)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: changeEmailSuccessAction,
          failureAction: changeEmailFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getAppDataRequest,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: changeEmailFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
