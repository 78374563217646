// EDIT_STANDARD_DATA_ITEM TYPES & ACTIONS
export const EDIT_STANDARD_DATA_ITEM_REQUEST: string =
  'EDIT_STANDARD_DATA_ITEM_REQUEST';
export const EDIT_STANDARD_DATA_ITEM_SUCCESS: string =
  'EDIT_STANDARD_DATA_ITEM_SUCCESS';
export const EDIT_STANDARD_DATA_ITEM_FAILURE: string =
  'EDIT_STANDARD_DATA_ITEM_FAILURE';
export const editStandardDataItemRequestAction = (): {type: string} => ({
  type: EDIT_STANDARD_DATA_ITEM_REQUEST,
});

export const editStandardDataItemSuccessAction = (payload: any) => ({
  type: EDIT_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const editStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: EDIT_STANDARD_DATA_ITEM_FAILURE,
  payload,
});

// UPDATE_STANDARD_DATA_ITEM TYPES & ACTIONS
export const UPDATE_STANDARD_DATA_ITEM_REQUEST: string =
  'UPDATE_STANDARD_DATA_ITEM_REQUEST';
export const UPDATE_STANDARD_DATA_ITEM_SUCCESS: string =
  'UPDATE_STANDARD_DATA_ITEM_SUCCESS';
export const UPDATE_STANDARD_DATA_ITEM_FAILURE: string =
  'UPDATE_STANDARD_DATA_ITEM_FAILURE';

export const updateStandardDataItemRequestAction = (): {type: string} => ({
  type: UPDATE_STANDARD_DATA_ITEM_REQUEST,
});

export const updateStandardDataItemSuccessAction = (payload: any) => ({
  type: UPDATE_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const updateStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: UPDATE_STANDARD_DATA_ITEM_FAILURE,
  payload,
});
