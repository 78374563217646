import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ListWrapper = styled.div<{
  widthColIncreaseScreen: number | false;
  widthColDecreaseScreen: number | false;
}>`
  .ant-row > * {
    width: ${({widthColIncreaseScreen, widthColDecreaseScreen}) => {
      if (widthColIncreaseScreen && widthColDecreaseScreen) {
        const maxColWidth = Math.max(
          widthColIncreaseScreen,
          widthColDecreaseScreen,
        );
        return `${maxColWidth}% !important`;
      }

      if (widthColIncreaseScreen && !widthColDecreaseScreen) {
        return `${widthColIncreaseScreen}% !important`;
      }

      return `${widthColDecreaseScreen}% !important`;
    }};
    max-width: ${({widthColIncreaseScreen, widthColDecreaseScreen}) => {
      if (widthColIncreaseScreen && widthColDecreaseScreen) {
        const maxColWidth = Math.max(
          widthColIncreaseScreen,
          widthColDecreaseScreen,
        );
        return `${maxColWidth}% !important`;
      }

      if (widthColIncreaseScreen && !widthColDecreaseScreen) {
        return `${widthColIncreaseScreen}% !important`;
      }

      return `${widthColDecreaseScreen}% !important`;
    }};
  }

  @media (max-width: 1200px) {
    .ant-row > * {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;
