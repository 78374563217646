// USER_LOGIN TYPES & ACTIONS
export const USER_LOGIN_REQUEST: string = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS: string = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE: string = 'USER_LOGIN_FAILURE';

export const userLoginRequestAction = (): {type: string} => ({
  type: USER_LOGIN_REQUEST,
});

export const userLoginSuccessAction = (payload: any) => ({
  type: USER_LOGIN_SUCCESS,
  payload,
});

export const userLoginFailureAction = (
  payload: string,
): {type: string; payload: string} => ({type: USER_LOGIN_FAILURE, payload});
