import styled from 'styled-components';
import {Button} from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const ButtonWrapper = styled(Button)`
  height: 48px;
  margin-right: 16px;
  padding: 12px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.white};
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-color: ${(p) => p.theme.main.colors.brand};
  background-color: ${(p) => p.theme.main.colors.brand};

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) => p.theme.main.colors.black};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    background-color: ${(p) => p.theme.main.colors.lightPurple};
  }
`;
