import api from './config';
import {PROFILE} from './constants';
import {IProfileMap, ITransactionData} from '../state/Profile/profile.types';

const getProfileInformation = () => api.get(PROFILE.SETTINGS);
const postProfileInformation = (updatedProfile: IProfileMap) =>
  api.post(PROFILE.SETTINGS, {...updatedProfile});
const getDataUser = (transactionData: ITransactionData) =>
  api.post(PROFILE.DATA_USER, {...transactionData});

export {getProfileInformation, postProfileInformation, getDataUser};
