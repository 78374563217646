import {IGetClientListResponse} from '../chat.types';

// GET_CLIENT_LIST TYPES & ACTIONS
export const GET_CLIENT_LIST_REQUEST: string = 'GET_CLIENT_LIST_REQUEST';
export const GET_CLIENT_LIST_SUCCESS: string = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_FAILURE: string = 'GET_CLIENT_LIST_FAILURE';

export const getClientListRequestAction = (): {type: string} => ({
  type: GET_CLIENT_LIST_REQUEST,
});

export const getClientListSuccessAction = (
  payload: IGetClientListResponse,
) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload,
});

export const getClientListFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_CLIENT_LIST_FAILURE,
  payload,
});

// SET_INTERLOCUTOR_ID TYPES & ACTIONS
export const SET_INTERLOCUTOR_ID: string = 'SET_INTERLOCUTOR_ID';

export const setInterlocutorId = (
  payload: number,
): {type: string; payload: number} => ({
  type: SET_INTERLOCUTOR_ID,
  payload,
});

// GLEAR_CLIENT_LIST TYPES & ACTIONS
export const CLEAR_CLIENT_LIST: string = 'CLEAR_CLIENT_LIST';

export const clearClientList = (): {type: string} => ({
  type: CLEAR_CLIENT_LIST,
});
