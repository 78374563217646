import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const DashboardPrev: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 2C7 0.895431 6.10457 0 5 0H2C0.895431 0 0 0.89543 0 2V7C0 8.10457 0.89543 9 2 9H5C6.10457 9 7 8.10457 7 7V2Z'
      fill={fillColor}
    />
    <path
      d='M7 13C7 11.8954 6.10457 11 5 11H2C0.895431 11 0 11.8954 0 13V14C0 15.1046 0.89543 16 2 16H5C6.10457 16 7 15.1046 7 14V13Z'
      fill={fillColor}
    />
    <path
      d='M9 2C9 0.895431 9.89543 0 11 0H14C15.1046 0 16 0.89543 16 2V3C16 4.10457 15.1046 5 14 5H11C9.89543 5 9 4.10457 9 3V2Z'
      fill={fillColor}
    />
    <path
      d='M16 9C16 7.89543 15.1046 7 14 7H11C9.89543 7 9 7.89543 9 9V14C9 15.1046 9.89543 16 11 16H14C15.1046 16 16 15.1046 16 14V9Z'
      fill={fillColor}
    />
  </svg>
);
