import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams, useLocation} from 'react-router-dom';
import {Spin} from 'antd';
import {socialAuthCallbackRequest} from '../../../../../state';
import {Container, Title} from './SocialAuth.styles';

const SocialAuth = () => {
  const dispatch = useDispatch();
  const {authProvider} = useParams<{authProvider: string}>();
  const {search} = useLocation();

  useEffect(() => {
    dispatch(socialAuthCallbackRequest({authProvider, params: search}));
  }, []);

  return (
    <Container>
      <Spin tip='Loading...' size='large' />
      <Title>Auth in process...</Title>
    </Container>
  );
};

export default SocialAuth;
