import {FC} from 'react';
import {Select} from 'antd';
import {SelectValue} from 'antd/lib/select';
import {FocusedInputShape} from 'react-dates';
import {Down} from '../../../../../static/images';
import {StyledSelect, TopPanelWrapper} from './CalendarTopPanel.styles';
import {periodPresetsList} from './periodPresetsList';

const {Option} = Select;

interface IProps {
  show: boolean;
  isOpenSelect: boolean;
  selectedPresetPeriod: string;
  presetDate: (period: string) => void; // eslint-disable-line
  // eslint-disable-next-line
  handleFocusedInput: (focusedShape: FocusedInputShape) => void;
  handleOpenSelect: (bool: boolean) => void; // eslint-disable-line
  handlePresetPeriod: (period: string) => void; // eslint-disable-line
}

const CalendarTopPanel: FC<IProps> = ({
  show,
  presetDate,
  handleFocusedInput,
  selectedPresetPeriod,
  isOpenSelect,
  handleOpenSelect,
  handlePresetPeriod,
}: IProps) => {
  const chooseOption = (value: SelectValue) => {
    if (!value) return;
    presetDate(value as string);
  };

  const expandSelect = () => {
    if (selectedPresetPeriod) {
      handlePresetPeriod('');
    }

    handleFocusedInput('startDate');
    handleOpenSelect(true);
  };

  return (
    <TopPanelWrapper show={show}>
      <StyledSelect
        className='calendar-select'
        placeholder='Period'
        open={isOpenSelect && !selectedPresetPeriod}
        onClick={expandSelect}
        onChange={chooseOption}
        onDropdownVisibleChange={expandSelect}
        suffixIcon={<Down />}
      >
        {periodPresetsList.map((periodPreset) => (
          <Option key={periodPreset.value} value={periodPreset.value}>
            {periodPreset.label}
          </Option>
        ))}
      </StyledSelect>
    </TopPanelWrapper>
  );
};

export default CalendarTopPanel;
