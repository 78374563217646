import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 52px;

  @media (max-width: 400px) {
    margin-top: 24px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 40px;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;

  @media (max-width: 400px) {
    padding: 10px;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const Input = styled.input`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 8px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button`
  width: 120px;
  ${(p) => p.theme.main.typography.bodyMedium};
  background-color: ${(p) => p.theme.main.colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 14px;
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 8px;

  &:disabled {
    background-color: ${(p) => p.theme.main.colors.grey};
  }

  @media (max-width: 380px) {
    padding: 10px;
  }

  @media (max-width: 360px) {
    width: 110px;
  }
`;

export const ButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.white};
  margin: 0 8px;

  @media (max-width: 360px) {
    ${(p) => p.theme.main.typography.captionMedium};
  }
`;
