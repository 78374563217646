import {FC, useState, useEffect, ChangeEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../state/store';
import {
  changeChatStatusRequest,
  deleteChatRequest,
  deleteMessageRequest,
  sendMessageRequest,
} from '../../../../state';
import ChatComponent from '../ChatComponent';
import {IDeleteMessageBody} from '../../../../state/Chat/chat.types';
import {IChatContainerProps} from './ChatContainer.types';

const ChatContainer: FC<IChatContainerProps> = ({
  formateDateTime,
}: IChatContainerProps) => {
  const dispatch = useDispatch();

  const {oneChat, sendMessageRes, chatId, chatName} = useSelector(
    (state: RootState) => state.chatReducer,
  );

  const [messageValue, setMessageValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMessageValue(event.target.value);
  };

  const replyMessage = (): void => {
    dispatch(sendMessageRequest({message: messageValue, chat_id: chatId}));
  };

  const removeMessage = (args: IDeleteMessageBody): void => {
    dispatch(
      deleteMessageRequest({
        chat_id: args.chat_id,
        message_id: args.message_id,
      }),
    );
  };

  const swapChatStatus = (status: boolean): void => {
    dispatch(changeChatStatusRequest({chat_id: chatId, status}));
  };

  const switchChatStatus = (): void => {
    switch (oneChat?.status) {
      case 'open':
        swapChatStatus(false);
        return;
      case 'closed':
        swapChatStatus(true);
        return;
      default:
        swapChatStatus(true);
    }
  };

  const removeChat = (): void => {
    dispatch(deleteChatRequest(chatId.toString()));
  };

  useEffect(() => {
    if (!sendMessageRes) return;
    setMessageValue('');
  }, [sendMessageRes]);

  return (
    <ChatComponent
      chatName={chatName}
      messageValue={messageValue}
      switchChatStatus={switchChatStatus}
      removeChat={removeChat}
      handleChange={handleChange}
      formateDateTime={formateDateTime}
      removeMessage={removeMessage}
      replyMessage={replyMessage}
    />
  );
};

export default ChatContainer;
