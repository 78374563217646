import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Spin} from 'antd';
import {RootState} from '../../../../state/store';
import {
  TypeView,
  IFormFields,
  ISendFormArgs,
} from '../../../../state/FormConstructor/formConstructor.types';
import {
  getFormViewRequest,
  setPageTitleAction,
  clearSendFormRes,
  clearAllFormRes,
  postRequest,
  deleteRequest,
} from '../../../../state';
import {useFormHandler} from '../../../hooks';
import FormCollection from './FormCollection';
import {createFormItems} from '../../../../utils';

const FormConstructor = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {formName} = useParams<{formName?: string}>();

  const {error, formViewMap, apiUrl, sendFormRes, loading} = useSelector(
    (state: RootState) => state.formConstructorReducer,
  );

  const [formFields, setFormFields] = useState<IFormFields>({
    fields: [],
    buttons: [],
  });

  const handleCancelButton = (): void => {
    history.goBack();
    dispatch(clearAllFormRes());
  };

  const sendFormAction = ({slugUrl, body}: ISendFormArgs): void => {
    dispatch(postRequest({slugUrl, body}));
  };

  const deleteRequestAction = ({slugUrl, body}: ISendFormArgs): void => {
    dispatch(deleteRequest({slugUrl, body}));
  };

  const formHook = useFormHandler({
    formViewMap,
    apiUrl,
    sendFormRes,
    actions: {sendFormAction, deleteRequestAction},
  });

  useEffect(() => {
    if (!formName) return;
    dispatch(getFormViewRequest({slugUrl: `/api/display/${formName}`}));
  }, []);

  useEffect(() => {
    if (formName || formViewMap) return;
    const formConstructorUrl = localStorage.getItem('formConstructorUrl');
    if (!formConstructorUrl) return;
    dispatch(getFormViewRequest({slugUrl: formConstructorUrl}));
  }, []);

  useEffect(() => {
    dispatch(setPageTitleAction('Form Constructor'));
  }, []);

  useEffect(
    () => () => {
      dispatch(clearAllFormRes());
    },
    [],
  );

  useEffect(() => {
    if (!formViewMap) return;
    setFormFields(
      createFormItems({formItems: formViewMap.data, typeView: TypeView.Page}),
    );
  }, [formViewMap]);

  useEffect(() => {
    if (!sendFormRes) return;
    if (!sendFormRes.type) return;

    if (sendFormRes.type === 'file') {
      window.open(sendFormRes.fileUrl);
      dispatch(clearSendFormRes());
      return;
    }

    if (sendFormRes.type === 'standardized') {
      history.push(`/s/${sendFormRes.name}`);
      dispatch(clearAllFormRes());
      return;
    }

    if (sendFormRes.type === 'redirect_inner') {
      history.push(sendFormRes.url);
      dispatch(clearAllFormRes());
      return;
    }

    if (sendFormRes.type === 'redirect_outer') {
      window.open(sendFormRes.url);
      dispatch(clearSendFormRes());
    }
  }, [sendFormRes]);

  if (error) return <div>{error}</div>;

  if (
    loading ||
    !formViewMap ||
    Object.keys(formHook.formValues).length === 0
  ) {
    return (
      <Spin
        tip='Loading...'
        spinning={
          loading ||
          !formViewMap ||
          Object.keys(formHook.formValues).length === 0
        }
        size='large'
      />
    );
  }

  return (
    <FormCollection
      typeView={TypeView.Page}
      formFields={formFields}
      formValues={formHook.formValues}
      formHasError={formHook.formHasError}
      submitForm={formHook.submitForm}
      handleValueChange={formHook.handleValueChange}
      handleCancelButton={handleCancelButton}
    />
  );
};

export default FormConstructor;
