import {FC, useState} from 'react';
import {Select} from 'antd';
import {Down} from '../../../../static/images';
import {SelectWrapper, StyledSelect} from './SingleSelect.styles';
import {ISingleSelectProps} from './SingleSelect.types';

const {Option} = Select;

const SingleSelect: FC<ISingleSelectProps> = ({
  name,
  value,
  options,
  handleSingleSelectChange,
}: ISingleSelectProps) => {
  const [isSelectInFocus, setSelectInFocus] = useState(false);

  const handleSelectInFocus = (bool: boolean) => () => setSelectInFocus(bool);

  return (
    <SelectWrapper isSelectInFocus={isSelectInFocus}>
      <StyledSelect
        className='transactions-filter-select'
        placeholder='Filter by'
        optionFilterProp='key'
        suffixIcon={<Down />}
        value={value}
        onFocus={handleSelectInFocus(true)}
        onBlur={handleSelectInFocus(false)}
        onSelect={(valueSelect, option) => {
          handleSingleSelectChange(name, option);
        }}
      >
        {Object.entries(options).map((option) => (
          <Option key={option[0]} value={option[1]}>
            {option[1]}
          </Option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
};

export default SingleSelect;
