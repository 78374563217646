const ERRORS_MESSAGES = {
  // REMOTE VALIDATION MESSAGES
  10001: 'OAuth2 inner translation error',
  10101: 'Refresh Token JWT Claims JSON could not be parsed',
  10111: 'Cannot convert Access token to JSON',
  10112: 'Cannot convert OpenID token to JSON',
  10121: 'OpenID token enhance exception',
  10201: 'Insufficient authorities, no required scopes',
  10202: 'Insufficient authorities, wrong scopes',
  21004: 'Read timed out',
  15001: 'User with email {email} already created',
  15002: 'User with email {email} is not found',
  15003: 'User with email {email}  is already activated',
  15004: 'User with email {email} is not activated but no required code',
  15005:
    'The code specified for confirmation does not match the code of the User with email {email}',
  15101: 'Unknown notification entity',
  21008: 'Unhandled exception',

  // LOCAL VALIDATION MESSAGES
  EMAIL_EXIST_VALIDATION_MESSAGE: 'Email is required!',
  ACTIVATION_CODE_EXIST_VALIDATION_MESSAGE: 'Activation code is required!',
  PASSWORD_EXIST_VALIDATION_MESSAGE: 'Password is required!',
  PASSWORD_MATCHING_VALIDATION_MESSAGE:
    'The two passwords that entered do not match!',
  MIN1_CAPITAL_LETTER_VALIDATION_MESSAGE:
    'Password must has minimum 1 capital letter!',
  MIN1_DIGIT_VALIDATION_MESSAGE: 'Password must has minimum 1 digit!',
  MIN1_LOWER_LETTER_VALIDATION_MESSAGE:
    'Password must has minimum 1 lowercase letter!',
  MIN8_CHARACTER_VALIDATION_MESSAGE: 'Password must has at least 8 letters!',
  MAX20_CHARACTER_VALIDATION_MESSAGE: 'Password must has maximum 20 letters!',
  COMPANY_NAME_EXIST_VALIDATION_MESSAGE: 'Company name is required!',
  FIELD_EXIST_VALIDATION_MESSAGE: 'Field is required!',
  NEW_EMAIL_CONFIRM_EXIST_VALIDATION_MESSAGE: 'Confirm new email is required!',
  NEW_EMAIL_MATCHING_VALIDATION_MESSAGE:
    'The two emails that entered do not match!',
  FIRST_NAME_EXIST_VALIDATION_MESSAGE: 'First name is required!',
  LAST_NAME_EXIST_VALIDATION_MESSAGE: 'Last name is required!',
  DAY_BIRTH_EXIST_VALIDATION_MESSAGE: 'Day birth is required!',
  MONTH_BIRTH_EXIST_VALIDATION_MESSAGE: 'Month birth is required!',
  YEAR_BIRTH_EXIST_VALIDATION_MESSAGE: 'Year birth is required!',
  PHONE_NUMBER_EXIST_VALIDATION_MESSAGE: 'Phone number is required!',
  COUNTRY_EXIST_VALIDATION_MESSAGE: 'Country is required!',
  CITY_EXIST_VALIDATION_MESSAGE: 'City is required!',
  ADDRESS_EXIST_VALIDATION_MESSAGE: 'Address is required!',
  POSTCODE_EXIST_VALIDATION_MESSAGE: 'Postal code is required!',
};

export default ERRORS_MESSAGES;
