// AUTH CONSTANTS
const AUTHORIZATION = {
  REGISTER: '/api/register',
  LOGIN: '/api/login',
  REFRESH_TOKEN: '/api/refreshToken',
  SOCAIL_AUTH: '/api/auth',
  CALLBACK: '/callback',
};

// APP CONSTANTS
const APP = {
  MAIN_DATA: '/api/main-data',
  COUNTRIES_LIST: '/api/countries_list',
  CONTACT_SEND: '/api/contact_send',
  PARTNER_SEND: '/api/partner_send',
};

// DASHBOARD CONSTANTS
const DASHBOARD = '/api/dashboard';

// TRANSACTIONS CONSTANTS
const TRANSACTIONS = {
  API: '/api/transactions',
  DATE_RANGE_FROM: 'date_range[date_range_from]',
  DATE_RANGE_TO: 'date_range[date_range_to]',
};

// VOUCHER CONSTANTS
const VOUCHER = '/api/voucher';

// ACCOUNTS CONSTANTS
const ACCOUNTS = '/api/accounts';

// STANDARD DATA CONSTANTS
const STANDARD_DATA = '/api';

// TOP UP CONSTANTS
const TOP_UP = {
  INIT: '/api/top_up',
};

// INCOMPLETE CONSTANTS
const INCOMPLETE = {
  API: '/api',
  API_INCOMPLETE: '/api/incomplete',
  PAY_INVOICE: '/api/invoices/pay',
  DECLINE_INVOICE: '/api/invoices/decline',
};

// PROFILE CONSTANTS
const PROFILE = {SETTINGS: '/api/settings', DATA_USER: '/api/data_user'};

// VERIFICATION CONSTANTS
const VERIFICATION = {
  API: '/api',
  UPLOAD: '/api/verification/upload',
  DELETE: '/api/verification/delete',
};

// PAYMENT CONSTANTS
const PAYMENT = {
  SEND_PAYMENT: '/api/payment/send',
};

// BADGE COUNT CONSTANTS
const BADGE_COUNT = {
  GET: '/api/get-badge',
  CHANGE_STATUS: '/api/change_status_notification',
};

// EXPORT FILE CONSTANTS
const EXPORT_FILE = {
  API: '/api/',
  EXPORT: '/export',
};

// PHONE CODE CONSTANTS
const PHONE_CODE = {
  API: '/api/phone_countries_list',
  GEO_LOCATION:
    process.env.REACT_APP_GEO_LOCATION_URL ||
    'https://geolocation-db.com/json/',
};

// CHAT CONSTANTS
const CHAT = {
  GET_ALL_CHATS: '/api/chat/fetch-all',
  GET_ONE_CHAT: '/api/chat/fetch',
  SEND_MESSAGE: '/api/chat/send',
  DELETE_MESSAGE: '/api/chat/delete',
  CHANGE_CHAT_STATUS: '/api/chat/changeStatus',
  DELETE_CHAT: '/api/chat/deleteConversation',
  CLIENT_LIST: '/api/client-list',
};

export {
  AUTHORIZATION,
  DASHBOARD,
  TRANSACTIONS,
  VOUCHER,
  APP,
  ACCOUNTS,
  STANDARD_DATA,
  TOP_UP,
  INCOMPLETE,
  PROFILE,
  VERIFICATION,
  PAYMENT,
  BADGE_COUNT,
  EXPORT_FILE,
  PHONE_CODE,
  CHAT,
};
