import {defineIcon} from '../PaymentProvider/defineIcon';
import {StyledRadio} from '../../../Auth/Auth.styles';
import {Container, Header, Heading} from './PreferredPaymentProvider.styles';
import {IPreferredPaymentProviderProps} from './PreferredPaymentProvider.types';

const PreferredPaymentProvider = ({
  keyProp,
  checked,
  paymentMethodKey,
  paymentMethodName,
  handlePaymentMethod,
}: IPreferredPaymentProviderProps) => (
  <Container checked={checked}>
    <Header>
      <StyledRadio
        checked={checked}
        onChange={() =>
          handlePaymentMethod(keyProp, 'method', paymentMethodKey)
        }
      />
      {defineIcon(paymentMethodName.toLowerCase())}
      <Heading>{paymentMethodName}</Heading>
    </Header>
  </Container>
);

export default PreferredPaymentProvider;
