import {FC} from 'react';
import LoginForm from './LoginForm';
import LoginTitle from './LoginTitle';
// import Socials from '../../Auth/Socials';
import SocialsProviders from '../SocialsProviders';

const Login: FC = (): JSX.Element => (
  <div className='form-container'>
    <LoginTitle />
    <LoginForm />
    {/* <Socials pageTitle='login' /> */}
    <SocialsProviders pageTitle='login' />
  </div>
);

export default Login;
