import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import {IFormValues} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

// eslint-disable-next-line import/prefer-default-export
export const decreaseSelectValue = (
  formValuesMap: IFormValues,
  key: string,
  option: OptionData | OptionGroupData,
  optionField: string,
) => {
  if (Array.isArray(formValuesMap[key])) {
    const filteredList = formValuesMap[key].filter(
      (item: string) => item !== option[optionField],
    );

    if (filteredList.length > 1) {
      return {...formValuesMap, [key]: filteredList};
    }

    if (filteredList.length === 1 && optionField === 'key') {
      return {...formValuesMap, [key]: filteredList[0]};
    }

    if (filteredList.length === 1 && optionField === 'value') {
      return {...formValuesMap, [key]: filteredList};
    }
  }

  return {...formValuesMap, [key]: optionField === 'key' ? '' : []};
};
