import styled from 'styled-components';
import {Table} from 'antd';

export const StyledTable = styled(Table)`
  tbody tr {
    cursor: pointer;
  }

  td {
    white-space: nowrap;
  }

  .ant-table-column-title {
    white-space: nowrap;
    margin-right: 10px;
  }
`;

export const ExpandedRowData = styled.div`
  margin-bottom: 8px;
`;

export const TermItem = styled.div`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-right: 4px;
`;

export const DefinitionItem = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
`;

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  color: #4d50c0;
  font-weight: 600;
  font-style: italic;
  transition: color 0.2s linear;

  &:hover {
    color: #8f91e2;
  }
`;

export const LinkButtonText = styled.span`
  margin-left: 6px;
`;
