import {AxiosResponse} from 'axios';
import {
  createStandardDataItemRequestAction,
  createStandardDataItemSuccessAction,
  createStandardDataItemFailureAction,
  storeStandardDataItemRequestAction,
  storeStandardDataItemSuccessAction,
  storeStandardDataItemFailureAction,
} from '../actions/create.actions';
import {
  createStandardDataItem,
  storeStandardDataItem,
  storeRolesItem,
} from '../../../infra/standardized.service';
import {ThunkActionType} from '../../store';
import {getStandardDataRequest} from './read.requests';
import {ItemDataCreate} from '../standardized.types';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraAction,
  formFilteredUrl,
} from '../../../utils';
import {setNotificationState} from '../../Notification/notification.actions';

export const createStandardDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(createStandardDataItemRequestAction());
    return createStandardDataItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: createStandardDataItemSuccessAction,
          failureAction: createStandardDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: createStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const storeStandardDataItemRequest =
  (slug: string, dataCreate: ItemDataCreate): ThunkActionType =>
  (dispatch, getState) => {
    const {currentPageTable, pageSizeTable, filterDataMap} =
      getState().standardizedReducer;

    const slugFilter = formFilteredUrl({
      slugUrl: slug,
      currentPageTable,
      pageSizeTable,
      filterDataMap,
    });

    dispatch(storeStandardDataItemRequestAction());
    return storeStandardDataItem(slug, dataCreate)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: storeStandardDataItemSuccessAction,
          failureAction: storeStandardDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugFilter,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: storeStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const storeStandardDataItemQueryParamsRequest =
  (slug: string): ThunkActionType =>
  (dispatch, getState) => {
    const {currentPageTable, pageSizeTable} = getState().standardizedReducer;
    const idxSlash = slug.indexOf('/');
    const slugGetAllItem = slug.substring(0, idxSlash);
    const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

    dispatch(storeStandardDataItemRequestAction());
    return storeRolesItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: storeStandardDataItemSuccessAction,
          failureAction: storeStandardDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugPagination,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: storeStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
