import benefit1 from '../../../styles/img/benefits-img-1.png';
import benefit2 from '../../../styles/img/benefits-img-2.png';
import benefit3 from '../../../styles/img/benefits-img-3.png';
import benefit4 from '../../../styles/img/benefits-img-4.png';
import benefit4mobile from '../../../styles/img/benefits-img-4-mobile.png';
import '../../../styles/preview.css';

const AboutPage = () => (
  <>
    <section className='main-section main-section-color-line about-us'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 wrapper-content'>
            <h1 className='main-section__main-title main-title'>About Us</h1>
            <p className='main-section__description standard-description mb-0'>
              Blixti’s mission is to make online shopping secure and accessible
              for everyone. With a Blixti prepaid voucher, hackers and scammers
              can’t access personal or financial data because they are
              effectively locked in a vault.
            </p>
            <p className='main-section__description standard-description'>
              Blixti has been developed to accept the payment method that works
              for you. We offer over 300 different payment methods, including
              credit cards, eWallets, bank transfers and cash. We are
              continually expanding different payment options to ensure our
              customers can purchase a Blixti prepaid voucher via their
              preferred method.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className='section benefits'>
      <div className='benefits-container'>
        <div className='row'>
          <div className='col-12 wrapper-content-column'>
            <h2 className='section__title'>Why Blixty?</h2>
          </div>
        </div>
        <div className='row text-section text-first-line'>
          <div className='col-12 col-lg-5 text-block'>
            <div className='color-tile red'>
              <svg
                width='20'
                height='16'
                viewBox='0 0 20 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15 5V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H5M7 15H17C18.1046 15 19 14.1046 19 13V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V13C5 14.1046 5.89543 15 7 15ZM14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10Z'
                  stroke='#EF4444'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <h3 className='section__middle-title'>
              Take charge of your finances
            </h3>
            <p className='section__small-description'>
              Blixti ensures that going over budget is a thing of the past. That
              surprise recurring payment that you forgot can’t happen with
              Blixti. We offer tools that allow you to oversee your online
              expenses in one integrated platform, whilst also offering options
              to set spending limits. Once you draw your line in the sand, you
              can forget about surprise bills at the end of the month.
            </p>
          </div>
          <div className='col-12 col-lg-7 img-first-line'>
            <img src={benefit1} alt='benefit 1' />
          </div>
        </div>
        <div className='row text-section text-second-line'>
          <div className='col-12 col-lg-6 img-second-line'>
            <img src={benefit2} alt='benefit 2' />
          </div>
          <div className='col-12 col-lg-5 text-block'>
            <div className='color-tile green'>
              <svg
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7 11L9 13L13 9.00001M18.6179 4.98434C18.4132 4.99472 18.2072 4.99997 18 4.99997C14.9265 4.99997 12.123 3.84453 9.99993 1.94434C7.87691 3.84446 5.07339 4.99985 2 4.99985C1.79277 4.99985 1.58678 4.9946 1.38213 4.98422C1.1327 5.94783 1 6.95842 1 8.00001C1 13.5915 4.82432 18.2898 10 19.622C15.1757 18.2898 19 13.5915 19 8.00001C19 6.95847 18.8673 5.94791 18.6179 4.98434Z'
                  stroke='#10B981'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <h3 className='section__middle-title'>
              Less is more in the world of online privacy and security
            </h3>
            <p className='section__small-description'>
              We don’t need your financial details, in fact – we don’t want
              them. The best way we can protect you from online fraud is by not
              sharing your sensitive information online. That’s why we developed
              the Blixti prepaid voucher to ensure websites don’t have access to
              your financial or personal information.{' '}
            </p>
          </div>
        </div>
        <div className='row text-section justify-content-between'>
          <div className='col-12 col-lg-5 text-block'>
            <div className='color-tile yellow'>
              <svg
                width='20'
                height='16'
                viewBox='0 0 20 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 2C0 0.895431 0.89543 0 2 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16H2C0.895432 16 0 15.1046 0 14V2ZM2 7V14H18V7H2ZM18 5H2V2L18 2V5ZM4 11C4 10.4477 4.44772 10 5 10H6C6.55228 10 7 10.4477 7 11C7 11.5523 6.55228 12 6 12H5C4.44772 12 4 11.5523 4 11ZM9 11C9 10.4477 9.44771 10 10 10H11C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12H10C9.44771 12 9 11.5523 9 11Z'
                  fill='#F97316'
                />
              </svg>
            </div>
            <h3 className='section__middle-title'>
              No credit card? No problem!
            </h3>
            <p className='section__small-description'>
              Blixti liberates online shoppers from using credit cards and bank
              accounts. You can purchase everything you need with Blixti and
              forget about all the associated bank fees.{' '}
            </p>
          </div>
          <div className='col-12 col-lg-6 img-third-line'>
            <img src={benefit3} alt='benefit 3' />
          </div>
        </div>
      </div>
      <div className='text-section stick-img mb-0'>
        <div className='stick-img-block'>
          <img src={benefit4} alt='benefit 4' className='hide-less-sm' />
          <img
            src={benefit4mobile}
            alt='benefit 4 mobile'
            className='hide-more-sm'
          />
        </div>
        <div className='container'>
          <div className='row justify-content-end'>
            <div className='col-6 phantom-block' />
            <div className='col-12 col-lg-6 text-block'>
              <div className='color-tile violet'>
                <svg
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2 21C0.895431 21 0 20.1046 0 19V9C0 7.89543 0.89543 7 2 7H6C6.55228 7 7 7.44771 7 8C7 8.55229 6.55228 9 6 9H2V19L18 19V9H14C13.4477 9 13 8.55229 13 8C13 7.44771 13.4477 7 14 7H18C19.1046 7 20 7.89543 20 9V19C20 20.1046 19.1046 21 18 21H2Z'
                    fill='#7367FE'
                  />
                  <path
                    d='M6 16C6 16.5523 5.55228 17 5 17C4.44772 17 4 16.5523 4 16V12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12V16Z'
                    fill='#7367FE'
                  />
                  <path
                    d='M16 16C16 16.5523 15.5523 17 15 17C14.4477 17 14 16.5523 14 16V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V16Z'
                    fill='#7367FE'
                  />
                  <path
                    d='M11 8C11 8.55229 10.5523 9 10 9C9.44771 9 9 8.55229 9 8V3.41421L7.70711 4.70711C7.31658 5.09763 6.68342 5.09763 6.29289 4.70711C5.90237 4.31658 5.90237 3.68342 6.29289 3.29289L9.29289 0.292892C9.68342 -0.0976315 10.3166 -0.0976315 10.7071 0.292892L13.7071 3.29289C14.0976 3.68342 14.0976 4.31658 13.7071 4.70711C13.3166 5.09763 12.6834 5.09763 12.2929 4.70711L11 3.41421V8Z'
                    fill='#7367FE'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10 17C8.34315 17 7 15.6569 7 14C7 12.3431 8.34315 11 10 11C11.6569 11 13 12.3431 13 14C13 15.6569 11.6569 17 10 17ZM9 14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14Z'
                    fill='#7367FE'
                  />
                </svg>
              </div>
              <div className='context'>
                <h3 className='section__middle-title'>
                  Get connected to the global village
                </h3>
                <p className='section__small-description'>
                  Blixti allows everyone everywhere to take advantage of online
                  shopping. We offer over 300 payment methods in 195 countries.
                  It doesn’t matter where you live, how you want to pay, or what
                  you want to buy, Blixti has an option for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default AboutPage;
