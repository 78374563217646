import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Select} from 'antd';
import {SelectValue} from 'antd/lib/select';
import Loader from '../Loader';
import {RootState} from '../../../../../state/store';
import {
  payIncompleteInvoiceInitRequest,
  payIncompleteInvoiceSendRequest,
} from '../../../../../state';
import {WalletColored, Down} from '../../../../static/images';
import coloredStripes from '../../../../styles/img/colored-stripes.png';
import {
  BackgroundImage,
  ButtonWrapper,
  Container,
  Inner,
  Title,
  ButtonGroup,
  Content,
  TextPart,
  Group,
  TermItem,
  DefinitionItem,
  StyledSelect,
} from './ConfirmInvoice.styles';
import {OptionField} from '../../../../../state/IncompleteInvoices/incompleteInvoices.types';

const {Option} = Select;

const vouchersList = ['Voucher in EUR', 'Voucher in IDR', 'Voucher in USD'];

const ConfirmInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {loading, currentSlug, payList, paySuccessRes, error} = useSelector(
    (state: RootState) => state.incompleteInvoicesReducer,
  );

  const [selectValue, setSelectValue] = useState<SelectValue>(vouchersList[0]);
  const [vouchersOptions, setVouchersOptions] = useState<OptionField>({});
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [descriptionInvoice, setDescriptionInvoice] = useState('');

  const confirmPayInvoice = () => {
    if (!payList) return;
    if (!payList.list.fields.voucher.options) return;
    if (!payList.list.fields.account.options) return;
    if (!payList.list.fields.from_invoice.options) return;

    const from_invoice = Object.keys(
      payList?.list.fields.from_invoice.options,
    )[0];

    const account = Object.keys(payList?.list.fields.account.options)[0];
    const voucher = Object.keys(payList?.list.fields.voucher.options)[0];
    dispatch(payIncompleteInvoiceSendRequest({from_invoice, account, voucher}));
  };

  useEffect(() => {
    if (paySuccessRes || error) {
      history.push('/incomplete');
    }
  }, [paySuccessRes, error]);

  useEffect(() => {
    if (!currentSlug) return;
    dispatch(payIncompleteInvoiceInitRequest(currentSlug));
  }, [currentSlug]);

  useEffect(() => {
    if (!payList) return;
    if (!payList.list.fields.voucher.options) return;
    if (!payList.list.fields.description.value) return;
    if (!payList.list.fields.from_invoice.options) return;

    setVouchersOptions(payList.list.fields.voucher.options);
    setSelectValue(Object.entries(payList.list.fields.voucher.options)[0]);
    setBeneficiaryName(
      Object.values(payList.list.fields.from_invoice.options)[0],
    );
    setDescriptionInvoice(payList.list.fields.description.value);
  }, [payList]);

  if (loading || !payList) return <Loader />;

  const amount = payList.list.fields.amount.value;

  return (
    <Container>
      <BackgroundImage src={coloredStripes} alt='background' />
      <Inner>
        <WalletColored />
        <Title>Please confirm you want to pay invoice below:</Title>
        <Content>
          <TextPart>
            <Group>
              <TermItem>Amount</TermItem>
              <DefinitionItem>{amount}</DefinitionItem>
            </Group>
            <Group>
              <TermItem>Benefeciary</TermItem>
              <DefinitionItem>{beneficiaryName}</DefinitionItem>
            </Group>
            <Group>
              <TermItem>Description</TermItem>
              <DefinitionItem>{descriptionInvoice}</DefinitionItem>
            </Group>
          </TextPart>
          <TermItem marginLeft='14px'>Voucher</TermItem>
          <StyledSelect
            value={selectValue}
            optionFilterProp='key'
            placeholder='Voucher'
            suffixIcon={<Down />}
            onChange={(value) => setSelectValue(value)}
          >
            {Object.entries(vouchersOptions).map((option) => (
              <Option key={option[0]} value={option[0]}>
                {option[1]}
              </Option>
            ))}
          </StyledSelect>
        </Content>
        <ButtonGroup>
          <ButtonWrapper onClick={() => history.push('/incomplete')}>
            Cancel
          </ButtonWrapper>
          <ButtonWrapper onClick={confirmPayInvoice}>Confirm</ButtonWrapper>
        </ButtonGroup>
      </Inner>
    </Container>
  );
};

export default ConfirmInvoice;
