import {v4 as uuidv4} from 'uuid';
import AmountInput from '../AmountInput';
import PaymentMethodItem from '../PaymentMethodItem';
import {ArrowRight} from '../../../../static/images';
import {ITopUpFieldItem} from '../../../../../state/TopUp/topUp.types';
import FormSelect from '../FormSelect';
import {ITopUpFormComponentProps} from './TopUpFormComponent.types';
import {
  PageWrapper,
  FormWrapper,
  Input,
  ButtonGroup,
  Button,
  ButtonText,
} from './TopUpFormComponent.styles';

const TopUpFormComponent = ({
  description,
  formFieldsList,
  formValues,
  currentCurrency,
  isDisabledCheckoutButton,
  goPrevPage,
  handleInputChange,
  handleSelectChange,
  handlePaymentMethod,
  sendTopUpPaymentDetails,
}: ITopUpFormComponentProps) => (
  <PageWrapper>
    <FormWrapper>
      {formFieldsList.map((formField: ITopUpFieldItem) => {
        if (formField.type === 'input' && formField.name === 'amount') {
          return (
            <AmountInput
              key={formField.name}
              description={description}
              placeholder={formField?.label || formField.name}
              name={formField.name}
              value={formField?.value || formValues[formField.name]}
              handleChange={handleInputChange}
              currentCurrency={currentCurrency}
            />
          );
        }

        if (
          formField.type === 'select' &&
          formField.options &&
          formField.name !== 'method'
        ) {
          const defaultValue = formField?.value?.toString();

          return (
            <FormSelect
              valueSelect={formValues[formField.name] || defaultValue}
              key={formField.name}
              keyProp={formField.name}
              placeholder={formField?.label || formField.name}
              options={Object.entries(formField.options)}
              handleSelectChange={handleSelectChange}
            />
          );
        }

        if (
          formField.type === 'select' &&
          formField.options &&
          formField.name === 'method'
        ) {
          return (
            <div key={formField.name}>
              {Object.entries(formField.options).map((paymentMethod) => (
                <PaymentMethodItem
                  key={uuidv4()}
                  keyProp={formField.name}
                  paymentMethodKey={paymentMethod[0]}
                  paymentMethodName={paymentMethod[1]}
                  paymentMethodIcon={paymentMethod[1]}
                  handlePaymentMethod={handlePaymentMethod}
                  checked={formValues[formField.name] === paymentMethod[0]}
                />
              ))}
            </div>
          );
        }

        if (formField.type === 'util') return null;

        return (
          <Input
            type='text'
            name={formField.name}
            value={formField.value || formValues[formField.name]}
            onChange={handleInputChange}
            placeholder={formField.label}
          />
        );
      })}
      <ButtonGroup>
        <Button onClick={goPrevPage}>
          <ButtonText>Cancel</ButtonText>
        </Button>
        <Button
          disabled={isDisabledCheckoutButton}
          onClick={sendTopUpPaymentDetails}
        >
          <ButtonText>Checkout</ButtonText>
          <ArrowRight strokeColor='#fff' />
        </Button>
      </ButtonGroup>
    </FormWrapper>
  </PageWrapper>
);

export default TopUpFormComponent;
