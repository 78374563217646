import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);
`;

export const Input = styled.input`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  background-color: ${(p) => p.theme.main.colors.white};
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  border-radius: 8px;

  &::placeholder {
    color: ${(p) => p.theme.main.colors.grey};
    ${(p) => p.theme.main.typography.body};
    text-transform: capitalize;
  }

  &:focus {
    outline: 2px solid ${(p) => p.theme.main.colors.black};
  }
`;

export const IconWrapper = styled.div`
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  transform: translateY(-50%);
`;
