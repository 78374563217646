const colors = {
  brand: '#7367FE',
  brandDark: '#5C53CB',
  white: '#FFFFFF',
  black: '#192B3B',
  blackSecondary: '#171533',
  snow: '#FCFDFE',
  darkGrey: '#74828A',
  grey: '#BAC0C5',
  lightGrey: '#EBEBEB',
  light: '#F7F7F7',
  sidebar: '#0E1A33',
  signUp: '#F6F5FF',
  red: '#EF4444',
  green: '#10B981',
  lightPurple: '#E3E1FF',
};

export default colors;
