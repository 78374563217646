import {AxiosResponse} from 'axios';
import {getPhoneCodes, getGeoLocation} from '../../infra/phoneCode.service';
import {ThunkActionType} from '../store';
import {
  getPhoneCodesRequestAction,
  getPhoneCodesSuccessAction,
  getPhoneCodesFailureAction,
  getGeoLocationRequestAction,
  getGeoLocationSuccessAction,
  getGeoLocationFailureAction,
} from './phoneCodes.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleErrorFirstStep as handleError,
} from '../../utils';

export const getPhoneCodesRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getPhoneCodesRequestAction());

  return getPhoneCodes()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getPhoneCodesSuccessAction,
        failureAction: getPhoneCodesFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getPhoneCodesFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const getGeoLocationRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getGeoLocationRequestAction());

  return getGeoLocation()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getGeoLocationSuccessAction,
        failureAction: getGeoLocationFailureAction,
      });
    })
    .catch((error) => {
      handleError({
        error,
        dispatch,
        failureAction: getGeoLocationFailureAction,
      });
    });
};
