import {FC} from 'react';
import {Radio, RadioChangeEvent} from 'antd';
import FormItem from '../../FormItem';
import {IRadioComponentProps} from './RadioComponent.types';
import {Container, StyledRadio} from './RadioComponent.styles';

const RadioComponent: FC<IRadioComponentProps> = ({
  name,
  label,
  radioItems,
  value,
  required,
  handleValueChange,
}: IRadioComponentProps) => {
  const onChange = (event: RadioChangeEvent) => {
    handleValueChange({keyName: name, value: event.target.value});
  };

  return (
    <FormItem label={label} required={required}>
      <Radio.Group onChange={onChange} defaultValue={value}>
        <Container>
          {radioItems.map((item) => (
            <StyledRadio key={item.value} value={item.value}>
              {item.label}
            </StyledRadio>
          ))}
        </Container>
      </Radio.Group>
    </FormItem>
  );
};

export default RadioComponent;
