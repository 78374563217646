import styled from 'styled-components';
import {Tag, Checkbox} from 'antd';

const defineTagTextColor = (tagStatus: string): string => {
  switch (tagStatus) {
    case 'open':
      return '#065f46';
    case 'open_pay':
      return '#9a3412';
    default:
      return '#991b1b';
  }
};

export const DetailedInfoList = styled.div`
  margin: 32px 0 16px 0;
`;

export const RoleDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const TermItem = styled.div`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-right: 5px;
`;

export const TermItemRole = styled.div`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  text-transform: capitalize;
`;

export const DefinitionItem = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
`;

export const DefinitionItemRole = styled.label`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: 6px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
`;

export const BackButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: 8px;
`;

export const StyledTag = styled(Tag)<{status: string}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${({status}) => defineTagTextColor(status)};
  align-self: flex-start;
  text-transform: capitalize;
`;

export const RoleItemGroup = styled.div`
  margin-bottom: 8px; ;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxItem = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const StyledCheckbox = styled(Checkbox)``;
