import {FC} from 'react';
import {ProfileCircle} from '../../static/images';
import {IPersonalFormLayoutProps} from './PersonalFormLayout.types';
import PersonalFormComponent from '../../components/PersonalFormComponent';
import {
  Header,
  IconWrapper,
  SubTitle,
  Title,
  Content,
  FormWrapper,
} from './PersonalFormLayout.styles';

const PersonalFormLayout: FC<IPersonalFormLayoutProps> = ({
  formRules,
  isSelectInFocus,
  countriesList,
  isDateFieldRequired,
  initialPhoneCode,
  handleSelectInFocus,
}: IPersonalFormLayoutProps) => (
  <Content>
    <Header>
      <IconWrapper>
        <ProfileCircle />
      </IconWrapper>
      <div>
        <Title>Personal information</Title>
        <SubTitle>
          Kindly make sure that all details are correct and up to date.
        </SubTitle>
      </div>
    </Header>
    <FormWrapper>
      <PersonalFormComponent
        formRules={formRules}
        isSelectInFocus={isSelectInFocus}
        countriesList={countriesList}
        isDateFieldRequired={isDateFieldRequired}
        handleSelectInFocus={handleSelectInFocus}
        initialPhoneCode={initialPhoneCode}
      />
    </FormWrapper>
  </Content>
);

export default PersonalFormLayout;
