import {FC, useState} from 'react';
import moment from 'moment';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import {useMediaQuery} from 'react-responsive';
import CalendarTopPanel from './CalendarTopPanel';
import CalendarBottomPanel from './CalendarBottomPanel';
import PeriodPresets from './PeriodPresets';
import {
  ArrowRight,
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
  CloseIcon,
} from '../../../../static/images';
import {ICalendarPickerProps} from './CalendarPicker.types';
import {
  ArrowIconWrapper,
  CloseIconWrapper,
  DateRangePickerWrapper,
  NextButtonWrapper,
  PrevButtonWrapper,
} from './CalendarPicker.styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const CalendarPicker: FC<ICalendarPickerProps> = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  filterTransactions,
}) => {
  const maxWidth1200PX = useMediaQuery({query: '(max-width: 1200px)'});

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );
  const [selectedPresetPeriod, setSelectedPresetPeriod] = useState('');
  const [isOpenSelectPeriod, setOpenSelect] = useState(false);

  const handleOpenSelect = (bool: boolean) => setOpenSelect(bool);

  const handleFocusedInput = (focusedShape: FocusedInputShape) => {
    setFocusedInput(focusedShape);
  };

  const handlePresetPeriod = (period: string) => {
    setSelectedPresetPeriod(period);
  };

  const presetDate = (period: string) => {
    switch (period) {
      case 'this_month':
        handleStartDateChange(moment().startOf('month'));
        handleEndDateChange(moment().endOf('month'));
        setSelectedPresetPeriod('this_month');
        break;
      case 'last_month':
        handleStartDateChange(moment().subtract(1, 'months').date(1));
        handleEndDateChange(moment().subtract(1, 'months').endOf('month'));
        setSelectedPresetPeriod('last_month');
        break;
      case 'last_90_days':
        handleStartDateChange(moment().subtract(90, 'days'));
        handleEndDateChange(moment());
        setSelectedPresetPeriod('last_90_days');
        break;
      case 'this_year':
        handleStartDateChange(moment().startOf('year'));
        handleEndDateChange(moment());
        setSelectedPresetPeriod('this_year');
        break;
      case 'last_year':
        handleStartDateChange(moment().subtract(1, 'year').month(0).date(1));
        handleEndDateChange(moment().subtract(1, 'year').month(11).date(31));
        setSelectedPresetPeriod('last_year');
        break;
      default:
        handleStartDateChange(moment().subtract(1, 'days'));
        handleEndDateChange(moment());
        setSelectedPresetPeriod('');
    }

    setFocusedInput('startDate');
    setOpenSelect(false);
  };

  return (
    <DateRangePickerWrapper focusedInput={!!focusedInput}>
      <DateRangePicker
        showClearDates
        keepOpenOnDateSelect
        hideKeyboardShortcutsPanel
        startDate={startDate}
        endDate={endDate}
        focusedInput={focusedInput}
        startDateId='startDateValue'
        endDateId='endDateValue'
        inputIconPosition='after'
        displayFormat='DD.MM.YYYY'
        calendarInfoPosition='bottom'
        orientation={maxWidth1200PX ? 'vertical' : 'horizontal'}
        isOutsideRange={() => false}
        onFocusChange={(focusedInputDate) => setFocusedInput(focusedInputDate)}
        onDatesChange={({startDate: startDateValue, endDate: endDateValue}) => {
          handleStartDateChange(startDateValue);
          handleEndDateChange(endDateValue);
        }}
        navPrev={
          <PrevButtonWrapper>
            <ChevronLeft />
          </PrevButtonWrapper>
        }
        navNext={
          <NextButtonWrapper>
            <ChevronRight />
          </NextButtonWrapper>
        }
        customCloseIcon={
          <CloseIconWrapper>
            <CloseIcon fillColor='#BAC0C5' />
          </CloseIconWrapper>
        }
        customInputIcon={<CalendarIcon fillColor='#BAC0C5' />}
        customArrowIcon={
          <ArrowIconWrapper>
            <ArrowRight />
          </ArrowIconWrapper>
        }
        renderCalendarInfo={() => (
          <CalendarBottomPanel
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            setFocusedInput={setFocusedInput}
            filterDataByDateRange={filterTransactions}
          />
        )}
      />
      <PeriodPresets
        show={!!focusedInput}
        presetDate={presetDate}
        selectedPresetPeriod={selectedPresetPeriod}
      />
      {maxWidth1200PX ? (
        <CalendarTopPanel
          isOpenSelect={isOpenSelectPeriod}
          show={!!focusedInput}
          presetDate={presetDate}
          handleFocusedInput={handleFocusedInput}
          selectedPresetPeriod={selectedPresetPeriod}
          handleOpenSelect={handleOpenSelect}
          handlePresetPeriod={handlePresetPeriod}
        />
      ) : null}
    </DateRangePickerWrapper>
  );
};

export default CalendarPicker;
