// USER_REGISTRATION_PAYMENT TYPES & ACTIONS
export const USER_REGISTRATION_PAYMENT_REQUEST: string =
  'USER_REGISTRATION_PAYMENT_REQUEST';
export const USER_REGISTRATION_PAYMENT_SUCCESS: string =
  'USER_REGISTRATION_PAYMENT_SUCCESS';
export const USER_REGISTRATION_PAYMENT_FAILURE: string =
  'USER_REGISTRATION_PAYMENT_FAILURE';

export const userRegistrationPaymentRequestAction = (): {type: string} => ({
  type: USER_REGISTRATION_PAYMENT_REQUEST,
});

export const userRegistrationPaymentSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: USER_REGISTRATION_PAYMENT_SUCCESS,
  payload,
});

export const userRegistrationPaymentFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: USER_REGISTRATION_PAYMENT_FAILURE,
  payload,
});

// REDIRECT_PAYMENT_REGISTER TYPES & ACTIONS
export const REDIRECT_PAYMENT_REGISTER_REQUEST: string =
  'REDIRECT_PAYMENT_REGISTER_REQUEST';
export const REDIRECT_PAYMENT_REGISTER_SUCCESS: string =
  'REDIRECT_PAYMENT_REGISTER_SUCCESS';
export const REDIRECT_PAYMENT_REGISTER_FAILURE: string =
  'REDIRECT_PAYMENT_REGISTER_FAILURE';

export const redirectPaymentRegisterRequestAction = (): {type: string} => ({
  type: REDIRECT_PAYMENT_REGISTER_REQUEST,
});

export const redirectPaymentRegisterSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: REDIRECT_PAYMENT_REGISTER_SUCCESS,
  payload,
});

export const redirectPaymentRegisterFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: REDIRECT_PAYMENT_REGISTER_FAILURE,
  payload,
});

// USER_LOGIN_PAYMENT TYPES & ACTIONS
export const USER_LOGIN_PAYMENT_REQUEST: string = 'USER_LOGIN_PAYMENT_REQUEST';
export const USER_LOGIN_PAYMENT_SUCCESS: string = 'USER_LOGIN_PAYMENT_SUCCESS';
export const USER_LOGIN_PAYMENT_FAILURE: string = 'USER_LOGIN_PAYMENT_FAILURE';

export const userLoginPaymentRequestAction = (): {type: string} => ({
  type: USER_LOGIN_PAYMENT_REQUEST,
});

export const userLoginPaymentSuccessAction = (payload: any) => ({
  type: USER_LOGIN_PAYMENT_SUCCESS,
  payload,
});

export const userLoginPaymentFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: USER_LOGIN_PAYMENT_FAILURE,
  payload,
});
