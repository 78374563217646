import siteNotReadyImg from '../../../styles/img/site-not-ready.png';
import {Container} from './SiteNotReady.styles';

const SiteNotReady = () => (
  <Container>
    <img src={siteNotReadyImg} alt='site-not-ready' />
  </Container>
);

export default SiteNotReady;
