import {FC} from 'react';
import {Popover} from 'antd';
import {IPopoverComponentProps} from './PopoverComponent.types';
import {
  PopoverTitle,
  PopoverButtonGroup,
  PopoverButton,
  PopoverButtonText,
} from './PopoverComponent.styles';

const PopoverComponent: FC<IPopoverComponentProps> = ({
  visible,
  questionText,
  acceptText,
  declineText,
  children,
  acceptClick,
  declineClick,
  handleShowPopover,
}: IPopoverComponentProps) => (
  <Popover
    overlayClassName='delete-popover'
    trigger='click'
    content={
      <div>
        <PopoverTitle>{questionText}</PopoverTitle>
        <PopoverButtonGroup>
          <PopoverButton
            bgColor='#E3E1FF'
            onClick={() => {
              acceptClick();
            }}
          >
            <PopoverButtonText>{acceptText}</PopoverButtonText>
          </PopoverButton>
          <PopoverButton bgColor='#F6F5FF' onClick={() => declineClick()}>
            <PopoverButtonText>{declineText}</PopoverButtonText>
          </PopoverButton>
        </PopoverButtonGroup>
      </div>
    }
    visible={visible}
    onVisibleChange={handleShowPopover}
  >
    {children}
  </Popover>
);

export default PopoverComponent;
