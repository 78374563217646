import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 40px;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
`;

export const Heading = styled.h3`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 10px;
`;

export const Subtitle = styled.h4`
  ${(p) => p.theme.main.typography.paragraph};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 10px;
`;

export const DepositAmount = styled.div`
  margin-bottom: 32px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 8px;
`;

export const InputCurrencySign = styled.div`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.darkGrey};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`;

export const AcceptTerms = styled.p`
  ${(p) => p.theme.main.typography.captionMedium};
  margin-top: 8px;
`;

export const GreyText = styled.span`
  color: ${(p) => p.theme.main.colors.darkGrey};
`;

export const ColoredText = styled.span`
  color: ${(p) => p.theme.main.colors.brand};
  cursor: pointer;
`;

export const PaymentMethods = styled.div``;
