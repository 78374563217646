import {FC} from 'react';
import {Select} from 'antd';
import FormItem from '../../FormItem';
import {Down} from '../../../../../static/images';
import {IExpiredDateProps} from './ExpiredDate.types';
import {SelectWrapper, Container} from './ExpiredDate.styles';
import {monthsList, yearsList} from './optionsList';

const {Option} = Select;

const ExpiredDate: FC<IExpiredDateProps> = ({
  label,
  required,
  expiredMonth,
  expiredYear,
  handleValueChange,
}: IExpiredDateProps) => (
  <FormItem label={label} required={required}>
    <Container>
      <SelectWrapper
        placeholder='MM'
        defaultValue={expiredMonth || undefined}
        suffixIcon={<Down />}
        onChange={(value) =>
          handleValueChange({keyName: 'expired_month', value})
        }
      >
        {monthsList.map((month) => (
          <Option key={month} value={month}>
            {month}
          </Option>
        ))}
      </SelectWrapper>
      <SelectWrapper
        placeholder='YYYY'
        defaultValue={expiredYear || undefined}
        suffixIcon={<Down />}
        onChange={(value) =>
          handleValueChange({keyName: 'expired_year', value})
        }
      >
        {yearsList.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </SelectWrapper>
    </Container>
  </FormItem>
);

export default ExpiredDate;
