import {AxiosResponse} from 'axios';
import {
  editIncompleteDataItemRequestAction,
  editIncompleteDataItemSuccessAction,
  editIncompleteDataItemFailureAction,
  updateIncompleteDataItemRequestAction,
  updateIncompleteDataItemSuccessAction,
  updateIncompleteDataItemFailureAction,
} from '../actions/update.actions';
import {
  editIncompleteInvoice,
  updateIncompleteInvoice,
} from '../../../infra/incompleteInvoices.service';
import {getIncompleteDataRequest} from './read.requests';
import {ThunkActionType} from '../../store';
import {ItemDataEdit} from '../incompleteInvoices.types';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraAction,
} from '../../../utils';

export const editIncompleteDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(editIncompleteDataItemRequestAction());
    return editIncompleteInvoice(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: editIncompleteDataItemSuccessAction,
          failureAction: editIncompleteDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: editIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const updateIncompleteDataItemRequest =
  (slug: string, updatedData: ItemDataEdit): ThunkActionType =>
  (dispatch, getState) => {
    const {currentPageTable, pageSizeTable} = getState().standardizedReducer;
    const idxSlash = slug.indexOf('/');
    const slugGetAllItem = slug.substring(0, idxSlash);
    const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

    dispatch(updateIncompleteDataItemRequestAction());
    return updateIncompleteInvoice(slug, updatedData)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: updateIncompleteDataItemSuccessAction,
          failureAction: updateIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getIncompleteDataRequest,
          extraRequestArg: slugPagination,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: updateIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
