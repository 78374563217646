import {FC} from 'react';
import {Down} from '../../static/images';
import {IPanelHeaderProps} from './PanelHeader.types';
import {
  ArrowWrapper,
  Description,
  LeftPanelPart,
  PanelInner,
  RightPanelPart,
  EndBlock,
  TextPartPanel,
  Title,
  ActionsListPaymentWrapper,
} from './PanelHeader.styles';
import ActionsListPayment from '../ActionsListPayment';

const PanelHeader: FC<IPanelHeaderProps> = ({
  actionsList,
  collapsedKeysList,
  itemId,
  image,
  title,
  description,
  date,
}: IPanelHeaderProps) => {
  const displayImageElement = (imagePath: string | JSX.Element | undefined) => {
    if (imagePath && typeof imagePath === 'string') {
      return <img src={imagePath} alt='icon' />;
    }

    if (imagePath && typeof imagePath !== 'string') {
      return imagePath;
    }

    return null;
  };

  return (
    <PanelInner>
      <LeftPanelPart>
        {displayImageElement(image)}
        <TextPartPanel>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextPartPanel>
      </LeftPanelPart>
      <RightPanelPart>
        {actionsList ? (
          <ActionsListPaymentWrapper>
            <ActionsListPayment actionsList={actionsList} />
          </ActionsListPaymentWrapper>
        ) : null}
        <EndBlock>
          {date ? <div>{date}</div> : null}
          <ArrowWrapper
            isOpen={
              !!collapsedKeysList?.length && collapsedKeysList[0] === itemId
            }
          >
            <Down />
          </ArrowWrapper>
        </EndBlock>
      </RightPanelPart>
    </PanelInner>
  );
};

export default PanelHeader;
