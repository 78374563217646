export const MESSAGE_NOTIFICATION_OPTIONS = {
  isShow: false,
  type: '',
  message: '',
};

export const MESSAGE_MODAL_OPTIONS = {
  isShow: false,
  type: '',
  message: '',
  isModalView: false,
};

export const NOTICE_UPLOAD_FILES_OPTIONS = {
  isShow: true,
  type: 'error',
  message: 'Upload files, please.',
};
