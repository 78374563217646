import styled from 'styled-components';

export const ContainerMessages = styled.div`
  margin-bottom: 16px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const UserPart = styled.div`
  display: flex;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
`;

export const DateTimeMessage = styled.h4`
  ${(p) => p.theme.main.typography.microMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-bottom: 4px;
  line-height: 12px;
  text-align: left;
`;

export const UserName = styled.h5`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  line-height: 20px;
`;

export const Text = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: 52px;
  line-height: 18px;
  text-align: left;

  @media (max-width: 400px) {
    margin-left: 0;
  }
`;
