import styled from 'styled-components';
import {Radio} from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    margin-bottom: 4px;
  }

  &.ant-radio-wrapper:last-child {
    margin-bottom: 0;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #171533 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #171533;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #171533;
  }
`;
