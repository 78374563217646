import {FC, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Form, Input, Select, Radio, Spin} from 'antd';
import {FormProps} from 'antd/lib/form';
import styled from 'styled-components';
import {RootState} from '../../../../state/store';
import {INotificationMap} from '../../../../state/Notification/notification.types';
import {sendPartnerRequest, getCountriesListRequest} from '../../../../state';
import {
  FormItemRow,
  FormItemRowTextArea,
  FormItemRowAlignSelf,
  InputWrapper,
  ButtonWrapper,
} from '../../MemberArea/Auth/Auth.styles';
import {Down, Search} from '../../../static/images';

const {TextArea} = Input;
const {Option} = Select;

const PartnerForm: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {notificationMap}: {notificationMap: INotificationMap} = useSelector(
    (state: RootState) => state.notificationReducer,
  );
  const {countriesList, loadingCountriesList} = useSelector(
    (state: RootState) => state.appReducer,
  );

  const [isSelectInFocus, setSelectInFocus] = useState(false);

  const handleSelectInFocus = (bool: boolean) => () => setSelectInFocus(bool);

  const sendPartnerHandler = ({
    name,
    email,
    phone,
    company,
    country,
    type_client,
    comment,
  }: {
    name: string;
    email: string;
    phone: string;
    company: string;
    country: string;
    type_client: string;
    comment: string;
  }): void => {
    dispatch(
      sendPartnerRequest(
        name,
        email,
        phone,
        company,
        country,
        type_client,
        comment,
      ),
    );
  };

  useEffect(() => {
    if (notificationMap.isShow && notificationMap.type === 'success') {
      form.resetFields();
    }
  }, [notificationMap]);

  useEffect(() => {
    dispatch(getCountriesListRequest());
  }, []);

  if (loadingCountriesList) {
    return (
      <Spin tip='Loading...' spinning={loadingCountriesList} size='large' />
    );
  }

  return (
    <StyledForm
      layout='vertical'
      form={form}
      onFinish={sendPartnerHandler}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        company: '',
        country: 'Select your country',
        type_client: '',
        comment: '',
      }}
    >
      <FormItemRow label='Name' name='name'>
        <InputWrapper placeholder='Name' />
      </FormItemRow>
      <FormItemRow label='Email address' name='email'>
        <InputWrapper
          autoComplete='off'
          placeholder='Enter your email address'
        />
      </FormItemRow>
      <FormItemRow label='Phone number' name='phone'>
        <InputWrapper placeholder='Enter your phone number' />
      </FormItemRow>
      <FormItemRow label='Company' name='company'>
        <InputWrapper placeholder='Enter your company website address' />
      </FormItemRow>
      {countriesList ? (
        <FormItemRow label='Country' name='country'>
          <StyledSelect
            showSearch
            optionFilterProp='value'
            placeholder='Select your country'
            suffixIcon={isSelectInFocus ? <Search /> : <Down />}
            onFocus={handleSelectInFocus(true)}
            onBlur={handleSelectInFocus(false)}
            filterOption={(input, option) =>
              option?.value.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {countriesList.map((countryName: string) => (
              <Option key={countryName} value={countryName}>
                {countryName}
              </Option>
            ))}
          </StyledSelect>
        </FormItemRow>
      ) : null}
      <FormItemRow label='I am interested in becoming a' name='type_client'>
        <Radio.Group>
          <StyledRadio value='distributor'>Distributor</StyledRadio>
          <StyledRadio value='merchant'>Merchant</StyledRadio>
        </Radio.Group>
      </FormItemRow>
      <FormItemRowTextArea label='Comment' name='comment'>
        <TextArea
          rows={4}
          autoSize={{minRows: 3, maxRows: 4}}
          placeholder='Enter your question or comment'
        />
      </FormItemRowTextArea>
      <FormItemRowAlignSelf>
        <ButtonWrapper type='primary' htmlType='submit'>
          Send
        </ButtonWrapper>
      </FormItemRowAlignSelf>
    </StyledForm>
  );
};

export default PartnerForm;

const StyledForm: FC<FormProps> = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }
`;

const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #171533 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #171533;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #171533;
  }
`;
