import {AxiosResponse} from 'axios';
import {
  uploadVerificationFiles,
  getVerificationDocuments,
  deleteVerificationDocument,
  storePersonalInfo,
  verifyClient,
} from '../../infra/verification.service';
import {ThunkActionType} from '../store';
import {
  IDeleteFileReqArgs,
  IStorePersonalInfoArgs,
  IUploadFileReqArgs,
  IVerifyClientArgs,
} from './verification.types';
import {
  uploadVerificationFileRequestAction,
  uploadVerificationFileSuccessAction,
  uploadVerificationFileFailureAction,
  getVerificationDocumentsRequestAction,
  getVerificationDocumentsSuccessAction,
  getVerificationDocumentsFailureAction,
  deleteVerificationDocumentRequestAction,
  deleteVerificationDocumentSuccessAction,
  deleteVerificationDocumentFailureAction,
  storePersonalInfoRequestAction,
  storePersonalInfoSuccessAction,
  storePersonalInfoFailureAction,
  verifyClientRequestAction,
  verifyClientSuccessAction,
  verifyClientFailureAction,
} from './verification.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraAction,
  handleResponseSuccessAndFailNotification,
} from '../../utils';
import {getOneChatForClient} from '../Chat/chat/requests';

// eslint-disable-next-line arrow-body-style
export const uploadFiles = (
  uploadFileArgs: IUploadFileReqArgs,
): ThunkActionType => {
  const {documentType, verificationFile, uploadUrl} = uploadFileArgs;

  return (dispatch) => {
    dispatch(uploadVerificationFileRequestAction(documentType));

    return uploadVerificationFiles(verificationFile, uploadUrl)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: uploadVerificationFileSuccessAction,
          failureAction: uploadVerificationFileFailureAction,
          notificationAction: setNotificationState,
          isModalViewNotification: true,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: uploadVerificationFileFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
};

// eslint-disable-next-line arrow-body-style
export const getVerificationDocs = (slugUrl: string): ThunkActionType => {
  return (dispatch) => {
    dispatch(getVerificationDocumentsRequestAction());

    return getVerificationDocuments(slugUrl)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getVerificationDocumentsSuccessAction,
          failureAction: getVerificationDocumentsFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getVerificationDocumentsFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
};

// eslint-disable-next-line arrow-body-style
export const deleteFile = (
  deleteFileArgs: IDeleteFileReqArgs,
): ThunkActionType => {
  const {deleteFileQueryString, verificationUrl, documentType} = deleteFileArgs;

  return (dispatch) => {
    dispatch(deleteVerificationDocumentRequestAction(documentType));

    return deleteVerificationDocument(deleteFileQueryString)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: deleteVerificationDocumentSuccessAction,
          failureAction: deleteVerificationDocumentFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getVerificationDocs,
          extraRequestArg: verificationUrl,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: uploadVerificationFileFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
};

export const storePersonalInfoRequest = (
  storePersonalInfoRequestArgs: IStorePersonalInfoArgs,
  // eslint-disable-next-line arrow-body-style
): ThunkActionType => {
  return (dispatch) => {
    dispatch(storePersonalInfoRequestAction());

    return storePersonalInfo(storePersonalInfoRequestArgs)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: storePersonalInfoSuccessAction,
          failureAction: storePersonalInfoFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getOneChatForClient,
          textMessage: 'Your documents successfully submitted',
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: storePersonalInfoFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
};

export const verifyClientRequest = (
  args: IVerifyClientArgs,
  // eslint-disable-next-line arrow-body-style
): ThunkActionType => {
  return (dispatch) => {
    dispatch(verifyClientRequestAction());

    return verifyClient(args)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: verifyClientSuccessAction,
          failureAction: verifyClientFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: verifyClientSuccessAction,
          notificationAction: setNotificationState,
        });
      });
  };
};
