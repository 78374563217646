import {Route, Switch, Redirect} from 'react-router-dom';

// AUTHENTICATED PART
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import Dashboard from '../../pages/MemberArea/Dashboard';
import Welcome from '../../pages/MemberArea/Welcome';
import Vouchers from '../../pages/MemberArea/Vouchers';
import Transactions from '../../pages/MemberArea/Transactions';
import TopUp from '../../pages/MemberArea/TopUp';
import VerifyAccount from '../../components/VerifyAccount';
import Settings from '../../pages/MemberArea/Settings';
import VerificationDocuments from '../../pages/MemberArea/VerificationDocuments';
import IncompleteInvoices from '../../pages/MemberArea/IncompleteInvoices';
import Standardized from '../../pages/MemberArea/Standardized';
import SingleItem from '../../pages/MemberArea/Standardized/SingleItem';
import FormConstructor from '../../pages/MemberArea/FormConstructor';

// UNAUTHENTICATED PART
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import Login from '../../pages/MemberArea/Auth/Login';
import Registration from '../../pages/MemberArea/Auth/Registration';
import VerificationEmail from '../../pages/MemberArea/Auth/VerificationEmail';
import ForgotPassword from '../../pages/MemberArea/Auth/ForgotPassword';
import SocialAuth from '../../pages/MemberArea/Auth/SocialAuth';
// Preview (landing)
// import HomePage from '../../pages/Preview/Home';
import SecurityPage from '../../pages/Preview/Security';
import PartnerPage from '../../pages/Preview/Partner';
import ContactPage from '../../pages/Preview/Contact';
import AboutPage from '../../pages/Preview/About';
import SiteNotReady from '../../pages/Preview/SiteNotReady';

// Payment invoice process
import RegistrationPayment from '../../pages/MemberArea/PaymentInvoice/RegistrationPayment';
import PaymentMethods from '../../pages/MemberArea/PaymentInvoice/PaymentMethods';
import LoginBeforePayment from '../../pages/MemberArea/PaymentInvoice/LoginBeforePayment';
import SuccessfulPayment from '../../pages/MemberArea/PaymentInvoice/SuccessfulPayment';
import ConfirmInvoice from '../../pages/MemberArea/IncompleteInvoices/ConfirmInvoice';
// Terms
import VoucherTerms from '../../pages/Preview/VoucherTerms';
import PrivacyPolicy from '../../pages/Preview/PrivacyPolicy';

// eslint-disable-next-line import/prefer-default-export
export const useRoutes = (
  authenticationState: string,
  isDashboardMenuItemExist: boolean,
): JSX.Element => {
  const pageTryToOpen = localStorage.getItem('pageTryToOpen');
  const startPage = isDashboardMenuItemExist ? '/dashboard' : '/welcome';
  const isVerificationEmailPageTryOpen = pageTryToOpen?.includes(
    '/verification-email',
  );

  const isApiPageTryOpen = pageTryToOpen?.includes('/api');

  const unTrackedPage = isVerificationEmailPageTryOpen || isApiPageTryOpen;

  // eslint-disable-next-line
  const redirectPage =
    pageTryToOpen && !unTrackedPage ? pageTryToOpen : startPage;

  switch (authenticationState) {
    case 'authenticated':
      return (
        <AuthenticatedLayout>
          <Switch>
            <Route
              exact
              path={isDashboardMenuItemExist ? '/dashboard' : '/welcome'}
              component={isDashboardMenuItemExist ? Dashboard : Welcome}
            />
            <Route exact path='/voucher' component={Vouchers} />
            <Route exact path='/transactions' component={Transactions} />
            <Route exact path='/vouchers/:id' component={Transactions} />
            <Route exact path='/top-up' component={TopUp} />
            <Route exact path='/top-up/verify' component={VerifyAccount} />
            <Route exact path='/top-up/:id' component={TopUp} />
            <Route exact path='/top-up/:id/verify' component={VerifyAccount} />
            <Route exact path='/settings' component={Settings} />
            <Route
              exact
              path='/verification/:id'
              component={VerificationDocuments}
            />
            <Route exact path='/incomplete' component={IncompleteInvoices} />
            <Route
              exact
              path='/incomplete-confirm/:id'
              component={ConfirmInvoice}
            />
            <Route exact path='/voucher-terms' component={VoucherTerms} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            {/* 
              /s stands for standaridzed pages routes.
              These standardized pages render different data with similiar shape 
              in two ways: 
              - table view 
              - list view 
            */}
            <Route exact path='/s/:tableName' component={Standardized} />
            <Route exact path='/s/:tableName/:id' component={SingleItem} />
            <Route exact path='/form-constructor' component={FormConstructor} />
            <Route
              exact
              path='/form-constructor/:formName'
              component={FormConstructor}
            />
            <Redirect to={redirectPage} />
          </Switch>
        </AuthenticatedLayout>
      );

    case 'unauthenticated':
      return (
        <UnauthenticatedLayout>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/register' component={Registration} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route
              exact
              path='/auth/:authProvider/callback'
              component={SocialAuth}
            />
            <Route exact path='/home' component={SiteNotReady} />
            <Route exact path='/security' component={SecurityPage} />
            <Route exact path='/partner' component={PartnerPage} />
            <Route exact path='/contact' component={ContactPage} />
            <Route exact path='/about' component={AboutPage} />
            <Route exact path='/voucher-terms' component={VoucherTerms} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route
              exact
              path='/verification-email/:id'
              component={VerificationEmail}
            />
            {/* Payment auth routes start */}
            <Route
              exact
              path='/register-payment'
              component={RegistrationPayment}
            />
            <Route
              exact
              path='/payment-method/:id'
              component={PaymentMethods}
            />
            <Route exact path='/login-payment' component={LoginBeforePayment} />
            <Route exact path='/incomplite' component={SuccessfulPayment} />
            {/* Payment auth routes end */}
            <Redirect to='/home' />
          </Switch>
        </UnauthenticatedLayout>
      );

    default:
      return (
        <UnauthenticatedLayout>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Redirect to='/login' />
          </Switch>
        </UnauthenticatedLayout>
      );
  }
};
