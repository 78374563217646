import styled from 'styled-components';
import {Layout} from 'antd';

const {Sider} = Layout;

export const SideBarContainer = styled(Sider)`
  overflow: auto;
  min-height: 100vh;
  padding: 32px 32px 0 32px;
  background-color: #f8f8fb;
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.05);

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SideBarInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoNavPart = styled.div`
  margin-bottom: 32px;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 32px;
`;

export const NavigationContainer = styled.ul`
  display: block;
  margin-top: 42px;
`;

export const MenuItem = styled.li<{isActive?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: ${(p) =>
    p.isActive ? p.theme.main.colors.black : p.theme.main.colors.darkGrey};
  width: 100%;
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.main.colors.black};
  }
`;

export const MenuItemExpanded = styled.li<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  color: ${(p) => p.theme.main.colors.darkGrey};
  width: 100%;
  cursor: pointer;

  &:hover {
    color: ${(p) =>
      p.isActive ? p.theme.main.colors.darkGrey : p.theme.main.colors.black};
  }
`;

export const SubNavigationContainer = styled.ul<{open: boolean}>`
  display: ${(p) => (p.open ? 'block' : 'none')};
  overflow: hidden;
  padding: 0 18px;
`;

export const SubMenuItem = styled.li<{isActive: boolean}>`
  margin-top: 20px;
  color: ${(p) =>
    p.isActive ? p.theme.main.colors.black : p.theme.main.colors.darkGrey};

  &:hover {
    color: ${(p) => p.theme.main.colors.black};
  }
`;

export const MenuItemText = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  margin-left: 8px;
`;

export const CopyrightText = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  padding-bottom: 32px;
`;

export const MenuItemExpandedInner = styled.div<{isBadgeExist?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuItemInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconMenuItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DepositButton = styled.button`
  background-color: ${(p) => p.theme.main.colors.brand};
  display: flex;
  align-items: center;
  padding: 20px 60px;
  border-radius: 8px;
`;

export const DepositButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.white};
  margin-left: 8px;
`;

export const ContactLink = styled.a`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-bottom: 20px;

  &:hover {
    color: ${(p) => p.theme.main.colors.black};
  }
`;

export const ContactLinkText = styled.span`
  margin-left: 8px;
`;

export const TextIcon = styled.div`
  display: flex;
`;

export const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  margin-left: 8px;
  background-color: ${(p) => p.theme.main.colors.red};
  border-radius: 50%;
`;
