import {IButtonItem, IFormValues} from './ListTreatBar.types';
import {IFilterItem} from '../../Standardized/FiltersDrawer/FiltersDrawer.types';
import {createQueryString} from '../../../../../utils';

type MultiSelectValue = string[] | string | undefined;

interface IRemoveFieldMapArgs {
  initialObject: IFormValues;
  fieldName: string;
}

interface IUpdateMapValuesArgs {
  formValues: IFormValues;
  filterValues: IFormValues;
  fieldName: string;
}

interface INewMapValues {
  newFilterValues: IFormValues;
  newFormValues: IFormValues;
}

export const formFilterBarData = (
  buttonList: IButtonItem[],
  filterList: IFilterItem[],
) => {
  const dateRangeAction = buttonList.find(
    (itm: IButtonItem) => itm.action === 'date-range',
  );

  const filterAction = buttonList.find(
    (itm: IButtonItem) => itm.action === 'filter',
  );

  if (dateRangeAction && filterAction) {
    return [dateRangeAction, ...filterList];
  }

  return [];
};

export const defineGridMap = (listLen: number) => {
  const nonChangedPart = {gutter: 16, xs: 1, sm: 1, md: 1};

  if (listLen <= 3) {
    return {
      ...nonChangedPart,
      lg: 1,
      xl: listLen < 3 ? listLen : 3,
      xxl: listLen < 3 ? listLen : 3,
    };
  }
  return {
    ...nonChangedPart,
    lg: 2,
    xl: listLen < 2 ? listLen : 2,
    xxl: listLen < 4 ? listLen : 4,
  };
};

export const buildQueryString = (formValues: IFormValues): string => {
  const {calendar, trn_amount, ...restValueMap} = formValues;
  const queryString = createQueryString(restValueMap);

  const queryStringDate = calendar ? `${queryString}&${calendar}` : queryString;

  const finalQueryString = trn_amount
    ? `trn_amount=${trn_amount}&${queryStringDate}`
    : queryStringDate;

  return finalQueryString;
};

export const makeMultiSelectValue = (value: MultiSelectValue): string[] => {
  if (!value) return [];
  if (typeof value === 'string') return [value];
  if (Array.isArray(value)) return value;
  return [];
};

const removeFieldMap = (args: IRemoveFieldMapArgs): IFormValues => {
  const {initialObject, fieldName} = args;

  const updatedMap = Object.entries(initialObject).reduce(
    (acc: IFormValues, item) => {
      const [key, value] = item;
      if (key !== fieldName) acc[key] = value;
      return acc;
    },
    {},
  );

  return updatedMap;
};

export const updateMapValues = (args: IUpdateMapValuesArgs): INewMapValues => {
  const {formValues, filterValues, fieldName} = args;

  const newFilterValues = removeFieldMap({
    fieldName,
    initialObject: filterValues,
  });

  const newFormValues = removeFieldMap({
    fieldName,
    initialObject: formValues,
  });

  return {newFilterValues, newFormValues};
};
