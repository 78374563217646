import api from './config';
import {TOP_UP} from './constants';
import {IPaymentDetailsMap} from '../state/TopUp/topUp.types';

const initializeTopUp = () => api.get(TOP_UP.INIT);
const initializeTopUpId = (id: string) => api.get(`${TOP_UP.INIT}/${id}`);
const sendTopUp = (slugUrl: string, topUpData: IPaymentDetailsMap) =>
  api.post(slugUrl, {...topUpData});

export {initializeTopUp, initializeTopUpId, sendTopUp};
