import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const AccountsIcon: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.07 6.38055C6.55735 6.38055 5.33111 5.15431 5.33111 3.64166C5.33111 2.12901 6.55735 0.902771 8.07 0.902771C9.58265 0.902771 10.8089 2.12901 10.8089 3.64166C10.8089 5.15431 9.58265 6.38055 8.07 6.38055ZM8.07 7.4761C10.9915 7.4761 13.5 9.09722 13.5 12.4061C13.5 13.4419 11.9044 15.0972 8.07 15.0972C4.23556 15.0972 2.5 13.5614 2.5 12.4061C2.5 9.09722 5.14852 7.4761 8.07 7.4761Z'
      fill={fillColor}
    />
  </svg>
);
