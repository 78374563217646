import {FC, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Form} from 'antd';
import {useIntl} from 'react-intl';
import {useLocalValidation} from '../../../../hooks';
import {RootState} from '../../../../../state/store';
import {loginUser, loginUserPayment} from '../../../../../state';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';
import {ILoginUserData} from '../../Auth/Auth.types';
import {
  FormItem,
  FormItemButton,
  InputWrapper,
  InputPasswordWrapper,
  ButtonWrapper,
  ForgotPasswordText,
} from '../../Auth/Auth.styles';

const LoginForm: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const {search} = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const {formatMessage} = useIntl();
  const {emailRules} = useLocalValidation(formatMessage);

  const dispatch = useDispatch();
  const {loading} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  const [initialFormValues, setInitialFormValues] = useState({});
  const [emailQueryString, setEmailQueryString] = useState('');

  const loginUserHandler = (values: ILoginUserData): void => {
    const checkBalance = urlSearchParams.get('check_balance') || '';
    const invoiceId = urlSearchParams.get('inv_id') || '';

    if (checkBalance) {
      if (checkBalance === '1') {
        localStorage.setItem('pageTryToOpen', '/incomplete');
        dispatch(loginUser(values.email, values.password));
        return;
      }
      localStorage.setItem('redirect_url_payment', `api/payment/${invoiceId}`);
      dispatch(loginUserPayment(values.email, values.password));
    }
  };

  useEffect(() => {
    const email = urlSearchParams.get('email') || '';
    setEmailQueryString(email);
  }, []);

  useEffect(() => {
    if (!emailQueryString.length) return;

    setInitialFormValues({
      email: emailQueryString,
      password: '',
    });
  }, [emailQueryString]);

  if (Object.keys(initialFormValues).length === 0) return <div />;

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={loginUserHandler}
      initialValues={initialFormValues}
    >
      <FormItem
        label={formatMessage({id: 'EMAIL_INPUT_FIELD'})}
        name='email'
        rules={emailRules}
      >
        <InputWrapper
          type='email'
          autoComplete='off'
          $borderRadius='8px'
          placeholder={formatMessage({id: 'EMAIL_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'PASSWORD_INPUT_FIELD'})}
        name='password'
      >
        <InputPasswordWrapper
          $borderRadius='8px'
          placeholder={formatMessage({id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <ForgotPasswordText to='/forgot-password'>
        {formatMessage({id: 'FORGOT_PASSWORD_LINK'})}
      </ForgotPasswordText>
      <FormItemButton>
        <ButtonWrapper
          type='primary'
          htmlType='submit'
          $borderRadius='8px'
          loading={loading}
        >
          {formatMessage({id: 'LOGIN_BUTTON'})}
        </ButtonWrapper>
      </FormItemButton>
    </Form>
  );
};

export default LoginForm;
