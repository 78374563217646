import {IFormValues} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

// eslint-disable-next-line import/prefer-default-export
export const convertStringValueToNumber = (mapValue: IFormValues) => {
  const entries = Object.entries(mapValue);
  const newObj: IFormValues = {};

  entries.forEach((itm: any) => {
    newObj[itm[0]] = +itm[1];
  });

  return newObj;
};
