import {FC} from 'react';
import {IAmountCurrencyProps} from './AmountCurrency.types';
import {Label, Input, Container} from './AmountCurrency.styles';

const AmountCurrency: FC<IAmountCurrencyProps> = ({
  value,
  name,
  label,
  handleChange,
}: IAmountCurrencyProps) => (
  <Container>
    <Label htmlFor='amount'>Amount</Label>
    <Input
      id='amount'
      type='text'
      name={name}
      value={value}
      onChange={handleChange}
      placeholder={label}
    />
  </Container>
);

export default AmountCurrency;
