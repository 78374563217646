import {LoadingOutlined} from '@ant-design/icons';
import {Container, StyledSpin} from './ChatSkeleton.styles';

const ChatSkeleton = () => (
  <Container>
    <StyledSpin
      spinning
      tip='Loading...'
      size='large'
      indicator={
        <LoadingOutlined spin style={{fontSize: 60, color: '#7367FE'}} />
      }
    />
  </Container>
);

export default ChatSkeleton;
