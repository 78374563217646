import {AnyAction} from 'redux';
import {IExportFileState} from './exportFile.types';
import {
  EXPORT_FILE_REQUEST,
  EXPORT_FILE_SUCCESS,
  EXPORT_FILE_FAILURE,
  CLEAR_EXPORT_FILE_RES,
} from './exportFile.actions';

const initialState: IExportFileState = {
  loading: false,
  exportFileMap: null,
  error: null,
};

const exportFileReducer = (
  state: IExportFileState = initialState,
  action: AnyAction,
): IExportFileState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case EXPORT_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case EXPORT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        exportFileMap: action.payload,
        error: null,
      };

    case CLEAR_EXPORT_FILE_RES:
      return {
        ...state,
        exportFileMap: null,
      };

    // FAILURE REQUEST ACTIONS
    case EXPORT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default exportFileReducer;
