import {Select} from 'antd';
import {FC} from 'react';
import {Down} from '../../../../static/images';
import {StyledSelect} from './FormSelect.styles';
import {IFormSelectProps} from './FormSelect.types';

const {Option} = Select;

const FormSelect: FC<IFormSelectProps> = ({
  valueSelect,
  keyProp,
  placeholder,
  options,
  handleSelectChange,
}: IFormSelectProps) => (
  <StyledSelect
    value={valueSelect}
    placeholder={placeholder}
    optionFilterProp='key'
    suffixIcon={<Down />}
    onSelect={(value, option) => {
      handleSelectChange(keyProp, option);
    }}
  >
    {options.map((option) => (
      <Option key={option[0]} value={option[0]}>
        {option[1]}
      </Option>
    ))}
  </StyledSelect>
);

export default FormSelect;
