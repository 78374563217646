import api from './config';
import {CHAT} from './constants';
import {
  IChangeStatusChatBody,
  IGetOneChatBody,
  ISendMessageBody,
} from '../state/Chat/chat.types';

const getAllChats = () => api.post(CHAT.GET_ALL_CHATS);

const getOneChat = (body: IGetOneChatBody) =>
  api.post(CHAT.GET_ONE_CHAT, {...body});

const sendMessage = (body: ISendMessageBody) =>
  api.post(CHAT.SEND_MESSAGE, {...body});

const deleteMessage = (id: string) =>
  api.delete(`${CHAT.DELETE_MESSAGE}/${id}`);

const changeChatStatus = (body: IChangeStatusChatBody) =>
  api.post(CHAT.CHANGE_CHAT_STATUS, {...body});

const deleteChat = (id: string) => api.delete(`${CHAT.DELETE_CHAT}/${id}`);

const getClientList = () => api.get(CHAT.CLIENT_LIST);

export {
  getAllChats,
  getOneChat,
  sendMessage,
  deleteMessage,
  changeChatStatus,
  deleteChat,
  getClientList,
};
