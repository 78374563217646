import giropay from '../../../../../styles/img/giropay.png';
import klarna from '../../../../../styles/img/klarna.png';
import paypal from '../../../../../styles/img/paypal.png';
import skrill from '../../../../../styles/img/skrill.png';
import visa from '../../../../../styles/img/visa.png';
import card from '../../../../../styles/img/card.svg';

// eslint-disable-next-line import/prefer-default-export
export const defineIcon = (iconName: string) => {
  switch (iconName) {
    case 'giropay':
      return <img src={giropay} alt='giropay' />;
    case 'sofort/klarna':
      return <img src={klarna} alt='klarna' />;
    case 'paypal':
      return <img src={paypal} alt='paypal' />;
    case 'skrill':
      return <img src={skrill} alt='scrill' />;
    case 'visa/mastercard':
      return <img src={visa} alt='visa' />;
    default:
      return <img src={card} alt='payment' />;
  }
};
