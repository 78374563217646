import {FC} from 'react';
import '../../../styles/preview.css';
import PartnerForm from './PartnerForm';

const PartnerPageText: FC = (): JSX.Element => (
  <>
    <section className='main-section main-section-color-line business'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 wrapper-content'>
            <h1 className='main-section__main-title main-title'>
              Increase your revenue with Blixti customers
            </h1>
            <p className='main-section__description standard-description mb-0'>
              Blixti is an alternative payment provider for consumers who don’t
              have access to traditional online payment methods, or simply don’t
              trust sharing their personal or financial data online. Alternate
              payment methods have boomed in the wake of the global pandemic, as
              housebound consumers around the world have turned to online
              shopping. Blixti’s 300+ local payment methods allow you to take
              advantage of this new and expanding customer base in countries
              where your company doesn’t have a local presence.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className='section contact-form contact-form--business'>
      <div className='business-container'>
        <div className='row business-row'>
          <div className='col-12 col-md-6 business-form'>
            <div className='form-container form-container--partner'>
              <PartnerForm />
            </div>
          </div>
          <div className='col-12 col-md-6 content-block wrapper-content-column business-form-banner'>
            <div className='form-side--partner'>
              <h3 className='section__title'>Get your free quote today</h3>
              <p className='standard-description'>
                Simply fill out our online form and a member from Blixti will
                get in touch with a personalised offer.
              </p>
              <ul className='list-questions'>
                <li>
                  <p className='section__small-description'>
                    General inquiries:
                  </p>
                  <a
                    href='mailto:info@blixti.com'
                    rel='noopener noreferrer'
                    className='standard-description link'
                  >
                    info@blixti.com
                  </a>
                </li>
                <li>
                  <p className='section__small-description'>
                    Customer Support:
                  </p>
                  <a
                    href='mailto:support@blixti.com'
                    rel='noopener noreferrer'
                    className='standard-description link'
                  >
                    support@blixti.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default PartnerPageText;
