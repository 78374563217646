import {AnyAction} from 'redux';
import {
  INIT_TOP_UP_REQUEST,
  INIT_TOP_UP_SUCCESS,
  INIT_TOP_UP_FAILURE,
  INIT_TOP_UP_ID_REQUEST,
  INIT_TOP_UP_ID_SUCCESS,
  INIT_TOP_UP_ID_FAILURE,
  SEND_TOP_UP_REQUEST,
  SEND_TOP_UP_SUCCESS,
  SEND_TOP_UP_FAILURE,
  SET_PAYMENT_DETAILS_TOP_UP,
  CLEAR_TOP_UP_RESPONSE,
} from './topUp.actions';
import {ITopUpState} from './topUp.types';

const initialState: ITopUpState = {
  loading: false,
  topUpList: null,
  topUpPaymentDetails: null,
  paymentDetailsMap: null,
  topUpSendSuccess: null,
  error: null,
  errorTopUpSend: null,
};

const topUpReducer = (
  state: ITopUpState = initialState,
  action: AnyAction,
): ITopUpState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case INIT_TOP_UP_REQUEST:
    case INIT_TOP_UP_ID_REQUEST:
      return {
        ...state,
        topUpList: null,
        topUpPaymentDetails: null,
        paymentDetailsMap: null,
        topUpSendSuccess: null,
        errorTopUpSend: null,
        loading: true,
        error: null,
      };
    case SEND_TOP_UP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case INIT_TOP_UP_SUCCESS:
    case INIT_TOP_UP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        topUpList: action.payload,
        error: null,
      };

    case SEND_TOP_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        topUpSendSuccess: action.payload,
        error: null,
      };

    // MISC ACTIONS
    case SET_PAYMENT_DETAILS_TOP_UP:
      return {
        ...state,
        paymentDetailsMap: action.payload,
      };

    case CLEAR_TOP_UP_RESPONSE:
      return {
        ...state,
        topUpSendSuccess: null,
      };

    // FAILURE REQUEST ACTIONS
    case INIT_TOP_UP_FAILURE:
    case INIT_TOP_UP_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SEND_TOP_UP_FAILURE:
      return {
        ...state,
        loading: false,
        errorTopUpSend: action.payload,
      };

    default:
      return state;
  }
};

export default topUpReducer;
