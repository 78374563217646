import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Reports: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 2C2 0.895432 2.89543 0 4 0H8.58579C9.11622 0 9.62493 0.210714 10 0.585786L13.4142 4C13.7893 4.37507 14 4.88378 14 5.41421V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2ZM4 8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 7.44772 12 8C12 8.55229 11.5523 9 11 9H5C4.44772 9 4 8.55229 4 8ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H5Z'
      fill={fillColor}
    />
  </svg>
);
