import {
  IGetAllChatsResponse,
  IGetOneChatResponse,
  ISuccessResponse,
} from '../chat.types';

// GET_ALL_CHATS TYPES & ACTIONS
export const GET_ALL_CHATS_REQUEST: string = 'GET_ALL_CHATS_REQUEST';
export const GET_ALL_CHATS_SUCCESS: string = 'GET_ALL_CHATS_SUCCESS';
export const GET_ALL_CHATS_FAILURE: string = 'GET_ALL_CHATS_FAILURE';

export const getAllChatsRequestAction = (): {type: string} => ({
  type: GET_ALL_CHATS_REQUEST,
});

export const getAllChatsSuccessAction = (payload: IGetAllChatsResponse) => ({
  type: GET_ALL_CHATS_SUCCESS,
  payload,
});

export const getAllChatsFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_ALL_CHATS_FAILURE,
  payload,
});

// GET_ONE_CHAT TYPES & ACTIONS
export const GET_ONE_CHAT_REQUEST: string = 'GET_ONE_CHAT_REQUEST';
export const GET_ONE_CHAT_SUCCESS: string = 'GET_ONE_CHAT_SUCCESS';
export const GET_ONE_CHAT_FAILURE: string = 'GET_ONE_CHAT_FAILURE';

export const getOneChatRequestAction = (): {type: string} => ({
  type: GET_ONE_CHAT_REQUEST,
});

export const getOneChatSuccessAction = (payload: IGetOneChatResponse) => ({
  type: GET_ONE_CHAT_SUCCESS,
  payload,
});

export const getOneChatFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_ONE_CHAT_FAILURE,
  payload,
});

// CHANGE_CHAT_STATUS TYPES & ACTIONS
export const CHANGE_CHAT_STATUS_REQUEST: string = 'CHANGE_CHAT_STATUS_REQUEST';
export const CHANGE_CHAT_STATUS_SUCCESS: string = 'CHANGE_CHAT_STATUS_SUCCESS';
export const CHANGE_CHAT_STATUS_FAILURE: string = 'CHANGE_CHAT_STATUS_FAILURE';

export const changeChatStatusRequestAction = (): {type: string} => ({
  type: CHANGE_CHAT_STATUS_REQUEST,
});

export const changeChatStatusSuccessAction = (payload: ISuccessResponse) => ({
  type: CHANGE_CHAT_STATUS_SUCCESS,
  payload,
});

export const changeChatStatusFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CHANGE_CHAT_STATUS_FAILURE,
  payload,
});

// DELETE_CHAT TYPES & ACTIONS
export const DELETE_CHAT_REQUEST: string = 'DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS: string = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAILURE: string = 'DELETE_CHAT_FAILURE';

export const deleteChatRequestAction = (): {type: string} => ({
  type: DELETE_CHAT_REQUEST,
});

export const deleteChatSuccessAction = (payload: ISuccessResponse) => ({
  type: DELETE_CHAT_SUCCESS,
  payload,
});

export const deleteChatFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DELETE_CHAT_FAILURE,
  payload,
});

// SET_CHAT_ID TYPES & ACTIONS
export const SET_CHAT_ID: string = 'SET_CHAT_ID';

export const setChatId = (
  payload: number,
): {type: string; payload: number} => ({
  type: SET_CHAT_ID,
  payload,
});

// SET_CHAT_NAME TYPES & ACTIONS
export const SET_CHAT_NAME: string = 'SET_CHAT_NAME';

export const setChatName = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_CHAT_NAME,
  payload,
});

// CLEAR_ONE_CHAT TYPES & ACTIONS
export const CLEAR_ONE_CHAT: string = 'CLEAR_ONE_CHAT';

export const clearOneChat = (): {type: string} => ({
  type: CLEAR_ONE_CHAT,
});
