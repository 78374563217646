import {AxiosResponse} from 'axios';
import {postProfileInformation} from '../../../infra/profile.service';
import {ThunkActionType} from '../../store';
import {IProfileMap} from '../profile.types';
import {
  changePasswordRequestAction,
  changePasswordSuccessAction,
  changePasswordFailureAction,
} from './actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseSuccessAndFailNotification,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const changePasswordRequest =
  (changedPasswordMap: IProfileMap): ThunkActionType =>
  (dispatch) => {
    dispatch(changePasswordRequestAction());

    return postProfileInformation(changedPasswordMap)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: changePasswordSuccessAction,
          failureAction: changePasswordFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: changePasswordFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
