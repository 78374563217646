import styled from 'styled-components';

export const BadgeOuter = styled.div`
  display: flex;
  align-items: center;
`;

export const Badge = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: ${(p) => p.theme.main.colors.red};
  border-radius: 50%;
`;

export const Counter = styled.p`
  height: 18px;
  margin-right: 8px;
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
`;
