import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const PeriodDivider = styled.div`
  ${(p) => p.theme.main.typography.captionMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-bottom: 16px;
  position: relative;
  &:after {
    content: '';
    width: calc(100% - 120px);
    height: 1px;
    background-color: #ebebeb;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }
`;
