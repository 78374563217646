import {FC, useState} from 'react';
import {
  Down,
  RedArrowInclined,
  GreenArrowInclined,
} from '../../../../static/images';
import {Print} from '../../../../static/images/Print';
import {IActivityItem, IPanelHeaderProps} from './CollapseList.types';
import {
  ArrowWrapperTop,
  CollapseContent,
  CollapseContentPartLeft,
  Contractor,
  DetailedInfoBlock,
  PanelInner,
  PaymentAmount,
  PaymentDate,
  PaymentState,
  PaymentStateBlock,
  StyledCollapse,
  StyledPanel,
  Heading,
  DescriptionText,
  DescriptionTextColored,
  CollapseContentPartRight,
  StyledTag,
  DetailsRow,
  DetailsTotal,
  DescriptionTextMedium,
  PrintDetailsBlock,
  HeadingMarginLeft,
  ArrowWrapperBottom,
  ArrowWrapperRotate,
} from './CollapseList.styles';

const defineTagBgColor = (tagStatus: string): string => {
  switch (tagStatus) {
    case 'success':
      return '#d1fae5';
    case 'open_pay':
      return '#ffedd5';
    default:
      return '#ffe2e2';
  }
};

const PanelHeader: FC<IPanelHeaderProps> = ({
  collapsedKeysList,
  contractorName,
  isPaymentReceived,
  paymentPeriod,
  paymentAmount,
  currency,
}: IPanelHeaderProps) => (
  <PanelInner>
    <div>
      <Contractor>{contractorName}</Contractor>
      <PaymentStateBlock>
        {isPaymentReceived === 1 ? (
          <GreenArrowInclined />
        ) : (
          <RedArrowInclined />
        )}
        <PaymentState>
          {isPaymentReceived === 1 ? 'Payment received' : 'Payment sent'}
        </PaymentState>
      </PaymentStateBlock>
      <PaymentDate>
        {new Date(paymentPeriod).toLocaleDateString('en-US', {
          year: '2-digit',
          month: 'short',
        })}
      </PaymentDate>
    </div>
    <div>
      <PaymentAmount isPaymentReceived={isPaymentReceived}>
        {isPaymentReceived === 1 ? '+' : '-'}
        {paymentAmount} {currency}
      </PaymentAmount>
      <ArrowWrapperTop>
        {!collapsedKeysList?.length ? <Down /> : null}
      </ArrowWrapperTop>
    </div>
  </PanelInner>
);

const CollapseList: FC<IActivityItem> = ({
  id,
  currency,
  contractorInfo,
  paymentPeriod,
  paymentAmount,
  transactionId,
  paymentMethod,
  paymentStatus,
  isPaymentReceived,
}: IActivityItem): JSX.Element => {
  const [collapsedKeysList, setCollapsedKeysList] = useState<string | string[]>(
    [],
  );

  return (
    <StyledCollapse
      onChange={(key) => setCollapsedKeysList(key)}
      activeKey={collapsedKeysList}
    >
      <StyledPanel
        key={id}
        showArrow={false}
        header={
          <PanelHeader
            collapsedKeysList={collapsedKeysList}
            contractorName={contractorInfo.name}
            isPaymentReceived={isPaymentReceived}
            paymentPeriod={paymentPeriod}
            paymentAmount={paymentAmount}
            currency={currency}
          />
        }
      >
        <CollapseContent>
          <CollapseContentPartLeft>
            <DetailedInfoBlock>
              <Heading>Transaction ID</Heading>
              <DescriptionText>{transactionId}</DescriptionText>
            </DetailedInfoBlock>
            <DetailedInfoBlock>
              <Heading>Paid with</Heading>
              <DescriptionText>{paymentMethod}</DescriptionText>
              <DescriptionText>
                {isPaymentReceived === 1 ? '+' : '-'}
                {paymentAmount} {currency}
              </DescriptionText>
            </DetailedInfoBlock>
            <div>
              <Heading>Contact Information</Heading>
              <DescriptionTextColored>
                {contractorInfo.name}
              </DescriptionTextColored>
              <DescriptionTextColored>
                {contractorInfo.website}
              </DescriptionTextColored>
              <DescriptionTextColored>
                {contractorInfo.email}
              </DescriptionTextColored>
            </div>
          </CollapseContentPartLeft>
          <CollapseContentPartRight>
            <DetailedInfoBlock>
              <Heading>Status</Heading>
              <StyledTag
                color={defineTagBgColor(paymentStatus.toLowerCase())}
                status={paymentStatus.toLowerCase()}
              >
                {paymentStatus}
              </StyledTag>
            </DetailedInfoBlock>
            <DetailedInfoBlock>
              <Heading>Details</Heading>
              <DetailsRow>
                <DescriptionText>{contractorInfo.name}</DescriptionText>
                <DescriptionText>
                  {isPaymentReceived === 1 ? '+' : '-'}
                  {paymentAmount} {currency}
                </DescriptionText>
              </DetailsRow>
              <DetailsTotal>
                <DescriptionTextMedium>Total</DescriptionTextMedium>
                <DescriptionTextMedium>
                  {isPaymentReceived === 1 ? '+' : '-'}
                  {paymentAmount} {currency}
                </DescriptionTextMedium>
              </DetailsTotal>
            </DetailedInfoBlock>
            <PrintDetailsBlock>
              <Print fillColor='#74828A' />
              <HeadingMarginLeft>Print Details</HeadingMarginLeft>
            </PrintDetailsBlock>
            <ArrowWrapperBottom>
              <ArrowWrapperRotate onClick={() => setCollapsedKeysList([])}>
                {collapsedKeysList?.length ? <Down /> : null}
              </ArrowWrapperRotate>
            </ArrowWrapperBottom>
          </CollapseContentPartRight>
        </CollapseContent>
      </StyledPanel>
    </StyledCollapse>
  );
};

export default CollapseList;
