// CHANGE_EMAIL TYPES & ACTIONS
export const CHANGE_EMAIL_REQUEST: string = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS: string = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE: string = 'CHANGE_EMAIL_FAILURE';

export const changeEmailRequestAction = (): {type: string} => ({
  type: CHANGE_EMAIL_REQUEST,
});

export const changeEmailSuccessAction = (payload: any) => ({
  type: CHANGE_EMAIL_SUCCESS,
  payload,
});

export const changeEmailFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CHANGE_EMAIL_FAILURE,
  payload,
});
