// DESTROY_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const DESTROY_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'DESTROY_INCOMPLETE_DATA_ITEM_REQUEST';
export const DESTROY_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'DESTROY_INCOMPLETE_DATA_ITEM_SUCCESS';
export const DESTROY_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'DESTROY_INCOMPLETE_DATA_ITEM_FAILURE';

export const destroyIncompleteDataItemRequestAction = (): {type: string} => ({
  type: DESTROY_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const destroyIncompleteDataItemSuccessAction = (): {type: string} => ({
  type: DESTROY_INCOMPLETE_DATA_ITEM_SUCCESS,
});

export const destroyIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DESTROY_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});
