// USER_REGISTRATION TYPES & ACTIONS
export const USER_REGISTRATION_REQUEST: string = 'USER_REGISTRATION_REQUEST';
export const USER_REGISTRATION_SUCCESS: string = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAILURE: string = 'USER_REGISTRATION_FAILURE';

export const userRegistrationRequestAction = (): {type: string} => ({
  type: USER_REGISTRATION_REQUEST,
});

export const userRegistrationSuccessAction = (): {type: string} => ({
  type: USER_REGISTRATION_SUCCESS,
});

export const userRegistrationFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: USER_REGISTRATION_FAILURE,
  payload,
});
