import styled from 'styled-components';
import {Layout} from 'antd';

const {Content} = Layout;

export const Container = styled.div`
  position: relative;
`;

export const ContentWrapper = styled(Content)`
  padding: 24px 58px;
  background-color: ${(p) => p.theme.main.colors.snow};
  position: relative;

  @media (max-width: 677px) {
    padding: 24px 24px 120px;
  }

  @media (max-width: 400px) {
    padding: 24px 16px 120px;
  }
`;
