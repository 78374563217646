import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const TopUpIcon: FC<IProps> = ({fillColor = '#10B981'}: IProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V8C16 9.10457 15.1046 10 14 10H13C12.4477 10 12 9.55229 12 9C12 8.44772 12.4477 8 13 8H14V2H2V8H3C3.55228 8 4 8.44772 4 9C4 9.55229 3.55228 10 3 10H2C0.895431 10 0 9.10457 0 8V2Z'
      fill={fillColor}
    />
    <path
      d='M9 9C9 8.44771 8.55229 8 8 8C7.44772 8 7 8.44771 7 9V12H5.60087C5.10662 12 4.8245 12.5131 5.12105 12.8727L7.52017 15.7818C7.76009 16.0727 8.23991 16.0727 8.47983 15.7818L10.879 12.8727C11.1755 12.5131 10.8934 12 10.3991 12H9V9Z'
      fill={fillColor}
    />
    <path
      d='M8 7C9.10457 7 10 6.10457 10 5C10 3.89543 9.10457 3 8 3C6.89543 3 6 3.89543 6 5C6 6.10457 6.89543 7 8 7Z'
      fill={fillColor}
    />
  </svg>
);
