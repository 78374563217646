const AUTH_MESSAGES = {
    // REGISTER PAGE
    CREATE_ACCOUNT_TITLE: 'Create your account',
    ALREADY_EXIST_SUBTITLE: 'Do you already have an account?',
    LOGIN_LINK: 'Log in',
    FIRST_NAME_INPUT_FIELD: 'First name',
    LAST_NAME_INPUT_FIELD: 'Last name',
    CONTINUE_WITH_TEXT: 'Or continue with',
    REPEAT_PASSWORD_INPUT_FIELD: 'Repeat password',
    CREATE_ACCOUNT_BUTTON: 'Create account',
    ACTIVATION_CODE_INPUT_FIELD: 'Activation Code',
    ACTIVATION_CODE_INPUT_FIELD_PLACEHOLDER: 'Enter your activation code',
    ACTIVATION_CODE_BUTTON: 'Activate',

    // LOGIN PAGE
    WELCOME_BACK_TITLE: 'Welcome back',
    ACCOUNT_EXIST_SUBTITLE: 'Don’t have an account?',
    SIGN_UP_LINK: 'Sign up',
    EMAIL_INPUT_FIELD: 'Email address',
    EMAIL_INPUT_FIELD_PLACEHOLDER: 'Enter your email address',
    PASSWORD_INPUT_FIELD: 'Password',
    PASSWORD_INPUT_FIELD_PLACEHOLDER: 'Enter password',
    LOGIN_BUTTON: 'Log in',
    FORGOT_PASSWORD_LINK: 'Forgot password?',
    LOGIN_WITH_TEXT: 'Or log in with',
    LOGIN_AND: 'and',
    PRIVACY_POLICY_TEXT: 'By signing up you agree to our',
    PRIVACY_POLICY_TITLE: 'Privacy Policy',
    TERMS_OF_USE_TITLE: 'Terms of Use',

    // FORGOT PASSWORD PAGE
    FORGOT_PASSWORD_TITLE: 'Forgot password?',
    FORGOT_PASSWORD_SUBTITLE: 'Just enter the email address you registered with and we’ll send you a link to reset your password.',
    FORGOT_PASSWORD_BUTTON: ' Send password reset link',

    // USER SETTINGS
    USER_SETTINGS_PROFILE: 'Profile',
    USER_SETTINGS_VERIFICATION: 'Verification',
    USER_LOGOUT: 'Logout',
}

export default AUTH_MESSAGES;
