import {FC, ChangeEvent} from 'react';
import {useSelector} from 'react-redux';
import {Spin} from 'antd';
import {RootState} from '../../../../../state/store';
import {IFileUploaderProps} from './FileUploader.types';
import {UploadIcon} from '../../../../static/images';
import {
  Input,
  Container,
  Content,
  TextPart,
  Title,
  ColoredTitle,
  BlackTitle,
  SubTitle,
} from './FileUploader.styles';

const FileUploader: FC<IFileUploaderProps> = ({
  documentType,
  uploadFile,
}: IFileUploaderProps) => {
  const {loadingsUploadFiles} = useSelector(
    (state: RootState) => state.verificationReducer,
  );

  const handleFileSelected = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    uploadFile({documentType, files});
  };

  if (loadingsUploadFiles && loadingsUploadFiles[documentType]) {
    return (
      <Spin
        tip='Loading...'
        spinning={loadingsUploadFiles && loadingsUploadFiles[documentType]}
        size='small'
      />
    );
  }

  return (
    <div>
      <Container>
        <Content>
          <UploadIcon />
          <TextPart>
            <Title>
              <ColoredTitle>Upload a file</ColoredTitle>
              <BlackTitle> or drag and drop</BlackTitle>
            </Title>
            <SubTitle>PNG, JPG up to 10MB</SubTitle>
          </TextPart>
        </Content>
        <Input
          multiple
          type='file'
          accept='image/png, image/jpeg, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, xlsx, .pdf'
          onChange={handleFileSelected}
        />
      </Container>
    </div>
  );
};

export default FileUploader;
