import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const RegularButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 5px 20px;
  text-transform: capitalize;
  background-color: ${(p) => p.theme.main.colors.lightPurple};
  border-radius: 8px;
`;

export const FilterButton = styled.button<{isSelected: boolean}>`
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 5px 20px;

  text-transform: capitalize;
  border-radius: 8px;
  background-color: ${(p) =>
    p.isSelected ? p.theme.main.colors.brand : 'transaparent'};
`;

export const ButtonText = styled.span<{marginLeft?: string}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : 0)};
`;

export const FiltersButtonText = styled.span<{
  marginLeft?: string;
  isSelected: boolean;
}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) =>
    p.isSelected ? p.theme.main.colors.white : p.theme.main.colors.black};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : 0)};

  @media (max-width: 400px) {
    display: none;
  }
`;

export const AddButtonText = styled.span<{marginLeft?: string}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : 0)};

  @media (max-width: 400px) {
    display: none;
  }
`;

export const ExportFileButtonText = styled.span`
  margin-left: 8px;

  @media (max-width: 400px) {
    display: none;
  }
`;

export const ButtonTextIcon = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
