import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Refresh: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='13'
    height='16'
    viewBox='0 0 13 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 5.6822L12.0792 6.67906C12.3504 6.65751 12.6012 6.52636 12.7736 6.31586C12.946 6.10535 13.0252 5.83367 12.9929 5.5635L12 5.6822ZM12.4929 1.38129C12.4274 0.832912 11.9297 0.44151 11.3813 0.507071C10.8329 0.572632 10.4415 1.07033 10.5071 1.61871L12.4929 1.38129ZM7.9208 5.00314C7.37025 5.04688 6.9594 5.52865 7.00314 6.0792C7.04688 6.62975 7.52865 7.0406 8.0792 6.99686L7.9208 5.00314ZM12.6857 13.084C13.0082 12.6356 12.9063 12.0107 12.4579 11.6882C12.0096 11.3657 11.3847 11.4677 11.0622 11.916L12.6857 13.084ZM7 14C4.23858 14 2 11.7614 2 9H0C0 12.866 3.13401 16 7 16V14ZM2 9C2 6.23858 4.23858 4 7 4V2C3.13401 2 0 5.13401 0 9H2ZM7 4C8.73817 4 10.2698 4.8861 11.1673 6.23588L12.8327 5.12853C11.5803 3.24491 9.43577 2 7 2V4ZM12.9929 5.5635L12.4929 1.38129L10.5071 1.61871L11.0071 5.80091L12.9929 5.5635ZM11.9208 4.68535L7.9208 5.00314L8.0792 6.99686L12.0792 6.67906L11.9208 4.68535ZM11.0622 11.916C10.1528 13.1801 8.67217 14 7 14V16C9.34288 16 11.4167 14.848 12.6857 13.084L11.0622 11.916Z'
      fill={fillColor}
    />
  </svg>
);
