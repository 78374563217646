// eslint-disable-next-line import/prefer-default-export
export const DocumentCircle = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      d='M21 24H27M21 28H27M24.5858 15C24.851 15 25.1054 15.1054 25.2929 15.2929L30.7071 20.7071C30.8946 20.8946 31 21.149 31 21.4142V32C31 32.5523 30.5523 33 30 33H18C17.4477 33 17 32.5612 17 32.0089C17 28.8663 17 19.1464 17 15.9928C17 15.4405 17.4461 15 17.9984 15C19.8737 15 23.8043 15 24.5858 15Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
