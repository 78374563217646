import {AnyAction} from 'redux';
import {
  GET_TRANSACTIONS_DATA_REQUEST,
  GET_TRANSACTIONS_DATA_SUCCESS,
  GET_TRANSACTIONS_DATA_FAILURE,
  SET_FILTER_TRANSACTIONS_MAP,
  SET_VALUES_TRANSACTIONS_MAP,
  EXTRACT_FILTER_TRANSACTIONS_MAP,
  EXTRACT_VALUES_TRANSACTIONS_MAP,
  CLEAR_FILTER_TRANSACTIONS_MAP,
  CLEAR_VALUES_TRANSACTIONS_MAP,
} from './transactions.actions';
import {ITransactionsState} from './transactions.types';

const initialState: ITransactionsState = {
  loading: false,
  data: null,
  error: null,
  filterMap: null,
  valueMap: null,
};

const transactionsReducer = (
  state: ITransactionsState = initialState,
  action: AnyAction,
): ITransactionsState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_TRANSACTIONS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    // MISC ACTIONS
    case SET_FILTER_TRANSACTIONS_MAP:
      return {
        ...state,
        filterMap: {...state.filterMap, ...action.payload},
      };

    case SET_VALUES_TRANSACTIONS_MAP:
      return {
        ...state,
        valueMap: {...state.valueMap, ...action.payload},
      };

    case EXTRACT_VALUES_TRANSACTIONS_MAP:
      return {
        ...state,
        valueMap: action.payload,
      };

    case EXTRACT_FILTER_TRANSACTIONS_MAP:
      return {
        ...state,
        filterMap: action.payload,
      };

    case CLEAR_FILTER_TRANSACTIONS_MAP:
      return {
        ...state,
        filterMap: null,
      };

    case CLEAR_VALUES_TRANSACTIONS_MAP:
      return {
        ...state,
        valueMap: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_TRANSACTIONS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default transactionsReducer;
