import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Security: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.166107 3.05445C3.17437 3.01363 5.91528 1.86587 8 0C10.0847 1.86587 12.8256 3.01363 15.8339 3.05445C15.9431 3.70522 16 4.37376 16 5.05557C16 10.2804 12.6608 14.7254 8 16.3727C3.33923 14.7254 0 10.2804 0 5.05557C0 4.37376 0.0568637 3.70522 0.166107 3.05445ZM11.7071 6.76265C12.0976 6.37212 12.0976 5.73896 11.7071 5.34844C11.3166 4.95791 10.6834 4.95791 10.2929 5.34844L7 8.64133L5.70711 7.34843C5.31658 6.95791 4.68342 6.95791 4.29289 7.34843C3.90237 7.73896 3.90237 8.37212 4.29289 8.76265L6.29289 10.7626C6.68342 11.1532 7.31658 11.1532 7.70711 10.7626L11.7071 6.76265Z'
      fill={fillColor}
    />
  </svg>
);
