import styled from 'styled-components';

export const Wrapper = styled.div<{marginTop?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({marginTop}) => marginTop};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 450px;
  height: 256px;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 450px;
`;

export const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const Text = styled.p`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  margin: 24px 0;
`;
