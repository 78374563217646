export const omitSpace = (initialValue: string): string => {
  const splittedValue = initialValue.split('');
  const valueOmitSpace = splittedValue.reduce((str, char) => {
    // eslint-disable-next-line no-param-reassign
    if (!(char.indexOf(' ') >= 0)) str += char;
    return str;
  }, '');

  return valueOmitSpace;
};

export const checkValidValue = (initialValue: string): boolean => {
  const pastedValueLength = initialValue.length === 16;
  const pastedValueToNumber = +initialValue;
  const isValidPastedValue = pastedValueLength && pastedValueToNumber;

  return !!isValidPastedValue;
};

export const maskValue = (initialValue: string): string => {
  const pastedValueWithStars = initialValue
    .split('')
    .map((itm, index) => {
      // eslint-disable-next-line no-param-reassign
      if (index > 3 && index < 12) itm = '*';
      return itm;
    })
    .join('');

  return pastedValueWithStars;
};
