import {FC} from 'react';
import coloredStripes from '../../styles/img/colored-stripes.png';
import {IMainCardProps} from './MainCard.types';
import {
  BackgroundImage,
  Button,
  Container,
  Inner,
  Text,
} from './MainCard.styles';

const MainCard: FC<IMainCardProps> = ({
  icon,
  title,
  textButton,
  handleClick,
}: IMainCardProps) => (
  <Container>
    <BackgroundImage src={coloredStripes} alt='background' />
    <Inner>
      {icon}
      <Text>{title}</Text>
      <Button onClick={handleClick}>{textButton}</Button>
    </Inner>
  </Container>
);

export default MainCard;
