import {AxiosResponse} from 'axios';
import {setUser} from '../../../infra/token.service';
import {login} from '../../../infra/auth.service';
import {ThunkActionType} from '../../store';
import {
  userLoginRequestAction,
  userLoginSuccessAction,
  userLoginFailureAction,
} from '../actions/login';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const loginUser =
  (email: string, password: string): ThunkActionType =>
  (dispatch) => {
    dispatch(userLoginRequestAction());

    return login(email, password)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: userLoginSuccessAction,
          failureAction: userLoginFailureAction,
          notificationAction: setNotificationState,
        });
        setUser(response.data);
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: userLoginFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
