import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getBadgeCountRequest, setPageTitleAction} from '../../../../state';
import {RootState} from '../../../../state/store';
import {Title} from './Welcome.styles';

const Welcome = () => {
  const dispatch = useDispatch();

  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  useEffect(() => {
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  useEffect(() => {
    dispatch(setPageTitleAction('Welcome'));
  }, []);

  return <Title>Welcome to Blixti</Title>;
};

export default Welcome;
