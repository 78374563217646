import {LoadingOutlined} from '@ant-design/icons';
import {Container, StyledSpin} from './LoadingMask.styles';

const LoadingMask = () => (
  <Container>
    <StyledSpin
      spinning
      tip='Loading...'
      size='large'
      indicator={
        <LoadingOutlined spin style={{fontSize: 60, color: '#7367FE'}} />
      }
    />
  </Container>
);

export default LoadingMask;
