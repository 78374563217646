import {AnyAction} from 'redux';
import {
  GET_APP_DATA_REQUEST,
  GET_APP_DATA_SUCCESS,
  GET_APP_DATA_FAILURE,
  GET_COUNTRIES_LIST_REQUEST,
  GET_COUNTRIES_LIST_SUCCESS,
  GET_COUNTRIES_LIST_FAILURE,
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAILURE,
  SEND_PARTNER_REQUEST,
  SEND_PARTNER_SUCCESS,
  SEND_PARTNER_FAILURE,
  SET_PAGE_TITLE,
  SET_TIMESTAMP_RELOAD,
  SET_VERIFY_NOTIFICATION_STATE,
  SET_VERIFICATION_URL,
} from './app.actions';
import {IAppState} from './app.types';

const initialState: IAppState = {
  loading: false,
  loadingCountriesList: false,
  data: null,
  countriesList: null,
  pageTitle: '',
  timestampReload: null,
  verifyNotificationShow: false,
  verificationUrl: '',
  error: null,
};

const appReducer = (
  state: IAppState = initialState,
  action: AnyAction,
): IAppState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_APP_DATA_REQUEST:
    case SEND_CONTACT_REQUEST:
    case SEND_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_COUNTRIES_LIST_REQUEST:
      return {
        ...state,
        loadingCountriesList: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_APP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case GET_COUNTRIES_LIST_SUCCESS:
      return {
        ...state,
        loadingCountriesList: false,
        countriesList: action.payload,
        error: null,
      };

    case SEND_CONTACT_SUCCESS:
    case SEND_PARTNER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    // MISC ACTIONS
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };

    case SET_TIMESTAMP_RELOAD:
      return {
        ...state,
        timestampReload: action.payload,
      };

    case SET_VERIFY_NOTIFICATION_STATE:
      return {
        ...state,
        verifyNotificationShow: action.payload,
      };

    case SET_VERIFICATION_URL:
      return {
        ...state,
        verificationUrl: action.payload,
      };

    // FAILURE REQUEST ACTIONS
    case GET_APP_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_COUNTRIES_LIST_FAILURE:
      return {
        ...state,
        loadingCountriesList: false,
        error: action.payload,
      };

    case SEND_CONTACT_FAILURE:
    case SEND_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
