import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getAllChatsRequest} from '../../../state';
import {RootState} from '../../../state/store';
import ChatContainer from './ChatContainer';

const ChatVerification = () => {
  const dispatch = useDispatch();
  const {allChats} = useSelector((state: RootState) => state.chatReducer);

  const formateDateTime = (dateTimeString: string): string => {
    const dateTime = new Date(dateTimeString);
    const userTimezoneOffset = dateTime.getTimezoneOffset() * 60000;
    const dateTimeOffset = new Date(dateTime.getTime() - userTimezoneOffset);

    return dateTimeOffset.toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  useEffect(() => {
    if (allChats) return;
    dispatch(getAllChatsRequest());
  }, []);

  return <ChatContainer formateDateTime={formateDateTime} />;
};

export default ChatVerification;
