import {AxiosResponse} from 'axios';
import {getBadgeCount, changeStatusNotification} from '../../infra/badgeCount';
import {ThunkActionType} from '../store';
import {
  getBadgeCountRequestAction,
  getBadgeCountSuccessAction,
  getBadgeCountFailureAction,
  changeStatusNotificationRequestAction,
  changeStatusNotificationSuccessAction,
  changeStatusNotificationFailureAction,
} from './badgeCount.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';

export const getBadgeCountRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getBadgeCountRequestAction());

  return getBadgeCount()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getBadgeCountSuccessAction,
        failureAction: getBadgeCountFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getBadgeCountFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const changeStatusNotificationRequest =
  (id: string): ThunkActionType =>
  (dispatch) => {
    dispatch(changeStatusNotificationRequestAction());

    return changeStatusNotification(id)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: changeStatusNotificationSuccessAction,
          failureAction: changeStatusNotificationFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: changeStatusNotificationFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
