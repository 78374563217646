import {FC} from 'react';

interface Props {
  // eslint-disable-next-line
  onClick?: any;
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const More: FC<Props> = ({onClick, fillColor = '#192B3B'}: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 2C10 3.105 9.105 4 8 4C6.895 4 6 3.105 6 2C6 0.895 6.895 -4.83011e-08 8 0C9.105 4.83011e-08 10 0.895 10 2ZM10 8C10 9.105 9.105 10 8 10C6.895 10 6 9.105 6 8C6 6.895 6.895 6 8 6C9.105 6 10 6.895 10 8ZM10 14C10 15.105 9.105 16 8 16C6.895 16 6 15.105 6 14C6 12.895 6.895 12 8 12C9.105 12 10 12.895 10 14Z'
      fill={fillColor}
    />
  </svg>
);
