import {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Popover} from 'antd';
import {useMediaQuery} from 'react-responsive';
import {setVerifyNotificationState} from '../../../../../state';
import WithdrawPopup from '../WithdrawPopup';
import {TopUpIcon, Deposit} from '../../../../static/images';
import coloredStripes from '../../../../styles/img/colored-stripes.png';
import dashedLine from '../../../../styles/img/dashed-line.png';
import logoIco from '../../../../styles/img/logo-transparent.png';
import {
  ColoredStripes,
  Button,
  ButtonGroup,
  Container,
  Inner,
  DashedLine,
  ButtonText,
  LogoIco,
  Content,
  Balance,
  VoucherNumbers,
  CardNumbers,
  CardNumbersMobile,
  VoucherAmount,
  VoucherTop,
  IconWrapper,
} from './FilledVoucher.styles';
import {IFilledVoucherProps} from './FilledVoucher.types';

const FilledVoucher: FC<IFilledVoucherProps> = ({
  voucherId,
  balance,
  currency,
  accountNumber,
}: IFilledVoucherProps) => {
  const maxWidth400PX = useMediaQuery({
    query: '(max-width: 400px)',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [showPopover, setShowPopover] = useState(false);

  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  const handleVisibleChange = (visible: boolean) => {
    setShowPopover(visible);
  };

  const addFunds = () => {
    if (userNotVerified) {
      dispatch(setVerifyNotificationState(true));
      return;
    }
    history.push(`/top-up/${voucherId}`);
  };

  return (
    <Container>
      <Inner>
        <Content>
          <VoucherTop>
            <LogoIco src={logoIco} alt='logo' />
            <Balance>Balance:</Balance>
          </VoucherTop>
          <VoucherNumbers>
            <CardNumbers>{accountNumber}</CardNumbers>
            <VoucherAmount>
              {balance} {currency}
            </VoucherAmount>
            <CardNumbersMobile>{accountNumber}</CardNumbersMobile>
          </VoucherNumbers>
        </Content>
        <ColoredStripes src={coloredStripes} alt='background' />
        <ButtonGroup>
          <Button onClick={addFunds}>
            <Deposit fillColor='#fff' />
            <ButtonText>Add Funds</ButtonText>
          </Button>
          <Popover
            overlayInnerStyle={{padding: maxWidth400PX ? 0 : 40}}
            content={<WithdrawPopup />}
            trigger='click'
            visible={showPopover}
            onVisibleChange={handleVisibleChange}
          >
            <Button>
              <IconWrapper>
                <TopUpIcon fillColor='#fff' />
              </IconWrapper>
              <ButtonText>Withdraw</ButtonText>
            </Button>
          </Popover>
        </ButtonGroup>
      </Inner>
      <DashedLine src={dashedLine} alt='line' />
    </Container>
  );
};

export default FilledVoucher;
