import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.black};
  margin-top: 15px;
`;
