import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
`;

export const IconWrapper = styled.div`
  min-width: 48px;
  margin-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.h4`
  ${(p) => p.theme.main.typography.subHeading};
  color: ${(p) => p.theme.main.colors.black};
`;

export const SubTitle = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
`;

export const FormWrapper = styled.div`
  margin-left: 54px;

  @media (max-width: 840px) {
    margin-left: 0;
  }
`;
