import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../../store';
import {
  getAllChats,
  sendMessage,
  deleteMessage,
} from '../../../infra/chat.service';
import {
  sendMessageRequestAction,
  sendMessageSuccessAction,
  sendMessageFailureAction,
  deleteMessageRequestAction,
  deleteMessageSuccessAction,
  deleteMessageFailureAction,
} from './actions';
import {
  handleErrorNotification,
  handleResponseExtraAction,
  handleResponseFailNotification,
} from '../../../utils';
import {setNotificationState} from '../../Notification/notification.actions';
import {IDeleteMessageBody, ISendMessageBody} from '../chat.types';
import {getAllChatsRequest, getOneChatRequest} from '../chat/requests';

export const sendMessageRequest =
  (body: ISendMessageBody): ThunkActionType =>
  (dispatch) => {
    dispatch(sendMessageRequestAction());
    return sendMessage(body)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: sendMessageSuccessAction,
          failureAction: sendMessageFailureAction,
          extraRequest: getOneChatRequest,
          extraRequestArg: {chat_id: body.chat_id},
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendMessageFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const deleteMessageRequest =
  (body: IDeleteMessageBody): ThunkActionType =>
  (dispatch) => {
    dispatch(deleteMessageRequestAction());
    return (
      deleteMessage(body.message_id)
        .then((response: AxiosResponse) => {
          // 1 Remove message
          handleResponseFailNotification({
            response,
            dispatch,
            successAction: deleteMessageSuccessAction,
            failureAction: deleteMessageFailureAction,
          });
          return response;
        })
        // eslint-disable-next-line arrow-body-style
        .then(() => {
          // 2 Get all chats for checking if only sole message was deleted
          return getAllChats();
        })
        .then((response: AxiosResponse) => {
          const chatsLength = Array.isArray(response.data.chats)
            ? response.data.chats.length
            : Object.keys(response.data.chats).length;

          // 3.1 If messages exist get messages by chat
          if (chatsLength) {
            dispatch(getOneChatRequest({chat_id: body.chat_id}));
            return;
          }

          // 3.2 If messages don't exist get all chats,
          // therefore refresh chats and
          // reset oneChat,  deleteMessageRes in reducer
          dispatch(getAllChatsRequest());
        })
        .catch((error) => {
          handleErrorNotification({
            error,
            dispatch,
            failureAction: deleteMessageFailureAction,
            notificationAction: setNotificationState,
          });
        })
    );
  };
