import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Spin} from 'antd';
import {Store} from 'rc-field-form/lib/interface';
import {useIntl} from 'react-intl';
import {RootState} from '../../../../../state/store';
import {
  getCountriesListRequest,
  getProfileInformationRequest,
  updateProfileRequest,
  getPhoneCodesRequest,
  getGeoLocationRequest,
} from '../../../../../state';
import {useLocalValidation} from '../../../../hooks';
import {FormItem} from '../../Auth/Auth.styles';
import {IPersonalFormFields} from './PersonalForm.types';
import {FormWrapper, ButtonGroup, ButtonWrapper} from './PersonalForm.styles';
import {formTelephone, formValuesVerificationForm} from '../../../../../utils';
import PersonalFormComponent from '../../../../components/PersonalFormComponent';
import {defaultFormValues, initialFormRules} from './formHelperData';

const PersonalForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {loadingCountriesList, error, countriesList} = useSelector(
    (state: RootState) => state.appReducer,
  );

  const {
    loading: loadingProfile,
    error: errorProfile,
    profileData,
  } = useSelector((state: RootState) => state.profileReducer);

  const {phoneCodes, geoLocation, errorGeoLocation, errorPhoneCodes} =
    useSelector((state: RootState) => state.phoneCodesReducer);

  const {formatMessage} = useIntl();
  const {firstNameRules} = useLocalValidation(formatMessage);

  const [isSelectInFocus, setSelectInFocus] = useState(false);
  const [initialFormValues, setInitialFormValues] =
    useState<IPersonalFormFields>({});

  const onReset = () => form.resetFields();
  const onClearFields = () => form.setFieldsValue({...defaultFormValues});
  const handleSelectInFocus = (bool: boolean) => () => setSelectInFocus(bool);

  const personalFormHandler = (values: IPersonalFormFields) => {
    const {
      day_birth,
      month_birth,
      year_birth,
      phone_number,
      ...restFormValues
    } = values;

    dispatch(
      updateProfileRequest({
        date_of_birth: `${day_birth}-${month_birth}-${year_birth}`,
        phone: phone_number,
        ...restFormValues,
      }),
    );
  };

  const handleValuesChange = (
    changedValues: any,
    values: {[key: string]: string},
  ) => {
    const {phone_number, phone_code} = values;
    const telephone = formTelephone({
      phoneNumber: phone_number,
      phoneCode: phone_code,
    });

    form.setFieldsValue({phone_number: telephone});
  };

  useEffect(() => {
    dispatch(getCountriesListRequest());
    dispatch(getProfileInformationRequest());
    dispatch(getPhoneCodesRequest());
    dispatch(getGeoLocationRequest());
  }, []);

  useEffect(() => {
    if (!profileData) return;

    formValuesVerificationForm({
      phoneCodes,
      geoLocation,
      initialFormFields: profileData.fields,
      errorsMap: {errorGeoLocation, errorPhoneCodes},
      setStateFunction: setInitialFormValues,
    });
  }, [profileData, geoLocation, phoneCodes]);

  if (error) return <div>{error}</div>;
  if (errorProfile) return <div>{errorProfile}</div>;

  if (loadingCountriesList || !countriesList || loadingProfile || !profileData)
    return (
      <Spin
        tip='Loading...'
        spinning={
          loadingCountriesList ||
          !countriesList ||
          loadingProfile ||
          !profileData
        }
        size='large'
      />
    );

  return (
    <FormWrapper>
      {Object.keys(initialFormValues).length > 0 ? (
        <Form
          layout='vertical'
          form={form}
          onFinish={personalFormHandler}
          onValuesChange={handleValuesChange}
          initialValues={initialFormValues as Store}
        >
          <PersonalFormComponent
            formRules={{...initialFormRules, firstName: firstNameRules}}
            isSelectInFocus={isSelectInFocus}
            handleSelectInFocus={handleSelectInFocus}
            countriesList={countriesList}
            initialPhoneCode={initialFormValues.phone_code}
          />
          <FormItem>
            <ButtonGroup>
              <ButtonWrapper
                type='primary'
                htmlType='submit'
                $borderRadius='8px'
              >
                Save changes
              </ButtonWrapper>
              <ButtonWrapper
                type='primary'
                htmlType='button'
                $borderRadius='8px'
                onClick={onReset}
              >
                Cancel
              </ButtonWrapper>
              <ButtonWrapper
                type='primary'
                htmlType='button'
                $borderRadius='8px'
                onClick={onClearFields}
              >
                Clear
              </ButtonWrapper>
            </ButtonGroup>
          </FormItem>
        </Form>
      ) : null}
    </FormWrapper>
  );
};

export default PersonalForm;
