import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ChevronLeft: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='6'
    height='8'
    viewBox='0 0 6 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.33337 6.66634L1.66671 3.99968L4.33337 1.33301'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
