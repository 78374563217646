import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Minus: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 9C2.44771 9 2 8.55228 2 8C2 7.44772 2.44771 7 3 7C10.5569 7 5.44312 7 13 7C13.5523 7 14 7.44771 14 8C14 8.55228 13.5523 9 13 9C5.44312 9 10.5569 9 3 9Z'
      fill={fillColor}
    />
  </svg>
);
