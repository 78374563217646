import {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import PopoverComponent from '../PopoverComponent';
import {Copy, Delete, DocumentIcon, Edit} from '../../static/images';
import {ActionItem} from '../../../state/Standardized/standardized.types';
import {IActionsListProps} from './ActionsList.types';
import {ActionButtons, ActionButton, ButtonText} from './ActionsList.styles';

const ActionsList: FC<IActionsListProps> = ({
  actionsList,
  dispatchActionsMap,
}: IActionsListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {copyDataItemAction, editDataItemAction, deleteDataItemAction} =
    dispatchActionsMap;

  const [isShowPopover, setShowPopover] = useState(false);
  const handleShowPopover = (visible: boolean) => setShowPopover(visible);

  return (
    <ActionButtons>
      {actionsList &&
        actionsList.length > 0 &&
        actionsList.map((actionItem: ActionItem) => {
          if (actionItem.action === 'edit') {
            return (
              <ActionButton
                key={uuidv4()}
                onClick={() => dispatch(editDataItemAction(actionItem.slug))}
              >
                <Edit fillColor='#74828A' />
                <ButtonText>{actionItem.content}</ButtonText>
              </ActionButton>
            );
          }
          if (actionItem.action === 'copy') {
            return (
              <ActionButton
                key={uuidv4()}
                onClick={() => dispatch(copyDataItemAction(actionItem.slug))}
              >
                <Copy fillColor='#74828A' />
                <ButtonText>{actionItem.content}</ButtonText>
              </ActionButton>
            );
          }

          if (actionItem.action === 'delete') {
            return (
              <PopoverComponent
                key={uuidv4()}
                visible={isShowPopover}
                questionText='Are you sure you want to delete ?'
                acceptText='Yes'
                declineText='No'
                acceptClick={() => {
                  dispatch(deleteDataItemAction(actionItem.slug));
                  setShowPopover(false);
                }}
                declineClick={() => setShowPopover(false)}
                handleShowPopover={handleShowPopover}
              >
                <ActionButton>
                  <Delete fillColor='#EF4444' />
                  <ButtonText color='EF4444'>{actionItem.content}</ButtonText>
                </ActionButton>
              </PopoverComponent>
            );
          }

          if (actionItem.action === 'topup') {
            return (
              <ActionButton
                key={uuidv4()}
                onClick={() => history.push(`/top-up/${actionItem.id}`)}
              >
                <DocumentIcon fillColor='#74828A' />
                <ButtonText>{actionItem.content || 'Top Up'}</ButtonText>
              </ActionButton>
            );
          }

          return (
            <ActionButton key={uuidv4()} onClick={() => {}}>
              <DocumentIcon fillColor='#74828A' />
              <ButtonText>{actionItem.content || 'Click'}</ButtonText>
            </ActionButton>
          );
        })}
    </ActionButtons>
  );
};

export default ActionsList;
