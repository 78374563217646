// eslint-disable-next-line import/prefer-default-export
export const formatDateBirth = (dateBirth: string) => {
  const splittedDateOfBirth = dateBirth.split('-');

  const dateOfBirthMap = {
    day_birth: splittedDateOfBirth[0],
    month_birth: splittedDateOfBirth[1],
    year_birth: splittedDateOfBirth[2],
  };

  return dateOfBirthMap;
};
