import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Pagination} from 'antd';
import {RootState} from '../../../state/store';
import {
  setCurrentTablePageAction,
  setPageSizeTableAction,
  getStandardDataPerPageRequest,
} from '../../../state';
import {IPaginationComponentProps} from './PaginationComponent.types';

const PaginationComponent: FC<IPaginationComponentProps> = ({
  paginationTotal,
}: IPaginationComponentProps) => {
  const dispatch = useDispatch();
  const {tableName} = useParams<{tableName: string}>();
  const {currentPageTable, pageSizeTable} = useSelector(
    (state: RootState) => state.standardizedReducer,
  );
  const isPerPageExist = paginationTotal && paginationTotal > pageSizeTable;

  return (
    <Pagination
      pageSize={pageSizeTable}
      total={paginationTotal}
      current={currentPageTable}
      showSizeChanger={!!isPerPageExist}
      onChange={(page: number, pageSize?: number | undefined) => {
        dispatch(setCurrentTablePageAction(page));
        if (pageSize) {
          dispatch(setPageSizeTableAction(pageSize));
          dispatch(
            getStandardDataPerPageRequest(
              tableName,
              `page=${page}&perPage=${pageSize}`,
            ),
          );
        }
      }}
    />
  );
};

export default PaginationComponent;
