import {useState, useEffect, ChangeEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Spin} from 'antd';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import {RootState} from '../../../../../state/store';
import {
  redirectPayment,
  formSendPaymentRequest,
  setCurrentMethodKey,
  setPreferredMethod,
  verifyEmailRequest,
  logoutUser,
} from '../../../../../state';
import PaymentMethodsComponent from './PaymentMethodsComponent';
import {IFormValues} from './PaymentMethodsComponent/PaymentMethodsComponent.types';
import {
  IPaymentFormFieldRes,
  IPaymentFormFieldView,
} from '../../../../../state/Auth/auth.types';
import {
  createInitialFormValues,
  formatePaymentForm,
  reorderInputAmount,
} from '../../../../../utils';
import {
  Container,
  Title,
} from './PaymentMethodsComponent/PaymentMethodsComponent.styles';

type PreferMethodType = IPaymentFormFieldRes | null;

const PaymentMethods = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const {id} = useParams<{id: string}>();

  const urlSearchParams = new URLSearchParams(search);
  const verifyUrl = urlSearchParams.get('url') || '';
  const redirectPaymentUrl = localStorage.getItem('redirect_url_payment');

  const {loading, paymentFormRes, verifyEmailRes} = useSelector(
    (state: RootState) => state.authReducer,
  );

  const {loading: loadingPayment} = useSelector(
    (state: RootState) => state.paymentReducer,
  );

  const [formValues, setFormValues] = useState<IFormValues>({});
  const [formFields, setFormFields] = useState<IPaymentFormFieldView[]>([]);
  const [preferMethod, setPreferMethod] = useState<PreferMethodType>(null);

  const goPrevPage = () => {
    dispatch(logoutUser());
    history.push('/login');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePaymentMethod = (
    key: string,
    alterKey: string,
    paymentMethod: string,
  ): void => {
    setFormValues((prevState) => ({
      ...prevState,
      restPaymentOptionsChecked: false,
      [alterKey]: '',
      [key]: paymentMethod,
    }));
  };

  const handleSelectChange = (
    key: string,
    option: OptionData | OptionGroupData,
  ): void => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: option.value,
    }));
  };

  const handleRestPaymentOptions = (): void => {
    setFormValues((prevState) => ({
      ...prevState,
      restPaymentOptionsChecked: true,
      preferred: '',
      method: '',
    }));
  };

  useEffect(() => {
    dispatch(setCurrentMethodKey(formValues.method));

    dispatch(
      formSendPaymentRequest({
        invoice: +formValues.invoice,
        method: +formValues.method,
      }),
    );

    if (formValues.preferred) {
      dispatch(
        formSendPaymentRequest({
          invoice: +formValues.invoice,
          method: +formValues.method,
          code: +formValues.preferred,
        }),
      );
    }
  }, [formValues]);

  useEffect(() => {
    if (!paymentFormRes) return;

    const {preferred, ...restFields} = paymentFormRes.list.fields;

    if (preferred && preferred.label && preferred.value) {
      dispatch(setPreferredMethod(preferred.label as string));
      setPreferMethod(preferred);
    }

    const formattedFieldsList = formatePaymentForm(
      reorderInputAmount(Object.values(restFields)),
    );

    setFormFields(formattedFieldsList);

    const initialFormValues = createInitialFormValues(
      paymentFormRes.list.fields,
    );

    setFormValues({...initialFormValues, restPaymentOptionsChecked: false});
  }, [paymentFormRes]);

  useEffect(() => {
    if (verifyUrl) {
      dispatch(verifyEmailRequest(verifyUrl));
      return;
    }

    if (redirectPaymentUrl) dispatch(redirectPayment(redirectPaymentUrl));
  }, []);

  useEffect(() => {
    if (verifyEmailRes) {
      if (redirectPaymentUrl) {
        dispatch(redirectPayment(redirectPaymentUrl));
        return;
      }
      dispatch(redirectPayment(`/api/payment/${id}`));
    }
  }, [verifyEmailRes]);

  if (loading || loadingPayment) {
    return (
      <Spin
        tip='Loading...'
        spinning={loading || loadingPayment}
        size='large'
      />
    );
  }

  if (verifyUrl && !verifyEmailRes) {
    return (
      <Container>
        <Spin tip='Loading...' size='large' />
        <Title>Your email is verifying</Title>
      </Container>
    );
  }

  return (
    <PaymentMethodsComponent
      formFields={formFields}
      formValues={formValues}
      preferMethod={preferMethod}
      goPrevPage={goPrevPage}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      handlePaymentMethod={handlePaymentMethod}
      handleRestPaymentOptions={handleRestPaymentOptions}
    />
  );
};

export default PaymentMethods;
