import {IHandlerMenuItemArgs} from '../view/pages/MemberArea/Standardized/DropDownMenu/DropDownMenu.types';
import {ColumnItem} from '../view/pages/MemberArea/Standardized/TableView/dataTable';

const sliceColumns = (
  columnsList: ColumnItem[],
  columnActions: ColumnItem | undefined,
) => {
  const slicedColumns = columnActions
    ? columnsList.slice(0, 4)
    : columnsList.slice(0, 5);

  return slicedColumns;
};

const formateColumns = (
  columnActions: ColumnItem | undefined,
  slicedColumns: ColumnItem[],
  // eslint-disable-next-line no-unused-vars
  handlerActions: (args: IHandlerMenuItemArgs) => void,
  // eslint-disable-next-line no-unused-vars
  goToVerificationPage: (path: string) => void,
  // eslint-disable-next-line
  switchStatusNotification: (id: string) => void,
  addHandlerActions: (
    columnsList: ColumnItem[], // eslint-disable-line
    handlerMenuItem: (args: IHandlerMenuItemArgs) => void, // eslint-disable-line
    goToVerificationPage: (path: string) => void, // eslint-disable-line
    switchStatusNotification: (id: string) => void, // eslint-disable-line
  ) => ColumnItem[],
) => {
  const formattedColumns = columnActions
    ? addHandlerActions(
        [...slicedColumns, columnActions],
        handlerActions,
        goToVerificationPage,
        switchStatusNotification,
      )
    : slicedColumns;

  return formattedColumns;
};

export const handleColumnsCount = (
  notMobileScreen: boolean,
  columnActions: ColumnItem | undefined,
  columnsList: ColumnItem[],
  // eslint-disable-next-line no-unused-vars
  handlerActions: (args: IHandlerMenuItemArgs) => void,
  // eslint-disable-next-line no-unused-vars
  goToVerificationPage: (path: string) => void,
  // eslint-disable-next-line
  switchStatusNotification: (id: string) => void,
  addHandlerActions: (
    columnsList: ColumnItem[], // eslint-disable-line
    handlerMenuItem: (args: IHandlerMenuItemArgs) => void, // eslint-disable-line
    goToVerificationPage: (path: string) => void, // eslint-disable-line
    switchStatusNotification: (id: string) => void, // eslint-disable-line
  ) => ColumnItem[],
) => {
  if (notMobileScreen || columnsList.length <= 5) {
    return addHandlerActions(
      columnsList,
      handlerActions,
      goToVerificationPage,
      switchStatusNotification,
    );
  }

  const slicedColumns = sliceColumns(columnsList, columnActions);

  const formattedColumns = formateColumns(
    columnActions,
    slicedColumns,
    handlerActions,
    goToVerificationPage,
    switchStatusNotification,
    addHandlerActions,
  );

  return formattedColumns;
};

export const whetherExpandRow = (
  mobileScreen: boolean,
  columnsList: ColumnItem[],
) => mobileScreen && columnsList.length >= 6;
