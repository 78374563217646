import {useHistory} from 'react-router-dom';
import MainCard from '../../../../components/MainCard';
import {Close} from '../../../../static/images';
import {Container, IconWrapper} from './RejectedDeposit.styles';

const RejectedDeposit = () => {
  const history = useHistory();

  const icon = () => (
    <IconWrapper>
      <Close fillColor='#fff' />
    </IconWrapper>
  );

  return (
    <Container>
      <MainCard
        icon={icon()}
        title='Payment is rejected'
        textButton='Return to Blixti'
        handleClick={() => history.push('/dashboard')}
      />
    </Container>
  );
};

export default RejectedDeposit;
