// GET_PROFILE_INFO TYPES & ACTIONS
export const GET_PROFILE_INFO_REQUEST: string = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_SUCCESS: string = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILURE: string = 'GET_PROFILE_INFO_FAILURE';

export const getProfileInfoRequestAction = (): {type: string} => ({
  type: GET_PROFILE_INFO_REQUEST,
});

export const getProfileInfoSuccessAction = (payload: any) => ({
  type: GET_PROFILE_INFO_SUCCESS,
  payload,
});

export const getProfileInfoFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_PROFILE_INFO_FAILURE,
  payload,
});

// UPDATE_PROFILE TYPES & ACTIONS
export const UPDATE_PROFILE_REQUEST: string = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS: string = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE: string = 'UPDATE_PROFILE_FAILURE';

export const updateProfileRequestAction = (): {type: string} => ({
  type: UPDATE_PROFILE_REQUEST,
});

export const updateProfileSuccessAction = (payload: any) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfileFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: UPDATE_PROFILE_FAILURE,
  payload,
});
