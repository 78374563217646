import {FC} from 'react';
import FormItem from '../../FormItem';
import {InputComponentProps} from './InputComponent.types';
import {Input} from './InputComponent.styles';

const InputComponent: FC<InputComponentProps> = ({
  value,
  name,
  label,
  required,
  handleValueChange,
}: InputComponentProps) => (
  <FormItem label={label} required={required}>
    <Input
      placeholder={label}
      name={name}
      value={value}
      onChange={(event) =>
        handleValueChange({keyName: name, value: event.target.value})
      }
    />
  </FormItem>
);

export default InputComponent;
