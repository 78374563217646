import {FC} from 'react';
import moment from 'moment';
import FormItem from '../../FormItem';
import {DatePickerWrapper} from './DateTimeComponent.styles';
import {IDateTimeComponentProps} from './DateTimeComponent.types';

const DateTimeComponent: FC<IDateTimeComponentProps> = ({
  label,
  name,
  value,
  required,
  handleValueChange,
}: IDateTimeComponentProps) => (
  <FormItem label={label} required={required}>
    <DatePickerWrapper
      showTime
      format='DD/MM/YYYY HH:mm:ss'
      name={name}
      placeholder={label}
      defaultValue={value ? moment(value, 'DD/MM/YYYY HH:mm:ss') : undefined}
      onChange={(dateValue, dateString) =>
        handleValueChange({keyName: name, value: dateString})
      }
    />
  </FormItem>
);

export default DateTimeComponent;
