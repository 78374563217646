import {AnyAction} from 'redux';
import {SET_NOTIFICATION_STATE} from './notification.actions';
import {INotificationMap} from './notification.types';

interface INotificationState {
  notificationMap: INotificationMap;
}

const initialState: INotificationState = {
  notificationMap: {
    isShow: false,
    type: '',
    message: '',
    isModalView: false,
  },
};

const notificationReducer = (
  state: INotificationState = initialState,
  action: AnyAction,
): INotificationState => {
  switch (action.type) {
    case SET_NOTIFICATION_STATE:
      return {
        ...state,
        notificationMap: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
