import {useHistory} from 'react-router-dom';
import MainCard from '../../../../components/MainCard';
import {CheckmarkColored} from '../../../../static/images';
import {Container} from './SuccessfulDeposit.styles';

const SuccessfulDeposit = () => {
  const history = useHistory();

  return (
    <Container>
      <MainCard
        icon={<CheckmarkColored />}
        title='Deposit is successful. You may now close this tab'
        textButton='Return to Blixti'
        handleClick={() => history.push('/dashboard')}
      />
    </Container>
  );
};

export default SuccessfulDeposit;
