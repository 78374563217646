import {
  IPayIncompleteInvoiceResponse,
  IPayIncompleteInvoiceSendResponse,
} from '../incompleteInvoices.types';

// PAY_INCOMPLETE_INVOICE_INIT TYPES & ACTIONS
export const PAY_INCOMPLETE_INVOICE_INIT_REQUEST: string =
  'PAY_INCOMPLETE_INVOICE_INIT_REQUEST';
export const PAY_INCOMPLETE_INVOICE_INIT_SUCCESS: string =
  'PAY_INCOMPLETE_INVOICE_INIT_SUCCESS';
export const PAY_INCOMPLETE_INVOICE_INIT_FAILURE: string =
  'PAY_INCOMPLETE_INVOICE_INIT_FAILURE';

export const payIncompleteInvoiceInitRequestAction = (): {type: string} => ({
  type: PAY_INCOMPLETE_INVOICE_INIT_REQUEST,
});

export const payIncompleteInvoiceInitSuccessAction = (
  payload: IPayIncompleteInvoiceResponse,
): {type: string; payload: IPayIncompleteInvoiceResponse} => ({
  type: PAY_INCOMPLETE_INVOICE_INIT_SUCCESS,
  payload,
});

export const payIncompleteInvoiceInitFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: PAY_INCOMPLETE_INVOICE_INIT_FAILURE,
  payload,
});

// PAY_INCOMPLETE_INVOICE_SEND TYPES & ACTIONS
export const PAY_INCOMPLETE_INVOICE_SEND_REQUEST: string =
  'PAY_INCOMPLETE_INVOICE_SEND_REQUEST';
export const PAY_INCOMPLETE_INVOICE_SEND_SUCCESS: string =
  'PAY_INCOMPLETE_INVOICE_SEND_SUCCESS';
export const PAY_INCOMPLETE_INVOICE_SEND_FAILURE: string =
  'PAY_INCOMPLETE_INVOICE_SEND_FAILURE';

export const payIncompleteInvoiceSendRequestAction = (): {type: string} => ({
  type: PAY_INCOMPLETE_INVOICE_SEND_REQUEST,
});

export const payIncompleteInvoiceSendSuccessAction = (
  payload: IPayIncompleteInvoiceSendResponse,
): {type: string; payload: IPayIncompleteInvoiceSendResponse} => ({
  type: PAY_INCOMPLETE_INVOICE_SEND_SUCCESS,
  payload,
});

export const payIncompleteInvoiceSendFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: PAY_INCOMPLETE_INVOICE_SEND_FAILURE,
  payload,
});

// DECLINE_INCOMPLETE_INVOICE TYPES & ACTIONS
export const DECLINE_INCOMPLETE_INVOICE_REQUEST: string =
  'DECLINE_INCOMPLETE_INVOICE_REQUEST';
export const DECLINE_INCOMPLETE_INVOICE_SUCCESS: string =
  'DECLINE_INCOMPLETE_INVOICE_SUCCESS';
export const DECLINE_INCOMPLETE_INVOICE_FAILURE: string =
  'DECLINE_INCOMPLETE_INVOICE_FAILURE';

export const declineIncompleteInvoiceRequestAction = (): {type: string} => ({
  type: DECLINE_INCOMPLETE_INVOICE_REQUEST,
});

export const declineIncompleteInvoiceSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: DECLINE_INCOMPLETE_INVOICE_SUCCESS,
  payload,
});

export const declineIncompleteInvoiceFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DECLINE_INCOMPLETE_INVOICE_FAILURE,
  payload,
});
