import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../../store';
import {
  getStandardDataRequestAction,
  getStandardDataSuccessAction,
  getStandardDataFailureAction,
  getStandardDataItemRequestAction,
  getStandardDataItemSuccessAction,
  getStandardDataItemFailureAction,
  copyStandardDataItemRequestAction,
  copyStandardDataItemSuccessAction,
  copyStandardDataItemFailureAction,
  getStandardDataPerPageRequestAction,
  getStandardDataPerPageSuccessAction,
  getStandardDataPerPageFailureAction,
  initialGetStandardDataRequestAction,
  initialGetStandardDataSuccessAction,
  initialGetStandardDataFailureAction,
} from '../actions/read.actions';
import {
  getStandardData,
  getStandardDataItem,
  copyStandardDataItem,
  getStandardDataPerPage,
} from '../../../infra/standardized.service';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  createQueryString,
} from '../../../utils';

export const initialGetStandardDataRequest =
  (tableName: string): ThunkActionType =>
  (dispatch) => {
    dispatch(initialGetStandardDataRequestAction());
    return getStandardData(tableName)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: initialGetStandardDataSuccessAction,
          failureAction: initialGetStandardDataFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: initialGetStandardDataFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getStandardDataRequest =
  (tableName: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getStandardDataRequestAction());
    return getStandardData(tableName)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getStandardDataSuccessAction,
          failureAction: getStandardDataFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getStandardDataFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getStandardDataPerPageRequest =
  (tableName: string, pageConfig: string): ThunkActionType =>
  (dispatch, getState) => {
    dispatch(getStandardDataPerPageRequestAction());

    const {filterDataMap} = getState().standardizedReducer;

    const filterQueryString = filterDataMap
      ? createQueryString(filterDataMap)
      : false;

    const slugFilter = filterQueryString
      ? `${pageConfig}&${filterQueryString}`
      : pageConfig;

    return getStandardDataPerPage(tableName, slugFilter)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getStandardDataPerPageSuccessAction,
          failureAction: getStandardDataPerPageFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getStandardDataPerPageFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getStandardDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getStandardDataItemRequestAction());
    return getStandardDataItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getStandardDataItemSuccessAction,
          failureAction: getStandardDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const copyStandardDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(copyStandardDataItemRequestAction());
    return copyStandardDataItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: copyStandardDataItemSuccessAction,
          failureAction: copyStandardDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: copyStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
