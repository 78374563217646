import {
  IUploadedDocsMap,
  IVerificationDocItem,
  IVerifyFilesMap,
} from '../../state/Verification/verification.types';

// eslint-disable-next-line import/prefer-default-export
export const formVerifyDocsLoad = (
  valuesDocsLoad: IVerificationDocItem[],
  // eslint-disable-next-line arrow-body-style
): IUploadedDocsMap => {
  return valuesDocsLoad.reduce(
    (acc: IVerifyFilesMap | IUploadedDocsMap, itm) => {
      acc[itm.name] = itm.value ? itm.value : [];
      return acc;
    },
    {},
  );
};
