import {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import SuccessfulDeposit from '../../../pages/MemberArea/PaymentInvoice/SuccessfulDeposit';
import RejectedDeposit from '../../../pages/MemberArea/PaymentInvoice/RejectedDeposit';
import DashboardSection from '../DashboardSection';

const MemberArea = ({children}: {children: ReactNode}): JSX.Element => {
  const {pathname} = useLocation();

  if (pathname === '/success-payment') return <SuccessfulDeposit />;

  if (pathname === '/reject-payment') return <RejectedDeposit />;

  return <DashboardSection>{children}</DashboardSection>;
};

export default MemberArea;
