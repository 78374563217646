import {FC} from 'react';
import {useIntl} from 'react-intl';
import {
  TitlesContainer,
  TitleWrapper,
  SecondaryTextWrapper,
  LinkWrapper,
} from '../../Auth/Auth.styles';

const RegistrationTitle: FC = (): JSX.Element => {
  const {formatMessage} = useIntl();

  return (
    <TitlesContainer>
      <TitleWrapper level={2}>
        {formatMessage({id: 'CREATE_ACCOUNT_TITLE'})}
      </TitleWrapper>
      <SecondaryTextWrapper type='secondary'>
        {formatMessage({id: 'ALREADY_EXIST_SUBTITLE'})}&nbsp;
        <LinkWrapper to='/login'>
          {formatMessage({id: 'LOGIN_LINK'})}
        </LinkWrapper>
      </SecondaryTextWrapper>
    </TitlesContainer>
  );
};

export default RegistrationTitle;
