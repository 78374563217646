import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const SortIcon: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6106 3.29152L13.8906 6.22452C14.2256 6.65452 14.0516 7.00852 13.4986 7.00852H12.0006V13.0055C12.0018 13.1373 11.9768 13.268 11.927 13.3901C11.8772 13.5121 11.8036 13.623 11.7106 13.7163C11.6175 13.8097 11.5068 13.8836 11.385 13.9337C11.2631 13.9839 11.1324 14.0093 11.0006 14.0085C10.4446 14.0085 10.0006 13.5595 10.0006 13.0055V7.00852H8.50263C7.94763 7.00852 7.77263 6.65852 8.11063 6.22452L10.3906 3.29252C10.7256 2.86252 11.2736 2.85952 11.6106 3.29252V3.29152ZM5.61063 12.7075C5.27363 13.1405 4.72563 13.1375 4.39063 12.7075L2.11063 9.77552C1.77363 9.34152 1.94763 8.99152 2.50263 8.99152H4.00063V2.99452C4.00011 2.86291 4.02559 2.73249 4.07562 2.61076C4.12565 2.48902 4.19924 2.37838 4.29217 2.28518C4.38509 2.19197 4.49552 2.11805 4.6171 2.06765C4.73868 2.01726 4.86902 1.99138 5.00063 1.99152C5.55263 1.99152 6.00063 2.42952 6.00063 2.99452V8.99152H7.49863C8.05163 8.99152 8.22563 9.34452 7.89063 9.77552L5.61063 12.7075Z'
      fill={fillColor}
    />
  </svg>
);
