import {AnyAction} from 'redux';
import {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
} from './dashboard.actions';
import {IDashboardState} from './dashboard.types';

const initialState: IDashboardState = {
  loading: false,
  data: null,
  error: null,
};

const dashboardReducer = (
  state: IDashboardState = initialState,
  action: AnyAction,
): IDashboardState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
