import {
  IFilterItem,
  IFormValues,
} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

// eslint-disable-next-line import/prefer-default-export
export const createFilterFormValues = (filterTemplateList: IFilterItem[]) =>
  filterTemplateList.reduce((acc: IFormValues, item: IFilterItem) => {
    if (item.multi) {
      acc[item.name] = [];
    } else {
      acc[item.name] = '';
    }
    return acc;
  }, {});
