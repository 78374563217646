/* eslint-disable import/prefer-default-export */
import {
  ArrayQueryStringTuple,
  FormValueItemTuple,
  IFormValues,
} from '../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

const groupArrayQueryString = (queryStringTuple: ArrayQueryStringTuple) =>
  queryStringTuple[1].reduce(
    // eslint-disable-next-line prefer-template
    (acc: string, item: string) => acc + `${queryStringTuple[0]}[]=${item}&`,
    '',
  );

const groupQueryString = (formValuesMap: IFormValues) =>
  Object.entries(formValuesMap).reduce(
    (acc: string, item: FormValueItemTuple) => {
      if (Array.isArray(item[1])) {
        return acc + groupArrayQueryString(item as ArrayQueryStringTuple);
      }

      if (item[1]) {
        // eslint-disable-next-line prefer-template
        return acc + `${item[0]}=${item[1]}&`;
      }

      return acc;
    },
    '',
  );

const removeLastCharacter = (str: string, char: string) => {
  if (str[str.length - 1] === char) {
    return str.substring(0, str.length - 1);
  }
  return str;
};

export const createQueryString = (formValuesMap: IFormValues) => {
  const groupedQueryString = groupQueryString(formValuesMap);
  return removeLastCharacter(groupedQueryString, '&');
};
