import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Merchants: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 9C5.89543 9 5 9.89543 5 11V14C5 15.1046 5.89543 16 7 16H14C15.1046 16 16 15.1046 16 14V11C16 9.89543 15.1046 9 14 9H7ZM10.5 14C11.3284 14 12 13.3284 12 12.5C12 11.6716 11.3284 11 10.5 11C9.67157 11 9 11.6716 9 12.5C9 13.3284 9.67157 14 10.5 14Z'
      fill={fillColor}
    />
    <path
      d='M2 2.5C2 3.88071 3.11929 5 4.5 5C5.88071 5 7 3.88071 7 2.5C7 1.11929 5.88071 0 4.5 0C3.11929 0 2 1.11929 2 2.5Z'
      fill={fillColor}
    />
    <path
      d='M8.53929 8C7.83505 6.49339 6.37311 6 4.5 6C1.83333 6 0 7 0 10.5C0 11.5025 0.903753 12.4064 4 12.4932V11C4 9.34315 5.34323 8 7.00008 8C7.55276 8 8.0948 8 8.53929 8Z'
      fill={fillColor}
    />
  </svg>
);
