import {ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Layout, Spin} from 'antd';
import {
  removeVerifyStateUser,
  removeUser,
} from '../../../../infra/token.service';
import {storeVerificationUrl} from '../../../../infra/verification.service';
import {RootState} from '../../../../state/store';
import {
  clearDateRange,
  clearFilterDataMap,
  clearVerifyEmailRes,
  setVerificationUrl,
  setVerifyNotificationState,
  getAppDataRequest,
  setAuthenticationState,
} from '../../../../state';
import WithNotification from '../../../HOC/WithNotification';
import HeaderContainer from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import BottomNavigation from '../../../components/BottomNavigation';
import TopNotification from '../../../components/TopNotification';
import {IMenuItem, ISubMenuItem} from './DashboardSection.types';
import {Container, ContentWrapper} from './DashboardSection.styles';
import {useAutoLogout} from '../../../hooks';

const DashboardSection = ({children}: {children: ReactNode}): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {loading, data, verifyNotificationShow} = useSelector(
    (state: RootState) => state.appReducer,
  );

  useAutoLogout(() => {
    removeUser();
    dispatch(setAuthenticationState('unauthenticated'));
    history.push('/login');
  });

  const [isDashboardMenuItemExist, setDashboardMenuItemExist] = useState(true);
  const [activeNavLink, setActiveNavLink] = useState<string>('Dashboard');
  const [activeTitle, setActiveTitle] = useState<string>('');

  const verificationSubMenu = data?.navbar?.children.find(
    (itm: ISubMenuItem) => itm?.icon?.toLowerCase() === 'verification',
  );

  const verificationPath = verificationSubMenu
    ? verificationSubMenu.slug
    : '/dasboard';

  useEffect(() => {
    if (!data) return;

    const dashboardItemMenu = data.menu.find(
      (menuItem: IMenuItem) => menuItem.slug === '/dashboard',
    );

    setDashboardMenuItemExist(!!dashboardItemMenu);
    dispatch(setVerificationUrl(verificationPath));
    storeVerificationUrl(verificationPath);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    const userData = data.navbar.user;
    const clientField = userData?.client;
    const isUserVerified = localStorage.getItem('isUserVerified');

    if (!isUserVerified && clientField === 'unverified') {
      localStorage.setItem('isUserVerified', 'unverified');
    }

    if (isUserVerified && !clientField) {
      removeVerifyStateUser();
    }
  }, [data]);

  useEffect(() => {
    dispatch(getAppDataRequest());
    dispatch(clearVerifyEmailRes());
  }, []);

  // Routes handling useEffect
  useEffect(() => {
    const pageTryToOpen = localStorage.getItem('pageTryToOpen');
    const redirectUrlPayment = localStorage.getItem('redirect_url_payment');
    const firstStepAuthStepper = localStorage.getItem('firstStepAuthStepper');

    if (pageTryToOpen && pageTryToOpen === location.pathname) {
      setActiveNavLink(location.pathname);
      setActiveTitle(location.pathname);
      localStorage.removeItem('pageTryToOpen');
    }

    if (pageTryToOpen && pageTryToOpen?.includes('/verification-email')) {
      localStorage.removeItem('pageTryToOpen');
    }

    if (pageTryToOpen && pageTryToOpen?.includes('/api')) {
      localStorage.removeItem('pageTryToOpen');
    }

    if (pageTryToOpen && pageTryToOpen?.includes('/auth')) {
      localStorage.removeItem('pageTryToOpen');
    }

    if (pageTryToOpen && pageTryToOpen?.includes('/success-payment')) {
      localStorage.removeItem('pageTryToOpen');
    }

    if (redirectUrlPayment) {
      localStorage.removeItem('redirect_url_payment');
    }

    if (firstStepAuthStepper) {
      localStorage.removeItem('firstStepAuthStepper');
    }
  }, [location]);

  useEffect(() => {
    dispatch(clearDateRange());
    dispatch(clearFilterDataMap());
  }, [location.pathname]);

  if (loading || !data)
    return <Spin tip='Loading...' spinning={loading || !data} size='large' />;
  return (
    <Container>
      <TopNotification
        goToVerification={() => history.push(verificationPath)}
        closeNotification={() => dispatch(setVerifyNotificationState(false))}
        isShow={verifyNotificationShow}
      />
      <Layout>
        <Sidebar
          menu={data.menu}
          activeNavLink={activeNavLink}
          activeTitle={activeTitle}
          setActiveNavLink={setActiveNavLink}
          setActiveTitle={setActiveTitle}
        />
        <Layout>
          <HeaderContainer
            navbar={data.navbar}
            isDashboardMenuItemExist={isDashboardMenuItemExist}
            setActiveNavLink={setActiveNavLink}
            setActiveTitle={setActiveTitle}
          />
          <ContentWrapper>
            <main>{children}</main>
          </ContentWrapper>
        </Layout>
      </Layout>
      <BottomNavigation menu={data.menu} />
    </Container>
  );
};

export default WithNotification(DashboardSection);
