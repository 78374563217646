import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Dashboard: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 6.10102C1.76281 7.36355 1 9.09269 1 11H3M3 6.10102C4.27052 4.80447 6.04131 4 8 4M3 6.10102L4.5 7.5M8 4C9.95869 4 11.7295 4.80447 13 6.10102M8 4V6.5M13 6.10102C14.2372 7.36355 15 9.09269 15 11H13M13 6.10102L11.5 7.5M8 11L9.5 9.5'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
