import {FC} from 'react';

interface IProps {
  strokeColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ArrowRight: FC<IProps> = ({strokeColor = '#BAC0C5'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 5L14 8M11 11L14 8M14 8L2 8'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
