import {FC} from 'react';
import {ICancelButtonProps} from './CancelButton.types';
import {ButtonWrapper} from './CancelButton.styles';

const CancelButton: FC<ICancelButtonProps> = ({
  name,
  label,
  handleCancelButton,
}: ICancelButtonProps) => (
  <ButtonWrapper name={name} onClick={handleCancelButton}>
    {label}
  </ButtonWrapper>
);

export default CancelButton;
