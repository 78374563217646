import styled from 'styled-components';
import {Switch} from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const SwitchWrapper = styled(Switch)`
  &.ant-switch {
    width: 36px;
    height: 16px;
  }

  &.ant-switch-checked {
    background-color: ${(p) => p.theme.main.colors.black};
  }

  & .ant-switch-handle {
    width: 20px;
    height: 20px;
    top: -2px;
    left: 0;

    &:before {
      border-width: 1px;
      border-style: solid;
      border-color: ${(p) => p.theme.main.colors.grey};
    }
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
  }
`;
