import {
  IFilterItem,
  IFormValues,
} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

// eslint-disable-next-line import/prefer-default-export
export const createFormValues = (filterTemplateList: IFilterItem[]) =>
  filterTemplateList.reduce((acc: IFormValues, item: IFilterItem) => {
    acc[item.name] = '';
    return acc;
  }, {});
