import {FC} from 'react';
import ForgotPasswordTitle from './ForgotPasswordTitle';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword: FC = (): JSX.Element => (
  <div className='form-container'>
    <ForgotPasswordTitle />
    <ForgotPasswordForm />
  </div>
);

export default ForgotPassword;
