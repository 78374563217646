// EDIT_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const EDIT_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'EDIT_INCOMPLETE_DATA_ITEM_REQUEST';
export const EDIT_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'EDIT_INCOMPLETE_DATA_ITEM_SUCCESS';
export const EDIT_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'EDIT_INCOMPLETE_DATA_ITEM_FAILURE';

export const editIncompleteDataItemRequestAction = (): {type: string} => ({
  type: EDIT_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const editIncompleteDataItemSuccessAction = (payload: any) => ({
  type: EDIT_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const editIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: EDIT_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});

// UPDATE_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const UPDATE_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'UPDATE_INCOMPLETE_DATA_ITEM_REQUEST';
export const UPDATE_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'UPDATE_INCOMPLETE_DATA_ITEM_SUCCESS';
export const UPDATE_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'UPDATE_INCOMPLETE_DATA_ITEM_FAILURE';

export const updateIncompleteDataItemRequestAction = (): {type: string} => ({
  type: UPDATE_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const updateIncompleteDataItemSuccessAction = (payload: any) => ({
  type: UPDATE_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const updateIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: UPDATE_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});
