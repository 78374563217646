import {FC} from 'react';
import FileChips from '../FileChips';
import FileUploader from '../FileUploader';
import {IVerificationItemProps} from './VerificationItem.types';
import {
  Header,
  IconWraper,
  Content,
  SubTitle,
  Title,
  TextPart,
  Container,
} from './VerificationItem.styles';
import {defineIcon} from './defineIcon';

const VerificationItem: FC<IVerificationItemProps> = ({
  title,
  subTitle,
  documentType,
  uploadedFiles,
  uploadFile,
  deleteUploadedFile,
}: IVerificationItemProps) => (
  <Container>
    <Header>
      <IconWraper>{defineIcon(documentType)}</IconWraper>
      <TextPart>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </TextPart>
    </Header>
    <Content>
      <FileChips
        documentType={documentType}
        uploadedFiles={uploadedFiles}
        deleteUploadedFile={deleteUploadedFile}
      />
      <FileUploader documentType={documentType} uploadFile={uploadFile} />
    </Content>
  </Container>
);

export default VerificationItem;
