import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../../../state/store';
import {sendPaymentRequest} from '../../../../../../state';
import {ArrowRight} from '../../../../../static/images';
import {ICheckoutButtonProps} from './CheckoutButton.types';
import {Button, ButtonText} from './CheckoutButton.styles';

const CheckoutButton: FC<ICheckoutButtonProps> = ({
  isDisabled,
}: ICheckoutButtonProps) => {
  const dispatch = useDispatch();
  const {sendPaymentReqBody, sendPaymentResponse} = useSelector(
    (state: RootState) => state.paymentReducer,
  );

  useEffect(() => {
    if (!sendPaymentResponse) return;
    window.open(sendPaymentResponse.redirect, '_self', 'noopener');
  }, [sendPaymentResponse]);

  return (
    <Button
      disabled={isDisabled}
      onClick={() => dispatch(sendPaymentRequest(sendPaymentReqBody))}
    >
      <ButtonText>Checkout</ButtonText>
      <ArrowRight strokeColor='#fff' />
    </Button>
  );
};

export default CheckoutButton;
