import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 355px;

  @media (max-width: 400px) {
    max-width: 280px;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.h3};
  margin-bottom: 8px;
`;

export const Paragraph = styled.p`
  ${(p) => p.theme.main.typography.body};
  text-align: center;
`;

export const BlackText = styled.span`
  color: ${(p) => p.theme.main.colors.black};
`;

export const ColoredText = styled.span`
  color: ${(p) => p.theme.main.colors.brand};
`;
