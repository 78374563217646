// eslint-disable-next-line import/prefer-default-export
export const useLocalValidation = (
  formatMessage: any,
  confirmPasswordName: string = 'password',
) => {
  const emailRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'EMAIL_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const createCustomerAsMerchantRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'COMPANY_NAME_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const createCustomerAsPrivateIndividualsRules: {
    [key: string]: string | any;
  }[] = [
    {
      required: true,
      message: formatMessage({id: 'FIELD_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const codeRules: {[key: string]: string | boolean}[] = [
    {
      required: true,
      message: formatMessage({id: 'ACTIVATION_CODE_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const passwordRules: {[key: string]: string | number | boolean | RegExp}[] = [
    {
      required: true,
      message: formatMessage({id: 'PASSWORD_EXIST_VALIDATION_MESSAGE'}),
    },
    {
      pattern: /(?=.*[A-Z])/,
      message: formatMessage({id: 'MIN1_CAPITAL_LETTER_VALIDATION_MESSAGE'}),
    },
    {
      pattern: /(?=.*[0-9])/,
      message: formatMessage({id: 'MIN1_DIGIT_VALIDATION_MESSAGE'}),
    },
    {
      pattern: /(?=.*[a-z])/,
      message: formatMessage({id: 'MIN1_LOWER_LETTER_VALIDATION_MESSAGE'}),
    },
    {
      min: 8,
      message: formatMessage({id: 'MIN8_CHARACTER_VALIDATION_MESSAGE'}),
    },
    {
      max: 20,
      message: formatMessage({id: 'MAX20_CHARACTER_VALIDATION_MESSAGE'}),
    },
  ];

  const confirmPasswordRules: {[key: string]: any}[] = [
    {
      required: true,
      message: formatMessage({id: 'PASSWORD_EXIST_VALIDATION_MESSAGE'}),
    },
    // eslint-disable-next-line no-unused-vars
    ({getFieldValue}: {getFieldValue: (arg: string) => string}): object => ({
      validator(_: unknown, value: string) {
        if (!value || getFieldValue(confirmPasswordName) === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(
            formatMessage({id: 'PASSWORD_MATCHING_VALIDATION_MESSAGE'}),
          ),
        );
      },
    }),
  ];

  const passwordProfileRules: {
    [key: string]: string | number | boolean | RegExp;
  }[] = [
    {
      required: true,
      message: formatMessage({id: 'PASSWORD_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const confirmNewEmailRules: {[key: string]: any}[] = [
    {
      required: true,
      message: formatMessage({
        id: 'NEW_EMAIL_CONFIRM_EXIST_VALIDATION_MESSAGE',
      }),
    },
    // eslint-disable-next-line no-unused-vars
    ({getFieldValue}: {getFieldValue: (arg: string) => string}): object => ({
      validator(_: unknown, value: string) {
        if (!value || getFieldValue('new_email') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(
            formatMessage({id: 'NEW_EMAIL_MATCHING_VALIDATION_MESSAGE'}),
          ),
        );
      },
    }),
  ];

  const firstNameRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'FIRST_NAME_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const lastNameRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'LAST_NAME_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const dayBirthRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'DAY_BIRTH_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const monthBirthRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'MONTH_BIRTH_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const yearBirthRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'YEAR_BIRTH_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const phoneNumberRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'PHONE_NUMBER_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const countryRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'COUNTRY_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const cityRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({id: 'CITY_EXIST_VALIDATION_MESSAGE'}),
    },
  ];

  const addressRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({
        id: 'ADDRESS_EXIST_VALIDATION_MESSAGE',
      }),
    },
  ];

  const postCodeRules: {[key: string]: string | any}[] = [
    {
      required: true,
      message: formatMessage({
        id: 'POSTCODE_EXIST_VALIDATION_MESSAGE',
      }),
    },
  ];

  return {
    emailRules,
    codeRules,
    passwordRules,
    firstNameRules,
    lastNameRules,
    confirmPasswordRules,
    confirmNewEmailRules,
    passwordProfileRules,
    dayBirthRules,
    monthBirthRules,
    yearBirthRules,
    phoneNumberRules,
    countryRules,
    cityRules,
    addressRules,
    postCodeRules,
    createCustomerAsMerchantRules,
    createCustomerAsPrivateIndividualsRules,
  };
};
