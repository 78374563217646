import ERRORS_MESSAGES from "./errors";
import AUTH_MESSAGES from "./auth";
import CUSTOMER_MESSAGES from "./customer";
import LINKS_MESSAGES from "./links";

const ENGLISH_MESSAGES = {
    ...AUTH_MESSAGES,
    ...CUSTOMER_MESSAGES,
    ...LINKS_MESSAGES,
    ...ERRORS_MESSAGES
}

export default ENGLISH_MESSAGES;
