import api from './config';
import {VERIFICATION} from './constants';
import {
  IStorePersonalInfoArgs,
  IVerifyClientArgs,
} from '../state/Verification/verification.types';

const uploadVerificationFiles = (
  verificationFiles: FormData,
  uploadUrl: string,
) =>
  api.post(`${VERIFICATION.API}${uploadUrl}`, verificationFiles, {
    headers: {'Content-Type': 'multipart/form-data'},
  });

const getVerificationDocuments = (slugUrl: string) =>
  api.get(`${VERIFICATION.API}${slugUrl}`);

const deleteVerificationDocument = (deleteDocQueryParams: string) =>
  api.delete(`${VERIFICATION.DELETE}?${deleteDocQueryParams}`);

const storePersonalInfo = (storePersonalInfoArgs: IStorePersonalInfoArgs) => {
  const {slugUrl, values} = storePersonalInfoArgs;
  return api.post(`${VERIFICATION.API}/${slugUrl}`, {...values});
};

const verifyClient = (args: IVerifyClientArgs) => {
  const {userId, ...body} = args;
  return api.post(`/api/users/${userId}/update`, {...body, role_id: 3});
};

const storeVerificationUrl = (verificationUrl: string) => {
  localStorage.setItem('verificationUrl', verificationUrl);
};

const clearVerificationUrl = () => localStorage.removeItem('verificationUrl');

const clearFirstStepAuth = () =>
  localStorage.removeItem('firstStepAuthStepper');

const clearRedirectUrlPayment = () =>
  localStorage.removeItem('redirect_url_payment');

export {
  uploadVerificationFiles,
  getVerificationDocuments,
  deleteVerificationDocument,
  storePersonalInfo,
  storeVerificationUrl,
  clearVerificationUrl,
  clearFirstStepAuth,
  clearRedirectUrlPayment,
  verifyClient,
};
