// eslint-disable-next-line import/prefer-default-export
export const publicRoutes: string[] = [
  '/',
  '/login',
  '/register',
  '/forgot-password',
  '/home',
  '/about',
  '/partner',
  '/security',
  '/contact',
  '/voucher-terms',
  '/privacy-policy',
  '/register-payment',
  '/payment-method',
  '/login-payment',
  '/verify',
  '/incomplite',
];
