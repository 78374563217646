// eslint-disable-next-line import/prefer-default-export
export const EnvelopeCircle = () => (
  <svg
    width='49'
    height='48'
    viewBox='0 0 49 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1429_42260)'>
      <circle cx='24.5' cy='24' r='24' fill='#E3E1FF' />
      <path
        d='M17.003 19.884L25 23.882L32.997 19.884C32.9674 19.3744 32.7441 18.8955 32.3728 18.5452C32.0016 18.195 31.5104 17.9999 31 18H19C18.4896 17.9999 17.9984 18.195 17.6272 18.5452C17.2559 18.8955 17.0326 19.3744 17.003 19.884Z'
        fill='#7367FE'
      />
      <path
        d='M33 22.118L25 26.118L17 22.118V28C17 28.5304 17.2107 29.0391 17.5858 29.4142C17.9609 29.7893 18.4696 30 19 30H31C31.5304 30 32.0391 29.7893 32.4142 29.4142C32.7893 29.0391 33 28.5304 33 28V22.118Z'
        fill='#7367FE'
      />
    </g>
    <defs>
      <clipPath id='clip0_1429_42260'>
        <rect width='48' height='48' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);
