import {FC} from 'react';
import {Select} from 'antd';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import FormItem from '../../FormItem';
import {
  InitValue,
  KeyValue,
  IMultiSelectComponentProps,
} from './MultiSelectComponent.types';
import {MultiSelectWrapper} from './MultiSelectComponent.styles';
import {decreaseSelectValueKey} from '../../../../../../utils';

const {Option} = Select;

const MultiSelectComponent: FC<IMultiSelectComponentProps> = ({
  name,
  label,
  value,
  options,
  required,
  handleValueChange,
}: IMultiSelectComponentProps) => {
  const setInitialKeyValue = (initValue: InitValue): KeyValue => {
    if (!initValue) return {};
    if (initValue.indexOf(',') >= 0) return {[name]: initValue.split(',')};
    return {[name]: initValue};
  };

  const setDefaultValue = (initValue: InitValue) => {
    if (!initValue) return undefined;
    if (initValue.indexOf(',') >= 0) return initValue.split(',');
    return initValue;
  };

  const valueState = setInitialKeyValue(value);

  const addMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    const {value: optionValue} = option;
    const initValue = valueState[prop];

    if (initValue && !Array.isArray(initValue)) {
      handleValueChange({keyName: name, value: [initValue, optionValue]});
      return;
    }

    if (initValue && Array.isArray(initValue)) {
      const stringArray = initValue as string[];
      handleValueChange({keyName: name, value: [...stringArray, optionValue]});
      return;
    }

    handleValueChange({keyName: name, value: optionValue});
  };

  const removeMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    const updatedValue = decreaseSelectValueKey(valueState, prop, option);
    handleValueChange({keyName: name, value: updatedValue[name]});
  };

  return (
    <FormItem label={label} required={required}>
      <MultiSelectWrapper
        mode='tags'
        defaultValue={setDefaultValue(value)}
        placeholder={label}
        onSelect={(selectVal, option) => addMultiSelectValue(name, option)}
        onDeselect={(selectVal, option) => removeMultiSelectValue(name, option)}
      >
        {options.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </MultiSelectWrapper>
    </FormItem>
  );
};

export default MultiSelectComponent;
