import {FC, Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {Modal, Form, Select} from 'antd';
import {Store} from 'rc-field-form/lib/interface';
import {RootState} from '../../../state/store';
import {
  updateStandardDataItemQueryParamsRequest,
  storeStandardDataItemQueryParamsRequest,
} from '../../../state';
import {Down} from '../../static/images';
import {
  ButtonWrapper,
  FormItem,
  InputWrapper,
} from '../../pages/MemberArea/Auth/Auth.styles';
import {
  CheckboxGroup,
  StyledCheckbox,
  StyledSelect,
  CheckboxBlock,
  CheckboxBlockTitle,
} from './FormModal.styles';
import {FormField, IFormModalProps, IFormValues} from './FormModal.types';
import {convertFormValuesWithCheckbox, createQueryString} from '../../../utils';

const {Option} = Select;

const FormModal: FC<IFormModalProps> = ({
  titleModal,
  visible,
  formFields,
  currentSlug,
  onClose,
  sendFormAction,
  clearFormAction,
  createInitialFormValues,
}: IFormModalProps) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {pageTitle} = useSelector((state: RootState) => state.appReducer);

  const [formFieldsList, setFormFieldsList] = useState<FormField[]>([]);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isCheckboxExist, setCheckboxExist] = useState(false);

  const handleCancel = () => {
    dispatch(clearFormAction());
    onClose();
  };

  useEffect(() => {
    if (formFields) {
      const isCheckboxTypeExist = Object.values(formFields).some(
        (itm) => itm.type === 'checkbox',
      );
      setCheckboxExist(isCheckboxTypeExist);
      setFormFieldsList(Object.values(formFields));
      setInitialFormValues(createInitialFormValues(formFields));
    }
  }, [formFields]);

  const formHandler = (values: IFormValues) => {
    const formValuesRaw = convertFormValuesWithCheckbox({
      formValues: values,
      formFields,
    });
    const queryString = createQueryString(formValuesRaw);

    const updatedSlugUrl = currentSlug.indexOf('update') >= 0;
    const storeSlugUrl = currentSlug.indexOf('store') >= 0;

    if (isCheckboxExist && updatedSlugUrl) {
      dispatch(
        updateStandardDataItemQueryParamsRequest(
          `${currentSlug}?${queryString}`,
        ),
      );
      return;
    }

    if (isCheckboxExist && storeSlugUrl) {
      dispatch(
        storeStandardDataItemQueryParamsRequest(
          `${currentSlug}?${queryString}`,
        ),
      );
      return;
    }

    dispatch(sendFormAction(currentSlug, values));
  };

  const modalTitle =
    pageTitle === 'incomplete'
      ? 'Create new Invoice'
      : `${titleModal} ${pageTitle}`;

  return (
    <Modal
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      visible={visible}
      title={modalTitle}
    >
      {Object.keys(initialFormValues).length > 0 ? (
        <Form
          preserve={false}
          layout='vertical'
          form={form}
          onFinish={formHandler}
          initialValues={initialFormValues as Store}
        >
          <>
            {formFieldsList.map((formField: FormField) => {
              if (formField.type === 'select' && formField.options) {
                return (
                  <Fragment key={uuidv4()}>
                    <FormItem
                      label={formField.label}
                      name={formField.name}
                      rules={[
                        {
                          required: !!formField?.required,
                          message: 'Please fill input',
                        },
                      ]}
                    >
                      <StyledSelect
                        showSearch
                        optionFilterProp='key'
                        placeholder={formField.label}
                        suffixIcon={<Down />}
                        filterOption={(input, option) => {
                          const keyValue = option?.key as string;
                          return keyValue
                            .toLowerCase()
                            .startsWith(input.toLowerCase());
                        }}
                      >
                        {Object.entries(formField.options).map((option) => (
                          <Option
                            key={`${option[1]}-${uuidv4()}`}
                            value={option[0]}
                          >
                            {option[1]}
                          </Option>
                        ))}
                      </StyledSelect>
                    </FormItem>
                  </Fragment>
                );
              }

              if (formField.type === 'checkbox' && formField.options) {
                return (
                  <CheckboxBlock key={uuidv4()}>
                    <CheckboxBlockTitle>{formField.label}</CheckboxBlockTitle>
                    <CheckboxGroup>
                      {Object.entries(formField.options).map(
                        (option: [string, string]) => (
                          <FormItem
                            key={uuidv4()}
                            name={option[1]}
                            valuePropName='checked'
                            rules={[
                              {
                                required: !!formField?.required,
                                message: 'Please fill input',
                              },
                            ]}
                          >
                            <StyledCheckbox>{option[1]}</StyledCheckbox>
                          </FormItem>
                        ),
                      )}
                    </CheckboxGroup>
                  </CheckboxBlock>
                );
              }

              return (
                <Fragment key={uuidv4()}>
                  <FormItem
                    label={formField.label}
                    name={formField.name}
                    rules={[
                      {
                        required: !!formField?.required,
                        message: 'Please fill input',
                      },
                    ]}
                  >
                    <InputWrapper
                      placeholder={formField.label}
                      $borderRadius='8px'
                    />
                  </FormItem>
                </Fragment>
              );
            })}
            <FormItem>
              <ButtonWrapper
                type='primary'
                htmlType='submit'
                $borderRadius='8px'
              >
                Save
              </ButtonWrapper>
            </FormItem>
          </>
        </Form>
      ) : null}
    </Modal>
  );
};

export default FormModal;
