import {IBadgeCountRes} from './badgeCount.types';

// GET_BADGE_COUNT TYPES & ACTIONS
export const GET_BADGE_COUNT_REQUEST: string = 'GET_BADGE_COUNT_REQUEST';
export const GET_BADGE_COUNT_SUCCESS: string = 'GET_BADGE_COUNT_SUCCESS';
export const GET_BADGE_COUNT_FAILURE: string = 'GET_BADGE_COUNT_FAILURE';

export const getBadgeCountRequestAction = (): {type: string} => ({
  type: GET_BADGE_COUNT_REQUEST,
});

export const getBadgeCountSuccessAction = (payload: IBadgeCountRes) => ({
  type: GET_BADGE_COUNT_SUCCESS,
  payload,
});

export const getBadgeCountFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_BADGE_COUNT_FAILURE,
  payload,
});

// CHANGE_STATUS_NOTIFICATION TYPES & ACTIONS
export const CHANGE_STATUS_NOTIFICATION_REQUEST: string =
  'CHANGE_STATUS_NOTIFICATION_REQUEST';
export const CHANGE_STATUS_NOTIFICATION_SUCCESS: string =
  'CHANGE_STATUS_NOTIFICATION_SUCCESS';
export const CHANGE_STATUS_NOTIFICATION_FAILURE: string =
  'CHANGE_STATUS_NOTIFICATION_FAILURE';

export const changeStatusNotificationRequestAction = (): {type: string} => ({
  type: CHANGE_STATUS_NOTIFICATION_REQUEST,
});

export const changeStatusNotificationSuccessAction = (payload: any) => ({
  type: CHANGE_STATUS_NOTIFICATION_SUCCESS,
  payload,
});

export const changeStatusNotificationFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CHANGE_STATUS_NOTIFICATION_FAILURE,
  payload,
});
