import {ISuccessResponse} from '../chat.types';

// SEND_MESSAGE TYPES & ACTIONS
export const SEND_MESSAGE_REQUEST: string = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS: string = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE: string = 'SEND_MESSAGE_FAILURE';

export const sendMessageRequestAction = (): {type: string} => ({
  type: SEND_MESSAGE_REQUEST,
});

export const sendMessageSuccessAction = (payload: ISuccessResponse) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload,
});

export const sendMessageFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_MESSAGE_FAILURE,
  payload,
});

// DELETE_MESSAGE TYPES & ACTIONS
export const DELETE_MESSAGE_REQUEST: string = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS: string = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE: string = 'DELETE_MESSAGE_FAILURE';

export const deleteMessageRequestAction = (): {type: string} => ({
  type: DELETE_MESSAGE_REQUEST,
});

export const deleteMessageSuccessAction = (payload: ISuccessResponse) => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload,
});

export const deleteMessageFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DELETE_MESSAGE_FAILURE,
  payload,
});
