import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import MainCard from '../MainCard';
import {RootState} from '../../../state/store';
import {PageWrapper} from './VerifyAccount.styles';
import {OpenedLetterColored} from '../../static/images';

const VerifyAccount = () => {
  const history = useHistory();
  const {verificationUrl} = useSelector((state: RootState) => state.appReducer);

  return (
    <PageWrapper>
      <MainCard
        icon={<OpenedLetterColored />}
        title='According to the company‘s policy, you must verify your account
            first to be able to use this payment method'
        textButton=' Verify account'
        handleClick={() => history.push(verificationUrl)}
      />
    </PageWrapper>
  );
};

export default VerifyAccount;
