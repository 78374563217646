import {IFormValues} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

// eslint-disable-next-line import/prefer-default-export
export const formValuesPersonalForm = (values: IFormValues): IFormValues => {
  const {
    day_birth,
    month_birth,
    year_birth,
    phone_code,
    phone_number,
    ...restFormValues
  } = values;

  const date_of_birth = `${day_birth}-${month_birth}-${year_birth}`;

  return {date_of_birth, phone: phone_number, ...restFormValues};
};
