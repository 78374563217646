import {useState, useEffect, useRef, RefObject} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Plus} from '../../../../static/images';
import {ICurrenciesItemProps, IListGrid} from './CurrenciesItem.types';
import {
  CurrenciesListItem,
  BalancePart,
  IconWrapper,
  BalanceText,
  Title,
  BalanceTitle,
  SubTitle,
  CurrencySymbol,
  AddMoneyButton,
  ButtonText,
} from './CurrenciesItem.styles';
import {defineCurrencySymbolIcon} from '../../../../../utils';

const CurrenciesItem = ({
  currenciesItem,
  goToTopUp,
  handleKeyBreakpointChanges,
  handleScreenIncreasing,
}: ICurrenciesItemProps) => {
  const balanceValueRef = useRef(null) as RefObject<HTMLParagraphElement>;

  const [isElementOverlapped, setElementOverlapped] = useState(false);
  const [screenWidthOverlapped, setScreenWidthOverlapped] = useState<
    number | null
  >(null);
  const [currentInnerWidth, setCurrentInnerWidth] = useState<number | null>(
    null,
  );

  const screenXXL = useMediaQuery({minWidth: 1600});
  const screenXL = useMediaQuery({maxWidth: 1599, minWidth: 1200});
  const screenLG = useMediaQuery({maxWidth: 1199, minWidth: 992});
  const screenMD = useMediaQuery({maxWidth: 991, minWidth: 768});
  const screenSM = useMediaQuery({maxWidth: 767, minWidth: 576});
  const screenXS = useMediaQuery({maxWidth: 575});

  const gridBreakpointMap = {
    xxl: screenXXL,
    xl: screenXL,
    lg: screenLG,
    md: screenMD,
    sm: screenSM,
    xs: screenXS,
  };

  const resizeHandler = () => {
    setCurrentInnerWidth(window.innerWidth);

    if (
      balanceValueRef.current &&
      balanceValueRef.current?.clientWidth <
        balanceValueRef.current?.scrollWidth
    ) {
      setElementOverlapped(true);
      setScreenWidthOverlapped(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  useEffect(() => {
    const breakpointEntry = Object.entries(gridBreakpointMap).find(
      (itm) => itm[1],
    );

    if (!breakpointEntry) return;

    const breakpointKey = breakpointEntry[0] as keyof IListGrid;

    if (!breakpointKey) return;

    if (isElementOverlapped) {
      handleKeyBreakpointChanges(breakpointKey);
      handleScreenIncreasing(false);
      setElementOverlapped(false);

      return;
    }

    if (
      !isElementOverlapped &&
      screenWidthOverlapped &&
      currentInnerWidth &&
      currentInnerWidth > screenWidthOverlapped
    ) {
      handleKeyBreakpointChanges(breakpointKey);
      handleScreenIncreasing(true);
    }
  }, [isElementOverlapped, screenWidthOverlapped, currentInnerWidth]);

  return (
    <CurrenciesListItem key={currenciesItem.id}>
      <BalancePart>
        <IconWrapper>
          {defineCurrencySymbolIcon(currenciesItem.title || 'default')}
        </IconWrapper>
        <BalanceText>
          <Title>
            <BalanceTitle>Balance</BalanceTitle>
          </Title>
          <SubTitle ref={balanceValueRef}>
            <span>{currenciesItem.description}</span>
            <CurrencySymbol>
              {currenciesItem.currency || currenciesItem.title}
            </CurrencySymbol>
          </SubTitle>
        </BalanceText>
      </BalancePart>
      <AddMoneyButton onClick={goToTopUp(currenciesItem.id)}>
        <Plus />
        <ButtonText>Add money</ButtonText>
      </AddMoneyButton>
    </CurrenciesListItem>
  );
};

export default CurrenciesItem;
