import {
  FieldEntry,
  IFieldItems,
  IFormValuesInitial,
} from '../../state/Standardized/standardized.types';

// eslint-disable-next-line import/prefer-default-export
export const createInitialFilledFormValues = (obj: IFieldItems) => {
  const newObj: IFormValuesInitial = {};
  Object.entries(obj).forEach((itm: FieldEntry) => {
    newObj[itm[0]] = itm[1].value;
  });

  return newObj;
};
