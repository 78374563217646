import {FC} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const VerificationCircle: FC = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.333 14.1992C23.7127 13.8594 24.2872 13.8594 24.6669 14.1992C26.6136 15.9416 29.182 17 32 17C32.1903 17 32.3794 16.9951 32.5672 16.9856C33.0414 16.9616 33.467 17.2742 33.586 17.7338C33.8564 18.7785 34 19.8732 34 21C34 27.0589 29.856 32.1473 24.2493 33.5904C24.0858 33.6325 23.9143 33.6325 23.7507 33.5904C18.144 32.1473 14 27.0589 14 21C14 19.8732 14.1436 18.7784 14.414 17.7336C14.533 17.2741 14.9587 16.9615 15.4328 16.9855C15.6206 16.995 15.8097 16.9998 16 16.9998C18.8179 16.9998 21.3863 15.9416 23.333 14.1992ZM16.1815 18.9986C16.0623 19.647 16 20.3159 16 21C16 26.034 19.3824 30.2805 24 31.5862C28.6176 30.2805 32 26.034 32 21C32 20.3159 31.9377 19.6471 31.8186 18.9987C28.8723 18.9584 26.1613 17.937 23.9999 16.2475C21.8386 17.9369 19.1276 18.9583 16.1815 18.9986ZM27.7071 21.2929C28.0976 21.6834 28.0976 22.3166 27.7071 22.7071L23.7071 26.7071C23.3166 27.0976 22.6834 27.0976 22.2929 26.7071L20.2929 24.7071C19.9024 24.3166 19.9024 23.6834 20.2929 23.2929C20.6834 22.9024 21.3166 22.9024 21.7071 23.2929L23 24.5858L26.2929 21.2929C26.6834 20.9024 27.3166 20.9024 27.7071 21.2929Z'
      fill='#7367FE'
    />
  </svg>
);
