import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ArrowLeft: FC<IProps> = ({fillColor = '#74828A'}: IProps) => (
  <svg
    width='14'
    height='8'
    viewBox='0 0 14 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.70711 7.70711C4.31658 8.09763 3.68342 8.09763 3.29289 7.70711L0.292894 4.7071C-0.0976311 4.31658 -0.0976311 3.68342 0.292894 3.29289L3.29289 0.292892C3.68342 -0.0976324 4.31658 -0.0976323 4.70711 0.292892C5.09763 0.683416 5.09763 1.31658 4.70711 1.7071L3.41421 3L13 3C13.5523 3 14 3.44771 14 4C14 4.55228 13.5523 5 13 5L3.41421 5L4.70711 6.29289C5.09763 6.68342 5.09763 7.31658 4.70711 7.70711Z'
      fill={fillColor}
    />
  </svg>
);
