import {FC} from 'react';
import {IFormItemProps} from './FormItem.types';
import {InputInner, InputWrapper, Label} from './FormItem.styles';

const FormItem: FC<IFormItemProps> = ({
  label,
  children,
  required,
}: IFormItemProps) => (
  <InputWrapper>
    <Label required={required}>{label}</Label>
    <InputInner>{children}</InputInner>
  </InputWrapper>
);

export default FormItem;
