import {FC} from 'react';
import {IPaymentProviderProps} from './PaymentProvider.types';
import {defineIcon} from './defineIcon';
import {StyledRadio} from '../../../Auth/Auth.styles';
import {Container, Header, Heading} from './PaymentProvider.styles';

const PaymentProvider: FC<IPaymentProviderProps> = ({
  keyProp,
  checked,
  paymentMethodKey,
  paymentMethodName,
  handlePaymentMethod,
}: IPaymentProviderProps) => (
  <Container checked={checked}>
    <Header>
      <StyledRadio
        checked={checked}
        onChange={() =>
          handlePaymentMethod(keyProp, 'preferred', paymentMethodKey)
        }
      />
      {defineIcon(paymentMethodName.toLowerCase())}
      <Heading>{paymentMethodName}</Heading>
    </Header>
  </Container>
);

export default PaymentProvider;
