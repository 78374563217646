import {FC} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../state/store';
import {BadgeOuter, Badge, Counter} from './BadgeCount.styles';
import {IBadgeCountProps} from './BadgeCount.types';

const BadgeCount: FC<IBadgeCountProps> = ({
  badgeCount,
  badgeKey,
}: IBadgeCountProps) => {
  const {badgeCountMap} = useSelector(
    (state: RootState) => state.badgeCountReducer,
  );

  if (badgeCountMap && badgeCountMap[badgeKey] === 0) return <div />;

  const badgeCountDisplayed =
    (badgeCountMap && badgeCountMap[badgeKey]) || badgeCount;

  return (
    <BadgeOuter>
      <Badge />
      <Counter>{badgeCountDisplayed}</Counter>
    </BadgeOuter>
  );
};

export default BadgeCount;
