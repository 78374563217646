import {IAuthSocials, ITokenRes} from '../auth.types';

// SOCIAL_AUTH TYPES & ACTIONS
export const SOCIAL_AUTH_REQUEST: string = 'SOCIAL_AUTH_REQUEST';
export const SOCIAL_AUTH_SUCCESS: string = 'SOCIAL_AUTH_SUCCESS';
export const SOCIAL_AUTH_FAILURE: string = 'SOCIAL_AUTH_FAILURE';

export const socialAuthRequestAction = (): {type: string} => ({
  type: SOCIAL_AUTH_REQUEST,
});

export const socialAuthSuccessAction = (payload: IAuthSocials) => ({
  type: SOCIAL_AUTH_SUCCESS,
  payload,
});

export const socialAuthFailureAction = (
  payload: string,
): {type: string; payload: string} => ({type: SOCIAL_AUTH_FAILURE, payload});

// SOCIAL_AUTH_CALLBACK TYPES & ACTIONS
export const SOCIAL_AUTH_CALLBACK_REQUEST: string =
  'SOCIAL_AUTH_CALLBACK_REQUEST';
export const SOCIAL_AUTH_CALLBACK_SUCCESS: string =
  'SOCIAL_AUTH_CALLBACK_SUCCESS';
export const SOCIAL_AUTH_CALLBACK_FAILURE: string =
  'SOCIAL_AUTH_CALLBACK_FAILURE';

export const socialAuthCallbackRequestAction = (): {type: string} => ({
  type: SOCIAL_AUTH_CALLBACK_REQUEST,
});

export const socialAuthCallbackSuccessAction = (payload: ITokenRes) => ({
  type: SOCIAL_AUTH_CALLBACK_SUCCESS,
  payload,
});

export const socialAuthCallbackFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SOCIAL_AUTH_CALLBACK_FAILURE,
  payload,
});
