import {FC} from 'react';
import {Moment} from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {DatePicker} from 'antd';
import {RangeValue} from 'rc-picker/lib/interface';
import {RootState} from '../../../state/store';
import {ICalendarProps} from './Calendar.types';
import {setDateRange} from '../../../state';

const {RangePicker} = DatePicker;

const Calendar: FC<ICalendarProps> = ({filterDataAction}: ICalendarProps) => {
  const dispatch = useDispatch();
  const {tableName} = useParams<{tableName: string}>();

  const {rangePickerValue} = useSelector(
    (state: RootState) => state.standardizedReducer,
  );

  const formQueryString = (dateStringsTuple: [string, string]) =>
    `date_range[date_range_from]=${dateStringsTuple[0]}&date_range[date_range_to]=${dateStringsTuple[1]}`;

  const handleChange = (
    dates: RangeValue<Moment>,
    dateStrings: [string, string],
  ) => {
    const queryString = formQueryString(dateStrings);
    dispatch(filterDataAction(`${tableName}?${queryString}`));
    dispatch(setDateRange(dates));
  };

  return (
    <div>
      <RangePicker
        dropdownClassName='standardized-calendar'
        onChange={handleChange}
        value={rangePickerValue}
      />
    </div>
  );
};

export default Calendar;
