import {
  FieldEntry,
  IFieldItems,
  IFormValuesInitial,
} from '../../state/Standardized/standardized.types';

// eslint-disable-next-line import/prefer-default-export
export const createInitialFormValues = (obj: IFieldItems) => {
  const newObj: IFormValuesInitial = {};

  Object.entries(obj).forEach((itm: FieldEntry) => {
    if (itm[1].type === 'checkbox' && itm[1].options) {
      Object.entries(itm[1].options).forEach((elem) => {
        if (itm[1].value && Array.isArray(itm[1].value)) {
          itm[1].value.forEach((value: any) => {
            if (+elem[0] === +value || newObj[elem[1]]) {
              newObj[elem[1]] = true;
              return;
            }
            newObj[elem[1]] = false;
          });
          return;
        }
        newObj[elem[1]] = false;
      });
      return;
    }

    if (itm[1].value) {
      newObj[itm[0]] = itm[1].value;
      return;
    }

    newObj[itm[0]] = '';
  });

  return newObj;
};
