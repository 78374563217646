import {AxiosResponse} from 'axios';
import {setUser} from '../../../infra/token.service';
import {
  login,
  registerPayment,
  redirectPaymentRegister,
} from '../../../infra/auth.service';
import {ThunkActionType} from '../../store';
import {
  userRegistrationPaymentRequestAction,
  userRegistrationPaymentSuccessAction,
  userRegistrationPaymentFailureAction,
  userLoginPaymentRequestAction,
  userLoginPaymentSuccessAction,
  userLoginPaymentFailureAction,
  redirectPaymentRegisterRequestAction,
  redirectPaymentRegisterSuccessAction,
  redirectPaymentRegisterFailureAction,
} from '../actions/auth.payment';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';
import {IRegisterPayment} from '../auth.types';

export const registerUserPayment =
  ({
    firstName,
    lastName,
    email,
    password,
    password_confirmation,
    short,
    inv_id,
  }: IRegisterPayment): ThunkActionType =>
  (dispatch) => {
    dispatch(userRegistrationPaymentRequestAction());

    return registerPayment(
      firstName,
      lastName,
      email,
      password,
      password_confirmation,
      short,
      inv_id,
    )
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: userRegistrationPaymentSuccessAction,
          failureAction: userRegistrationPaymentFailureAction,
          notificationAction: setNotificationState,
        });
        setUser(response.data);

        if (response.data.redirect) {
          localStorage.setItem('redirect_url_payment', response.data.redirect);
        }
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: userRegistrationPaymentFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const redirectPayment =
  (url: string): ThunkActionType =>
  (dispatch) => {
    dispatch(redirectPaymentRegisterRequestAction());

    return redirectPaymentRegister(url)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: redirectPaymentRegisterSuccessAction,
          failureAction: redirectPaymentRegisterFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: redirectPaymentRegisterFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const loginUserPayment =
  (email: string, password: string): ThunkActionType =>
  (dispatch) => {
    dispatch(userLoginPaymentRequestAction());

    return login(email, password)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: userLoginPaymentSuccessAction,
          failureAction: userLoginPaymentFailureAction,
          notificationAction: setNotificationState,
        });
        setUser(response.data);
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: userLoginPaymentFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
