import {AxiosResponse} from 'axios';
import {Dispatch, ActionCreator} from 'redux';

interface IHandleResponseFailNotification {
  response: AxiosResponse;
  dispatch: Dispatch;
  successAction: ActionCreator<any>;
  failureAction: ActionCreator<any>;
  notificationAction?: ActionCreator<any>;
}

// eslint-disable-next-line import/prefer-default-export
export const handleResponseFailNotification = ({
  response,
  dispatch,
  successAction,
  failureAction,
  notificationAction,
}: IHandleResponseFailNotification) => {
  if ('error' in response.data && response.data.error) {
    if (response.data.message) {
      dispatch(failureAction(response.data.message));

      if (notificationAction) {
        dispatch(
          notificationAction({
            isShow: true,
            type: 'error',
            message: response.data.message || 'Error',
          }),
        );
      }
      return;
    }

    dispatch(failureAction('Something went wrong, try later'));

    if (notificationAction) {
      dispatch(
        notificationAction({
          isShow: true,
          type: 'error',
          message: response.data.message || 'Error',
        }),
      );
    }
    return;
  }

  dispatch(successAction(response.data));
};
