import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {declineIncompleteInvoiceRequest, setCurrentSlug} from '../../../state';
import {ButtonGroup, ButtonWrapper} from './ActionsListPayment.styles';
import {IActionsListPaymentProps} from './ActionsListPayment.types';

const ActionsListPayment: FC<IActionsListPaymentProps> = ({
  actionsList,
}: IActionsListPaymentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ButtonGroup>
      {actionsList.map((actionItem) => {
        if (actionItem.action === 'pay') {
          return (
            <ButtonWrapper
              key={actionItem.action}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(setCurrentSlug(actionItem.slug));
                history.push(`/incomplete-confirm/${actionItem.id}`);
              }}
            >
              {actionItem.content}
            </ButtonWrapper>
          );
        }
        if (actionItem.action === 'decline') {
          return (
            <ButtonWrapper
              key={actionItem.action}
              onClick={(event) => {
                event.stopPropagation();
                if (!actionItem.id) return;

                dispatch(
                  declineIncompleteInvoiceRequest(actionItem.slug, {
                    id: +actionItem.id,
                  }),
                );
              }}
            >
              {actionItem.content}
            </ButtonWrapper>
          );
        }
        return (
          <ButtonWrapper key={actionItem.action}>
            {actionItem.content}
          </ButtonWrapper>
        );
      })}
    </ButtonGroup>
  );
};

export default ActionsListPayment;
