import {FC, useState, useEffect} from 'react';
import {Col, Form, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {
  FormItem,
  InputWrapper,
  InputPasswordWrapper,
  ButtonWrapper,
  FadeFormItem,
} from '../../Auth/Auth.styles';
import {useLocalValidation} from '../../../../hooks';
import {IRegisterUserData} from '../../Auth/Auth.types';
import {registerUser, registerUserPayment} from '../../../../../state';
import {RootState} from '../../../../../state/store';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';

const RegistrationForm: FC = (): JSX.Element => {
  const history = useHistory();
  const {search} = useLocation();

  const [form] = Form.useForm();
  const {formatMessage} = useIntl();
  const {emailRules, passwordRules, codeRules, confirmPasswordRules} =
    useLocalValidation(formatMessage);

  const dispatch = useDispatch();
  const {loading, isRegister, isCodeActivated} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  const [initialFormValues, setInitialFormValues] = useState({});
  const [queryStringParams, setQueryStringParams] = useState({
    firstName: '',
    lastName: '',
    email: '',
    short: '',
    inv_id: '',
  });

  useEffect(() => {
    if (isCodeActivated) {
      history.push('/login');
    }
  }, [history, isCodeActivated]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    const firstName = urlSearchParams.get('firstName') || '';
    const lastName = urlSearchParams.get('lastName') || '';
    const email = urlSearchParams.get('email') || '';
    const short = urlSearchParams.get('short') || '';
    const inv_id = urlSearchParams.get('inv_id') || '';

    setQueryStringParams({
      firstName,
      lastName,
      email,
      short,
      inv_id,
    });
  }, []);

  useEffect(() => {
    const isFilledSomeQueryParam = Object.values(queryStringParams).some(
      (itm) => itm,
    );

    if (!isFilledSomeQueryParam) return;

    setInitialFormValues({
      firstName: queryStringParams.firstName,
      lastName: queryStringParams.lastName,
      email: queryStringParams.email,
      password: '',
      confirmPassword: '',
      code: '',
    });
  }, [queryStringParams]);

  const registerUserHandler = (values: IRegisterUserData): void => {
    if (search) {
      dispatch(
        registerUserPayment({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPassword,
          short: queryStringParams.short,
          inv_id: queryStringParams.inv_id,
        }),
      );
      return;
    }

    dispatch(
      registerUser(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.confirmPassword,
      ),
    );
  };

  if (Object.keys(initialFormValues).length === 0) return <div />;

  return (
    <Form
      preserve={false}
      layout='vertical'
      form={form}
      onFinish={registerUserHandler}
      initialValues={initialFormValues}
    >
      <Row justify='space-between'>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <FormItem
            label={formatMessage({id: 'FIRST_NAME_INPUT_FIELD'})}
            name='firstName'
          >
            <InputWrapper
              $borderRadius='8px'
              disabled={isRegister}
              placeholder={formatMessage({id: 'FIRST_NAME_INPUT_FIELD'})}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <FormItem
            label={formatMessage({id: 'LAST_NAME_INPUT_FIELD'})}
            name='lastName'
          >
            <InputWrapper
              $borderRadius='8px'
              disabled={isRegister}
              placeholder={formatMessage({id: 'LAST_NAME_INPUT_FIELD'})}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem
        label={formatMessage({id: 'EMAIL_INPUT_FIELD'})}
        name='email'
        rules={emailRules}
      >
        <InputWrapper
          type='email'
          autoComplete='off'
          $borderRadius='8px'
          disabled={isRegister}
          placeholder={formatMessage({id: 'EMAIL_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'PASSWORD_INPUT_FIELD'})}
        name='password'
        rules={passwordRules}
      >
        <InputPasswordWrapper
          disabled={isRegister}
          $borderRadius='8px'
          placeholder={formatMessage({
            id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER',
          })}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'REPEAT_PASSWORD_INPUT_FIELD'})}
        name='confirmPassword'
        dependencies={['password']}
        hasFeedback
        rules={confirmPasswordRules}
      >
        <InputPasswordWrapper
          disabled={isRegister}
          $borderRadius='8px'
          placeholder={formatMessage({
            id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER',
          })}
        />
      </FormItem>
      {isRegister && (
        <FadeFormItem
          $isRegister={isRegister}
          label={formatMessage({id: 'ACTIVATION_CODE_INPUT_FIELD'})}
          name='code'
          rules={codeRules}
        >
          <InputWrapper
            $borderRadius='8px'
            autoComplete='off'
            placeholder={formatMessage({
              id: 'ACTIVATION_CODE_INPUT_FIELD_PLACEHOLDER',
            })}
          />
        </FadeFormItem>
      )}
      <FormItem>
        <ButtonWrapper
          type='primary'
          htmlType='submit'
          $borderRadius='8px'
          loading={loading}
        >
          {formatMessage({
            id: !isRegister
              ? 'CREATE_ACCOUNT_BUTTON'
              : 'ACTIVATION_CODE_BUTTON',
          })}
        </ButtonWrapper>
      </FormItem>
    </Form>
  );
};

export default RegistrationForm;
