import styled from 'styled-components';

export const NotificationBanner = styled.div<{isShow: boolean}>`
  background-color: ${(p) => p.theme.main.colors.brand};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: ${({isShow}) => (isShow ? 'block' : 'none')};
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;

  @media (max-width: 500px) {
    padding: 12px 16px;
  }
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;

  @media (max-width: 420px) {
    margin-right: 10px;
  }
`;

export const RightPart = styled.div`
  display: flex;
  align-items: center;
`;

export const IconBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  background-color: #5c52cb;
  border-radius: 8px;

  @media (max-width: 420px) {
    min-width: 30px;
    min-height: 30px;
  }
`;

export const Text = styled.p`
  ${(p) => p.theme.main.typography.paragraph};
  color: ${(p) => p.theme.main.colors.white};
  margin-left: 16px;

  @media (max-width: 500px) {
    ${(p) => p.theme.main.typography.body};
  }

  @media (max-width: 380px) {
    margin-left: 10px;
    ${(p) => p.theme.main.typography.caption};
  }
`;

export const ContainedButton = styled.button`
  padding: 10px 20px;
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.white};

  @media (max-width: 420px) {
    padding: 10px;
  }

  @media (max-width: 380px) {
    ${(p) => p.theme.main.typography.captionMedium};
  }
`;

export const IconButton = styled.button`
  margin-left: 24px;

  @media (max-width: 420px) {
    margin-left: 16px;
  }
`;
