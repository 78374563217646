// GET_DASHBOARD_DATA TYPES & ACTIONS
export const GET_DASHBOARD_DATA_REQUEST: string = 'GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS: string = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE: string = 'GET_DASHBOARD_DATA_FAILURE';

export const getDashboardDataRequestAction = (): {type: string} => ({
  type: GET_DASHBOARD_DATA_REQUEST,
});

export const getDashboardDataSuccessAction = (payload: any) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload,
});

export const getDashboardDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_DASHBOARD_DATA_FAILURE,
  payload,
});
