import {FC} from 'react';
import {IRestPaymentProvidersProps} from './RestPaymentProviders.types';
import {defineIcon} from '../PaymentProvider/defineIcon';
import {StyledRadio} from '../../../Auth/Auth.styles';
import {Container, Header, Heading} from './RestPaymentProviders.styles';
import FormSelect from '../FormSelect';

const RestPaymentProviders: FC<IRestPaymentProvidersProps> = ({
  checked,
  paymentMethodKey,
  additionalProviders,
  handleSelectChange,
  handleRestPaymentOptions,
}: IRestPaymentProvidersProps) => {
  const valueSelect = additionalProviders.find(
    (itm) => itm[0] === paymentMethodKey,
  );

  return (
    <Container checked={checked}>
      <Header>
        <StyledRadio
          checked={checked}
          onChange={() => handleRestPaymentOptions()}
        />
        {defineIcon('Other payment options'.toLowerCase())}
        <Heading>Other payment options</Heading>
      </Header>
      {checked ? (
        <FormSelect
          keyProp='method'
          valueSelect={valueSelect || 'Method'}
          placeholder='Method'
          options={additionalProviders}
          handleSelectChange={handleSelectChange}
        />
      ) : null}
    </Container>
  );
};

export default RestPaymentProviders;
