import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Down: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.999999 1L5 5L9 1'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
