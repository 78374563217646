import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Edit: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5858 1.58579C12.3668 0.804738 13.6332 0.804738 14.4142 1.58579C15.1953 2.36683 15.1953 3.63316 14.4142 4.41421L13.6213 5.20711L10.7929 2.37868L11.5858 1.58579Z'
      fill={fillColor}
    />
    <path
      d='M9.37868 3.79289L1 12.1716V15H3.82842L12.2071 6.62132L9.37868 3.79289Z'
      fill={fillColor}
    />
  </svg>
);
