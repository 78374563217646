import {FC} from 'react';
import {Collapse, Tag} from 'antd';
import {CollapseProps} from 'antd/lib/collapse';
import styled from 'styled-components';
import {IPaymentAmountProps, IStyledTagProps} from './CollapseList.types';

const defineTagTextColor = (tagStatus: string): string => {
  switch (tagStatus) {
    case 'success':
      return '#065f46';
    case 'open_pay':
      return '#9a3412';
    default:
      return '#991b1b';
  }
};

export const PeriodDivider = styled.div`
  ${(p) => p.theme.main.typography.captionMedium};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-bottom: 16px;
  position: relative;
  &:after {
    content: '';
    width: calc(100% - 120px);
    height: 1px;
    background-color: #ebebeb;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

export const StyledCollapse: FC<CollapseProps> = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: 0;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 24px;
  }

  .ant-collapse-content {
    border-top: 0;
    border-radius: 8px !important;
  }

  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  border: 0;
`;

export const StyledPanel = styled(Collapse.Panel)``;

export const PanelInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Contractor = styled.p`
  ${(p) => p.theme.main.typography.subHeading};
  margin-bottom: 4px;
  color: #192b3b;
`;

export const PaymentStateBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const PaymentState = styled.p`
  ${(p) => p.theme.main.typography.caption};
  margin-left: 8px;
  color: #192b3b;
`;

export const PaymentDate = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: #74828a;
`;

export const PaymentAmount = styled.p<IPaymentAmountProps>`
  ${(p) => p.theme.main.typography.subHeading};
  color: ${({isPaymentReceived}) =>
    isPaymentReceived === 1 ? '#10b981' : '#192b3b'};
`;

export const ArrowWrapperTop = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 34px;
`;

export const ArrowWrapperBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
`;

export const ArrowWrapperRotate = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
`;

export const CollapseContent = styled.div`
  display: flex;

  @media (max-width: 400px) {
    flex-wrap: wrap;
  }
`;

export const CollapseContentPartLeft = styled.div`
  width: 50%;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const CollapseContentPartRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const Heading = styled.h3`
  ${(p) => p.theme.main.typography.bodyMedium};
  margin-bottom: 8px;
  color: #192b3b;
`;

export const HeadingMarginLeft = styled.h3`
  ${(p) => p.theme.main.typography.bodyMedium};
  margin-left: 8px;
  color: #192b3b;
`;

export const DescriptionText = styled.p`
  ${(p) => p.theme.main.typography.caption};
  margin-bottom: 4px;
  color: #74828a;
`;

export const DescriptionTextMedium = styled.p`
  ${(p) => p.theme.main.typography.captionMedium};
  margin-top: 4px;
  color: #192b3b;
`;

export const DescriptionTextColored = styled.p`
  ${(p) => p.theme.main.typography.caption};
  margin-bottom: 4px;
  color: #7367fe;
`;

export const DetailedInfoBlock = styled.div`
  margin-bottom: 16px;
`;

export const StyledTag = styled(Tag)<IStyledTagProps>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${({status}) => defineTagTextColor(status)};
  text-transform: capitalize;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
`;

export const DetailsTotal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PrintDetailsBlock = styled.div`
  display: flex;
  align-items: center;
`;
