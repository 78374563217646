import {EnvelopeCircle} from '../../../../static/images/EnvelopeCircle';
import {
  BlackText,
  ColoredText,
  Container,
  IconWrapper,
  Paragraph,
  Title,
} from './WithdrawPopup.styles';

const WithdrawPopup = () => (
  <Container>
    <IconWrapper>
      <EnvelopeCircle />
    </IconWrapper>
    <Title>Withdraw</Title>
    <Paragraph>
      <BlackText>Please contact us by</BlackText>
      <ColoredText> support@blixti.com </ColoredText>
      <BlackText>in advanced to clarify withdrawal details.</BlackText>
    </Paragraph>
  </Container>
);

export default WithdrawPopup;
