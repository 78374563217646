import {useEffect} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useAutoLogout = (logout: () => void) => {
  const timeout = 1200000; // 20min
  let interval: NodeJS.Timer;

  const updateExpiredTime = () => {
    localStorage.setItem('_expiredTime', (Date.now() + timeout).toString());
  };

  const startInterval = () => {
    updateExpiredTime();

    interval = setInterval(() => {
      const expiredTime = localStorage.getItem('_expiredTime');
      const parsedExpiredTime = parseInt(expiredTime || '0', 10);

      if (parsedExpiredTime < Date.now()) {
        logout();
        cleanUp();
      }
    }, 1000);
  };

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime');
    clearInterval(interval);
    window.removeEventListener('mousemove', updateExpiredTime);
    window.removeEventListener('keydown', updateExpiredTime);
    window.removeEventListener('click', updateExpiredTime);
    window.removeEventListener('scroll', updateExpiredTime);
  };

  useEffect(() => {
    startInterval();
  }, []);

  window.addEventListener('mousemove', updateExpiredTime);
  window.addEventListener('keydown', updateExpiredTime);
  window.addEventListener('click', updateExpiredTime);
  window.addEventListener('scroll', updateExpiredTime);
};
