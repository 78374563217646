import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form, Input, Select} from 'antd';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import {v4 as uuidv4} from 'uuid';
import {
  clearFilterDataMap,
  setFilterDataMap,
  filterStandardDataRequest,
} from '../../../../../state';
import {
  IFilterItem,
  IFiltersDrawerProps,
  IFormValues,
  OptionTuple,
} from './FiltersDrawer.types';
import {
  createFilterFormValues,
  createFormValues,
  createQueryString,
  decreaseSelectValue,
  formFilterValues,
} from '../../../../../utils';
import {
  ButtonWrapper,
  CloseIconButton,
  DrawerMain,
  DrawerHeader,
  ButtonGroup,
  StyledDrawer,
  DrawerContent,
} from './FiltersDrawer.styles';
import {CloseIcon} from '../../../../static/images';
import {FormItem} from '../../Auth/Auth.styles';
import {RootState} from '../../../../../state/store';

const {Option} = Select;

const FiltersDrawer: FC<IFiltersDrawerProps> = ({
  visible,
  filterTemplate,
  handleFiltersVisible,
}: IFiltersDrawerProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {tableName} = useParams<{tableName: string}>();
  const [formValues, setFormValues] = useState<IFormValues>({});

  const {filterDataMap} = useSelector(
    (state: RootState) => state.standardizedReducer,
  );

  const initialFilterValues = filterDataMap || {};

  const [filterValues, setFilterValues] =
    useState<IFormValues>(initialFilterValues);

  const addMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    if (formValues[prop] || filterValues[prop]) {
      setFormValues((prevState) => ({
        ...prevState,
        [prop]: [...formValues[prop], option.key],
      }));

      setFilterValues((prevState) => ({
        ...prevState,
        [prop]: [...filterValues[prop], option.value],
      }));

      return;
    }

    setFormValues((prevState) => ({...prevState, [prop]: option.key}));
    setFilterValues((prevState) => ({...prevState, [prop]: [option.value]}));
  };

  const removeMultiSelectValue = (
    prop: string,
    option: OptionData | OptionGroupData,
  ): void => {
    setFormValues((prevState) =>
      decreaseSelectValue(prevState, prop, option, 'key'),
    );

    setFilterValues((prevState) =>
      decreaseSelectValue(prevState, prop, option, 'value'),
    );
  };

  const handleSelectChange = (
    prop: string,
    option: OptionData | OptionGroupData,
  ) => {
    setFormValues((prevState) => ({
      ...prevState,
      [prop]: option.key,
    }));

    setFilterValues((prevState) => ({
      ...prevState,
      [prop]: option.value,
    }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    setFilterValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const applyFilters = () => {
    const resultValues = formFilterValues({
      filterValues,
      formValues,
      filterTemplate,
    });

    const formedQueryString = createQueryString(resultValues);
    dispatch(filterStandardDataRequest(`${tableName}?${formedQueryString}`));
    dispatch(setFilterDataMap(filterValues));
    handleFiltersVisible(false);
  };

  const clearFilters = () => {
    setFormValues(createFilterFormValues(filterTemplate));
    setFilterValues(createFilterFormValues(filterTemplate));
    dispatch(clearFilterDataMap());
    form.setFieldsValue(createFilterFormValues(filterTemplate));
  };

  const cancelFilters = () => {
    clearFilters();
    handleFiltersVisible(false);
  };

  useEffect(() => {
    setFormValues(createFormValues(filterTemplate));
  }, []);

  return (
    <StyledDrawer
      width={561}
      placement='right'
      visible={visible}
      closable={false}
      onClose={() => handleFiltersVisible(false)}
    >
      <DrawerHeader>
        <p>Filters</p>
        <CloseIconButton onClick={cancelFilters}>
          <CloseIcon />
        </CloseIconButton>
      </DrawerHeader>
      <DrawerContent>
        <Form
          form={form}
          preserve={false}
          layout='vertical'
          initialValues={filterValues}
        >
          <DrawerMain>
            {filterTemplate.map((filter: IFilterItem) => {
              if (filter.type === 'select' && filter.multi && filter.options) {
                return (
                  <FormItem
                    key={filter.name}
                    label={filter.label}
                    name={filter.name}
                  >
                    <Select
                      mode='tags'
                      value={formValues[filter.name]}
                      onSelect={(value, option) => {
                        addMultiSelectValue(filter.name, option);
                      }}
                      onDeselect={(value, option) => {
                        removeMultiSelectValue(filter.name, option);
                      }}
                    >
                      {Object.entries(filter.options).map(
                        (option: OptionTuple) => (
                          <React.Fragment key={uuidv4()}>
                            <Option key={option[0]} value={option[1]}>
                              {option[1]}
                            </Option>
                          </React.Fragment>
                        ),
                      )}
                    </Select>
                  </FormItem>
                );
              }

              if (filter.type === 'select' && filter.options) {
                return (
                  <FormItem
                    key={filter.name}
                    label={filter.label}
                    name={filter.name}
                  >
                    <Select
                      value={formValues[filter.name]}
                      onSelect={(value, option) => {
                        handleSelectChange(filter.name, option);
                      }}
                    >
                      {Object.entries(filter.options).map(
                        (option: OptionTuple) => (
                          <Option key={option[0]} value={option[1]}>
                            {option[1]}
                          </Option>
                        ),
                      )}
                    </Select>
                  </FormItem>
                );
              }

              if (filter.type === 'input') {
                return (
                  <FormItem
                    name={filter.name}
                    key={filter.name}
                    label={filter.label}
                    tooltip={filter.tooltip ? {title: filter.tooltip} : null}
                  >
                    <Input
                      name={filter.name}
                      value={formValues[filter.name] || filter.value}
                      onChange={handleInputChange}
                      placeholder={`Enter ${filter.name} ${
                        filter.name === 'counterparty' ? 'name' : ''
                      }`}
                    />
                  </FormItem>
                );
              }

              return null;
            })}
          </DrawerMain>
        </Form>
        <ButtonGroup>
          <ButtonWrapper action='apply' onClick={applyFilters}>
            Apply filters
          </ButtonWrapper>
          <ButtonWrapper onClick={cancelFilters} action='cancel'>
            Cancel
          </ButtonWrapper>
          <ButtonWrapper action='clear' onClick={clearFilters}>
            Clear
          </ButtonWrapper>
        </ButtonGroup>
      </DrawerContent>
    </StyledDrawer>
  );
};

export default FiltersDrawer;
