import {FC, useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {useSelector} from 'react-redux';
import {useRoutes} from './view/hooks';
import {RootState} from './state/store';
import themes from './view/styles/themes';

interface IMenuItem {
  icon: string | null;
  title: string;
  slug: string;
}

const App: FC = (): JSX.Element => {
  const {data} = useSelector((state: RootState) => state.appReducer);
  const {authenticationState}: {authenticationState: string} = useSelector(
    (state: RootState) => state.authReducer,
  );

  const [isDashboardMenuItemExist, setDashboardMenuItemExist] = useState(true);

  useEffect(() => {
    if (!data) return;

    const dashboardItemMenu = data.menu.find(
      (menuItem: IMenuItem) => menuItem.slug === '/dashboard',
    );

    setDashboardMenuItemExist(!!dashboardItemMenu);
  }, [data]);

  const routes: JSX.Element = useRoutes(
    authenticationState,
    isDashboardMenuItemExist,
  );

  return (
    <ThemeProvider theme={themes}>
      <Router>{routes}</Router>
    </ThemeProvider>
  );
};

export default App;
