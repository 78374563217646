import LOCALES from "../locales";
import ENGLISH_MESSAGES from "./EN";
import RUSSIAN_MESSAGES from "./RU";

const messages: {[key: string]: {[key: string]: string | any}} = {
    [LOCALES.ENGLISH]: ENGLISH_MESSAGES,
    [LOCALES.RUSSIAN]: RUSSIAN_MESSAGES,
}

export default messages;
