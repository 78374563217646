import styled from 'styled-components';
import {Spin} from 'antd';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgb(255 255 255 / 30%);
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: 16px;
    ${(p) => p.theme.main.typography.paragraph};
    color: ${(p) => p.theme.main.colors.brand};
  }
`;
