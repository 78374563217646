import {IStandardizedDataItemCreate} from '../standardized.types';

// CREATE_STANDARD_DATA_ITEM TYPES & ACTIONS
export const CREATE_STANDARD_DATA_ITEM_REQUEST: string =
  'CREATE_STANDARD_DATA_ITEM_REQUEST';
export const CREATE_STANDARD_DATA_ITEM_SUCCESS: string =
  'CREATE_STANDARD_DATA_ITEM_SUCCESS';
export const CREATE_STANDARD_DATA_ITEM_FAILURE: string =
  'CREATE_STANDARD_DATA_ITEM_FAILURE';
export const createStandardDataItemRequestAction = (): {type: string} => ({
  type: CREATE_STANDARD_DATA_ITEM_REQUEST,
});

export const createStandardDataItemSuccessAction = (
  payload: IStandardizedDataItemCreate,
): {type: string; payload: any} => ({
  type: CREATE_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const createStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CREATE_STANDARD_DATA_ITEM_FAILURE,
  payload,
});

// STORE_STANDARD_DATA_ITEM TYPES & ACTIONS
export const STORE_STANDARD_DATA_ITEM_REQUEST: string =
  'STORE_STANDARD_DATA_ITEM_REQUEST';
export const STORE_STANDARD_DATA_ITEM_SUCCESS: string =
  'STORE_STANDARD_DATA_ITEM_SUCCESS';
export const STORE_STANDARD_DATA_ITEM_FAILURE: string =
  'STORE_STANDARD_DATA_ITEM_FAILURE';

export const storeStandardDataItemRequestAction = (): {type: string} => ({
  type: STORE_STANDARD_DATA_ITEM_REQUEST,
});

export const storeStandardDataItemSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: STORE_STANDARD_DATA_ITEM_SUCCESS,
  payload,
});

export const storeStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: STORE_STANDARD_DATA_ITEM_FAILURE,
  payload,
});
