import {IFormValues, IFormViewRes} from './formConstructor.types';

// GET_FORM_VIEW TYPES & ACTIONS
export const GET_FORM_VIEW_REQUEST: string = 'GET_FORM_VIEW_REQUEST';
export const GET_FORM_VIEW_SUCCESS: string = 'GET_FORM_VIEW_SUCCESS';
export const GET_FORM_VIEW_FAILURE: string = 'GET_FORM_VIEW_FAILURE';

export const getFormViewRequestAction = (
  payload: string | undefined,
): {type: string; payload: string | undefined} => ({
  type: GET_FORM_VIEW_REQUEST,
  payload,
});

export const getFormViewSuccessAction = (
  payload: IFormViewRes,
): {type: string; payload: IFormViewRes} => ({
  type: GET_FORM_VIEW_SUCCESS,
  payload,
});

export const getFormViewFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_FORM_VIEW_FAILURE,
  payload,
});

// SEND_FORM TYPES & ACTIONS
export const SEND_FORM_REQUEST: string = 'SEND_FORM_REQUEST';
export const SEND_FORM_SUCCESS: string = 'SEND_FORM_SUCCESS';
export const SEND_FORM_FAILURE: string = 'SEND_FORM_FAILURE';

export const sendFormRequestAction = (): {type: string} => ({
  type: SEND_FORM_REQUEST,
});

export const sendFormSuccessAction = (
  payload: IFormValues,
): {type: string; payload: IFormValues} => ({
  type: SEND_FORM_SUCCESS,
  payload,
});

export const sendFormFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_FORM_FAILURE,
  payload,
});

// CLEAR_FORM_VIEW_RES TYPES & ACTIONS
export const CLEAR_FORM_VIEW_RES: string = 'CLEAR_FORM_VIEW_RES';

export const clearFormViewRes = (): {type: string} => ({
  type: CLEAR_FORM_VIEW_RES,
});

// CLEAR_SEND_FORM_RES TYPES & ACTIONS
export const CLEAR_SEND_FORM_RES: string = 'CLEAR_SEND_FORM_RES';

export const clearSendFormRes = (): {type: string} => ({
  type: CLEAR_SEND_FORM_RES,
});

// CLEAR_ALL_FORM_RES TYPES & ACTIONS
export const CLEAR_ALL_FORM_RES: string = 'CLEAR_ALL_FORM_RES';

export const clearAllFormRes = (): {type: string} => ({
  type: CLEAR_ALL_FORM_RES,
});
