import styled from 'styled-components';
import {Select} from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 340px;
  max-width: 360px;

  @media (max-width: 850px) {
    min-width: 300px;
  }

  @media (max-width: 720px) {
    min-width: 260px;
  }

  @media (max-width: 650px) {
    min-width: 340px;
  }

  @media (max-width: 400px) {
    min-width: 300px;
  }

  @media (max-width: 370px) {
    min-width: 260px;
  }
`;

export const SelectWrapper = styled(Select)`
  width: 48%;
  max-width: 160px;
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    border-color: ${(p) => p.theme.main.colors.lightGrey} !important;
  }

  .ant-select-selection-placeholder {
    text-transform: capitalize;
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    margin-bottom: 8px;
    line-height: 48px !important;
  }

  .ant-select-multiple .ant-select-selection-search .ant-select-selection-item {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }

  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.main.colors.black};
  }
`;
