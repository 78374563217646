import {Spin} from 'antd';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {verifyEmailRequest} from '../../../../../state';
import {RootState} from '../../../../../state/store';
import {Container, Title} from './VerificationEmail.styles';

const VerificationEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();

  const {verifyEmailRes} = useSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    const verifyUrl = urlSearchParams.get('url');

    if (verifyUrl) {
      dispatch(verifyEmailRequest(verifyUrl));
    }
  }, []);

  useEffect(() => {
    if (verifyEmailRes) {
      history.push('/login');
    }
  }, [verifyEmailRes]);

  return (
    <Container>
      <Spin tip='Loading...' size='large' />
      <Title>Your email is verifying</Title>
    </Container>
  );
};

export default VerificationEmail;
