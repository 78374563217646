const AUTH_MESSAGES = {
    // REGISTER PAGE
    CREATE_ACCOUNT_TITLE: 'Создать аккаунт',
    ALREADY_EXIST_SUBTITLE: 'Уже есть аккаунт?',
    LOGIN_LINK: 'Авторизируйтесь',
    FIRST_NAME_INPUT_FIELD: 'Имя',
    LAST_NAME_INPUT_FIELD: 'Фамилия',
    CONTINUE_WITH_TEXT: 'Или продолжить с',
    REPEAT_PASSWORD_INPUT_FIELD: 'Повторите пароль',
    CREATE_ACCOUNT_BUTTON: 'Создать аккаунт',
    ACTIVATION_CODE_INPUT_FIELD: 'Код активации',
    ACTIVATION_CODE_INPUT_FIELD_PLACEHOLDER: 'Введите код активации',
    ACTIVATION_CODE_BUTTON: 'Активировать',

    // LOGIN PAGE
    WELCOME_BACK_TITLE: 'Добро пожаловать',
    ACCOUNT_EXIST_SUBTITLE: 'Еще нет аккаунта?',
    SIGN_UP_LINK: 'Зарегистрируйтесь',
    EMAIL_INPUT_FIELD: 'Почта',
    EMAIL_INPUT_FIELD_PLACEHOLDER: 'Введите адрес вашей почты',
    PASSWORD_INPUT_FIELD: 'Пароль',
    PASSWORD_INPUT_FIELD_PLACEHOLDER: 'Введите пароль',
    LOGIN_BUTTON: 'Логин',
    FORGOT_PASSWORD_LINK: 'Забыли пароль?',
    LOGIN_WITH_TEXT: 'Или авторизируйтесь с помощью',
    LOGIN_AND: 'и',
    PRIVACY_POLICY_TEXT: 'Регистрируясь, вы соглашаетесь с нашими',
    PRIVACY_POLICY_TITLE: 'Политикой конфиденциальности',
    TERMS_OF_USE_TITLE: 'Правилами использования',

    // FORGOT PASSWORD PAGE
    FORGOT_PASSWORD_TITLE: 'Забыли пароль?',
    FORGOT_PASSWORD_SUBTITLE: 'Просто введите адрес электронной почты, на который вы зарегистрировались, и мы пришлем вам ссылку для сброса пароля.',
    FORGOT_PASSWORD_BUTTON: 'Отправить ссылку для сброса пароля',

    // USER SETTINGS
    USER_SETTINGS_PROFILE: 'Профиль',
    USER_SETTINGS_VERIFICATION: 'Верификация',
    USER_LOGOUT: 'Логаут',
}

export default AUTH_MESSAGES;
