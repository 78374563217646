// eslint-disable-next-line import/prefer-default-export
export const Logo = ({small}: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={small ? '57.5' : '92'} height={small ? '20' : '32'} viewBox="0 0 92 32" fill="none" style={small ? {textAlign: 'center'} : {}}>
        <path d="M7.90041 6.74252C7.24445 6.74252 6.76614 6.12161 6.93351 5.48737L8.16634 0.815585C8.28218 0.376597 8.67922 0.0707397 9.13324 0.0707397H17.0206C22.3174 0.0707397 26.6113 4.36464 26.6113 9.66143C26.6113 12.1678 25.6499 14.4496 24.0758 16.1581C25.401 17.8029 26.1943 19.8943 26.1943 22.171C26.1943 27.4678 21.9004 31.7617 16.6036 31.7617H1.29812C0.642165 31.7617 0.163851 31.1408 0.331221 30.5066L1.56405 25.8348C1.6799 25.3958 2.07694 25.0899 2.53095 25.0899H16.6036C18.2157 25.0899 19.5226 23.7831 19.5226 22.171C19.5226 20.559 18.2157 19.2521 16.6036 19.2521H4.59927C3.94331 19.2521 3.46499 18.6312 3.63236 17.997L4.8652 13.3252C4.98104 12.8862 5.37808 12.5803 5.8321 12.5803H17.0206C18.6327 12.5803 19.9395 11.2735 19.9395 9.66143C19.9395 8.04936 18.6327 6.74252 17.0206 6.74252H7.90041Z" fill="#7367FE"/>
        <path d="M29.977 30.7903C29.977 31.2838 30.377 31.6839 30.8705 31.6839H35.1026C35.5961 31.6839 35.9962 31.2838 35.9962 30.7903V5.19371C35.9962 4.70022 35.5961 4.30017 35.1026 4.30017H30.8705C30.377 4.30017 29.977 4.70022 29.977 5.19371V30.7903Z" fill="#7367FE"/>
        <path d="M39.2601 9.21529C39.2601 9.70878 39.6601 10.1088 40.1536 10.1088H44.3857C44.8792 10.1088 45.2793 9.70878 45.2793 9.21529V5.19371C45.2793 4.70022 44.8792 4.30017 44.3857 4.30017H40.1536C39.6601 4.30017 39.2601 4.70022 39.2601 5.19371V9.21529ZM39.2601 30.7903C39.2601 31.2838 39.6601 31.6839 40.1536 31.6839H44.3857C44.8792 31.6839 45.2793 31.2838 45.2793 30.7903V13.0571C45.2793 12.5637 44.8792 12.1636 44.3857 12.1636H40.1536C39.6601 12.1636 39.2601 12.5637 39.2601 13.0571V30.7903Z" fill="#7367FE"/>
        <path d="M78.961 32C80.1195 32 81.2982 31.839 82.0655 31.7038C82.4754 31.6315 82.7626 31.2704 82.7626 30.8542V28.1488C82.7626 27.5727 82.2221 27.1344 81.6496 27.1982C81.0978 27.2598 80.5006 27.2977 80.0302 27.2977C77.6542 27.2977 76.7038 26.6655 76.7038 24.6502V16.8263H82.0671C82.5606 16.8263 82.9606 16.4263 82.9606 15.9328V13.0571C82.9606 12.5637 82.5606 12.1636 82.0671 12.1636H76.7038V8.63149C76.7038 8.138 76.3038 7.73795 75.8103 7.73795H71.5782C71.0847 7.73795 70.6846 8.138 70.6846 8.63149V12.1636H68.6874C68.1939 12.1636 67.7938 12.5637 67.7938 13.0571V15.9328C67.7938 16.4263 68.1939 16.8263 68.6874 16.8263H70.6846V24.9664C70.6846 29.313 72.8626 32 78.961 32Z" fill="#7367FE"/>
        <path d="M85.654 9.21529C85.654 9.70878 86.0541 10.1088 86.5476 10.1088H90.7797C91.2732 10.1088 91.6732 9.70878 91.6732 9.21529V5.19371C91.6732 4.70022 91.2732 4.30017 90.7797 4.30017H86.5476C86.0541 4.30017 85.654 4.70022 85.654 5.19371V9.21529ZM85.654 30.7903C85.654 31.2838 86.0541 31.6839 86.5476 31.6839H90.7797C91.2732 31.6839 91.6732 31.2838 91.6732 30.7903V13.0571C91.6732 12.5637 91.2732 12.1636 90.7797 12.1636H86.5476C86.0541 12.1636 85.654 12.5637 85.654 13.0571V30.7903Z" fill="#7367FE"/>
        <path d="M56.7832 26.7504L60.0894 31.1931C60.258 31.4197 60.5238 31.5532 60.8063 31.5532H65.76C66.4886 31.5532 66.9109 30.7281 66.4848 30.1371L60.4709 21.7952L66.4438 13.769C66.8825 13.1796 66.4618 12.342 65.727 12.342H60.368C60.0808 12.342 59.8111 12.4801 59.6432 12.713L56.7832 16.68L53.9233 12.713C53.7554 12.4801 53.4857 12.342 53.1985 12.342H47.8395C47.1047 12.342 46.684 13.1796 47.1227 13.769L53.0956 21.7952L47.0816 30.1371C46.6556 30.7281 47.0779 31.5532 47.8065 31.5532H52.7602C53.0426 31.5532 53.3084 31.4197 53.477 31.1931L56.7832 26.7504Z" fill="#7367FE"/>
    </svg>
    )
