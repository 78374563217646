import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const Paragraph = styled.p`
  ${(p) => p.theme.main.typography.caption};
  color: ${(p) => p.theme.main.colors.darkGrey};
  margin-bottom: 4px;
`;

export const FileChip = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${(p) => p.theme.main.colors.signUp};

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 400px) {
    max-width: 170px;
    margin-right: 0;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;
`;

export const FileName = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  width: 100px;
  margin: 0 8px 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Button = styled.button`
  height: 14px;
  margin: 8px;
`;

export const FileLink = styled.a`
  &:hover {
    color: ${(p) => p.theme.main.colors.black};
  }
`;
