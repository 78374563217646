import {AxiosResponse} from 'axios';
import {ItemDataEdit} from '../standardized.types';
import {ThunkActionType} from '../../store';
import {
  editStandardDataItemRequestAction,
  editStandardDataItemSuccessAction,
  editStandardDataItemFailureAction,
  updateStandardDataItemRequestAction,
  updateStandardDataItemSuccessAction,
  updateStandardDataItemFailureAction,
} from '../actions/update.actions';
import {
  editStandardDataItem,
  updateRolesItem,
  updateStandardDataItem,
} from '../../../infra/standardized.service';
import {getStandardDataRequest} from './read.requests';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  formFilteredUrl,
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraAction,
} from '../../../utils';

export const editStandardDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(editStandardDataItemRequestAction());
    return editStandardDataItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: editStandardDataItemSuccessAction,
          failureAction: editStandardDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: editStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const updateStandardDataItemRequest =
  (slug: string, updatedData: ItemDataEdit): ThunkActionType =>
  (dispatch, getState) => {
    dispatch(updateStandardDataItemRequestAction());

    const {currentPageTable, pageSizeTable, filterDataMap} =
      getState().standardizedReducer;

    const slugFilter = formFilteredUrl({
      slugUrl: slug,
      currentPageTable,
      pageSizeTable,
      filterDataMap,
    });

    return updateStandardDataItem(slug, updatedData)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: updateStandardDataItemSuccessAction,
          failureAction: updateStandardDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugFilter,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: updateStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const updateStandardDataItemQueryParamsRequest =
  (slug: string): ThunkActionType =>
  (dispatch, getState) => {
    dispatch(updateStandardDataItemRequestAction());
    const {currentPageTable, pageSizeTable} = getState().standardizedReducer;
    const idxSlash = slug.indexOf('/');
    const slugGetAllItem = slug.substring(0, idxSlash);
    const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

    return updateRolesItem(slug)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: updateStandardDataItemSuccessAction,
          failureAction: updateStandardDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getStandardDataRequest,
          extraRequestArg: slugPagination,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: updateStandardDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
