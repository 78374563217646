export const defaultFormValues = {
  firstName: '',
  lastName: '',
  day_birth: '01',
  month_birth: '01',
  year_birth: '1990',
  phone_code: '+44',
  phone_number: '',
  country: 'Andorra',
  city: '',
  address: '',
  postcode: '',
};

export const initialFormRules = {
  firstName: [],
  lastName: [],
  day_birth: [],
  month_birth: [],
  year_birth: [],
  phone_code: [],
  phone_number: [],
  country: [],
  city: [],
  address: [],
  postcode: [],
};
