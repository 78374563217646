// USER_LOGOUT TYPES & ACTIONS
export const USER_LOGOUT_REQUEST: string = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS: string = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE: string = 'USER_LOGOUT_FAILURE';

export const userLogoutRequestAction = (): {type: string} => ({
  type: USER_LOGOUT_REQUEST,
});

export const userLogoutSuccessAction = (): {type: string} => ({
  type: USER_LOGOUT_SUCCESS,
});
