import api from './config';
import {APP} from './constants';

const getAppData = () => api.get(APP.MAIN_DATA);

const getCountriesList = () => api.get(APP.COUNTRIES_LIST);

const sendContact = (
  name: string,
  email: string,
  phone: string,
  comment: string,
) =>
  api.post(
    `${APP.CONTACT_SEND}?name=${name}&email=${email}&phone=${phone}&comment=${comment}`,
  );

const sendPartner = (
  name: string,
  email: string,
  phone: string,
  company: string,
  country: string,
  type_client: string,
  comment: string,
) =>
  api.post(
    `${APP.PARTNER_SEND}?name=${name}&email=${email}&phone=${phone}&company=${company}&country=${country}&type_client=${type_client}&comment=${comment}`,
  );

export {getAppData, getCountriesList, sendContact, sendPartner};
