import {Moment} from 'moment';
import {RangeValue} from 'rc-picker/lib/interface';
import {IFilterDataMap} from '../standardized.types';

// FILTER_STANDARD_DATA TYPES & ACTIONS
export const FILTER_STANDARD_DATA_REQUEST: string =
  'FILTER_STANDARD_DATA_REQUEST';
export const FILTER_STANDARD_DATA_SUCCESS: string =
  'FILTER_STANDARD_DATA_SUCCESS';
export const FILTER_STANDARD_DATA_FAILURE: string =
  'FILTER_STANDARD_DATA_FAILURE';

export const filterStandardDataRequestAction = (): {type: string} => ({
  type: FILTER_STANDARD_DATA_REQUEST,
});

export const filterStandardDataSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: FILTER_STANDARD_DATA_SUCCESS,
  payload,
});

export const filterStandardDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: FILTER_STANDARD_DATA_FAILURE,
  payload,
});

// SET_CURRENT_TABLE_PAGE TYPES & ACTIONS
export const SET_CURRENT_TABLE_PAGE: string = 'SET_CURRENT_TABLE_PAGE';

export const setCurrentTablePageAction = (
  payload: number,
): {type: string; payload: number} => ({
  type: SET_CURRENT_TABLE_PAGE,
  payload,
});

// SET_PAGE_SIZE_TABLE TYPES & ACTIONS
export const SET_PAGE_SIZE_TABLE: string = 'SET_PAGE_SIZE_TABLE';

export const setPageSizeTableAction = (
  payload: number,
): {type: string; payload: number} => ({
  type: SET_PAGE_SIZE_TABLE,
  payload,
});

// CLEAR_STANDARD_DATA_ITEM TYPES & ACTIONS
export const CLEAR_STANDARD_DATA_ITEM: string = 'CLEAR_STANDARD_DATA_ITEM';

export const clearStandardDataItem = (): {type: string} => ({
  type: CLEAR_STANDARD_DATA_ITEM,
});

// CLEAR_STANDARD_DATA_ITEM_COPY TYPES & ACTIONS
export const CLEAR_STANDARD_DATA_ITEM_COPY: string =
  'CLEAR_STANDARD_DATA_ITEM_COPY';

export const clearStandardDataItemCopy = (): {type: string} => ({
  type: CLEAR_STANDARD_DATA_ITEM_COPY,
});

// CLEAR_FIELDS_CREATE_DATA_ITEM_FORM TYPES & ACTIONS
export const CLEAR_FIELDS_CREATE_DATA_ITEM_FORM: string =
  'CLEAR_FIELDS_CREATE_DATA_ITEM_FORM';

export const clearFieldsCreateDataItemForm = (): {type: string} => ({
  type: CLEAR_FIELDS_CREATE_DATA_ITEM_FORM,
});

// SET_DATE_RANGE TYPES & ACTIONS
export const SET_DATE_RANGE: string = 'SET_DATE_RANGE';

export const setDateRange = (
  payload: RangeValue<Moment>,
): {type: string; payload: RangeValue<Moment>} => ({
  type: SET_DATE_RANGE,
  payload,
});

// CLEAR_DATE_RANGE TYPES & ACTIONS
export const CLEAR_DATE_RANGE: string = 'CLEAR_DATE_RANGE';

export const clearDateRange = (): {type: string} => ({
  type: CLEAR_DATE_RANGE,
});

// SET_FILTER_DATA_MAP TYPES & ACTIONS
export const SET_FILTER_DATA_MAP: string = 'SET_FILTER_DATA_MAP';

export const setFilterDataMap = (
  payload: IFilterDataMap,
): {type: string; payload: IFilterDataMap} => ({
  type: SET_FILTER_DATA_MAP,
  payload,
});

// CLEAR_FILTER_DATA_MAP TYPES & ACTIONS
export const CLEAR_FILTER_DATA_MAP: string = 'CLEAR_FILTER_DATA_MAP';

export const clearFilterDataMap = (): {type: string} => ({
  type: CLEAR_FILTER_DATA_MAP,
});
