import {FC, useEffect} from 'react';
import {Col, Form, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {
  FormItem,
  InputWrapper,
  InputPasswordWrapper,
  ButtonWrapper,
  FadeFormItem,
} from '../Auth.styles';
import {useLocalValidation} from '../../../../hooks';
import {IRegisterUserData} from '../Auth.types';
import {registerUser} from '../../../../../state';
import {RootState} from '../../../../../state/store';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';

const RegistrationForm: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const {formatMessage} = useIntl();
  const {emailRules, passwordRules, codeRules, confirmPasswordRules} =
    useLocalValidation(formatMessage);
  const dispatch = useDispatch();
  const {loading, isRegister, isCodeActivated} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );
  const history = useHistory();

  useEffect(() => {
    if (isCodeActivated) {
      history.push('/login');
    }
  }, [history, isCodeActivated]);

  const registerUserHandler = (values: IRegisterUserData): void => {
    dispatch(
      registerUser(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.confirmPassword,
      ),
    );
  };

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={registerUserHandler}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        code: '',
      }}
    >
      <Row justify='space-between'>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <FormItem
            label={formatMessage({id: 'FIRST_NAME_INPUT_FIELD'})}
            name='firstName'
          >
            <InputWrapper
              disabled={isRegister}
              $borderRadius='8px'
              placeholder={formatMessage({id: 'FIRST_NAME_INPUT_FIELD'})}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <FormItem
            name='lastName'
            label={formatMessage({id: 'LAST_NAME_INPUT_FIELD'})}
          >
            <InputWrapper
              $borderRadius='8px'
              disabled={isRegister}
              placeholder={formatMessage({id: 'LAST_NAME_INPUT_FIELD'})}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem
        label={formatMessage({id: 'EMAIL_INPUT_FIELD'})}
        name='email'
        rules={emailRules}
      >
        <InputWrapper
          type='email'
          autoComplete='off'
          $borderRadius='8px'
          disabled={isRegister}
          placeholder={formatMessage({id: 'EMAIL_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'PASSWORD_INPUT_FIELD'})}
        name='password'
        rules={passwordRules}
      >
        <InputPasswordWrapper
          $borderRadius='8px'
          disabled={isRegister}
          placeholder={formatMessage({
            id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER',
          })}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'REPEAT_PASSWORD_INPUT_FIELD'})}
        name='confirmPassword'
        dependencies={['password']}
        hasFeedback
        rules={confirmPasswordRules}
      >
        <InputPasswordWrapper
          $borderRadius='8px'
          disabled={isRegister}
          placeholder={formatMessage({
            id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER',
          })}
        />
      </FormItem>
      {isRegister && (
        <FadeFormItem
          $isRegister={isRegister}
          label={formatMessage({id: 'ACTIVATION_CODE_INPUT_FIELD'})}
          name='code'
          rules={codeRules}
        >
          <InputWrapper
            autoComplete='off'
            $borderRadius='8px'
            placeholder={formatMessage({
              id: 'ACTIVATION_CODE_INPUT_FIELD_PLACEHOLDER',
            })}
          />
        </FadeFormItem>
      )}
      <FormItem>
        <ButtonWrapper
          type='primary'
          htmlType='submit'
          $borderRadius='8px'
          loading={loading}
        >
          {formatMessage({
            id: !isRegister
              ? 'CREATE_ACCOUNT_BUTTON'
              : 'ACTIVATION_CODE_BUTTON',
          })}
        </ButtonWrapper>
      </FormItem>
    </Form>
  );
};

export default RegistrationForm;
