import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const DocumentsIcon: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 4C0 2.89543 0.895431 2 2 2H6L8 4H12C13.1046 4 14 4.89543 14 6V7H6C4.34315 7 3 8.34315 3 10V11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5V4Z'
      fill={fillColor}
    />
    <path
      d='M4 10C4 8.89543 4.89543 8 6 8H14C15.1046 8 16 8.89543 16 10V12C16 13.1046 15.1046 14 14 14H0H2C3.10457 14 4 13.1046 4 12V10Z'
      fill={fillColor}
    />
  </svg>
);
