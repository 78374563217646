import {AxiosResponse} from 'axios';
import {Dispatch, ActionCreator} from 'redux';
import {handleResponseFirstStep} from './handleResponseFirstStep';

interface IHandleResponseExtraAction {
  response: AxiosResponse;
  dispatch: Dispatch;
  successAction: ActionCreator<any>;
  failureAction: ActionCreator<any>;
  notificationAction?: ActionCreator<any>;
  extraRequest: ActionCreator<any>;
  extraRequestArg?: any;
  isModalViewNotification?: boolean;
  textMessage?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const handleResponseExtraAction = ({
  response,
  dispatch,
  successAction,
  failureAction,
  notificationAction,
  extraRequest,
  extraRequestArg,
  isModalViewNotification,
  textMessage,
}: IHandleResponseExtraAction) => {
  let successMessage = '';
  handleResponseFirstStep(response, dispatch, successAction, failureAction);

  const typeNotification = 'error' in response.data ? 'error' : 'success';

  if (textMessage) {
    successMessage = textMessage;
  } else if (response.data.message) {
    successMessage = response.data.message;
  } else {
    successMessage = typeNotification === 'error' ? 'Error' : 'Success';
  }

  if (notificationAction) {
    dispatch(
      notificationAction({
        isShow: !isModalViewNotification,
        type: typeNotification,
        message: successMessage,
        isModalView: !!isModalViewNotification,
      }),
    );
  }

  if (typeNotification === 'error') return;

  if (extraRequestArg) {
    dispatch(extraRequest(extraRequestArg));
    return;
  }

  dispatch(extraRequest());
};
