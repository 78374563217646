import {FC} from 'react';
import {Close, Speakerphone} from '../../static/images';
import {
  NotificationBanner,
  Inner,
  LeftPart,
  RightPart,
  IconBadge,
  Text,
  ContainedButton,
  IconButton,
} from './TopNotification.styles';
import {ITopNotificationProps} from './TopNotification.types';

const TopNotification: FC<ITopNotificationProps> = ({
  isShow,
  goToVerification,
  closeNotification,
}: ITopNotificationProps) => (
  <NotificationBanner isShow={isShow}>
    <Inner>
      <LeftPart>
        <IconBadge>
          <Speakerphone fillColor='#fff' />
        </IconBadge>
        <Text>You need to verify your account to make this action</Text>
      </LeftPart>
      <RightPart>
        <ContainedButton onClick={goToVerification}>Verify</ContainedButton>
        <IconButton onClick={closeNotification}>
          <Close fillColor='#fff' display='block' />
        </IconButton>
      </RightPart>
    </Inner>
  </NotificationBanner>
);

export default TopNotification;
