import {IVouchersResponse} from './vouchers.types';

// GET_VOUCHER_DATA TYPES & ACTIONS
export const GET_VOUCHER_DATA_REQUEST: string = 'GET_VOUCHER_DATA_REQUEST';
export const GET_VOUCHER_DATA_SUCCESS: string = 'GET_VOUCHER_DATA_SUCCESS';
export const GET_VOUCHER_DATA_FAILURE: string = 'GET_VOUCHER_DATA_FAILURE';

export const getVoucherDataRequestAction = (): {type: string} => ({
  type: GET_VOUCHER_DATA_REQUEST,
});

export const getVoucherDataSuccessAction = (payload: IVouchersResponse) => ({
  type: GET_VOUCHER_DATA_SUCCESS,
  payload,
});

export const getVoucherDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_VOUCHER_DATA_FAILURE,
  payload,
});
