import {AnyAction} from 'redux';
import {IUserRegisterState} from './auth.types';
import {
  getLocalAccessToken,
  checkOpenTabSecurePage,
} from '../../infra/token.service';
import {
  USER_REGISTRATION_PAYMENT_REQUEST,
  USER_REGISTRATION_PAYMENT_SUCCESS,
  USER_REGISTRATION_PAYMENT_FAILURE,
  REDIRECT_PAYMENT_REGISTER_REQUEST,
  REDIRECT_PAYMENT_REGISTER_SUCCESS,
  REDIRECT_PAYMENT_REGISTER_FAILURE,
  USER_LOGIN_PAYMENT_REQUEST,
  USER_LOGIN_PAYMENT_SUCCESS,
  USER_LOGIN_PAYMENT_FAILURE,
} from './actions/auth.payment';
import {
  SOCIAL_AUTH_REQUEST,
  SOCIAL_AUTH_SUCCESS,
  SOCIAL_AUTH_FAILURE,
  SOCIAL_AUTH_CALLBACK_REQUEST,
  SOCIAL_AUTH_CALLBACK_SUCCESS,
  SOCIAL_AUTH_CALLBACK_FAILURE,
} from './actions/auth.socials';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
} from './actions/login';
import {
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_REQUEST,
} from './actions/logout';
import {
  CHANGE_APP_LANGUAGE,
  SET_AUTHENTICATION_STATE,
  SET_IS_AUTHENTICATED,
} from './actions/misc';
import {
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILURE,
} from './actions/register';
import {
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  CLEAR_VERIFY_EMAIL_RES,
} from './actions/verify.email';

const initialState: IUserRegisterState = {
  loading: false,
  isRegister: false,
  isCodeActivated: false,
  verifyEmailRes: null,
  isAuthenticated: !!getLocalAccessToken(),
  authenticationState: checkOpenTabSecurePage(),
  redirectPaymentUrl: '',
  redirectSocialAuth: '',
  paymentFormRes: null,
  userData: null,
  locale: 'EN',
  error: null,
};

const authReducer = (
  state: IUserRegisterState = initialState,
  action: AnyAction,
): IUserRegisterState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case REDIRECT_PAYMENT_REGISTER_REQUEST:
    case USER_REGISTRATION_PAYMENT_REQUEST:
    case USER_REGISTRATION_REQUEST:
    case VERIFY_EMAIL_REQUEST:
    case USER_LOGIN_REQUEST:
    case USER_LOGIN_PAYMENT_REQUEST:
    case USER_LOGOUT_REQUEST:
    case SOCIAL_AUTH_REQUEST:
    case SOCIAL_AUTH_CALLBACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isRegister: true,
        isAuthenticated: true,
        authenticationState: 'unauthenticated',
        error: null,
      };

    case USER_REGISTRATION_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isRegister: true,
        isAuthenticated: true,
        authenticationState: 'unauthenticated',
        redirectPaymentUrl: action.payload.redirect,
        error: null,
      };

    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userData: null,
        authenticationState: 'unauthenticated',
      };

    case USER_LOGIN_SUCCESS:
    case SOCIAL_AUTH_CALLBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        isAuthenticated: true,
        authenticationState: 'authenticated',
        error: null,
      };

    case USER_LOGIN_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        isAuthenticated: true,
        authenticationState: 'unauthenticated',
        error: null,
      };

    case REDIRECT_PAYMENT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentFormRes: action.payload,
        error: null,
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyEmailRes: action.payload,
        error: null,
      };

    case SOCIAL_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        redirectSocialAuth: action.payload.redirect,
        error: null,
      };

    // MISC ACTIONS
    case CHANGE_APP_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };

    case SET_AUTHENTICATION_STATE:
      return {
        ...state,
        authenticationState: action.payload,
      };

    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case CLEAR_VERIFY_EMAIL_RES:
      return {
        ...state,
        verifyEmailRes: null,
      };

    // FAILURE REQUEST ACTIONS
    case REDIRECT_PAYMENT_REGISTER_FAILURE:
    case USER_REGISTRATION_PAYMENT_FAILURE:
    case USER_REGISTRATION_FAILURE:
    case VERIFY_EMAIL_FAILURE:
    case USER_LOGIN_FAILURE:
    case USER_LOGIN_PAYMENT_FAILURE:
    case USER_LOGOUT_FAILURE:
    case SOCIAL_AUTH_FAILURE:
    case SOCIAL_AUTH_CALLBACK_FAILURE:
      return {
        ...state,
        loading: false,
        isRegister: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
