import styled from 'styled-components';
import {Menu} from 'antd';

export const MenuItem = styled(Menu.Item)`
  padding: 12px;
`;

export const MenuItemText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  margin-left: 12px;
  color: #192b3b;
  text-transform: capitalize;
`;

export const DropDownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
