import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../../store';
import {getClientList} from '../../../infra/chat.service';
import {
  getClientListRequestAction,
  getClientListSuccessAction,
  getClientListFailureAction,
} from './actions';

import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';
import {setNotificationState} from '../../Notification/notification.actions';

// eslint-disable-next-line import/prefer-default-export
export const getClientListRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getClientListRequestAction());
  return getClientList()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getClientListSuccessAction,
        failureAction: getClientListFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getClientListFailureAction,
        notificationAction: setNotificationState,
      });
    });
};
