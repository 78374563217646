import {useState, useEffect, useRef, Ref, RefObject} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {Form, FormInstance, Spin} from 'antd';
import PersonalForm from './PersonalForm';
import VerifyClient from './VerifyClient';
import VerificationGroup from './VerificationGroup';
import ChatVerification from '../../../components/ChatVerification';
import {RootState} from '../../../../state/store';
import {
  setPageTitleAction,
  clearVerificationResponse,
  getVerificationDocs,
  getBadgeCountRequest,
  storePersonalInfoRequest,
  getCountriesListRequest,
  setNotificationState,
  getPhoneCodesRequest,
  getGeoLocationRequest,
  clearVerificationDocumentsData,
  clearClientList,
  getOneChatForAdmin,
  resetChatReducer,
  getOneChatForClient,
  verifyClientRequest,
  getClientListRequest,
} from '../../../../state';
import {
  IVerifyDocsLoad,
  IFormValues,
} from '../../../../state/Verification/verification.types';
import {NOTICE_UPLOAD_FILES_OPTIONS} from '../../../static/data/notifications';
import {
  PageWrapper,
  Heading,
  MainTitle,
  MainContainer,
  PageInner,
  IconWrapper,
} from './VerificationItem/VerificationItem.styles';
import {ButtonWrapper, ButtonInner} from './PersonalForm/PersonalForm.styles';
import {
  checkIsFilesUploaded,
  formValuesPersonalForm,
  formValuesVerificationForm,
  formVerifyDocsLoad,
} from '../../../../utils';

const Verification = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef() as Ref<FormInstance<IFormValues>>;
  const location = useLocation<{from: string; userId: string}>();
  const {id: userId} = useParams<{id: string}>();

  const {oneChat, clientsMap} = useSelector(
    (state: RootState) => state.chatReducer,
  );
  const {notificationMap} = useSelector(
    (state: RootState) => state.notificationReducer,
  );
  const {timestampReload, loadingCountriesList, error, countriesList} =
    useSelector((state: RootState) => state.appReducer);
  const {phoneCodes, geoLocation, errorGeoLocation, errorPhoneCodes} =
    useSelector((state: RootState) => state.phoneCodesReducer);
  const {
    loading,
    loadingStorePersonalInfo,
    loadingVerifyClient,
    sendFormUrl,
    responseVerification,
    verificationDocumentsData,
  } = useSelector((state: RootState) => state.verificationReducer);

  const [verifyDocsLoad, setVerifyDocsLoad] = useState<IVerifyDocsLoad>({});
  const [formValues, setFormValues] = useState<IFormValues>({});

  const handleForm = async () => {
    try {
      const values = await form.validateFields();
      sendPersonalInfo(values);
    } catch (errorForm) {
      // eslint-disable-next-line
      console.log(errorForm);
    }
  };

  const sendPersonalInfo = (values: IFormValues): void => {
    if (checkIsFilesUploaded(verifyDocsLoad)) {
      dispatch(setNotificationState(NOTICE_UPLOAD_FILES_OPTIONS));
      return;
    }

    dispatch(
      storePersonalInfoRequest({
        slugUrl: sendFormUrl,
        values: formValuesPersonalForm(values),
      }),
    );
  };

  const approveProfile = (): void => {
    const {firstName, lastName, email} = formValues;
    const name = `${firstName} ${lastName}`;
    dispatch(verifyClientRequest({userId, name, email}));
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(clearVerificationDocumentsData());
      dispatch(clearClientList());
      dispatch(resetChatReducer());
    };
  }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from === 'notifications'
    ) {
      dispatch(getOneChatForAdmin(location.state.userId));
    } else {
      dispatch(getOneChatForClient());
    }
  }, []);

  useEffect(() => {
    dispatch(setPageTitleAction('Verification'));
    dispatch(getClientListRequest());
    dispatch(getVerificationDocs(location.pathname));
  }, []);

  useEffect(() => {
    dispatch(getVerificationDocs(location.pathname));

    dispatch(getBadgeCountRequest());
  }, [timestampReload, userId]);

  useEffect(() => {
    if (!notificationMap.isShow && responseVerification) {
      dispatch(clearVerificationResponse());
    }
  }, [notificationMap]);

  useEffect(() => {
    if (countriesList) return;

    dispatch(getCountriesListRequest());
  }, []);

  useEffect(() => {
    dispatch(getPhoneCodesRequest());
    dispatch(getGeoLocationRequest());
  }, []);

  useEffect(() => {
    const formInstance = (formRef as RefObject<FormInstance<IFormValues>>)
      .current;

    if (!formInstance) return;
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  useEffect(() => {
    if (!verificationDocumentsData) return;

    const uploadedDocsMap = formVerifyDocsLoad(
      Object.values(verificationDocumentsData.data),
    );

    setVerifyDocsLoad(uploadedDocsMap);
  }, [verificationDocumentsData]);

  useEffect(() => {
    if (!(verificationDocumentsData && !loading)) return;
    const {fields} = verificationDocumentsData;

    formValuesVerificationForm({
      phoneCodes,
      geoLocation,
      initialFormFields: fields,
      errorsMap: {errorGeoLocation, errorPhoneCodes},
      setStateFunction: setFormValues,
    });
  }, [
    verificationDocumentsData?.fields,
    phoneCodes,
    geoLocation,
    loading,
    errorGeoLocation,
    errorPhoneCodes,
  ]);

  if (error) return <div>{error}</div>;

  if (
    !(
      !loading &&
      !loadingCountriesList &&
      countriesList &&
      Object.keys(formValues).length > 0 &&
      Object.keys(verifyDocsLoad).length > 0
    )
  ) {
    return (
      <Spin
        tip='Loading...'
        spinning={
          !(
            !loading &&
            !loadingCountriesList &&
            countriesList &&
            Object.keys(formValues).length > 0 &&
            Object.keys(verifyDocsLoad).length > 0
          )
        }
        size='large'
      />
    );
  }

  return (
    <PageWrapper>
      <PageInner>
        <Heading>
          <MainTitle>
            Please fill all required fields, upload your documents and push
            Submit button
          </MainTitle>
        </Heading>
        <MainContainer>
          <PersonalForm
            form={form}
            formRef={formRef}
            formValues={formValues}
            countriesList={countriesList}
            sendPersonalInfo={sendPersonalInfo}
          />
          <VerificationGroup verifyDocsLoad={verifyDocsLoad} />
          <ButtonWrapper onClick={handleForm}>
            <ButtonInner>
              {loadingStorePersonalInfo ? (
                <IconWrapper>
                  <Spin size='small' spinning={loadingStorePersonalInfo} />
                </IconWrapper>
              ) : null}
              <div>Submit for approval</div>
            </ButtonInner>
          </ButtonWrapper>
          {clientsMap && !Array.isArray(clientsMap) ? (
            <VerifyClient
              approveProfile={approveProfile}
              loadingVerifyClient={loadingVerifyClient}
            />
          ) : null}
        </MainContainer>
      </PageInner>
      {oneChat ? <ChatVerification /> : null}
    </PageWrapper>
  );
};

export default Verification;
