import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(p) => p.theme.main.colors.white};
  max-width: 334px;
  margin-left: 60px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  position: relative;

  @media (max-width: 1500px) {
    margin-left: 0;
    width: 100%;
    max-width: unset;
  }
`;

export const Input = styled.input`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 8px;
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.subHeading};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 16px;
  line-height: 26px;
`;

export const ChatStatus = styled.div`
  display: flex;
  height: 24px;
  margin-bottom: 30px;
`;

export const ChatStatusText = styled.p`
  margin-right: 6px;
`;

export const AvatarImg = styled.img`
  display: block;
`;

export const SenderMessage = styled.div`
  display: flex;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const AvatarReply = styled.div`
  min-width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 400px) {
    display: none;
  }
`;

export const TextController = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const TextareaMessage = styled.input`
  ${(p) => p.theme.main.typography.body};
  width: 250px;
  height: 88px;
  margin-bottom: 8px;
  padding: 14px 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 8px;
  resize: none;

  ::placeholder {
    ${(p) => p.theme.main.typography.body};
    color: ${(p) => p.theme.main.colors.grey};
    line-height: 20px;
  }

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const ButtonControl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1500px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 540px) {
    flex-wrap: wrap;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 1500px) {
    width: auto;
    flex-wrap: nowrap;
  }

  @media (max-width: 540px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ButtonWrapper = styled.button`
  width: 100%;
  height: 46px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.lightPurple};
  padding: 10px 24px;
  line-height: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  @media (max-width: 1500px) {
    width: 120px;
    &:last-child {
      margin-left: 20px;
    }
  }

  @media (min-width: 801px) and (max-width: 900px) {
    width: 100px;
  }

  @media (max-width: 540px) {
    width: 100%;

    margin-bottom: 20px;

    &:last-child {
      margin-left: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;
