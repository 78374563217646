import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const Label = styled.label`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.black};
`;

export const Input = styled.input`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  width: 100%;
  margin-top: 8px;
  padding: 14px 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 8px;
`;
