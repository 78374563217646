import {FC, Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {ActionItem} from '../../../state/Standardized/standardized.types';
import {RootState} from '../../../state/store';
import {defineCurrencySymbolIcon} from '../../../utils';
import CollapseContent from '../CollapseContent';
import PanelHeaderIncomplete from '../PanelHeaderIncomplete';
import PanelHeader from '../PanelHeader';
import PaginationComponent from '../PaginationComponent';
import {StyledCollapse, StyledPanel} from './ListView.styles';
import {
  IHandleCollapseArgs,
  IListViewProps,
  StandardizedDataItem,
} from './ListView.types';

const ListView: FC<IListViewProps> = ({
  data,
  loadings,
  itemData,
  actions,
  currentSlug,
  paginationTotal,
  createInitialFormValues,
  getDataItemAction,
}: IListViewProps) => {
  const dispatch = useDispatch();
  const {pageTitle} = useSelector((state: RootState) => state.appReducer);

  const [collapsedKeys, setCollapsedKeys] = useState<string | string[]>([]);

  const handleChangeCollapse = (handleCollapseArgs: IHandleCollapseArgs) => {
    const {key, viewAction} = handleCollapseArgs;
    if (viewAction) {
      dispatch(getDataItemAction(viewAction.slug));
    }
    setCollapsedKeys(key);
  };

  return (
    <>
      {data.map((item: StandardizedDataItem) => {
        const viewAction = item.actions
          ? item.actions.find(
              (actionItem: ActionItem) => actionItem.action === 'view',
            )
          : false;

        const defineImagePath = (imagePath: string) =>
          // eslint-disable-next-line no-unneeded-ternary
          imagePath ? imagePath : undefined;

        const defineImageElement = (imagePath: string | undefined) => {
          if (!imagePath) return;

          // eslint-disable-next-line consistent-return
          return pageTitle.toLowerCase() === 'accounts'
            ? defineCurrencySymbolIcon(imagePath)
            : defineImagePath(imagePath);
        };

        const isPayOrDeclineActions = item?.actions
          ? item.actions.some(
              (itm) => itm.action === 'pay' || itm.action === 'decline',
            )
          : false;

        return (
          <Fragment key={uuidv4()}>
            <StyledCollapse
              accordion
              key={item.id ? item.id : uuidv4()}
              onChange={(key) => handleChangeCollapse({key, viewAction})}
              activeKey={collapsedKeys}
            >
              <StyledPanel
                key={item.id ? item.id : uuidv4()}
                showArrow={false}
                header={
                  isPayOrDeclineActions ? (
                    <PanelHeaderIncomplete
                      actionsList={
                        isPayOrDeclineActions ? item.actions : undefined
                      }
                      itemId={item.id ? item.id.toString() : uuidv4()}
                      collapsedKeysList={collapsedKeys}
                      image={defineImageElement(item.image)}
                      title={item.title ? item.title : `Item ${pageTitle}`}
                      description={
                        item.description
                          ? item.description
                          : `Description ${pageTitle}`
                      }
                      date={item.date ? item.date : undefined}
                    />
                  ) : (
                    <PanelHeader
                      itemId={item.id ? item.id.toString() : uuidv4()}
                      collapsedKeysList={collapsedKeys}
                      image={defineImageElement(item.image)}
                      title={item.title ? item.title : `Item ${pageTitle}`}
                      description={
                        item.description
                          ? item.description
                          : `Description ${pageTitle}`
                      }
                      date={item.date ? item.date : undefined}
                    />
                  )
                }
              >
                <CollapseContent
                  loadings={loadings}
                  itemData={
                    !viewAction ? {...itemData, itemDataView: item} : itemData
                  }
                  actions={actions}
                  currentSlug={currentSlug}
                  createInitialFormValues={createInitialFormValues}
                />
              </StyledPanel>
            </StyledCollapse>
          </Fragment>
        );
      })}
      {paginationTotal ? (
        <PaginationComponent paginationTotal={paginationTotal} />
      ) : null}
    </>
  );
};

export default ListView;
