import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import BadgeCount from '../BadgeCount';
import {RootState} from '../../../state/store';
import {
  setVerifyNotificationState,
  clearFormViewRes,
  getFormViewRequest,
} from '../../../state';
import {Down, Up, Logo, Deposit} from '../../static/images';
import {
  LogoWrapper,
  CopyrightText,
  DepositButton,
  DepositButtonText,
  MenuItem,
  MenuItemInner,
  IconMenuItem,
  MenuItemText,
  NavigationContainer,
  SideBarContainer,
  SideBarInner,
  SubMenuItem,
  SubNavigationContainer,
  LogoNavPart,
  ContactLink,
  ContactLinkText,
  MenuItemExpanded,
  MenuItemExpandedInner,
  NotificationDot,
  TextIcon,
} from './Sidebar.styles';
import contactIco from '../../styles/img/menu_icon/contact.svg';
import {nonStandardPageList} from '../../static/data/nonStandardPageList';
import {defineMenuItemIcon} from '../../../utils';
import {ISidebarProps} from './Sidebar.types';

const Sidebar: FC<ISidebarProps> = ({
  menu,
  activeNavLink,
  activeTitle,
  setActiveNavLink,
  setActiveTitle,
}: ISidebarProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {badgeCountMap} = useSelector(
    (state: RootState) => state.badgeCountReducer,
  );

  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  const goToDepositPage = () => {
    if (userNotVerified) {
      dispatch(setVerifyNotificationState(true));
      return;
    }
    history.push('/top-up');
  };

  const showHideMenu = (title: string): void => {
    setActiveTitle((prevState) => {
      if (prevState.length === 0) {
        return title;
      }

      if (prevState === title) {
        return '';
      }

      return title;
    });
  };

  const setActiveNavLinkHandler = (hasParent: boolean, title: string): void => {
    if (title !== '/form-constructor') {
      dispatch(clearFormViewRes());
    }

    if (!hasParent) {
      showHideMenu(title);
    } else {
      showHideMenu(activeTitle);
    }

    setActiveNavLink(title);

    const pathNonStandard =
      nonStandardPageList.indexOf(title) >= 0 ? true : false; // eslint-disable-line

    if (pathNonStandard || title.includes('/form-constructor'))
      return history.push(title);

    if (title.includes('/api/display')) {
      dispatch(getFormViewRequest({slugUrl: title}));
      return; // eslint-disable-line consistent-return
    }

    if (title === '/top_up') return history.push('/top-up');

    return history.push(`/s${title}`);
  };

  return (
    <SideBarContainer width='260px'>
      <SideBarInner>
        <LogoNavPart>
          <div>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <DepositButton onClick={goToDepositPage}>
              <Deposit fillColor='#fff' />
              <DepositButtonText>Deposit</DepositButtonText>
            </DepositButton>
          </div>
          <NavigationContainer>
            {menu.map((item): JSX.Element => {
              if (item.children) {
                const isNestedLinkHasNotification = item.children.some(
                  (child) => {
                    const badgeCountCond =
                      badgeCountMap && badgeCountMap[child.title.toLowerCase()];

                    return (
                      child.badgeCount && child.badgeCount > 0 && badgeCountCond
                    );
                  },
                );

                return (
                  <MenuItemExpanded
                    key={uuidv4()}
                    isActive={
                      activeTitle.toLowerCase() === item.title.toLowerCase()
                    }
                    onClick={() => showHideMenu(item.title)}
                  >
                    <MenuItemExpandedInner>
                      <TextIcon>
                        {defineMenuItemIcon(
                          item.icon?.toLowerCase() || 'default',
                        )}
                        <MenuItemText>{item.title}</MenuItemText>
                      </TextIcon>
                      {isNestedLinkHasNotification ? <NotificationDot /> : null}
                      <button
                        type='button'
                        onClick={(e) => {
                          e.stopPropagation();
                          showHideMenu(item.title);
                        }}
                      >
                        {activeTitle === item.title ? <Up /> : <Down />}
                      </button>
                    </MenuItemExpandedInner>
                    <SubNavigationContainer open={activeTitle === item.title}>
                      {item.children.map((child) => (
                        <SubMenuItem
                          key={uuidv4()}
                          isActive={
                            activeNavLink.toLowerCase() ===
                            child.slug.toLowerCase()
                          }
                          onClick={() =>
                            setActiveNavLinkHandler(true, child.slug)
                          }
                        >
                          <MenuItemExpandedInner isBadgeExist>
                            <IconMenuItem>
                              {defineMenuItemIcon(
                                item.icon || 'default',
                                activeNavLink.toLowerCase() ===
                                  child.slug.toLowerCase()
                                  ? '#171533'
                                  : '#74828A',
                              )}
                              <MenuItemText>{child.title}</MenuItemText>
                            </IconMenuItem>
                            {child?.badgeCount ? (
                              <BadgeCount
                                badgeKey={child.title.toLowerCase()}
                                badgeCount={child?.badgeCount}
                              />
                            ) : null}
                          </MenuItemExpandedInner>
                        </SubMenuItem>
                      ))}
                    </SubNavigationContainer>
                  </MenuItemExpanded>
                );
              }

              if (item.slug === '/contact-us') {
                return (
                  <ContactLink
                    key={uuidv4()}
                    href='mailto:support@blixti.com'
                    rel='noreferrer'
                    target='_blank'
                    className='link'
                  >
                    <img src={contactIco} alt='contact' />
                    <ContactLinkText>Contact Us</ContactLinkText>
                  </ContactLink>
                );
              }

              return (
                <MenuItem
                  key={uuidv4()}
                  isActive={
                    activeNavLink.toLowerCase() === item.slug.toLowerCase()
                  }
                  onClick={() => setActiveNavLinkHandler(false, item.slug)}
                >
                  <MenuItemInner>
                    <IconMenuItem>
                      {defineMenuItemIcon(
                        item.icon || 'default',
                        activeNavLink.toLowerCase() === item.slug.toLowerCase()
                          ? '#171533'
                          : '#74828A',
                      )}
                      <MenuItemText>{item.title}</MenuItemText>
                    </IconMenuItem>
                    {item?.badgeCount ? (
                      <BadgeCount
                        badgeKey={item.title.toLowerCase()}
                        badgeCount={item?.badgeCount}
                      />
                    ) : null}
                  </MenuItemInner>
                </MenuItem>
              );
            })}
          </NavigationContainer>
        </LogoNavPart>
        <CopyrightText>Copyright © 2021 Blixti</CopyrightText>
      </SideBarInner>
    </SideBarContainer>
  );
};

export default Sidebar;
