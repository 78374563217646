import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ChevronDoubleLeft: FC<IProps> = ({
  fillColor = '#192B3B',
}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.29289 4.29289C6.68342 3.90237 7.31658 3.90237 7.70711 4.29289C8.09763 4.68342 8.09763 5.31658 7.70711 5.70711L5.41421 8L7.70711 10.2929C8.09763 10.6834 8.09763 11.3166 7.70711 11.7071C7.31658 12.0976 6.68342 12.0976 6.29289 11.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289L6.29289 4.29289ZM11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L10.4142 8L12.7071 10.2929C13.0976 10.6834 13.0976 11.3166 12.7071 11.7071C12.3166 12.0976 11.6834 12.0976 11.2929 11.7071L8.29289 8.70711C7.90237 8.31658 7.90237 7.68342 8.29289 7.29289L11.2929 4.29289Z'
      fill={fillColor}
    />
  </svg>
);
