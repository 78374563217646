import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {NavContainer, NavItem, NavItemText} from './BottomNavigation.styles';
import {IBottomNavigationProps} from './BottomNavigation.types';
import SubNavigation from '../SubNavigation';
import {nonStandardPageList} from '../../static/data/nonStandardPageList';
import {defineMenuItemIcon} from '../../../utils';

const BottomNavigation: FC<IBottomNavigationProps> = ({
  menu,
}: IBottomNavigationProps) => {
  const history = useHistory();

  const goToPage = (slugUrl: string) => {
    const pathNonStandard =
      nonStandardPageList.indexOf(slugUrl) >= 0 ? true : false; // eslint-disable-line

    if (pathNonStandard) return history.push(slugUrl);

    if (slugUrl === '/top_up') return history.push('/top-up');

    return history.push(`/s${slugUrl}`);
  };

  return (
    <NavContainer>
      {menu.map((menuItem) => {
        if (menuItem.children) {
          return (
            <SubNavigation
              key={uuidv4()}
              title={menuItem.title}
              subNav={menuItem.children}
              icon={menuItem.icon}
              goToPage={goToPage}
            />
          );
        }

        return (
          <NavItem key={uuidv4()} onClick={() => goToPage(menuItem.slug)}>
            {defineMenuItemIcon(menuItem.icon?.toLowerCase() || 'default')}
            <NavItemText>{menuItem.title}</NavItemText>
          </NavItem>
        );
      })}
    </NavContainer>
  );
};

export default BottomNavigation;
