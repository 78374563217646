import styled from 'styled-components';
import {TypeView} from '../../../../../state/FormConstructor/formConstructor.types';

export const Container = styled.div<{typeView: TypeView}>`
  display: ${({typeView}) => (typeView === TypeView.Page ? 'block' : 'flex')};
  justify-content: center;
`;

export const Inner = styled.div`
  width: 100%;
`;

export const FieldsWrapper = styled.div<{typeView: TypeView}>`
  display: flex;
  margin-bottom: 20px;

  justify-content: ${({typeView}) =>
    typeView === TypeView.Page ? 'flex-start' : 'center'};

  @media (max-width: 800px) {
    flex-direction: ${({typeView}) =>
      typeView === TypeView.Page ? 'column' : 'row'};
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const FieldsColumn = styled.div<{typeView: TypeView}>`
  padding: ${({typeView}) => (typeView === TypeView.Page ? '0' : '0 30px')};

  @media (max-width: 650px) {
    padding: 0;
  }
`;

export const ButtonsGroup = styled.div<{typeView: TypeView}>`
  display: flex;
  justify-content: ${({typeView}) =>
    typeView === TypeView.Page ? 'flex-start' : 'center'};
`;

export const ErrorMessage = styled.div`
  margin-top: 8px;
  color: ${(p) => p.theme.main.colors.red};
`;
