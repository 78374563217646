import {ICommonMap} from './transactions.types';

// GET_TRANSACTIONS_DATA TYPES & ACTIONS
export const GET_TRANSACTIONS_DATA_REQUEST: string =
  'GET_TRANSACTIONS_DATA_REQUEST';
export const GET_TRANSACTIONS_DATA_SUCCESS: string =
  'GET_TRANSACTIONS_DATA_SUCCESS';
export const GET_TRANSACTIONS_DATA_FAILURE: string =
  'GET_TRANSACTIONS_DATA_FAILURE';

export const getTransactionsDataRequestAction = (): {type: string} => ({
  type: GET_TRANSACTIONS_DATA_REQUEST,
});
export const getTransactionsDataSuccessAction = (payload: any) => ({
  type: GET_TRANSACTIONS_DATA_SUCCESS,
  payload,
});
export const getTransactionsDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_TRANSACTIONS_DATA_FAILURE,
  payload,
});

// SET_FILTER_TRANSACTIONS_MAP TYPES & ACTIONS
export const SET_FILTER_TRANSACTIONS_MAP: string =
  'SET_FILTER_TRANSACTIONS_MAP';

export const setFilterTransactionsMap = (
  payload: ICommonMap,
): {type: string; payload: ICommonMap} => ({
  type: SET_FILTER_TRANSACTIONS_MAP,
  payload,
});

// EXTRACT_FILTER_TRANSACTIONS_MAP TYPES & ACTIONS
export const EXTRACT_FILTER_TRANSACTIONS_MAP: string =
  'EXTRACT_FILTER_TRANSACTIONS_MAP';

export const extractFilterTransactionsMap = (
  payload: ICommonMap,
): {type: string; payload: ICommonMap} => ({
  type: EXTRACT_FILTER_TRANSACTIONS_MAP,
  payload,
});

// CLEAR_FILTER_TRANSACTIONS_MAP TYPES & ACTIONS
export const CLEAR_FILTER_TRANSACTIONS_MAP: string =
  'CLEAR_FILTER_TRANSACTIONS_MAP';

export const clearFilterTransactionsMap = (): {type: string} => ({
  type: CLEAR_FILTER_TRANSACTIONS_MAP,
});

// SET_VALUES_TRANSACTIONS_MAP TYPES & ACTIONS
export const SET_VALUES_TRANSACTIONS_MAP: string =
  'SET_VALUES_TRANSACTIONS_MAP';

export const setValuesTransactionsMap = (
  payload: ICommonMap,
): {type: string; payload: ICommonMap} => ({
  type: SET_VALUES_TRANSACTIONS_MAP,
  payload,
});

// EXTRACT_VALUES_TRANSACTIONS_MAP TYPES & ACTIONS
export const EXTRACT_VALUES_TRANSACTIONS_MAP: string =
  'EXTRACT_VALUES_TRANSACTIONS_MAP';

export const extractValuesTransactionsMap = (
  payload: ICommonMap,
): {type: string; payload: ICommonMap} => ({
  type: EXTRACT_VALUES_TRANSACTIONS_MAP,
  payload,
});

// CLEAR_VALUES_TRANSACTIONS_MAP TYPES & ACTIONS
export const CLEAR_VALUES_TRANSACTIONS_MAP: string =
  'CLEAR_VALUES_TRANSACTIONS_MAP';

export const clearValuesTransactionsMap = (): {type: string} => ({
  type: CLEAR_VALUES_TRANSACTIONS_MAP,
});
