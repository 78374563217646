import {
  IPaymentDetailsMap,
  ITopUpListResponse,
  ITopUpSendResponse,
} from './topUp.types';

// INIT_TOP_UP TYPES & ACTIONS
export const INIT_TOP_UP_REQUEST: string = 'INIT_TOP_UP_REQUEST';
export const INIT_TOP_UP_SUCCESS: string = 'INIT_TOP_UP_SUCCESS';
export const INIT_TOP_UP_FAILURE: string = 'INIT_TOP_UP_FAILURE';

export const initTopUpRequestAction = (): {type: string} => ({
  type: INIT_TOP_UP_REQUEST,
});

export const initTopUpSuccessAction = (
  payload: ITopUpListResponse,
): {type: string; payload: ITopUpListResponse} => ({
  type: INIT_TOP_UP_SUCCESS,
  payload,
});

export const initTopUpFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: INIT_TOP_UP_FAILURE,
  payload,
});

// INIT_TOP_UP_ID TYPES & ACTIONS
export const INIT_TOP_UP_ID_REQUEST: string = 'INIT_TOP_UP_ID_REQUEST';
export const INIT_TOP_UP_ID_SUCCESS: string = 'INIT_TOP_UP_ID_SUCCESS';
export const INIT_TOP_UP_ID_FAILURE: string = 'INIT_TOP_UP_ID_FAILURE';

export const initTopUpIdRequestAction = (): {type: string} => ({
  type: INIT_TOP_UP_ID_REQUEST,
});

export const initTopUpIdSuccessAction = (
  payload: ITopUpListResponse,
): {type: string; payload: ITopUpListResponse} => ({
  type: INIT_TOP_UP_ID_SUCCESS,
  payload,
});

export const initTopUpIdFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: INIT_TOP_UP_ID_FAILURE,
  payload,
});

// SEND_TOP_UP TYPES & ACTIONS
export const SEND_TOP_UP_REQUEST: string = 'SEND_TOP_UP_REQUEST';
export const SEND_TOP_UP_SUCCESS: string = 'SEND_TOP_UP_SUCCESS';
export const SEND_TOP_UP_FAILURE: string = 'SEND_TOP_UP_FAILURE';

export const sendTopUpRequestAction = (): {type: string} => ({
  type: SEND_TOP_UP_REQUEST,
});

export const sendTopUpSuccessAction = (
  payload: ITopUpListResponse,
): {type: string; payload: ITopUpSendResponse} => ({
  type: SEND_TOP_UP_SUCCESS,
  payload,
});

export const sendTopUpFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_TOP_UP_FAILURE,
  payload,
});

// SET_PAYMENT_DETAILS_TOP_UP TYPES & ACTIONS
export const SET_PAYMENT_DETAILS_TOP_UP: string = 'SET_PAYMENT_DETAILS_TOP_UP';

export const setPaymentDetailsTopUpAction = (
  payload: IPaymentDetailsMap,
): {type: string; payload: IPaymentDetailsMap} => ({
  type: SET_PAYMENT_DETAILS_TOP_UP,
  payload,
});

// CLEAR_TOP_UP_RESPONSE TYPES & ACTIONS
export const CLEAR_TOP_UP_RESPONSE: string = 'CLEAR_TOP_UP_RESPONSE';

export const clearTopUpResponse = (): {type: string} => ({
  type: CLEAR_TOP_UP_RESPONSE,
});
