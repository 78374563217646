import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const LoaderInner = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.h3};
  color: ${(p) => p.theme.main.colors.black};
`;

export const Paragraph = styled.p`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
`;
