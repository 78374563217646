import {FC} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const UserCircle: FC = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
      fill='#F7F7F7'
    />
    <path
      d='M12 30C12 25.7045 15.5817 22.2222 20 22.2222C24.4183 22.2222 28 25.7045 28 30M24.5714 14.4444C24.5714 16.899 22.5247 18.8889 20 18.8889C17.4753 18.8889 15.4286 16.899 15.4286 14.4444C15.4286 11.9898 17.4753 10 20 10C22.5247 10 24.5714 11.9898 24.5714 14.4444Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
