import {AxiosResponse} from 'axios';
import {
  getAppData,
  getCountriesList,
  sendContact,
  sendPartner,
} from '../../infra/app.service';
import {ThunkActionType} from '../store';
import {
  getAppDataRequestAction,
  getAppDataSuccessAction,
  getAppDataFailureAction,
  getCountriesListRequestAction,
  getCountriesListSuccessAction,
  getContriesListFailureAction,
  sendContactRequestAction,
  sendContactSuccessAction,
  sendContactFailureAction,
  sendPartnerRequestAction,
  sendPartnerSuccessAction,
  sendPartnerFailureAction,
} from './app.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseSuccessAndFailNotification,
} from '../../utils';

export const getAppDataRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getAppDataRequestAction());

  return getAppData()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getAppDataSuccessAction,
        failureAction: getAppDataFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getAppDataFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const getCountriesListRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getCountriesListRequestAction());

  return getCountriesList()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getCountriesListSuccessAction,
        failureAction: getContriesListFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getContriesListFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const sendContactRequest =
  (
    name: string,
    email: string,
    phone: string,
    comment: string,
  ): ThunkActionType =>
  (dispatch) => {
    dispatch(sendContactRequestAction());

    return sendContact(name, email, phone, comment)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: sendContactSuccessAction,
          failureAction: sendContactFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendContactFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const sendPartnerRequest =
  (
    name: string,
    email: string,
    phone: string,
    company: string,
    country: string,
    type_client: string,
    comment: string,
  ): ThunkActionType =>
  (dispatch) => {
    dispatch(sendPartnerRequestAction());

    return sendPartner(
      name,
      email,
      phone,
      company,
      country,
      type_client,
      comment,
    )
      .then((response) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: sendPartnerSuccessAction,
          failureAction: sendPartnerFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendPartnerFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
