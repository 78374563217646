import {FC} from 'react';
import {LIWrapper, PresetsWrapper, ULWrapper} from './PeriodPresets.styles';

interface IProps {
  show: boolean;
  selectedPresetPeriod: string;
  presetDate: (period: string) => void; // eslint-disable-line
}

const PeriodPresets: FC<IProps> = ({
  show,
  presetDate,
  selectedPresetPeriod,
}: IProps) => (
  <PresetsWrapper show={show}>
    <ULWrapper>
      <LIWrapper
        selected={selectedPresetPeriod === 'this_month'}
        onClick={() => presetDate('this_month')}
      >
        This Month
      </LIWrapper>
      <LIWrapper
        selected={selectedPresetPeriod === 'last_month'}
        onClick={() => presetDate('last_month')}
      >
        Last Month
      </LIWrapper>
      <LIWrapper
        selected={selectedPresetPeriod === 'last_90_days'}
        onClick={() => presetDate('last_90_days')}
      >
        Last 90 Days
      </LIWrapper>
      <LIWrapper
        selected={selectedPresetPeriod === 'this_year'}
        onClick={() => presetDate('this_year')}
      >
        This Year
      </LIWrapper>
      <LIWrapper
        selected={selectedPresetPeriod === 'last_year'}
        onClick={() => presetDate('last_year')}
      >
        Last Year
      </LIWrapper>
    </ULWrapper>
  </PresetsWrapper>
);

export default PeriodPresets;
