import {AxiosResponse} from 'axios';
import {Dispatch, ActionCreator} from 'redux';

// eslint-disable-next-line import/prefer-default-export
export const handleResponseFirstStep = (
  response: AxiosResponse,
  dispatch: Dispatch,
  successAction: ActionCreator<any>,
  failureAction: ActionCreator<any>,
) => {
  if ('error' in response.data && response.data.error) {
    if (response.data.message) {
      return dispatch(failureAction(response.data.message));
    }

    return dispatch(failureAction('Something went wrong, try later'));
  }

  return dispatch(successAction(response.data.message));
};
