import certified1 from '../../../styles/img/certified-img-1.png';
import certified2 from '../../../styles/img/certified-img-2.png';
import certified3 from '../../../styles/img/certified-img-3.png';
import '../../../styles/preview.css';

const SecurityPage = () => (
  <>
    <section className='main-section main-section-color-line security'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 wrapper-content'>
            <h1 className='main-section__main-title main-title'>
              Online payment protection and security
            </h1>
            <p className='standard-description'>
              Blixti ensures your online payments are confidential and secure.
              With a Blixti prepaid voucher, you no longer need to share
              sensitive personal or financial data to complete an online
              purchase.
            </p>
            <ul className='certified-list'>
              <li>
                <img src={certified1} alt='certified 1' />
              </li>
              <li>
                <img src={certified2} alt='certified 2' />
              </li>
              <li>
                <img src={certified3} alt='certified 2' />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className='section principles'>
      <div className='principles-container'>
        <div className='row justify-content-start'>
          <div className='col-12 wrapper-content-column'>
            <h3 className='section__title'>Blixti Safety comes first.</h3>
            <p className='main-section__description standard-description standard-description--security'>
              Blixti ensures your online payments are confidential and secure.
              With a Blixti prepaid voucher, you no longer need to share
              sensitive personal or financial data to complete an online
              purchase.
            </p>
          </div>
          <div className='col-12'>
            <div className='tiles'>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 34V6M1 6V3C1 1.89543 1.89543 1 3 1H37C38.1046 1 39 1.89543 39 3V6M1 6H39M39 6V32C39 33.1046 38.1046 34 37 34H22M15 29V26C15 24.8954 14.1046 24 13 24H11C9.89543 24 9 24.8954 9 26V29M12 33V35M20 11V15M20 15V19M20 15L23 13M20 15L17.0001 17M20 15L17 13M20 15L22.9999 17M31 11V15M31 15V19M31 15L34 13M31 15L28.0001 17M31 15L28 13M31 15L33.9999 17M9 11V15M9 15V19M9 15L12 13M9 15L6.00012 17M9 15L6 13M9 15L11.9999 17M8 39H16C17.1046 39 18 38.1046 18 37V34V31C18 29.8954 17.1046 29 16 29H8C6.89543 29 6 29.8954 6 31V34V37C6 38.1046 6.89543 39 8 39Z'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    Create a new password for your Blixti account that you have
                    never used before.
                  </p>
                </div>
              </div>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 23V28H1.58152M16.9381 30C16.446 26.0537 13.0796 23 9 23C5.64262 23 2.76829 25.0682 1.58152 28M1.58152 28H6M17 39V34H16.4185M16.4185 34C15.2317 36.9318 12.3574 39 9 39C4.92038 39 1.55399 35.9463 1.06189 32M16.4185 34H12M4 20V18C4 16.8954 4.89543 16 6 16H37C38.1046 16 39 16.8954 39 18V34C39 35.1046 38.1046 36 37 36H20M8 20H34C34.5523 20 35 20.4477 35 21V31C35 31.5523 34.5523 32 34 32H20M30 24V28M26 24V28M22 24V28M34 16V13.5C34 6.59644 28.4036 1 21.5 1C14.5964 1 9 6.59644 9 13.5V16M30 15V13.5C30 8.80558 26.1944 5 21.5 5C16.8056 5 13 8.80558 13 13.5V15'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    Use the password generator to create a unique, random, and
                    memorable password.
                  </p>
                </div>
              </div>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M23 18.0001C23 17.1717 22.6642 16.4217 22.1214 15.8788M23 18.0001C23 18.8285 22.6642 19.5785 22.1214 20.1214M23 18.0001H26M20 21.0001C19.1716 21.0001 18.4216 20.6643 17.8787 20.1215M20 21.0001C20.8284 21.0001 21.5785 20.6643 22.1214 20.1214M20 21.0001V24.0001M17 18.0001C17 17.1717 17.3358 16.4217 17.8787 15.8788M17 18.0001C17 18.8286 17.3358 19.5786 17.8787 20.1215M17 18.0001H14M20 15.0001C20.8284 15.0001 21.5785 15.3359 22.1214 15.8788M20 15.0001C19.1716 15.0001 18.4216 15.3359 17.8787 15.8788M20 15.0001V12.0001M24.2427 13.7575L22.1214 15.8788M15.7574 22.2428L17.8787 20.1215M24.2427 22.2427L22.1214 20.1214M15.7574 13.7575L17.8787 15.8788M28 1H37C38.1046 1 39 1.89543 39 3V15C39 23.8453 33.1932 30.0928 28 33.9115M28 1V7.86015M28 1H12M28 7.86015C25.6636 6.74347 22.9867 6.00012 20 6.00012C17.0133 6.00012 14.3364 6.74347 12 7.86015M28 7.86015C33.4099 10.4458 36.9939 15.0332 38.369 17.0283C38.778 17.6217 38.7781 18.3787 38.3694 18.9722C36.9948 20.9678 33.412 25.5561 28 28.1414M28 33.9115C24.896 36.1938 22.0112 37.6086 20.7165 38.1917C20.254 38.4 19.746 38.4 19.2835 38.1917C17.9888 37.6086 15.104 36.1939 12 33.9115M28 33.9115V28.1414M28 28.1414C25.664 29.2574 22.9871 30.0001 20 30.0001C17.0129 30.0001 14.336 29.2574 12 28.1414M12 1H3C1.89543 1 1 1.89543 1 3V15C1 23.8453 6.80676 30.0928 12 33.9115M12 1V7.86015M12 7.86015C6.59007 10.4458 3.00613 15.0332 1.63099 17.0283C1.22202 17.6217 1.22187 18.3787 1.63064 18.9722C3.00515 20.9678 6.588 25.5561 12 28.1414M12 28.1414V33.9115'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    Treat your Blixti prepaid voucher like cash. Never provide
                    it to anyone via email or telephone.
                  </p>
                </div>
              </div>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6 6V27C6 28.1046 6.89543 29 8 29H17M6 6V3C6 1.89543 6.89543 1 8 1H37C38.1046 1 39 1.89543 39 3V6M6 6L22.0352 14.4892C22.6365 14.8076 23.3585 14.7984 23.9515 14.4648L39 6M39 6V21M10 21H17M10 25H14M3 6C1.89543 6 1 6.89543 1 8V12V32C1 33.1046 1.89543 34 3 34H18M28 26V30M28 33V34M32 26V30M32 33V34M39 30C39 34.9706 34.9706 39 30 39C25.0294 39 21 34.9706 21 30C21 25.0294 25.0294 21 30 21C34.9706 21 39 25.0294 39 30Z'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    If you lose your Blixti prepaid voucher, you can quickly and
                    easily lock it from online use by sending an email to{' '}
                    <a
                      href='mailto:support@blixti.com'
                      rel='noopener noreferrer'
                      className='link'
                    >
                      support@blixti.com
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M27 10L30 13L35 7M1 7H5.5C6.38889 7 7.17111 7.58667 7.42 8.44L8.75 13M37 24L36.5714 25M12.25 25L13.58 29.56C13.8289 30.4133 14.6111 31 15.5 31H32.6812C33.4813 31 34.2043 30.5232 34.5195 29.7878L36.5714 25M12.25 25H36.5714M12.25 25L10.5 19M10.5 19H21M10.5 19L8.75 13M8.75 13H18M30 19C30 19 21 16.1 21 4.5L30 1L39 4.5C39 16.1 30 19 30 19ZM33 36.5C33 37.8807 31.8807 39 30.5 39C29.1193 39 28 37.8807 28 36.5C28 35.1193 29.1193 34 30.5 34C31.8807 34 33 35.1193 33 36.5ZM21.5 36.5C21.5 37.8807 20.3807 39 19 39C17.6193 39 16.5 37.8807 16.5 36.5C16.5 35.1193 17.6193 34 19 34C20.3807 34 21.5 35.1193 21.5 36.5Z'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    Only use your Blixti prepaid voucher at authorised Blixti
                    online shops.
                  </p>
                </div>
              </div>
              <div className='tile'>
                <div className='tile-body'>
                  <div className='color-tile'>
                    <svg
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M39 18V20M20 2L14.1538 6M1 20V37C1 38.1046 1.89543 39 3 39H37C38.1046 39 39 38.1046 39 37V20M1 20V16.0549C1 15.3947 1.32578 14.7771 1.87063 14.4043L6 11.5789M1 20L6 21.8421M39 20L20 27L6 21.8421M5 31H10M5 35H14M19 6H14.1538M6 21.8421V11.5789M10 10H19M10 14H19M10 18H19M14.1538 6H8C6.89543 6 6 6.89543 6 8V11.5789M27 9L30 12L35 7M39 9C39 13.4183 35.4183 17 31 17C26.5817 17 23 13.4183 23 9C23 4.58172 26.5817 1 31 1C35.4183 1 39 4.58172 39 9Z'
                        stroke='#7367FE'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <p className='section__small-description'>
                    In you have any concerns about your account’s security,
                    contact the Blixti Support Team:
                    <a
                      href='mailto:support@blixti.com'
                      rel='noopener noreferrer'
                      className='link'
                    >
                      support@blixti.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default SecurityPage;
