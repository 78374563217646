import {IFormValuesInitial} from '../../state/Standardized/standardized.types';
import {createInitialFormValues} from './createInitialFormValues';
import {formatDateBirth} from './formatDateBirth';
import {formatPhoneNumber} from './formatPhoneNumber';

// eslint-disable-next-line import/prefer-default-export
export const formatePersonalInformation = (fields: IFormValuesInitial) => {
  const {
    avatar,
    current_password,
    new_confirm_password,
    new_email,
    new_password,
    phoneCode,
    ...restFields
  } = fields;

  const initialFormValuesRaw = createInitialFormValues(restFields);
  const {date_of_birth, phone, ...restFormValues} = initialFormValuesRaw;
  let phoneMap;

  if (phoneCode) {
    phoneMap = formatPhoneNumber(phone, phoneCode);
  } else {
    phoneMap = formatPhoneNumber(phone);
  }

  const dateOfBirthMap = formatDateBirth(date_of_birth);

  const personalInformationValues = {
    ...dateOfBirthMap,
    ...phoneMap,
    ...restFormValues,
  };

  return personalInformationValues;
};
