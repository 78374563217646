import api from './config';
import {TRANSACTIONS} from './constants';

const getTransactionsData = () => api.get(TRANSACTIONS.API);

const getFilteredTransactionsDataQueryString = (slugUrl: string) =>
  api.get(`${TRANSACTIONS.API}?${slugUrl}`);

const getFilteredTransactionsDataByDateRange = (
  startDate: string,
  endDate: string,
) =>
  api.get(
    `${TRANSACTIONS.API}?${TRANSACTIONS.DATE_RANGE_FROM}=${startDate}&${TRANSACTIONS.DATE_RANGE_TO}=${endDate}`,
  );

export {
  getTransactionsData,
  getFilteredTransactionsDataQueryString,
  getFilteredTransactionsDataByDateRange,
};
