import styled from 'styled-components';
import {Select} from 'antd';

export const SelectWrapper = styled.div<{isSelectInFocus: boolean}>`
  height: 48px;
  border-radius: 8px;
  outline: ${({isSelectInFocus}) =>
    isSelectInFocus ? '2px solid #192b3b' : '0'};

  & .ant-select-arrow {
    user-select: auto !important;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    border-color: ${(p) => p.theme.main.colors.lightGrey} !important;
  }

  .ant-select-selection-placeholder {
    text-transform: capitalize;
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    margin-bottom: 8px;
    line-height: 24px !important;
  }

  .ant-select-multiple .ant-select-selection-search .ant-select-selection-item {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }

  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.main.colors.black};
  }
`;

export const IconButton = styled.button`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  transform: translateY(-50%);
`;
