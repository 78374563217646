import styled from 'styled-components';

export const TopTableInfo = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.black};
  margin: 30px 0 24px;
`;
