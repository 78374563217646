import {useState, useEffect, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../state/store';
import FormDialog from '../../pages/MemberArea/FormConstructor/FormDialog';
import MemberArea from './MemberArea';

const AuthenticatedLayout = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const {loading, formViewMap} = useSelector(
    (state: RootState) => state.formConstructorReducer,
  );

  const [isFormDialogVisible, setIsFormDialogVisible] = useState(
    !!(!loading && formViewMap && formViewMap.type === 'popup'),
  );

  const onCloseFormDialog = (): void => setIsFormDialogVisible(false);

  useEffect(() => {
    setIsFormDialogVisible(
      !!(!loading && formViewMap && formViewMap.type === 'popup'),
    );
  }, [loading, formViewMap]);

  return (
    <>
      <MemberArea>{children}</MemberArea>
      {isFormDialogVisible ? (
        <FormDialog visible={isFormDialogVisible} onClose={onCloseFormDialog} />
      ) : null}
    </>
  );
};

export default AuthenticatedLayout;
