import {useState, useEffect} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useCalculateProgress = () => {
  const intervalMs = 1000;
  const maxProgress = 100;
  const minProgress = 0;
  const stepProgress = 25;

  const [progress, setProgress] = useState(minProgress);
  const [inProcessing, setInProcessing] = useState(true);

  const secondsLeftBeforeRedirect = Math.floor(
    (maxProgress - progress) / stepProgress + 1,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === maxProgress) {
          setInProcessing(false);
          stopProgressing();
        }
        return prevProgress + stepProgress;
      });
    }, intervalMs);

    const stopProgressing = () => clearInterval(timer);

    return () => stopProgressing();
  }, []); // eslint-disable-line

  return {progress, inProcessing, secondsLeftBeforeRedirect};
};
