import {FC, useEffect, useState} from 'react';
import {
  updateStandardDataItemRequest,
  storeStandardDataItemRequest,
  clearStandardDataItem,
  clearStandardDataItemCopy,
} from '../../../../../state';
import FormModal from '../../../../components/FormModal';
import {ITableViewComponentProps} from './TableView.types';
import {createInitialFormValues} from '../../../../../utils';
import {StyledTable} from './TableView.styles';

const TableViewComponent: FC<ITableViewComponentProps> = ({
  onRow,
  expandable,
  dataSource,
  columns,
  pagination,
  loadings,
  itemData,
  currentSlug,
}: ITableViewComponentProps) => {
  const {loading, loadingDataItemEdit} = loadings;
  const {itemDataEdit, itemDataCopied} = itemData;

  const [isModalEditVisible, setIsModalEditVisible] = useState(
    !!(!loadingDataItemEdit && itemDataEdit),
  );

  const [isModalCopyVisible, setIsModaCopylVisible] = useState(
    !!(!loading && itemDataCopied),
  );

  const onCloseModal = () => setIsModalEditVisible(false);
  const onCloseCopyModal = () => setIsModaCopylVisible(false);

  useEffect(() => {
    setIsModaCopylVisible(!!(!loading && itemDataCopied));
  }, [loading, itemDataCopied]);

  useEffect(() => {
    setIsModalEditVisible(!!(!loadingDataItemEdit && itemDataEdit));
  }, [loadingDataItemEdit, itemDataEdit]);

  return (
    <div>
      <StyledTable
        onRow={onRow}
        expandable={expandable}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        scroll={{x: true}}
      />
      {isModalEditVisible ? (
        <FormModal
          titleModal='Edit'
          visible={isModalEditVisible}
          formFields={itemDataEdit}
          currentSlug={currentSlug}
          onClose={onCloseModal}
          clearFormAction={clearStandardDataItem}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={updateStandardDataItemRequest}
        />
      ) : null}
      {isModalCopyVisible ? (
        <FormModal
          titleModal='Copy'
          visible={isModalCopyVisible}
          formFields={itemDataCopied}
          currentSlug={currentSlug}
          onClose={onCloseCopyModal}
          clearFormAction={clearStandardDataItemCopy}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={storeStandardDataItemRequest}
        />
      ) : null}
    </div>
  );
};

export default TableViewComponent;
