import styled from 'styled-components';
import {Button} from 'antd';

export const Container = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const FormWrapper = styled.div`
  width: 100%;

  @media (max-width: 840px) {
    margin: 0 auto;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;

  @media (max-width: 400px) {
    justify-content: center;
  }
`;

export const ButtonInner = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled(Button)`
  height: 48px;
  margin-right: 16px;
  padding: 12px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.lightPurple};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;
