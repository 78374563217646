import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Banks: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.49631 1.13176C8.18888 0.956081 7.81146 0.956081 7.50403 1.13176L0.504032 5.13176C0.0245148 5.40577 -0.142082 6.01662 0.131928 6.49614C0.316311 6.81881 0.65322 6.99979 1 7.00017V14C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14V7.00017C15.3469 6.99991 15.684 6.81892 15.8684 6.49614C16.1424 6.01662 15.9758 5.40577 15.4963 5.13176L8.49631 1.13176ZM4 8C3.44772 8 3 8.44772 3 9V12C3 12.5523 3.44772 13 4 13C4.55228 13 5 12.5523 5 12V9C5 8.44772 4.55228 8 4 8ZM7 9C7 8.44772 7.44772 8 8 8C8.55229 8 9 8.44772 9 9V12C9 12.5523 8.55229 13 8 13C7.44772 13 7 12.5523 7 12V9ZM12 8C11.4477 8 11 8.44772 11 9V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V9C13 8.44772 12.5523 8 12 8Z'
      fill={fillColor}
    />
  </svg>
);
