import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
  width?: string; // eslint-disable-line
  height?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const CloseIcon: FC<IProps> = ({
  fillColor = '#192B3B',
  width = '14',
  height = '14',
}: IProps): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 14 14'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292894C12.6834 -0.0976305 13.3166 -0.0976305 13.7071 0.292894C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
      fill={fillColor}
    />
  </svg>
);
