// eslint-disable-next-line import/prefer-default-export
export const UploadIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V15M1 15V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H15C15.5304 19 16.0391 18.7893 16.4142 18.4142C16.7893 18.0391 17 17.5304 17 17V13M1 15L5.586 10.414C5.96106 10.0391 6.46967 9.82843 7 9.82843C7.53033 9.82843 8.03894 10.0391 8.414 10.414L11 13M17 9V13M17 13L15.414 11.414C15.0389 11.0391 14.5303 10.8284 14 10.8284C13.4697 10.8284 12.9611 11.0391 12.586 11.414L11 13M11 13L13 15M15 3H19M17 1V5M11 7H11.01'
      stroke='#BAC0C5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
