import api from './config';
import {INCOMPLETE} from './constants';
import {
  IPaySendMap,
  ItemDataCopied,
  ItemDataEdit,
} from '../state/IncompleteInvoices/incompleteInvoices.types';

// CREATE
const createIncompleteInvoice = (slug: string) =>
  api.get(`${INCOMPLETE.API}/${slug}`);

const storeIncompleteInvoice = (slug: string, createData: ItemDataCopied) =>
  api.post(`${INCOMPLETE.API}/${slug}`, {...createData});

// READ
const getIncompleteInvoices = () => api.get(INCOMPLETE.API_INCOMPLETE);

const getIncompleteInvoice = (slug: string) =>
  api.get(`${INCOMPLETE.API}/${slug}`);

const copyIncompleteInvoice = (slug: string) =>
  api.get(`${INCOMPLETE.API}/${slug}`);

// UPDATE
const editIncompleteInvoice = (slug: string) =>
  api.get(`${INCOMPLETE.API}/${slug}`);

const updateIncompleteInvoice = (slug: string, updatedData: ItemDataEdit) =>
  api.post(`${INCOMPLETE.API}/${slug}`, {...updatedData});

// DELETE
const destroyIncompleteInvoice = (slug: string) =>
  api.delete(`${INCOMPLETE.API}/${slug}`);

// PAYMENT
const payIncompleteInvoiceInit = (slug: string) =>
  api.get(`${INCOMPLETE.API}/${slug}`);

const payIncompleteInvoiceSend = (paymentMap: IPaySendMap) =>
  api.post(`${INCOMPLETE.PAY_INVOICE}`, {...paymentMap});

const declineIncompleteInvoice = (slug: string, idInvoice: {id: number}) =>
  api.post(`${INCOMPLETE.API}/${slug}`, {...idInvoice});

export {
  // CREATE
  createIncompleteInvoice,
  storeIncompleteInvoice,
  // READ
  getIncompleteInvoice,
  getIncompleteInvoices,
  copyIncompleteInvoice,
  // UPDATE
  editIncompleteInvoice,
  updateIncompleteInvoice,
  // DELETE
  destroyIncompleteInvoice,
  // PAYMENT
  payIncompleteInvoiceInit,
  payIncompleteInvoiceSend,
  declineIncompleteInvoice,
};
