import {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Spin} from 'antd';
import ListView from '../../../components/ListView';
import FormModal from '../../../components/FormModal';
import TopActionsBar from '../../../components/TopActionsBar';
import TableView from './TableView';
import FiltersDrawer from './FiltersDrawer';
import {RootState} from '../../../../state/store';
import {
  // CRUD REQUESTS
  createStandardDataItemRequest,
  storeStandardDataItemRequest,
  initialGetStandardDataRequest,
  getStandardDataItemRequest,
  copyStandardDataItemRequest,
  editStandardDataItemRequest,
  updateStandardDataItemRequest,
  destroyStandardDataItemRequest,
  // MISC ACTIONS
  filterStandardDataRequest,
  clearStandardDataItem,
  clearStandardDataItemCopy,
  clearFieldsCreateDataItemForm,
  setPageTitleAction,
  getBadgeCountRequest,
  exportFileRequest,
  clearExportFileRes,
  getFormViewRequest,
} from '../../../../state';
import {
  createInitialEmptyFormValues,
  createInitialFilledFormValues,
} from '../../../../utils';

const Standardized = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {tableName} = useParams<{tableName: string}>();

  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  const {loading: loadingExportFile, exportFileMap} = useSelector(
    (state: RootState) => state.exportFileReducer,
  );

  const {loading: loadingForm, formViewMap} = useSelector(
    (state: RootState) => state.formConstructorReducer,
  );

  const {
    loading,
    data,
    error,
    fieldsCreateItem,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
    itemDataView,
    itemDataEdit,
    itemDataCopied,
    currentSlug,
  } = useSelector((state: RootState) => state.standardizedReducer);

  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(
    !!(!loading && fieldsCreateItem),
  );

  const handleFiltersVisible = (bool: boolean): void => setFiltersVisible(bool);
  const onCloseModal = (): void => setIsModalVisible(false);

  useEffect(() => {
    if (!formViewMap) return;

    if (formViewMap.type === 'page') {
      history.push('/form-constructor');
    }
  }, [formViewMap]);

  useEffect(() => {
    if (exportFileMap) {
      window.open(exportFileMap.file);
    }

    dispatch(clearExportFileRes());
  }, [exportFileMap]);

  useEffect(() => {
    setIsModalVisible(!!(!loading && fieldsCreateItem));
  }, [loading, fieldsCreateItem]);

  useEffect(() => {
    dispatch(initialGetStandardDataRequest(tableName));
    dispatch(getBadgeCountRequest());
  }, [tableName, timestampReload]);

  useEffect(() => {
    if (data) {
      dispatch(setPageTitleAction(data.pageTitle));
    }

    const pathnameOmitSlash = location.pathname.substring(
      3,
      location.pathname.length,
    );

    dispatch(setPageTitleAction(pathnameOmitSlash));
  }, [data, location]);

  if (error) return <div>{error}</div>;

  if (loadingForm || loading || !data)
    return (
      <Spin
        tip='Loading...'
        spinning={loadingForm || loading || !data}
        size='large'
      />
    );

  const loadingsProps = {
    loading,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
  };

  const itemDataProps = {
    itemDataView,
    itemDataEdit,
    itemDataCopied,
  };

  const actionsProps = {
    copyDataItemAction: copyStandardDataItemRequest,
    editDataItemAction: editStandardDataItemRequest,
    deleteDataItemAction: destroyStandardDataItemRequest,
    clearEditFormAction: clearStandardDataItem,
    clearCopyFormAction: clearStandardDataItemCopy,
    sendEditFormAction: updateStandardDataItemRequest,
    sendCopyFormAction: storeStandardDataItemRequest,
  };

  return (
    <div>
      {data.buttons ? (
        <TopActionsBar
          actions={data.buttons}
          exportFileOptions={data?.exportOptions}
          loadingExportFile={loadingExportFile}
          createDataItemAction={createStandardDataItemRequest}
          handleFiltersVisible={handleFiltersVisible}
          filterDataAction={filterStandardDataRequest}
          exportFileAction={exportFileRequest}
          getFormViewAction={getFormViewRequest}
        />
      ) : null}
      {data?.table ? (
        <TableView data={data.table} pagination={data?.pagination} />
      ) : (
        <ListView
          data={data.list}
          paginationTotal={data?.pagination?.total}
          loadings={loadingsProps}
          itemData={itemDataProps}
          actions={actionsProps}
          currentSlug={currentSlug}
          createInitialFormValues={createInitialFilledFormValues}
          getDataItemAction={getStandardDataItemRequest}
        />
      )}
      {isModalVisible ? (
        <FormModal
          titleModal='Create'
          visible={isModalVisible}
          formFields={fieldsCreateItem}
          currentSlug={currentSlug}
          onClose={onCloseModal}
          clearFormAction={clearFieldsCreateDataItemForm}
          createInitialFormValues={createInitialEmptyFormValues}
          sendFormAction={storeStandardDataItemRequest}
        />
      ) : null}
      {data?.filterTemplate ? (
        <FiltersDrawer
          visible={isFiltersVisible}
          filterTemplate={data?.filterTemplate}
          handleFiltersVisible={handleFiltersVisible}
        />
      ) : null}
    </div>
  );
};

export default Standardized;
