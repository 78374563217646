import {AnyAction} from 'redux';
import {IPaymentState} from './payment.types';
import {
  SEND_PAYMENT_REQUEST,
  SEND_PAYMENT_SUCCESS,
  SEND_PAYMENT_FAILURE,
  FORM_SEND_PAYMENT_REQUEST,
  SET_CURRENT_METHOD_KEY,
  SET_PREFERRED_METHOD,
} from './payment.actions';

const initialState: IPaymentState = {
  loading: false,
  sendPaymentReqBody: {
    invoice: 0,
    method: 0,
  },
  sendPaymentResponse: null,
  currentMethodKey: '0',
  preferredMethod: '',
  error: null,
};

const paymentReducer = (
  state: IPaymentState = initialState,
  action: AnyAction,
): IPaymentState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case SEND_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case SEND_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        sendPaymentReqBody: {
          invoice: 0,
          method: 0,
        },
        sendPaymentResponse: action.payload,
        error: null,
      };

    case FORM_SEND_PAYMENT_REQUEST:
      return {
        ...state,
        sendPaymentReqBody: action.payload,
      };

    // MISC ACTIONS
    case SET_CURRENT_METHOD_KEY:
      return {
        ...state,
        currentMethodKey: action.payload,
      };

    case SET_PREFERRED_METHOD:
      return {
        ...state,
        preferredMethod: action.payload,
      };

    // FAILURE REQUEST ACTIONS
    case SEND_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default paymentReducer;
