import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ChevronDown: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='8'
    height='6'
    viewBox='0 0 8 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.33341 1.66669L4.00008 4.33335L6.66675 1.66669'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
