import {AxiosResponse} from 'axios';
import {getVoucherData} from '../../infra/voucher.service';
import {ThunkActionType} from '../store';
import {
  getVoucherDataRequestAction,
  getVoucherDataSuccessAction,
  getVoucherDataFailureAction,
} from './vouchers.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';

// eslint-disable-next-line import/prefer-default-export
export const getVoucherDataRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getVoucherDataRequestAction());

  return getVoucherData()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getVoucherDataSuccessAction,
        failureAction: getVoucherDataFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getVoucherDataFailureAction,
        notificationAction: setNotificationState,
      });
    });
};
