import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Speakerphone: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 9.63668V13.6313C7 14.3872 6.60941 15 5.85347 15C5.27502 15 4.75902 14.6364 4.56446 14.0916L2.89503 9.3089M12 8.77778C13.2887 8.77778 15 8.38889 15 6.44444C15 4.5 13.2887 4.11111 12 4.11111M2.89503 9.3089C1.78112 8.83541 1 7.73115 1 6.44444C1 4.72622 2.39289 3.33333 4.11111 3.33333H5.53607C8.72545 3.33333 10.7998 2.37348 12 1V12C10.7998 10.6265 8.72545 9.55556 5.53607 9.55556L4.11109 9.55555C3.67958 9.55555 3.2686 9.4677 2.89503 9.3089Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
