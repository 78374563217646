import styled from 'styled-components';
import {Select} from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 32px;

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }
`;
