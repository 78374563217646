// eslint-disable-next-line import/prefer-default-export
export const nonStandardPageList = [
  '/dashboard',
  '/voucher',
  '/transactions',
  '/incomplete',
  '/contact-us',
  '/deposit',
  '/top-up',
  '/settings',
  '/verification',
  '/form-constructor',
];
