import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const DocumentIcon: FC<IProps> = ({fillColor = '#74828A'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 1C3.89543 1 3 1.89543 3 3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V5.41421C13 4.88378 12.7893 4.37507 12.4142 4L10 1.58579C9.62493 1.21071 9.11622 1 8.58579 1H5ZM9.11629 3.19284L10.8072 4.88371C11.2191 5.29565 10.9274 6 10.3448 6H8.65391C8.29277 6 8 5.70723 8 5.34609V3.65522C8 3.07265 8.70435 2.7809 9.11629 3.19284Z'
      fill={fillColor}
    />
  </svg>
);
