// eslint-disable-next-line import/prefer-default-export
export const WalletColored = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 12V9C1 7.89543 1.89543 7 3 7H5M1 12V37C1 38.1046 1.89543 39 3 39H34C35.1046 39 36 38.1046 36 37V30M1 12H32M32 12H34C35.1046 12 36 12.8954 36 14V20M32 12V9C32 7.89543 31.1046 7 30 7H24M6 16H9M13 16H16M20 16H23M27 16H30M6 35H9M13 35H16M20 35H23M27 35H30M24 7H25C26.1046 7 27 7.89543 27 9V12M24 7H15C13.8954 7 13 7.89543 13 9V12M24 7V6C24 4.89543 23.1046 4 22 4H21M9 12V6C9 4.89543 9.89543 4 11 4H21M5 12V3C5 1.89543 5.89543 1 7 1H19C20.1046 1 21 1.89543 21 3V4M33 25C33 26.1046 32.1046 27 31 27C29.8954 27 29 26.1046 29 25C29 23.8954 29.8954 23 31 23C32.1046 23 33 23.8954 33 25ZM37 30H30C27.2386 30 25 27.7614 25 25V25C25 22.2386 27.2386 20 30 20H37C38.1046 20 39 20.8954 39 22V28C39 29.1046 38.1046 30 37 30Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
