import styled from 'styled-components';
import {Button, Drawer} from 'antd';

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }

  @media (max-width: 560px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .ant-drawer-wrapper-body {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  @media (max-width: 400px) {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0;

  @media (max-width: 400px) {
    position: relative;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonWrapper = styled(Button)<{action: string}>`
  height: 48px;
  width: 136px;
  margin: ${({action}) => (action === 'cancel' ? '0 20px' : '0')};
  padding: 12px 24px;

  background-color: ${(p) => {
    if (p.action === 'apply') {
      return p.theme.main.colors.brand;
    }

    if (p.action === 'cancel') {
      return p.theme.main.colors.signUp;
    }

    return p.theme.main.colors.white;
  }};

  color: ${(p) =>
    p.action === 'apply'
      ? p.theme.main.colors.white
      : p.theme.main.colors.black};
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}

  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;

  @media (max-width: 560px) {
    width: 106px;
    padding: 12px;
  }

  @media (max-width: 400px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const DrawerHeader = styled.section`
  ${(p) => p.theme.main.typography.h3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: column;
  // justify-content: center;
  width: 100%;
  height: 94px;
  padding: 24px;
  background-color: #f8f8fb;
`;

export const CloseIconButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 100px;
  cursor: pointer;
`;

export const DrawerMain = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 96px;
`;
