import {FC} from 'react';
import {useIntl} from 'react-intl';
import {
  TitlesContainer,
  TitleWrapper,
  SecondaryTextWrapper,
} from '../Auth.styles';

const ForgotPasswordTitle: FC = (): JSX.Element => {
  const {formatMessage} = useIntl();
  return (
    <TitlesContainer>
      <TitleWrapper level={2}>
        {formatMessage({id: 'FORGOT_PASSWORD_TITLE'})}
      </TitleWrapper>
      <SecondaryTextWrapper type='secondary'>
        {formatMessage({id: 'FORGOT_PASSWORD_SUBTITLE'})}
      </SecondaryTextWrapper>
    </TitlesContainer>
  );
};

export default ForgotPasswordTitle;
