import {
  IFieldItems,
  IFormValuesInitial,
} from '../../state/Standardized/standardized.types';

// eslint-disable-next-line import/prefer-default-export
export const createInitialEmptyFormValues = (obj: IFieldItems) => {
  const newObj: IFormValuesInitial = {};
  Object.keys(obj).forEach((itm: string) => {
    newObj[itm] = '';
  });
  return newObj;
};
