import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../../store';
import {
  getAllChats,
  getOneChat,
  changeChatStatus,
  deleteChat,
} from '../../../infra/chat.service';
import {
  getAllChatsRequestAction,
  getAllChatsSuccessAction,
  getAllChatsFailureAction,
  getOneChatRequestAction,
  getOneChatSuccessAction,
  getOneChatFailureAction,
  changeChatStatusRequestAction,
  changeChatStatusSuccessAction,
  changeChatStatusFailureAction,
  deleteChatRequestAction,
  deleteChatSuccessAction,
  deleteChatFailureAction,
  setChatId,
  setChatName,
} from './actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseExtraAction,
  handleResponseFailNotification,
} from '../../../utils';
import {IChangeStatusChatBody, IChatMap, IGetOneChatBody} from '../chat.types';
import {setInterlocutorId} from '../client/actions';

export const getAllChatsRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getAllChatsRequestAction());
  return getAllChats()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getAllChatsSuccessAction,
        failureAction: getAllChatsFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getAllChatsFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const getOneChatRequest =
  (body: IGetOneChatBody): ThunkActionType =>
  (dispatch) => {
    dispatch(getOneChatRequestAction());
    return getOneChat(body)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getOneChatSuccessAction,
          failureAction: getOneChatFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getOneChatFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getOneChatForAdmin =
  (userId: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getAllChatsRequestAction());
    dispatch(setInterlocutorId(+userId));

    return getAllChats()
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getAllChatsSuccessAction,
          failureAction: getAllChatsFailureAction,
        });
        return response.data.chats;
      })
      .then((chats) => {
        if (Array.isArray(chats)) {
          const foundChat = chats.find(
            (chat) => chat.interlocutor_id === +userId,
          );

          if (foundChat && foundChat.chat_id) {
            dispatch(getOneChatRequest({chat_id: foundChat.chat_id}));
            dispatch(setChatId(foundChat.chat_id));
            dispatch(setChatName(foundChat.name));
          }

          return;
        }

        const chatList: IChatMap[] = Object.values(chats);

        const foundChat = chatList.find(
          (chat) => chat.interlocutor_id === +userId,
        );

        if (foundChat && foundChat.chat_id) {
          dispatch(getOneChatRequest({chat_id: foundChat.chat_id}));
          dispatch(setChatId(foundChat.chat_id));
          dispatch(setChatName(foundChat.name));
        }
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getOneChatFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getOneChatForClient = (): ThunkActionType => (dispatch) => {
  dispatch(getAllChatsRequestAction());

  return getAllChats()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getAllChatsSuccessAction,
        failureAction: getAllChatsFailureAction,
      });
      return response.data.chats;
    })
    .then((chats) => {
      if (chats.length === 0) return;
      dispatch(getOneChatRequest({chat_id: chats[0].chat_id}));
      dispatch(setChatId(chats[0].chat_id));
      dispatch(setChatName(chats[0].name));
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getOneChatFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const changeChatStatusRequest =
  (body: IChangeStatusChatBody): ThunkActionType =>
  (dispatch) => {
    dispatch(changeChatStatusRequestAction());
    return changeChatStatus(body)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: changeChatStatusSuccessAction,
          failureAction: changeChatStatusFailureAction,
          extraRequest: getOneChatRequest,
          extraRequestArg: {chat_id: body.chat_id},
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: changeChatStatusFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const deleteChatRequest =
  (chat_id: string): ThunkActionType =>
  (dispatch) => {
    dispatch(deleteChatRequestAction());

    return deleteChat(chat_id)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: deleteChatSuccessAction,
          failureAction: deleteChatFailureAction,
          extraRequest: getAllChatsRequest,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: deleteChatFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
