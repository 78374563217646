import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  useEffect,
} from 'react';
import {IHandleValueChangeArgs} from '../../../state/FormConstructor/formConstructor.types';
import {omitSpace, checkValidValue, maskValue} from './helpers';
import {navKeys, numKeys} from './keyList';

interface IUseCardNumberArgs {
  keyName: string;
  initialValue: string;
  // eslint-disable-next-line no-unused-vars
  handleValueChange: (args: IHandleValueChangeArgs) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useCardNumber = (args: IUseCardNumberArgs) => {
  const {keyName, initialValue, handleValueChange} = args;

  const [cardNumberValue, setCardNumberValue] = useState('');
  const [cardNumberMasked, setCardNumberMasked] = useState('');
  const [enteredKey, setEnteredKey] = useState('');

  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.shiftKey) return;
    setEnteredKey(event.code);
  };

  const handlePasteCardNumber = (
    event: ClipboardEvent<HTMLInputElement>,
  ): void => {
    const {clipboardData} = event;
    const pastedValue = clipboardData.getData('text');

    const pastedValueOmitSpace = omitSpace(pastedValue);
    const isValidPastedValue = checkValidValue(pastedValueOmitSpace);
    if (!isValidPastedValue) return;
    const pastedValueWithStars = maskValue(pastedValueOmitSpace);

    setCardNumberValue(pastedValueOmitSpace);
    setCardNumberMasked(pastedValueWithStars);
  };

  const handleChangeCardNumber = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const {
      target: {value, selectionStart},
    } = event;

    const notNavKeys = !navKeys.includes(enteredKey);
    const notNumKeys = !numKeys.includes(enteredKey);
    const overflowCardNumber = value.length > 16;

    if ((notNumKeys && notNavKeys) || (notNavKeys && overflowCardNumber))
      return;

    if (selectionStart === null) return;

    setCardNumberValue((prevState) => {
      // If cursor in the end
      if (value.length === selectionStart) {
        // If number adds
        if (value.length > prevState.length) {
          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (prevState += value[value.length - 1]);
        }
        // If number removes
        const splittedPrevState = prevState.split('');
        splittedPrevState.pop();
        const joinedPrevState = splittedPrevState.join('');

        return joinedPrevState;
      }
      // If cursor in the middle
      if (prevState.length > value.length) {
        const splittedPrevState = prevState.split('');
        splittedPrevState.splice(selectionStart, 1);
        const joinedPrevState = splittedPrevState.join('');
        return joinedPrevState;
      }

      const splittedPrevState = prevState.split('');
      splittedPrevState.splice(
        selectionStart - 1,
        0,
        value[selectionStart - 1],
      );
      const updatedCardNumberValue = splittedPrevState.join('');

      return updatedCardNumberValue;
    });

    if (selectionStart === value.length) {
      if (cardNumberValue.length >= 4 && cardNumberMasked.length <= 11) {
        const maskedValue = value
          .split('')
          .map((itm, idx) => {
            if (idx >= 4 && idx <= 11) return '*';
            return itm;
          })
          .join('');

        setCardNumberMasked(maskedValue);
      } else {
        setCardNumberMasked(value);
      }
    } else if (selectionStart > 4 && selectionStart < 13) {
      const maskedValue = value
        .split('')
        .map((itm, idx) => {
          if (idx === selectionStart - 1) return '*';
          return itm;
        })
        .join('');
      setCardNumberMasked(maskedValue);
    } else {
      setCardNumberMasked(value);
    }

    setEnteredKey('');
  };

  useEffect(() => {
    if (!initialValue) return;
    const pastedValueOmitSpace = omitSpace(initialValue);
    const pastedValueWithStars = maskValue(pastedValueOmitSpace);

    setCardNumberValue(pastedValueOmitSpace);
    setCardNumberMasked(pastedValueWithStars);
  }, []);

  useEffect(() => {
    if (cardNumberValue.length === 0) return;
    handleValueChange({keyName, value: cardNumberValue});
  }, [cardNumberValue]);

  return {
    cardNumberValue,
    cardNumberMasked,
    handleEnterKey,
    handlePasteCardNumber,
    handleChangeCardNumber,
  };
};
