import {FC, useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import ActionsList from '../ActionsList';
import FormModal from '../FormModal';
import {
  Content,
  DetailedListInfo,
  ActionsListWrapper,
  FlexWrapper,
  TermItem,
  DefinitionItem,
} from './CollapseContent.styles';
import {DataItem, ICollapseContentProps} from './CollapseContent.types';

const CollapseContent: FC<ICollapseContentProps> = ({
  loadings,
  itemData,
  actions,
  currentSlug,
  createInitialFormValues,
}: ICollapseContentProps) => {
  const {itemDataView, itemDataEdit, itemDataCopied} = itemData;
  const {
    loading,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
  } = loadings;
  const {
    copyDataItemAction,
    editDataItemAction,
    deleteDataItemAction,
    clearEditFormAction,
    clearCopyFormAction,
    sendEditFormAction,
    sendCopyFormAction,
  } = actions;

  const [isModalEditVisible, setIsModaEditlVisible] = useState(
    !!(!loadingDataItemEdit && itemDataEdit),
  );

  const [isModalCopyVisible, setIsModaCopylVisible] = useState(
    !!(!loading && itemDataCopied),
  );

  const onCloseEditModal = () => setIsModaEditlVisible(false);
  const onCloseCopyModal = () => setIsModaCopylVisible(false);

  useEffect(() => {
    setIsModaEditlVisible(!!(!loadingDataItemEdit && itemDataEdit));
  }, [loadingDataItemEdit, itemDataEdit]);

  useEffect(() => {
    setIsModaCopylVisible(!!(!loading && itemDataCopied));
  }, [loading, itemDataCopied]);

  const dispatchActionsMapProps = {
    copyDataItemAction,
    editDataItemAction,
    deleteDataItemAction,
  };

  return (
    <>
      <Content>
        {loadingDataItemEdit ||
        loadingDataItemCopy ||
        loadingDataItemView ||
        !itemDataView ? (
          <div>Loading...</div>
        ) : (
          <>
            <DetailedListInfo>
              {itemDataView.data.map((dataItem: DataItem) => {
                const keyValueTuple: string[] = Object.values(dataItem);
                return (
                  <FlexWrapper key={uuidv4()}>
                    <TermItem>{keyValueTuple[0]}:</TermItem>
                    <DefinitionItem>{keyValueTuple[1]}</DefinitionItem>
                  </FlexWrapper>
                );
              })}
            </DetailedListInfo>
            {itemDataView && itemDataView.buttons ? (
              <ActionsListWrapper>
                <ActionsList
                  actionsList={itemDataView.buttons}
                  dispatchActionsMap={dispatchActionsMapProps}
                />
              </ActionsListWrapper>
            ) : null}
          </>
        )}
      </Content>
      {isModalEditVisible ? (
        <FormModal
          titleModal='Edit'
          visible={isModalEditVisible}
          formFields={itemDataEdit}
          currentSlug={currentSlug}
          onClose={onCloseEditModal}
          clearFormAction={clearEditFormAction}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={sendEditFormAction}
        />
      ) : null}
      {isModalCopyVisible ? (
        <FormModal
          titleModal='Copy'
          visible={isModalCopyVisible}
          formFields={itemDataCopied}
          currentSlug={currentSlug}
          onClose={onCloseCopyModal}
          clearFormAction={clearCopyFormAction}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={sendCopyFormAction}
        />
      ) : null}
    </>
  );
};

export default CollapseContent;
