import styled from 'styled-components';

export const Container = styled.div<{checked: boolean}>`
  margin-bottom: 4px;
  padding: 16px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: ${({checked}) => (checked ? '#f6f5ff' : '#fff')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Heading = styled.h4`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: 12px;
`;
