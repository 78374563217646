import {FC, useState} from 'react';
import {Select} from 'antd';
import {Down, Search} from '../../../../static/images';
import {IMultiSelectProps} from './MultiSelect.types';
import {IconButton, SelectWrapper, StyledSelect} from './MultiSelect.styles';

const {Option} = Select;

const MultiSelect: FC<IMultiSelectProps> = ({
  options,
  name,
  initialValue,
  addMultiSelectValue,
  removeMultiSelectValue,
  filterTransactions,
}: IMultiSelectProps) => {
  const [isSelectInFocus, setSelectInFocus] = useState(false);

  const handleSelectInFocus = (bool: boolean) => () => setSelectInFocus(bool);

  return (
    <SelectWrapper isSelectInFocus={isSelectInFocus}>
      <div style={{position: 'relative'}}>
        <StyledSelect
          showArrow
          mode='tags'
          className='transactions-filter-select'
          placeholder='Filter by'
          optionFilterProp='key'
          suffixIcon={null}
          value={initialValue}
          onSelect={(valueSelect, option) => {
            addMultiSelectValue(name, option);
          }}
          onDeselect={(valueSelect, option) => {
            removeMultiSelectValue(name, option);
          }}
          onFocus={handleSelectInFocus(true)}
          onBlur={handleSelectInFocus(false)}
        >
          {Object.entries(options).map((option) => (
            <Option key={option[0]} value={option[1]}>
              {option[1]}
            </Option>
          ))}
        </StyledSelect>
        <IconButton onClick={filterTransactions}>
          {initialValue ? <Search fillColor='#BAC0C5' /> : <Down />}
        </IconButton>
      </div>
    </SelectWrapper>
  );
};

export default MultiSelect;
