import {ITopUpFieldItem} from '../../state/TopUp/topUp.types';
import {reorderInputAmount} from './reorderInputAmount';

const reorderSelectMethod = (rawFieldsList: ITopUpFieldItem[]) => {
  const selectMethodIdx = rawFieldsList.findIndex(
    (itm: ITopUpFieldItem) =>
      itm.type === 'select' && itm.name.toLowerCase() === 'method',
  );

  const tmp = rawFieldsList[rawFieldsList.length - 1];
  rawFieldsList[rawFieldsList.length - 1] = rawFieldsList[selectMethodIdx]; // eslint-disable-line
  rawFieldsList[selectMethodIdx] = tmp; // eslint-disable-line

  return rawFieldsList;
};

// eslint-disable-next-line import/prefer-default-export
export const reorderTopUpFieldsList = (rawFieldsList: ITopUpFieldItem[]) => {
  const reorderListInputAmount = reorderInputAmount(rawFieldsList);
  return reorderSelectMethod(reorderListInputAmount);
};
