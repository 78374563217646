// GET_APP_DATA TYPES & ACTIONS
export const GET_APP_DATA_REQUEST: string = 'GET_APP_DATA_REQUEST';
export const GET_APP_DATA_SUCCESS: string = 'GET_APP_DATA_SUCCESS';
export const GET_APP_DATA_FAILURE: string = 'GET_APP_DATA_FAILURE';

export const getAppDataRequestAction = (): {type: string} => ({
  type: GET_APP_DATA_REQUEST,
});

export const getAppDataSuccessAction = (payload: any) => ({
  type: GET_APP_DATA_SUCCESS,
  payload,
});

export const getAppDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({type: GET_APP_DATA_FAILURE, payload});

// GET_COUNTRIES_LIST TYPES & ACTIONS
export const GET_COUNTRIES_LIST_REQUEST: string = 'GET_COUNTRIES_LIST_REQUEST';
export const GET_COUNTRIES_LIST_SUCCESS: string = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAILURE: string = 'GET_COUNTRIES_LIST_FAILURE';

export const getCountriesListRequestAction = (): {type: string} => ({
  type: GET_COUNTRIES_LIST_REQUEST,
});

export const getCountriesListSuccessAction = (payload: any) => ({
  type: GET_COUNTRIES_LIST_SUCCESS,
  payload,
});

export const getContriesListFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_COUNTRIES_LIST_FAILURE,
  payload,
});

// SEND_CONTACT TYPES & ACTIONS
export const SEND_CONTACT_REQUEST: string = 'SEND_CONTACT_REQUEST';
export const SEND_CONTACT_SUCCESS: string = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_FAILURE: string = 'SEND_CONTACT_FAILURE';

export const sendContactRequestAction = (): {type: string} => ({
  type: SEND_CONTACT_REQUEST,
});

export const sendContactSuccessAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_CONTACT_SUCCESS,
  payload,
});

export const sendContactFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_CONTACT_FAILURE,
  payload,
});

// SEND_PARTNER TYPES & ACTIONS
export const SEND_PARTNER_REQUEST: string = 'SEND_PARTNER_REQUEST';
export const SEND_PARTNER_SUCCESS: string = 'SEND_PARTNER_SUCCESS';
export const SEND_PARTNER_FAILURE: string = 'SEND_PARTNER_FAILURE';

export const sendPartnerRequestAction = (): {type: string} => ({
  type: SEND_PARTNER_REQUEST,
});

export const sendPartnerSuccessAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_PARTNER_SUCCESS,
  payload,
});

export const sendPartnerFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: SEND_PARTNER_FAILURE,
  payload,
});

// SET_PAGE_TITLE TYPES & ACTIONS
export const SET_PAGE_TITLE: string = 'SET_PAGE_TITLE';

export const setPageTitleAction = (payload: string) => ({
  type: SET_PAGE_TITLE,
  payload,
});

// SET_TIMESTAMP_RELOAD TYPES & ACTIONS
export const SET_TIMESTAMP_RELOAD: string = 'SET_TIMESTAMP_RELOAD';

export const setTimestampReloadAction = (payload: number) => ({
  type: SET_TIMESTAMP_RELOAD,
  payload,
});

// SET_VERIFY_NOTIFICATION_STATE TYPES & ACTIONS
export const SET_VERIFY_NOTIFICATION_STATE: string =
  'SET_VERIFY_NOTIFICATION_STATE';

export const setVerifyNotificationState = (payload: boolean) => ({
  type: SET_VERIFY_NOTIFICATION_STATE,
  payload,
});

// SET_VERIFICATION_URL TYPES & ACTIONS
export const SET_VERIFICATION_URL: string = 'SET_VERIFICATION_URL';

export const setVerificationUrl = (payload: string) => ({
  type: SET_VERIFICATION_URL,
  payload,
});
