import {IExportFileReqArgs} from '../state/ExportFile/exportFile.types';
import api from './config';
import {EXPORT_FILE} from './constants';

const exportFile = ({tableName, typeFile}: IExportFileReqArgs) =>
  api.get(
    `${EXPORT_FILE.API}${tableName}${EXPORT_FILE.EXPORT}?type=${typeFile}`,
  );

// eslint-disable-next-line import/prefer-default-export
export {exportFile};
