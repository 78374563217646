import {useCalculateProgress} from '../../../../hooks';
import {Container, Paragraph, StyledProgress} from './ProgressComponent.styles';

const ProgressComponent = () => {
  const {progress} = useCalculateProgress();

  const formatCountdown = (percent: number | undefined): string => {
    if (!percent) return 'Waiting...';

    switch (percent) {
      case 0:
        return '5 seconds';
      case 25:
        return '4 seconds';
      case 50:
        return '3 seconds';
      case 75:
        return '2 seconds';
      case 100:
        return '1 second';
      default:
        return '0 second';
    }
  };

  return (
    <Container>
      <Paragraph>
        Deposit is successful. You will be redirected to Blixti to proceed with
        payment of the invoice in
      </Paragraph>
      <StyledProgress
        type='circle'
        percent={progress}
        format={formatCountdown}
        strokeColor='#7367FE'
      />
    </Container>
  );
};

export default ProgressComponent;
