import {FC} from 'react';
import {Down} from '../../../../static/images';
import {
  ArrowWrapper,
  Description,
  LeftPanelPart,
  PanelInner,
  RightPanelPart,
  TextPartPanel,
  Title,
} from './PanelHeader.styles';

interface IPanelHeaderProps {
  collapsedKeysList: string | string[];
  panelId: string;
  icon: JSX.Element;
  title: string;
  description: string;
}

const PanelHeader: FC<IPanelHeaderProps> = ({
  icon,
  title,
  description,
  panelId,
  collapsedKeysList,
}: IPanelHeaderProps) => (
  <PanelInner>
    <LeftPanelPart>
      {icon}
      <TextPartPanel>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextPartPanel>
    </LeftPanelPart>
    <RightPanelPart>
      <ArrowWrapper isOpen={collapsedKeysList[0] === panelId}>
        <Down />
      </ArrowWrapper>
    </RightPanelPart>
  </PanelInner>
);

export default PanelHeader;
