import styled from 'styled-components';
import {CreditCard} from '../../view/static/images';
import auIco from '../../view/styles/img/currencies/au.svg';
import idIco from '../../view/styles/img/currencies/id.svg';
import inIco from '../../view/styles/img/currencies/in.svg';
import jpIco from '../../view/styles/img/currencies/jp.svg';
import nzIco from '../../view/styles/img/currencies/nz.svg';
import phIco from '../../view/styles/img/currencies/ph.svg';
import plIco from '../../view/styles/img/currencies/pl.svg';
import thIco from '../../view/styles/img/currencies/th.svg';
import ukIco from '../../view/styles/img/currencies/uk.svg';
import usIco from '../../view/styles/img/currencies/us.svg';
import vtIco from '../../view/styles/img/currencies/vt.svg';

const IconWrapper = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #192b3b;
`;

// eslint-disable-next-line import/prefer-default-export
export const defineCurrencySymbolIcon = (iconName: string) => {
  switch (iconName) {
    case 'au':
      return <img src={auIco} alt='au' />;
    case 'id':
      return <img src={idIco} alt='id' />;
    case 'in':
      return <img src={inIco} alt='in' />;
    case 'jp':
      return <img src={jpIco} alt='jp' />;
    case 'nz':
      return <img src={nzIco} alt='nz' />;
    case 'ph':
      return <img src={phIco} alt='ph' />;
    case 'pl':
      return <img src={plIco} alt='pl' />;
    case 'th':
      return <img src={thIco} alt='th' />;
    case 'uk':
      return <img src={ukIco} alt='uk' />;
    case 'us':
      return <img src={usIco} alt='us' />;
    case 'vt':
      return <img src={vtIco} alt='vt' />;
    default:
      return (
        <IconWrapper>
          <CreditCard width={20} height={20} />
        </IconWrapper>
      );
  }
};
