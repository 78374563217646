import {FC} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const EnvelopeCircleTransparent: FC = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      d='M15 20L22.8906 25.2604C23.5624 25.7083 24.4376 25.7083 25.1094 25.2604L33 20M17 31H31C32.1046 31 33 30.1046 33 29V19C33 17.8954 32.1046 17 31 17H17C15.8954 17 15 17.8954 15 19V29C15 30.1046 15.8954 31 17 31Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
