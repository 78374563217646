import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const SelectIcon: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='6'
    height='10'
    viewBox='0 0 6 10'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.70711 2.29289C6.09763 2.68342 6.09763 3.31658 5.70711 3.70711C5.31658 4.09763 4.68342 4.09763 4.29289 3.70711L3 2.41421L1.70711 3.70711C1.31658 4.09763 0.683417 4.09763 0.292893 3.70711C-0.0976306 3.31658 -0.0976316 2.68342 0.292893 2.29289L2.29289 0.292893C2.68342 -0.097631 3.31658 -0.097631 3.70711 0.292893L5.70711 2.29289ZM5.70711 7.70711C6.09763 7.31658 6.09763 6.68342 5.70711 6.29289C5.31658 5.90237 4.68342 5.90237 4.29289 6.29289L3 7.58579L1.70711 6.29289C1.31658 5.90237 0.683417 5.90237 0.292893 6.29289C-0.0976306 6.68342 -0.0976316 7.31658 0.292893 7.70711L2.29289 9.70711C2.68342 10.0976 3.31658 10.0976 3.70711 9.70711L5.70711 7.70711Z'
      fill={fillColor}
    />
  </svg>
);
