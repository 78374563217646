import {FC, Fragment} from 'react';
import {Menu, Dropdown} from 'antd';
import {
  More,
  Copy,
  Delete,
  Edit,
  Close,
  DocumentIcon,
} from '../../../../static/images';
import {ActionItem, IDropDownMenuProps} from './DropDownMenu.types';
import {DropDownWrapper, MenuItem, MenuItemText} from './DropDownMenu.styles';

const defineIconMenuItem = (iconName: string): JSX.Element => {
  switch (iconName) {
    case 'edit':
      return <Edit fillColor='#74828A' />;
    case 'copy':
      return <Copy fillColor='#74828A' />;
    case 'refund':
      return <Close fillColor='#74828A' />;
    case 'delete':
      return <Delete fillColor='#74828A' />;
    default:
      return <DocumentIcon fillColor='#74828A' />;
  }
};

const DropDownMenu: FC<IDropDownMenuProps> = ({
  actionList,
  handlerMenuItem,
}: IDropDownMenuProps) => {
  const menu = (
    <Menu
      onClick={({domEvent, key}) => {
        handlerMenuItem({
          slugUrl: key,
          altApiUrl: (domEvent.target as HTMLElement).dataset.alt,
        });
      }}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {actionList?.map((actionItem: ActionItem, index: number) => (
        // eslint-disable-next-line
        <Fragment key={`${actionItem.action}-${index}`}>
          <MenuItem key={actionItem.slug}>
            {defineIconMenuItem(actionItem.action)}
            <MenuItemText data-alt={actionItem?.altApiUrl}>
              {actionItem.content}
            </MenuItemText>
          </MenuItem>
        </Fragment>
      ))}
    </Menu>
  );

  return (
    <DropDownWrapper>
      <div>
        {actionList ? (
          <Dropdown overlay={menu} trigger={['click']} placement='bottomCenter'>
            <button
              type='button'
              className='ant-dropdown-link'
              onClick={(e) => e.preventDefault()}
            >
              <More />
            </button>
          </Dropdown>
        ) : null}
      </div>
    </DropDownWrapper>
  );
};

export default DropDownMenu;
