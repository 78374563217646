import {
  More,
  Verification,
  FilterIcon,
  Notifications,
  SortIcon,
  AccountsIcon,
  UsersIcon,
  Logout,
  Voucher,
  Dashboard,
  DashboardPrev,
  Transactions,
  Deposit,
  Withdraw,
  Settings,
  Search,
  Exclamation,
  Refresh,
  Reports,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Down,
  Up,
  Plus,
  Minus,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  ArrowLeft,
  SelectIcon,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  Share,
  Partners,
  Banks,
  DocumentsIcon,
  Edit,
  CalendarIcon,
  Delete,
  Email,
  CreditCard,
  Close,
  DocumentIcon,
  Sent,
  Received,
  Copy,
  CloseIcon,
  Send,
  Menu,
  Checkmark,
  Print,
  Incomplete,
  Security,
  Merchants,
} from '../../view/static/images';

// eslint-disable-next-line import/prefer-default-export
export const defineMenuItemIcon = (iconName: string, fillColor?: string) => {
  switch (iconName) {
    case 'more':
      return <More fillColor={fillColor} />;
    case 'verification':
      return <Verification fillColor={fillColor} />;
    case 'filter':
      return <FilterIcon fillColor={fillColor} />;
    case 'notifications':
      return <Notifications fillColor={fillColor} />;
    case 'sort':
      return <SortIcon fillColor={fillColor} />;
    case 'accounts':
      return <AccountsIcon fillColor={fillColor} />;
    case 'users':
      return <UsersIcon fillColor={fillColor} />;
    case 'logout':
      return <Logout fillColor={fillColor} />;
    case 'voucher':
      return <Voucher fillColor={fillColor} />;
    case 'dashboard':
      return <Dashboard fillColor={fillColor} />;
    case 'dashboard-prev':
      return <DashboardPrev fillColor={fillColor} />;
    case 'transactions':
      return <Transactions fillColor={fillColor} />;
    case 'deposit':
      return <Deposit fillColor={fillColor} />;
    case 'withdraw':
      return <Withdraw fillColor={fillColor} />;
    case 'settings':
      return <Settings fillColor={fillColor} />;
    case 'search':
      return <Search fillColor={fillColor} />;
    case 'exclamation':
      return <Exclamation fillColor={fillColor} />;
    case 'refresh':
      return <Refresh fillColor={fillColor} />;
    case 'reports':
      return <Reports fillColor={fillColor} />;
    case 'chevron-left':
      return <ChevronLeft fillColor={fillColor} />;
    case 'chevron-right':
      return <ChevronRight fillColor={fillColor} />;
    case 'chevron-down':
      return <ChevronDown fillColor={fillColor} />;
    case 'chevron-up':
      return <ChevronUp fillColor={fillColor} />;
    case 'up':
      return <Up fillColor={fillColor} />;
    case 'down':
      return <Down fillColor={fillColor} />;
    case 'plus':
      return <Plus fillColor={fillColor} />;
    case 'minus':
      return <Minus fillColor={fillColor} />;
    case 'arrow-down':
      return <ArrowDown fillColor={fillColor} />;
    case 'arrow-up':
      return <ArrowUp fillColor={fillColor} />;
    case 'arrow-right':
      return <ArrowRight strokeColor={fillColor} />;
    case 'arrow-left':
      return <ArrowLeft fillColor={fillColor} />;
    case 'select':
      return <SelectIcon fillColor={fillColor} />;
    case 'chevron-double-left':
      return <ChevronDoubleLeft fillColor={fillColor} />;
    case 'chevron-double-right':
      return <ChevronDoubleRight fillColor={fillColor} />;
    case 'share':
      return <Share fillColor={fillColor} />;
    case 'partners':
      return <Partners fillColor={fillColor} />;
    case 'banks':
      return <Banks fillColor={fillColor} />;
    case 'documents':
      return <DocumentsIcon fillColor={fillColor} />;
    case 'edit':
      return <Edit fillColor={fillColor} />;
    case 'calendar':
      return <CalendarIcon fillColor={fillColor} />;
    case 'delete':
      return <Delete fillColor={fillColor} />;
    case 'email':
      return <Email fillColor={fillColor} />;
    case 'credit-card':
      return <CreditCard fillColor={fillColor} />;
    case 'close':
      return <Close fillColor={fillColor} />;
    case 'document':
      return <DocumentIcon fillColor={fillColor} />;
    case 'sent':
      return <Sent fillColor={fillColor} />;
    case 'received':
      return <Received fillColor={fillColor} />;
    case 'copy':
      return <Copy fillColor={fillColor} />;
    case 'close-icon':
      return <CloseIcon fillColor={fillColor} />;
    case 'send':
      return <Send fillColor={fillColor} />;
    case 'menu':
      return <Menu fillColor={fillColor} />;
    case 'checkmark':
      return <Checkmark fillColor={fillColor} />;
    case 'print':
      return <Print fillColor={fillColor} />;
    case 'incomplete':
      return <Incomplete fillColor={fillColor} />;
    case 'security':
      return <Security fillColor={fillColor} />;
    case 'merchants':
      return <Merchants fillColor={fillColor} />;
    default:
      return <DocumentIcon fillColor={fillColor} />;
  }
};
