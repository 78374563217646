import {AxiosResponse} from 'axios';
import {
  getTransactionsData,
  getFilteredTransactionsDataByDateRange,
  getFilteredTransactionsDataQueryString,
} from '../../infra/transactions.service';
import {ThunkActionType} from '../store';
import {
  getTransactionsDataRequestAction,
  getTransactionsDataSuccessAction,
  getTransactionsDataFailureAction,
} from './transactions.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';

export const getTransactionsDataRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getTransactionsDataRequestAction());

  return getTransactionsData()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getTransactionsDataSuccessAction,
        failureAction: getTransactionsDataFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getTransactionsDataFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const getFilteredTransactionsDataByDateRangeRequest =
  (startDate: string, endDate: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getTransactionsDataRequestAction());

    return getFilteredTransactionsDataByDateRange(startDate, endDate)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getTransactionsDataSuccessAction,
          failureAction: getTransactionsDataFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getTransactionsDataFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const getFilteredTransactionsDataQueryStringRequest =
  (slugUrl: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getTransactionsDataRequestAction());

    return getFilteredTransactionsDataQueryString(slugUrl)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getTransactionsDataSuccessAction,
          failureAction: getTransactionsDataFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getTransactionsDataFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
