import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import EmptyCard from './EmptyCard';

import ListView from '../../../components/ListView';
import TopActionsBar from '../../../components/TopActionsBar';
import FormModal from '../../../components/FormModal';
import {RootState} from '../../../../state/store';
import {
  // CRUD REQUESTS
  createIncompleteDataItemRequest,
  storeIncompleteDataItemRequest,
  getIncompleteDataRequest,
  getIncompleteDataItemRequest,
  copyIncompleteDataItemRequest,
  editIncompleteDataItemRequest,
  updateIncompleteDataItemRequest,
  destroyIncompleteDataItemRequest,
  // MISC ACTIONS
  clearIncompleteDataItem,
  clearIncompleteDataItemCopy,
  clearIncompleteDataItemCreate,
  setPageTitleAction,
  clearPaySuccessRes,
  clearErrorState,
  getBadgeCountRequest,
} from '../../../../state';
import {
  createInitialEmptyFormValues,
  createInitialFilledFormValues,
} from '../../../../utils';

import Loader from './Loader';

const IncompleteInvoices = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  const {
    loading,
    incompleteData,
    error,
    fieldsCreateItem,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
    itemDataView,
    itemDataEdit,
    itemDataCopied,
    currentSlug,
  } = useSelector((state: RootState) => state.incompleteInvoicesReducer);

  const [isModalCreateVisible, setIsModalCreateVisible] = useState(
    !!(!loading && fieldsCreateItem),
  );

  const onCloseCreateModal = () => setIsModalCreateVisible(false);

  useEffect(() => {
    dispatch(clearPaySuccessRes());
    dispatch(clearErrorState());
  }, []);

  useEffect(() => {
    setIsModalCreateVisible(!!(!loading && fieldsCreateItem));
  }, [loading, fieldsCreateItem]);

  useEffect(() => {
    if (incompleteData) {
      dispatch(setPageTitleAction(incompleteData.pageTitle));
    }

    const pathnameOmitSlash = location.pathname.substring(
      1,
      location.pathname.length,
    );

    dispatch(setPageTitleAction(pathnameOmitSlash));
  }, [incompleteData]);

  useEffect(() => {
    dispatch(getIncompleteDataRequest());
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  if (error) return <div>{error}</div>;

  if (loading || !incompleteData) return <Loader />;

  const incompletedList = incompleteData.list
    ? [...Object.values(incompleteData.list)]
    : [];

  const incompletedListExtra = incompleteData.listExtra
    ? [...Object.values(incompleteData.listExtra)]
    : [];

  const formattedListData = [...incompletedList, ...incompletedListExtra];

  const loadingsProps = {
    loading,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
  };

  const itemDataProps = {
    itemDataView,
    itemDataEdit,
    itemDataCopied,
  };

  const actionsProps = {
    copyDataItemAction: copyIncompleteDataItemRequest,
    editDataItemAction: editIncompleteDataItemRequest,
    deleteDataItemAction: destroyIncompleteDataItemRequest,
    clearEditFormAction: clearIncompleteDataItem,
    clearCopyFormAction: clearIncompleteDataItemCopy,
    sendEditFormAction: updateIncompleteDataItemRequest,
    sendCopyFormAction: storeIncompleteDataItemRequest,
  };

  if (formattedListData.length === 0) return <EmptyCard />;

  return (
    <div>
      {incompleteData.buttons ? (
        <TopActionsBar
          actions={incompleteData.buttons}
          createDataItemAction={createIncompleteDataItemRequest}
        />
      ) : null}
      <ListView
        data={formattedListData}
        loadings={loadingsProps}
        itemData={itemDataProps}
        actions={actionsProps}
        currentSlug={currentSlug}
        createInitialFormValues={createInitialFilledFormValues}
        getDataItemAction={getIncompleteDataItemRequest}
      />
      {isModalCreateVisible ? (
        <FormModal
          titleModal='Create'
          visible={isModalCreateVisible}
          formFields={fieldsCreateItem}
          currentSlug={currentSlug}
          onClose={onCloseCreateModal}
          clearFormAction={clearIncompleteDataItemCreate}
          createInitialFormValues={createInitialEmptyFormValues}
          sendFormAction={storeIncompleteDataItemRequest}
        />
      ) : null}
    </div>
  );
};

export default IncompleteInvoices;
