import styled from 'styled-components';
import {DatePicker} from 'antd';
import {PickerProps} from 'antd/lib/date-picker/generatePicker';

// eslint-disable-next-line import/prefer-default-export
export const DatePickerWrapper: typeof DatePicker = styled(DatePicker)<
  PickerProps<''>
>`
  &.ant-picker {
    width: 100%;
    min-width: 340px;
    padding: 12px 16px;
    border-radius: 8px;
    border-color: ${(p) => p.theme.main.colors.lightGrey};
    box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);

    @media (max-width: 850px) {
      min-width: 300px;
    }

    @media (max-width: 720px) {
      min-width: 260px;
    }

    @media (max-width: 650px) {
      min-width: 340px;
    }

    @media (max-width: 400px) {
      min-width: 300px;
    }

    @media (max-width: 370px) {
      min-width: 260px;
    }
  }
` as any;
