import {FC} from 'react';
import {Search} from '../../../../static/images';
import {ISearchInputProps} from './SearchInput.types';
import {InputWrapper, Input, IconWrapper} from './SearchInput.styles';

const SearchInput: FC<ISearchInputProps> = ({
  label,
  name,
  value,
  handleChange,
  filterTransactions,
}: ISearchInputProps) => (
  <InputWrapper>
    <Input
      placeholder={label}
      name={name}
      value={value}
      onChange={handleChange}
    />
    <IconWrapper onClick={filterTransactions}>
      <Search fillColor='#BAC0C5' />
    </IconWrapper>
  </InputWrapper>
);

export default SearchInput;
