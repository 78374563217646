import {LinkOutlined} from '@ant-design/icons';
import DropDownMenu from '../DropDownMenu';
import {IHandlerMenuItemArgs} from '../DropDownMenu/DropDownMenu.types';
import {LinkButton, LinkButtonText} from './TableView.styles';

export interface ColumnItem {
  title: string | React.ReactNode;
  dataIndex: string;
}

interface ActionItem {
  action: string;
  content: string;
  slug: string;
}

// eslint-disable-next-line import/prefer-default-export
export const formColumns = (
  columnsList: ColumnItem[],
  // eslint-disable-next-line
  handlerMenuItem: (args: IHandlerMenuItemArgs) => void,
  // eslint-disable-next-line
  goToVerificationPage: (path: string) => void,
  // eslint-disable-next-line
  switchStatusNotification: (id: string) => void,
) =>
  columnsList.map((column) => {
    if (column.dataIndex === 'url') {
      return {
        ...column,
        // eslint-disable-next-line arrow-body-style
        render: (text: string, record: any) => {
          // eslint-disable-next-line react/destructuring-assignment
          const verificationPagePath = text.replace(
            'https://dev.blixti.com',
            '',
          );

          const handleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            switchStatusNotification(record.id);
            goToVerificationPage(verificationPagePath);
          };

          return (
            <LinkButton onClick={handleButton} onDoubleClick={handleButton}>
              <LinkOutlined />
              <LinkButtonText>Link</LinkButtonText>
            </LinkButton>
          );
        },
      };
    }

    if (column.dataIndex === 'actions') {
      return {
        ...column,
        render: (actionsList: ActionItem[]) => {
          const actionListFormatted =
            typeof actionsList === 'string'
              ? JSON.parse(actionsList)
              : actionsList;

          if (actionListFormatted.length === 0) return null;

          return (
            <DropDownMenu
              actionList={actionListFormatted}
              handlerMenuItem={handlerMenuItem}
            />
          );
        },
      };
    }

    return column;
  });
