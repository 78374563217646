import {FC, useState} from 'react';
import {Form} from 'antd';
import {Store} from 'rc-field-form/lib/interface';
import {useIntl} from 'react-intl';
import PersonalFormLayout from '../../../../layouts/PersonalFormLayout';
import {useLocalValidation} from '../../../../hooks';
import {IPersonalFormProps} from './PersonalForm.types';
import {Container, FormWrapper} from './PersonalForm.styles';
import {IFormValues} from '../../../../../state/Verification/verification.types';
import {formTelephone} from '../../../../../utils';

const PersonalForm: FC<IPersonalFormProps> = ({
  form,
  formRef,
  formValues,
  countriesList,
  sendPersonalInfo,
}: IPersonalFormProps) => {
  const {formatMessage} = useIntl();
  const formRulesMap = useLocalValidation(formatMessage);

  const [isSelectInFocus, setSelectInFocus] = useState(false);

  const handleSelectInFocus = (bool: boolean) => () => setSelectInFocus(bool);

  const handleValuesChange = (changedValues: any, values: IFormValues) => {
    const {phone_number, phone_code} = values;
    const telephone = formTelephone({
      phoneNumber: phone_number,
      phoneCode: phone_code,
    });

    form.setFieldsValue({phone_number: telephone});
  };

  const formRules = {
    firstName: formRulesMap.firstNameRules,
    lastName: formRulesMap.lastNameRules,
    dayBirth: formRulesMap.dayBirthRules,
    monthBirth: formRulesMap.monthBirthRules,
    yearBirth: formRulesMap.yearBirthRules,
    phoneNumber: formRulesMap.phoneNumberRules,
    country: formRulesMap.countryRules,
    city: formRulesMap.cityRules,
    address: formRulesMap.addressRules,
    postCode: formRulesMap.postCodeRules,
  };

  return (
    <Container>
      <FormWrapper>
        {Object.keys(formValues).length > 0 ? (
          <Form
            layout='vertical'
            form={form}
            ref={formRef}
            initialValues={formValues as Store}
            preserve={false}
            onFinish={sendPersonalInfo}
            onValuesChange={handleValuesChange}
          >
            <PersonalFormLayout
              isDateFieldRequired
              formRules={formRules}
              isSelectInFocus={isSelectInFocus}
              countriesList={countriesList}
              handleSelectInFocus={handleSelectInFocus}
              initialPhoneCode={formValues.phone_code}
            />
          </Form>
        ) : null}
      </FormWrapper>
    </Container>
  );
};

export default PersonalForm;
