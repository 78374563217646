import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../store';
import {sendPayment} from '../../infra/payment.service';
import {
  sendPaymentRequestAction,
  sendPaymentSuccessAction,
  sendPaymentFailureAction,
} from './payment.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';
import {IReqSendPayment} from './payment.types';

// eslint-disable-next-line import/prefer-default-export
export const sendPaymentRequest = (
  reqBody: IReqSendPayment,
  // eslint-disable-next-line arrow-body-style
): ThunkActionType => {
  return (dispatch) => {
    dispatch(sendPaymentRequestAction());

    return sendPayment(reqBody)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: sendPaymentSuccessAction,
          failureAction: sendPaymentFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendPaymentFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
};
