import {AnyAction} from 'redux';
import {RESET_CHAT_REDUCER} from './misc/actions';
import {
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILURE,
  SET_INTERLOCUTOR_ID,
  CLEAR_CLIENT_LIST,
} from './client/actions';
import {
  GET_ALL_CHATS_REQUEST,
  GET_ALL_CHATS_SUCCESS,
  GET_ALL_CHATS_FAILURE,
  GET_ONE_CHAT_REQUEST,
  GET_ONE_CHAT_SUCCESS,
  GET_ONE_CHAT_FAILURE,
  CHANGE_CHAT_STATUS_REQUEST,
  CHANGE_CHAT_STATUS_SUCCESS,
  CHANGE_CHAT_STATUS_FAILURE,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_FAILURE,
  SET_CHAT_ID,
  SET_CHAT_NAME,
  CLEAR_ONE_CHAT,
} from './chat/actions';
import {
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
} from './message/actions';
import {IChatState} from './chat.types';

const initialState: IChatState = {
  loading: false,
  loadingSendMessage: false,
  loadingDeleteMessage: false,
  loadingOneChat: false,
  loadingChangeChatStatus: false,
  allChats: null,
  oneChat: null,
  sendMessageRes: null,
  deleteMessageRes: null,
  changeChatStatusRes: null,
  deleteChatRes: null,
  clientsMap: null,
  interlocutorId: 0,
  chatId: 0,
  chatName: '',
  error: null,
};

const chatReducer = (
  state: IChatState = initialState,
  action: AnyAction,
): IChatState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_ALL_CHATS_REQUEST:
    case DELETE_CHAT_REQUEST:
    case GET_CLIENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ONE_CHAT_REQUEST:
      return {
        ...state,
        loadingOneChat: true,
        error: null,
      };

    case CHANGE_CHAT_STATUS_REQUEST:
      return {
        ...state,
        loadingChangeChatStatus: true,
        error: null,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        loadingSendMessage: true,
        error: null,
      };

    case DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        loadingDeleteMessage: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_ALL_CHATS_SUCCESS:
      return {
        ...state,
        loading: false,
        allChats: action.payload,
        oneChat: null,
        deleteMessageRes: null,
        error: null,
      };

    case GET_ONE_CHAT_SUCCESS:
      return {
        ...state,
        loadingOneChat: false,
        loadingSendMessage: false,
        loadingDeleteMessage: false,
        loadingChangeChatStatus: false,
        oneChat: action.payload,
        sendMessageRes: null,
        error: null,
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageRes: action.payload,
        error: null,
      };

    case CHANGE_CHAT_STATUS_SUCCESS:
      return {
        ...state,
        changeChatStatusRes: action.payload,
        error: null,
      };

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        deleteMessageRes: action.payload,
        error: null,
      };

    case DELETE_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteChatRes: action.payload,
        interlocutorId: 0,
        chatId: 0,
        chatName: '',
        error: null,
      };

    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsMap: action.payload,
        error: null,
      };

    // MISC ACTIONS
    case CLEAR_CLIENT_LIST:
      return {
        ...state,
        clientsMap: null,
      };

    case CLEAR_ONE_CHAT:
      return {
        ...state,
        oneChat: null,
      };

    case SET_INTERLOCUTOR_ID:
      return {
        ...state,
        interlocutorId: action.payload,
      };

    case SET_CHAT_ID:
      return {
        ...state,
        chatId: action.payload,
      };

    case SET_CHAT_NAME:
      return {
        ...state,
        chatName: action.payload,
      };

    case RESET_CHAT_REDUCER:
      return {
        loading: false,
        loadingSendMessage: false,
        loadingDeleteMessage: false,
        loadingOneChat: false,
        loadingChangeChatStatus: false,
        allChats: null,
        oneChat: null,
        sendMessageRes: null,
        deleteMessageRes: null,
        changeChatStatusRes: null,
        deleteChatRes: null,
        clientsMap: null,
        interlocutorId: 0,
        chatId: 0,
        chatName: '',
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_ALL_CHATS_FAILURE:
    case DELETE_CHAT_FAILURE:
    case GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ONE_CHAT_FAILURE:
      return {
        ...state,
        loadingOneChat: false,
        error: action.payload,
      };

    case CHANGE_CHAT_STATUS_FAILURE:
      return {
        ...state,
        loadingChangeChatStatus: true,
        error: null,
      };

    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loadingSendMessage: false,
        error: action.payload,
      };

    case DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        loadingDeleteMessage: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
