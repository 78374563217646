import {FC, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {RootState} from '../../../../../state/store';
import {
  setPageTitleAction,
  setUploadFileUrl,
  setSendFormUrl,
  uploadFiles,
  deleteFile,
} from '../../../../../state';
import VerificationItem from '../VerificationItem';
import {
  IDeleteFileArgs,
  IUploadFileArgs,
  IVerificationDocItem,
} from '../../../../../state/Verification/verification.types';
import {IVerificationGroupProps} from './VerificationGroup.types';
import {createQueryString} from '../../../../../utils';

const VerificationGroup: FC<IVerificationGroupProps> = ({
  verifyDocsLoad,
}: IVerificationGroupProps) => {
  const formData = new FormData();
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  const {verificationDocumentsData, uploadFileUrl} = useSelector(
    (state: RootState) => state.verificationReducer,
  );

  const [verifyBlocks, setVerifyBlocks] = useState<IVerificationDocItem[]>([]);

  const uploadFile = (uploadFileArgs: IUploadFileArgs): void => {
    const {documentType, files} = uploadFileArgs;

    files.forEach((file) => formData.append(documentType, file));

    dispatch(
      uploadFiles({
        documentType,
        verificationFile: formData,
        uploadUrl: `/${uploadFileUrl}` || '/verification/upload',
      }),
    );
  };

  const deleteUploadedFile = (deleteFileArgs: IDeleteFileArgs): void => {
    const deleteFileQueryString = createQueryString(deleteFileArgs);

    dispatch(
      deleteFile({
        deleteFileQueryString,
        verificationUrl: pathname,
        documentType: deleteFileArgs.group,
      }),
    );
  };

  useEffect(() => {
    if (!verificationDocumentsData) return;
    dispatch(setUploadFileUrl(verificationDocumentsData.slug_file));
    dispatch(setSendFormUrl(verificationDocumentsData.slug_form));
  }, [verificationDocumentsData]);

  useEffect(() => {
    if (!verificationDocumentsData) return;

    dispatch(
      setPageTitleAction(verificationDocumentsData.pageTitle || 'Verification'),
    );
  }, [verificationDocumentsData]);

  useEffect(() => {
    if (!verificationDocumentsData) return;
    setVerifyBlocks(Object.values(verificationDocumentsData.data));
  }, [verificationDocumentsData]);

  return (
    <div>
      {verifyBlocks.map((itm) => (
        <VerificationItem
          key={itm.name}
          title={itm.label}
          subTitle={itm.notification}
          documentType={itm.name}
          uploadedFiles={verifyDocsLoad[itm.name]}
          uploadFile={uploadFile}
          deleteUploadedFile={deleteUploadedFile}
        />
      ))}
    </div>
  );
};

export default VerificationGroup;
