import {AxiosResponse} from 'axios';
import {exportFile} from '../../infra/exportFile.service';
import {ThunkActionType} from '../store';
import {
  exportFileRequestAction,
  exportFileSuccessAction,
  exportFileFailureAction,
} from './exportFile.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';
import {IExportFileReqArgs} from './exportFile.types';

// eslint-disable-next-line import/prefer-default-export
export const exportFileRequest =
  (exportFileReqArgs: IExportFileReqArgs): ThunkActionType =>
  (dispatch) => {
    dispatch(exportFileRequestAction());

    return exportFile(exportFileReqArgs)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: exportFileSuccessAction,
          failureAction: exportFileFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: exportFileFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
