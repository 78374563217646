import {ReactNode} from 'react';
import {ConfigProvider} from 'antd';
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';
import messages from './i18n/messages';
import {RootState} from './state/store';
import {IUserRegisterState} from './state/Auth/auth.types';

const IntlProviderWrapper = ({children}: {children: ReactNode}) => {
  const {locale} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  return (
    <ConfigProvider>
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={locale}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default IntlProviderWrapper;
