import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Spin} from 'antd';
import {RootState} from '../../../../state/store';
import {
  initTopUpRequest,
  initTopUpIdRequest,
  setPageTitleAction,
  getBadgeCountRequest,
} from '../../../../state';
import TopUpFormContainer from './TopUpFormContainer';

const TopUp = () => {
  const dispatch = useDispatch();
  const {id} = useParams<{id: string}>();
  const {loading, topUpList, error} = useSelector(
    (state: RootState) => state.topUpReducer,
  );
  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  useEffect(() => {
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  useEffect(() => {
    if (id) {
      dispatch(initTopUpIdRequest(id));
      return;
    }
    dispatch(initTopUpRequest());
  }, []);

  useEffect(() => {
    if (topUpList) {
      dispatch(setPageTitleAction(topUpList.list.title));
    }
    dispatch(setPageTitleAction('Top Up'));
  }, [topUpList]);

  if (error) return <div>{error}</div>;

  if (loading || !topUpList) {
    return (
      <Spin tip='Loading...' spinning={loading || !topUpList} size='large' />
    );
  }

  return <TopUpFormContainer topUpList={topUpList.list} />;
};

export default TopUp;
