import {FC} from 'react';

// eslint-disable-next-line import/prefer-default-export
export const ProfileCircle: FC = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      d='M28 19C28 21.2091 26.2091 23 24 23C21.7909 23 20 21.2091 20 19C20 16.7909 21.7909 15 24 15C26.2091 15 28 16.7909 28 19Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24 26C20.134 26 17 29.134 17 33H31C31 29.134 27.866 26 24 26Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
