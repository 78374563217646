import {IPaymentFormFieldRes} from '../../state/Auth/auth.types';

// eslint-disable-next-line import/prefer-default-export
export const formatePaymentForm = (fieldsList: IPaymentFormFieldRes[]) => {
  const currencyField = fieldsList.find((itm) => itm.name === 'currency');

  const fieldsListAmountWithCurrency = fieldsList.map((itm) => {
    if (itm.name === 'amount') {
      return {...itm, currency: currencyField?.value};
    }
    return itm;
  });

  const fieldsListWithoutCurrency = fieldsListAmountWithCurrency.filter(
    (itm) => itm.name !== 'currency',
  );

  return fieldsListWithoutCurrency;
};
