import {Dispatch, ActionCreator} from 'redux';

interface IHandleErrorFirstStep {
  error: any;
  dispatch: Dispatch;
  failureAction: ActionCreator<any>;
}

// eslint-disable-next-line import/prefer-default-export
export const handleErrorFirstStep = ({
  error,
  dispatch,
  failureAction,
}: IHandleErrorFirstStep) => {
  const isErrorMessageReceived =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message;

  if (isErrorMessageReceived) {
    //! Fix when message will be string
    if (typeof error.response.data.message !== 'string') {
      return dispatch(
        failureAction(JSON.stringify(error.response.data.message)),
      );
    }
    return dispatch(failureAction(error.response.data.message));
  }

  return dispatch(failureAction('Something went wrong, try later'));
};
