import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row} from 'antd';
import {useIntl} from 'react-intl';
import {socialAuthRequest} from '../../../../../state';
import {FacebookIcon, GoogleIcon} from '../../../../static/images';
import {
  DividerWrapper,
  SocialsButtonWrapper,
  TextWrapper,
} from '../Auth.styles';
import {RootState} from '../../../../../state/store';

interface Props {
  pageTitle: string;
}

const Socials = ({pageTitle}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const {redirectSocialAuth} = useSelector(
    (state: RootState) => state.authReducer,
  );

  useEffect(() => {
    if (!redirectSocialAuth) return;
    window.open(redirectSocialAuth, '_self');
  }, [redirectSocialAuth]);

  return (
    <>
      <DividerWrapper>
        {pageTitle !== 'login'
          ? formatMessage({id: 'CONTINUE_WITH_TEXT'})
          : formatMessage({id: 'LOGIN_WITH_TEXT'})}
      </DividerWrapper>
      <Row justify='space-between'>
        <SocialsButtonWrapper
          onClick={() => dispatch(socialAuthRequest('facebook'))}
        >
          <FacebookIcon />
        </SocialsButtonWrapper>
        <SocialsButtonWrapper
          onClick={() => dispatch(socialAuthRequest('google'))}
        >
          <GoogleIcon />
        </SocialsButtonWrapper>
      </Row>
      <TextWrapper>
        {formatMessage({id: 'PRIVACY_POLICY_TEXT'})}&nbsp;
        <Link to='/voucher-terms'>
          {formatMessage({id: 'TERMS_OF_USE_TITLE'})}
        </Link>
        &nbsp;{formatMessage({id: 'LOGIN_AND'})}&nbsp;{' '}
        <Link to='/privacy-policy'>
          {formatMessage({id: 'PRIVACY_POLICY_TITLE'})}
        </Link>
        .
      </TextWrapper>
    </>
  );
};

export default Socials;
