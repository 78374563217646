import colors from './colors';

const button = {
  default: `
    background-color: ${colors.white};
    color: ${colors.white};
  `,
  brand: `
    background-color: ${colors.brand};
    color: ${colors.white};
  `,
};

export default button;
