import {IPhoneCodesRes} from './phoneCodes.types';

// GET_PHONE_CODES TYPES & ACTIONS
export const GET_PHONE_CODES_REQUEST: string = 'GET_PHONE_CODES_REQUEST';
export const GET_PHONE_CODES_SUCCESS: string = 'GET_PHONE_CODES_SUCCESS';
export const GET_PHONE_CODES_FAILURE: string = 'GET_PHONE_CODES_FAILURE';

export const getPhoneCodesRequestAction = (): {type: string} => ({
  type: GET_PHONE_CODES_REQUEST,
});

export const getPhoneCodesSuccessAction = (payload: IPhoneCodesRes) => ({
  type: GET_PHONE_CODES_SUCCESS,
  payload,
});

export const getPhoneCodesFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_PHONE_CODES_FAILURE,
  payload,
});

// GET_GEO_LOCATION TYPES & ACTIONS
export const GET_GEO_LOCATION_REQUEST: string = 'GET_GEO_LOCATION_REQUEST';
export const GET_GEO_LOCATION_SUCCESS: string = 'GET_GEO_LOCATION_SUCCESS';
export const GET_GEO_LOCATION_FAILURE: string = 'GET_GEO_LOCATION_FAILURE';

export const getGeoLocationRequestAction = (): {type: string} => ({
  type: GET_GEO_LOCATION_REQUEST,
});

export const getGeoLocationSuccessAction = (payload: any) => ({
  type: GET_GEO_LOCATION_SUCCESS,
  payload,
});

export const getGeoLocationFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_GEO_LOCATION_FAILURE,
  payload,
});
