import styled from 'styled-components';

export const NavContainer = styled.div`
  display: none;
  justify-content: space-between;
  padding: 16px 16px 32px;

  background: ${(p) => p.theme.main.colors.white};
  overflow: auto;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 677px) {
    display: flex;
  }
`;

export const NavItem = styled.div`
  min-width: 80px;
  width: 80px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const NavItemText = styled.p`
  ${(p) => p.theme.main.typography.microMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-top: 8px;
`;

export const SubNavItem = styled.div`
  min-width: 60px;
  width: 60px;
  margin-bottom: 15px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;
