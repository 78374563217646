import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'antd';
import {useIntl} from 'react-intl';
import {RootState} from '../../../../../state/store';
import {IProfileMap} from '../../../../../state/Profile/profile.types';
import {changeEmailRequest} from '../../../../../state';
import {useLocalValidation} from '../../../../hooks';
import {
  FormItem,
  InputWrapper,
  InputWrapperPassword,
  InputWrapperDisabled,
} from '../../Auth/Auth.styles';
import {
  FormWrapper,
  Title,
  SubTitle,
  LabelWrapper,
  ButtonGroup,
  ButtonWrapper,
} from './EmailForm.styles';

const EmailForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {data} = useSelector((state: RootState) => state.appReducer);
  const {email} = data.navbar.user;
  const {formatMessage} = useIntl();
  const {passwordProfileRules, emailRules, confirmNewEmailRules} =
    useLocalValidation(formatMessage);

  const [inFocusPasswordInput, setInFocusPasswordInput] = useState(false);
  const [valuePasswordInput, setValuePasswordInput] = useState('');

  const onReset = () => form.resetFields();
  const handleFocusPasswordInput = () => setInFocusPasswordInput(true);

  const handleBlurPasswordInput = () => {
    if (valuePasswordInput.length > 0) {
      setInFocusPasswordInput(true);
      return;
    }

    setInFocusPasswordInput(false);
  };

  const handleChangePasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValuePasswordInput(event.target.value);
  };

  const emailFormHandler = (values: IProfileMap) => {
    const {new_email, current_password} = values;
    dispatch(changeEmailRequest({new_email, current_password}));
  };

  return (
    <FormWrapper>
      <Form
        layout='vertical'
        form={form}
        onFinish={emailFormHandler}
        initialValues={{
          current_password: '',
          current_email: email,
          new_email: '',
          new_email_confirm: '',
        }}
      >
        <LabelWrapper>
          <Title>Enter your password</Title>
          <SubTitle>
            To help protect your account access, please first enter your login
            password to continue with this change.
          </SubTitle>
        </LabelWrapper>
        <FormItem label='' name='current_password' rules={passwordProfileRules}>
          <InputWrapperPassword
            spellCheck='false'
            onFocus={handleFocusPasswordInput}
            onBlur={handleBlurPasswordInput}
            onChange={handleChangePasswordInput}
            placeholder={inFocusPasswordInput ? '' : 'Enter your password'}
            $borderRadius='8px'
            type='text'
            $inFocus={inFocusPasswordInput}
          />
        </FormItem>
        <LabelWrapper>
          <Title>Enter your new email address</Title>
          <SubTitle>
            Please be aware that this email serves as your unique login
            credential and will be used for notifications. You will need to
            verify your new email before the change is applied.
          </SubTitle>
        </LabelWrapper>
        <FormItem label='Current email address' name='current_email'>
          <InputWrapperDisabled
            disabled
            placeholder='Your current email address'
            $borderRadius='8px'
          />
        </FormItem>
        <FormItem label='New email address' name='new_email' rules={emailRules}>
          <InputWrapper
            placeholder='Your new email address'
            $borderRadius='8px'
          />
        </FormItem>
        <FormItem
          label='Confirm new email address'
          name='new_email_confirm'
          rules={confirmNewEmailRules}
        >
          <InputWrapper
            onPaste={(e) => e.preventDefault()}
            placeholder='Confirm your new email address'
            $borderRadius='8px'
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <ButtonWrapper type='primary' htmlType='submit' $borderRadius='8px'>
              Confirm
            </ButtonWrapper>
            <ButtonWrapper
              type='primary'
              htmlType='button'
              $borderRadius='8px'
              onClick={onReset}
            >
              Cancel
            </ButtonWrapper>
          </ButtonGroup>
        </FormItem>
      </Form>
    </FormWrapper>
  );
};

export default EmailForm;
