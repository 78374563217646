import {INotificationMap} from './notification.types';

// SET_NOTIFICATION_STATE TYPES & ACTIONS
export const SET_NOTIFICATION_STATE: string = 'SET_NOTIFICATION_STATE';

export const setNotificationState = (
  payload: INotificationMap,
): {type: string; payload: INotificationMap} => ({
  type: SET_NOTIFICATION_STATE,
  payload,
});
