import {AnyAction} from 'redux';
import {
  CREATE_STANDARD_DATA_ITEM_REQUEST,
  CREATE_STANDARD_DATA_ITEM_SUCCESS,
  CREATE_STANDARD_DATA_ITEM_FAILURE,
  STORE_STANDARD_DATA_ITEM_REQUEST,
  STORE_STANDARD_DATA_ITEM_SUCCESS,
  STORE_STANDARD_DATA_ITEM_FAILURE,
} from './actions/create.actions';
import {
  INITIAL_GET_STANDARD_DATA_REQUEST,
  INITIAL_GET_STANDARD_DATA_SUCCESS,
  INITIAL_GET_STANDARD_DATA_FAILURE,
  GET_STANDARD_DATA_REQUEST,
  GET_STANDARD_DATA_SUCCESS,
  GET_STANDARD_DATA_FAILURE,
  GET_STANDARD_DATA_PER_PAGE_REQUEST,
  GET_STANDARD_DATA_PER_PAGE_SUCCESS,
  GET_STANDARD_DATA_PER_PAGE_FAILURE,
  GET_STANDARD_DATA_ITEM_REQUEST,
  GET_STANDARD_DATA_ITEM_SUCCESS,
  GET_STANDARD_DATA_ITEM_FAILURE,
  COPY_STANDARD_DATA_ITEM_REQUEST,
  COPY_STANDARD_DATA_ITEM_SUCCESS,
  COPY_STANDARD_DATA_ITEM_FAILURE,
} from './actions/read.actions';
import {
  EDIT_STANDARD_DATA_ITEM_REQUEST,
  EDIT_STANDARD_DATA_ITEM_SUCCESS,
  EDIT_STANDARD_DATA_ITEM_FAILURE,
  UPDATE_STANDARD_DATA_ITEM_REQUEST,
  UPDATE_STANDARD_DATA_ITEM_SUCCESS,
  UPDATE_STANDARD_DATA_ITEM_FAILURE,
} from './actions/update.actions';
import {
  DESTROY_STANDARD_DATA_ITEM_REQUEST,
  DESTROY_STANDARD_DATA_ITEM_SUCCESS,
  DESTROY_STANDARD_DATA_ITEM_FAILURE,
} from './actions/delete.actions';
import {
  CLEAR_STANDARD_DATA_ITEM,
  CLEAR_STANDARD_DATA_ITEM_COPY,
  CLEAR_FIELDS_CREATE_DATA_ITEM_FORM,
  FILTER_STANDARD_DATA_REQUEST,
  FILTER_STANDARD_DATA_SUCCESS,
  FILTER_STANDARD_DATA_FAILURE,
  SET_CURRENT_TABLE_PAGE,
  SET_PAGE_SIZE_TABLE,
  CLEAR_DATE_RANGE,
  SET_DATE_RANGE,
  SET_FILTER_DATA_MAP,
  CLEAR_FILTER_DATA_MAP,
} from './actions/misc.actions';
import {IStandardDataState} from './standardized.types';

const initialState: IStandardDataState = {
  loading: false,
  loadingDataItemView: false,
  loadingDataItemEdit: false,
  loadingDataItemCopy: false,
  data: null,
  currentSlug: '',
  currentPageTable: 1,
  pageSizeTable: 20,
  itemDataView: null,
  itemDataEdit: null,
  itemDataCopied: null,
  fieldsCreateItem: null,
  rangePickerValue: null,
  filterDataMap: null,
  error: null,
};

const standardizedReducer = (
  state: IStandardDataState = initialState,
  action: AnyAction,
): IStandardDataState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case INITIAL_GET_STANDARD_DATA_REQUEST:
    case GET_STANDARD_DATA_REQUEST:
    case GET_STANDARD_DATA_PER_PAGE_REQUEST:
    case CREATE_STANDARD_DATA_ITEM_REQUEST:
    case STORE_STANDARD_DATA_ITEM_REQUEST:
    case UPDATE_STANDARD_DATA_ITEM_REQUEST:
    case DESTROY_STANDARD_DATA_ITEM_REQUEST:
    case FILTER_STANDARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_STANDARD_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemView: true,
        error: null,
      };

    case COPY_STANDARD_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemCopy: true,
        error: null,
      };

    case EDIT_STANDARD_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemEdit: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_STANDARD_DATA_PER_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case INITIAL_GET_STANDARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPageTable: 1,
        pageSizeTable: 20,
        data: action.payload,
        error: null,
      };

    case GET_STANDARD_DATA_SUCCESS:
    case FILTER_STANDARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case GET_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemView: false,
        itemDataView: action.payload,
        error: null,
      };

    case COPY_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemCopy: false,
        currentSlug: action.payload.slug,
        itemDataCopied: action.payload.fields,
        error: null,
      };

    case CREATE_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: action.payload.slug,
        fieldsCreateItem: action.payload.fields,
        error: null,
      };

    case STORE_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        fieldsCreateItem: null,
        itemDataCopied: null,
        error: null,
      };

    case EDIT_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemEdit: false,
        currentSlug: action.payload.slug,
        itemDataEdit: action.payload.fields,
        error: null,
      };

    case UPDATE_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: null,
      };

    case DESTROY_STANDARD_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: null,
      };

    // MISC ACTIONS
    case CLEAR_FIELDS_CREATE_DATA_ITEM_FORM:
      return {
        ...state,
        currentSlug: '',
        fieldsCreateItem: null,
      };

    case CLEAR_STANDARD_DATA_ITEM:
      return {
        ...state,
        itemDataEdit: null,
      };

    case CLEAR_STANDARD_DATA_ITEM_COPY:
      return {
        ...state,
        itemDataCopied: null,
      };

    case SET_CURRENT_TABLE_PAGE:
      return {
        ...state,
        currentPageTable: action.payload,
      };

    case SET_PAGE_SIZE_TABLE:
      return {
        ...state,
        pageSizeTable: action.payload,
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        rangePickerValue: action.payload,
      };

    case CLEAR_DATE_RANGE:
      return {
        ...state,
        rangePickerValue: null,
      };

    case SET_FILTER_DATA_MAP:
      return {
        ...state,
        filterDataMap: action.payload,
      };

    case CLEAR_FILTER_DATA_MAP:
      return {
        ...state,
        filterDataMap: null,
      };

    // FAILURE REQUEST ACTIONS
    case COPY_STANDARD_DATA_ITEM_FAILURE:
      return {
        ...state,
        loadingDataItemCopy: false,
        error: action.payload,
      };

    case CREATE_STANDARD_DATA_ITEM_FAILURE:
    case STORE_STANDARD_DATA_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        fieldsCreateItem: null,
        itemDataCopied: null,
        error: action.payload,
      };

    case EDIT_STANDARD_DATA_ITEM_FAILURE:
    case UPDATE_STANDARD_DATA_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: action.payload,
      };

    case GET_STANDARD_DATA_ITEM_FAILURE:
      return {
        ...state,
        loadingDataItemView: false,
        error: action.payload,
      };

    case INITIAL_GET_STANDARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        currentPageTable: 1,
        pageSizeTable: 20,
        error: action.payload,
      };

    case GET_STANDARD_DATA_FAILURE:
    case DESTROY_STANDARD_DATA_ITEM_FAILURE:
    case FILTER_STANDARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_STANDARD_DATA_PER_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default standardizedReducer;
