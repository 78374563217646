import {AxiosResponse} from 'axios';
import {verifyEmail} from '../../../infra/auth.service';
import {ThunkActionType} from '../../store';
import {
  verifyEmailRequestAction,
  verifyEmailSuccessAction,
  verifyEmailFailureAction,
} from '../actions/verify.email';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const verifyEmailRequest =
  (verifyUrl: string): ThunkActionType =>
  (dispatch) => {
    dispatch(verifyEmailRequestAction());

    return verifyEmail(verifyUrl)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: verifyEmailSuccessAction,
          failureAction: verifyEmailFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: verifyEmailFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
