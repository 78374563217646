import {useState} from 'react';

interface IProgressMap {
  [key: string]: number;
}

// eslint-disable-next-line import/prefer-default-export
export const useCountdown = () => {
  const intervalMs = 1000;
  const maxProgress = 5;
  const stepProgress = 1;

  const [progressMap, setProgressMap] = useState<IProgressMap>({});
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const resetProgressMap = (): void => {
    if (!timer) return;
    clearInterval(timer);
    setTimer(undefined);
    setProgressMap({});
  };

  const startCountdown = (fieldName: string): void => {
    // Run countdown initially
    if (!timer) {
      countdown(fieldName);
      return;
    }

    // Rerun countdown
    clearInterval(timer);
    setTimer(undefined);
    countdown(fieldName);
  };

  const countdown = (fieldName: string): void => {
    const stopProgressing = () => clearInterval(interval);

    const interval = setInterval(() => {
      setProgressMap((prevProgressMap) => {
        const resettedProgressMap = Object.entries(prevProgressMap).reduce(
          (acc: IProgressMap, itm) => {
            const [key, value] = itm;

            if (key === fieldName) {
              acc[key] = value;
            } else {
              acc[key] = 0;
            }

            return acc;
          },
          {},
        );

        if (prevProgressMap[fieldName] === maxProgress) {
          stopProgressing();
          return {
            ...resettedProgressMap,
            [fieldName]: maxProgress,
          };
        }

        if (prevProgressMap[fieldName] < maxProgress) {
          return {
            ...resettedProgressMap,
            [fieldName]: prevProgressMap[fieldName] + stepProgress,
          };
        }

        return {...resettedProgressMap, [fieldName]: 1};
      });
    }, intervalMs);

    setTimer(interval);
  };

  return {progressMap, maxProgress, startCountdown, resetProgressMap};
};
