import {FC, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dropdown, Menu, Spin} from 'antd';
import Calendar from '../Calendar';
import {ActionItem} from '../../../state/Standardized/standardized.types';
import {
  RegularButton,
  FilterButton,
  ButtonText,
  ButtonTextIcon,
  Container,
  IconWrapper,
  ExportFileButtonText,
  FiltersButtonText,
  AddButtonText,
} from './TopActionsBar.styles';
import {ITopActionsBarProps} from './TopActionsBar.types';
import {FilterIcon, More, Plus} from '../../static/images';
import {RootState} from '../../../state/store';

const TopActionsBar: FC<ITopActionsBarProps> = ({
  actions,
  exportFileOptions,
  loadingExportFile,
  handleFiltersVisible,
  createDataItemAction,
  filterDataAction,
  exportFileAction,
  getFormViewAction,
}: ITopActionsBarProps) => {
  const dispatch = useDispatch();
  const {tableName} = useParams<{tableName: string}>();
  const {filterDataMap} = useSelector(
    (state: RootState) => state.standardizedReducer,
  );

  const menu = (
    <Menu
      onClick={({key}) => {
        if (exportFileAction) {
          dispatch(exportFileAction({tableName, typeFile: key}));
        }
      }}
    >
      {exportFileOptions?.options.map((itm) => (
        <Fragment key={itm.value}>
          <Menu.Item key={itm.value}>
            <span>{itm.label}</span>
          </Menu.Item>
        </Fragment>
      ))}
    </Menu>
  );

  return (
    <Container>
      {actions.map((actionItem: ActionItem) => {
        if (actionItem.action === 'create') {
          return (
            <RegularButton
              key={actionItem.action}
              type='button'
              onClick={() => {
                dispatch(createDataItemAction(actionItem.slug));
              }}
            >
              <Plus />
              <AddButtonText marginLeft='8px'>
                {actionItem.content || 'Add'}
              </AddButtonText>
            </RegularButton>
          );
        }

        if (actionItem.action === 'filter' && handleFiltersVisible) {
          return (
            <FilterButton
              key={actionItem.action}
              type='button'
              onClick={() => handleFiltersVisible(true)}
              isSelected={!!filterDataMap}
            >
              <FilterIcon fillColor={filterDataMap ? '#FFF' : '#74828A'} />
              <FiltersButtonText marginLeft='8px' isSelected={!!filterDataMap}>
                Filters
              </FiltersButtonText>
            </FilterButton>
          );
        }

        if (actionItem.action === 'date-range' && filterDataAction) {
          return (
            <Fragment key={actionItem.action}>
              <Calendar filterDataAction={filterDataAction} />
            </Fragment>
          );
        }

        if (
          actionItem.action === 'export' &&
          exportFileAction &&
          exportFileOptions
        ) {
          return (
            <Dropdown
              key={actionItem.action}
              overlay={menu}
              trigger={['click']}
            >
              <RegularButton type='button'>
                <ButtonTextIcon>
                  {loadingExportFile ? (
                    <IconWrapper>
                      <Spin size='small' spinning={loadingExportFile} />
                    </IconWrapper>
                  ) : (
                    <IconWrapper>
                      <More />
                    </IconWrapper>
                  )}
                  <ExportFileButtonText>More</ExportFileButtonText>
                </ButtonTextIcon>
              </RegularButton>
            </Dropdown>
          );
        }

        if (actionItem.action === 'form' && getFormViewAction) {
          return (
            <RegularButton
              key={actionItem.action}
              type='button'
              onClick={() =>
                dispatch(getFormViewAction({slugUrl: actionItem.slug}))
              }
            >
              <ButtonText>Form</ButtonText>
            </RegularButton>
          );
        }

        return (
          <RegularButton key={actionItem.action} type='button'>
            <ButtonText>{actionItem.content || 'Click'}</ButtonText>
          </RegularButton>
        );
      })}
    </Container>
  );
};

export default TopActionsBar;
