import {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'antd';
import {RootState} from '../../../state/store';
import {logoutUser, setTimestampReloadAction} from '../../../state';
import {
  Logout,
  Settings,
  Verification,
  DocumentIcon,
  Email,
  Refresh,
  UserCircle,
  Down,
} from '../../static/images';
import logoIco from '../../styles/img/logo-mobile.png';
import {IHeaderProps} from './Header.types';
import {
  MenuItemText,
  MenuItemInner,
  StyledMenu,
  UserDetailsDropdownItem,
  UserDetailsDropdownItemContact,
  AvatarWrapper,
  AvatarWrapperDefault,
  HeaderWrapper,
  HeaderTitle,
  HeaderBar,
  RefreshButtonDesktop,
  RefreshButtonMobile,
  UserData,
  UserDataDetailsWrapper,
  UserDataDetails,
  UserDataDetailsEmail,
  HeaderLogo,
  BurgerButton,
  BurgerNav,
  CopyrightMenuItem,
  UserDetailsDropdownItemCopyright,
  HeaderTitleWrapper,
  StyledTooltip,
  IconWrapper,
} from './Header.styles';

const HeaderContainer: FC<IHeaderProps> = ({
  navbar,
  isDashboardMenuItemExist,
  setActiveNavLink,
  setActiveTitle,
}: IHeaderProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {pageTitle} = useSelector((state: RootState) => state.appReducer);

  const [isNavBurgerOpen, setNavBurgerOpen] = useState(false);

  const setTimestampReload = () => {
    dispatch(setTimestampReloadAction(Date.now()));
  };

  const defineMenuIcon = (iconName: string) => {
    switch (iconName) {
      case 'verification':
        return <Verification fillColor='#74828A' />;
      case 'settings':
        return <Settings fillColor='#74828A' />;
      case 'contact_us':
        return <Email fillColor='#74828A' />;
      case 'logout':
        return <Logout fillColor='#74828A' />;
      default:
        return <DocumentIcon fillColor='#74828A' />;
    }
  };

  const handleContactUsLink = () => setNavBurgerOpen(false);

  const handlerDropdownItem = (slug: string) => () => {
    setNavBurgerOpen(false);
    setActiveNavLink('');
    setActiveTitle('');

    if (slug.indexOf('logout') >= 0) {
      return dispatch(logoutUser());
    }

    return history.push(slug);
  };

  const goToStartPage = () =>
    isDashboardMenuItemExist
      ? history.push('/dashboard')
      : history.push('/welcome');

  const navbarCopyRight = [
    ...navbar.children,
    {icon: null, title: 'copyright', slug: ''},
  ];

  const menu = (
    <StyledMenu>
      {navbarCopyRight.map(
        (item: {icon: string | null; title: string; slug: string}) => {
          if (item.title.toLowerCase() === 'contact us') {
            return (
              <UserDetailsDropdownItem
                key={item.title}
                title={item.title}
                onClick={handleContactUsLink}
              >
                <UserDetailsDropdownItemContact
                  key={item.title}
                  href='mailto:support@blixti.com'
                  rel='noreferrer'
                  target='_blank'
                >
                  <MenuItemInner>
                    {defineMenuIcon(item.icon || 'default')}
                    <MenuItemText>{item.title}</MenuItemText>
                  </MenuItemInner>
                </UserDetailsDropdownItemContact>
              </UserDetailsDropdownItem>
            );
          }

          if (item.title === 'copyright') {
            return (
              <UserDetailsDropdownItemCopyright
                key={item.title}
                title={item.title}
              >
                <CopyrightMenuItem>Copyright © 2021 Blixti</CopyrightMenuItem>
              </UserDetailsDropdownItemCopyright>
            );
          }

          return (
            <UserDetailsDropdownItem
              key={item.title}
              title={item.title}
              onClick={handlerDropdownItem(item.slug)}
            >
              <MenuItemInner>
                {defineMenuIcon(item.icon || 'default')}
                <MenuItemText>{item.title}</MenuItemText>
              </MenuItemInner>
            </UserDetailsDropdownItem>
          );
        },
      )}
    </StyledMenu>
  );

  const avatarImg = () => {
    if (navbar.user?.avatar) {
      return <AvatarWrapper size={40} src={navbar.user.avatar} />;
    }

    return (
      <AvatarWrapperDefault>
        <UserCircle />
      </AvatarWrapperDefault>
    );
  };

  return (
    <HeaderWrapper>
      <HeaderTitleWrapper>
        <HeaderTitle>{pageTitle}</HeaderTitle>
        <StyledTooltip
          title='Refresh'
          overlayClassName='tooltip-refresh-button'
        >
          <RefreshButtonDesktop onClick={setTimestampReload}>
            <Refresh fillColor='#74828A' />
          </RefreshButtonDesktop>
        </StyledTooltip>
      </HeaderTitleWrapper>
      <HeaderLogo onClick={goToStartPage}>
        <img src={logoIco} alt='logo' />
      </HeaderLogo>
      <HeaderBar>
        <UserData>
          <RefreshButtonMobile onClick={setTimestampReload}>
            <Refresh fillColor='#74828A' />
          </RefreshButtonMobile>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            overlayClassName='header-dropdown'
            onVisibleChange={() => setNavBurgerOpen(!isNavBurgerOpen)}
          >
            <div>
              <BurgerButton onClick={() => setNavBurgerOpen(!isNavBurgerOpen)}>
                <BurgerNav isOpen={isNavBurgerOpen} />
              </BurgerButton>
              <UserDataDetailsWrapper>
                {avatarImg()}
                <UserDataDetails>
                  <UserDataDetailsEmail>
                    {navbar.user.email}
                  </UserDataDetailsEmail>
                </UserDataDetails>
                <IconWrapper isOpen={isNavBurgerOpen}>
                  <Down />
                </IconWrapper>
              </UserDataDetailsWrapper>
            </div>
          </Dropdown>
        </UserData>
      </HeaderBar>
    </HeaderWrapper>
  );
};

export default HeaderContainer;
