import {IExportFileRes} from './exportFile.types';

// EXPORT_FILE TYPES & ACTIONS
export const EXPORT_FILE_REQUEST: string = 'EXPORT_FILE_REQUEST';
export const EXPORT_FILE_SUCCESS: string = 'EXPORT_FILE_SUCCESS';
export const EXPORT_FILE_FAILURE: string = 'EXPORT_FILE_FAILURE';

export const exportFileRequestAction = (): {type: string} => ({
  type: EXPORT_FILE_REQUEST,
});

export const exportFileSuccessAction = (payload: IExportFileRes) => ({
  type: EXPORT_FILE_SUCCESS,
  payload,
});

export const exportFileFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: EXPORT_FILE_FAILURE,
  payload,
});

// CLEAR_EXPORT_FILE_RES TYPES & ACTIONS
export const CLEAR_EXPORT_FILE_RES: string = 'CLEAR_EXPORT_FILE_RES';

export const clearExportFileRes = (): {type: string} => ({
  type: CLEAR_EXPORT_FILE_RES,
});
