import {FC} from 'react';
import {ICalendarBottomPanelProps} from './CalendarBottomPanel.types';
import {CalendarPanelInfo, StyledButton} from './CalendarBottomPanel.styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const CalendarBottomPanel: FC<ICalendarBottomPanelProps> = ({
  setFocusedInput,
  filterDataByDateRange,
  handleStartDateChange,
  handleEndDateChange,
}: ICalendarBottomPanelProps): JSX.Element => (
  <CalendarPanelInfo>
    <StyledButton
      type='button'
      onClick={() => {
        setFocusedInput(null);
        filterDataByDateRange();
      }}
    >
      Send date
    </StyledButton>
    <StyledButton
      type='button'
      onClick={() => {
        handleStartDateChange(null);
        handleEndDateChange(null);
        setFocusedInput('startDate');
      }}
    >
      Cancel
    </StyledButton>
  </CalendarPanelInfo>
);

export default CalendarBottomPanel;
