import {FC, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../state/store';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';
import LoginForm from './LoginForm';
import LoginTitle from './LoginTitle';
import SocialsProviders from '../../Auth/SocialsProviders';
import {getLocalAccessToken} from '../../../../../infra/token.service';

const LoginBeforePayment: FC = (): JSX.Element => {
  const history = useHistory();
  const {search} = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const {isAuthenticated} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  useEffect(() => {
    const isAuthenticatedCondition = isAuthenticated && !!getLocalAccessToken();

    if (!isAuthenticatedCondition) return;

    const checkBalance = urlSearchParams.get('check_balance') || '';
    const invoiceId = urlSearchParams.get('inv_id') || '';
    const isLacksBalance = checkBalance && checkBalance === '0';
    const condRedirectToPaymentMethods = isLacksBalance && invoiceId;

    if (condRedirectToPaymentMethods) {
      localStorage.setItem('firstStepAuthStepper', 'login');
      history.push(`payment-method/${invoiceId}`);
    }
  }, [isAuthenticated]);

  return (
    <div className='form-container'>
      <LoginTitle />
      <LoginForm />
      <SocialsProviders pageTitle='login' />
    </div>
  );
};

export default LoginBeforePayment;
