import {AnyAction} from 'redux';
import {
  GET_PHONE_CODES_REQUEST,
  GET_PHONE_CODES_SUCCESS,
  GET_PHONE_CODES_FAILURE,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
} from './phoneCodes.actions';
import {IPhoneCodesState} from './phoneCodes.types';

const initialState: IPhoneCodesState = {
  loading: false,
  phoneCodes: null,
  geoLocation: null,
  error: null,
  errorGeoLocation: null,
  errorPhoneCodes: null,
};

const phoneCodesReducer = (
  state: IPhoneCodesState = initialState,
  action: AnyAction,
): IPhoneCodesState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_PHONE_CODES_REQUEST:
    case GET_GEO_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_PHONE_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        phoneCodes: action.payload,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_GEO_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        geoLocation: action.payload,
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_PHONE_CODES_FAILURE:
      return {
        ...state,
        loading: false,
        errorPhoneCodes: action.payload,
      };

    case GET_GEO_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorGeoLocation: action.payload,
      };

    default:
      return state;
  }
};

export default phoneCodesReducer;
