import styled from 'styled-components';
import {Button} from 'antd';

export const ButtonGroup = styled.div`
  min-width: 228px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    margin-left: 0;
  }

  @media (max-width: 400px) {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled(Button)`
  min-width: 104px;
  height: 48px;
  padding: 10px 24px;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  color: ${(p) => p.theme.main.colors.black};
  text-transform: capitalize;
  border-color: ${(p) => p.theme.main.colors.lightPurple};
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.lightPurple};

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  &:active,
  &:focus {
    background-color: ${(p) => p.theme.main.colors.brand};
    border-color: ${(p) => p.theme.main.colors.lightPurple};
    color: ${(p) => p.theme.main.colors.white};
  }

  @media (max-width: 900px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 800px) {
    &:first-child {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 40px;
    padding: 8px 24px;
  }

  @media (max-width: 325px) {
    min-width: unset;
    padding: 8px;
  }
`;
