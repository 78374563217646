import {ISendFormArgs} from '../state/FormConstructor/formConstructor.types';
import api from './config';

const getFormView = (slugUrl: string) => api.get(slugUrl);

const postService = ({slugUrl, body}: ISendFormArgs) =>
  api.post(slugUrl, {...body});

const deleteService = ({slugUrl, body}: ISendFormArgs) =>
  api.delete(slugUrl, {...body});

// eslint-disable-next-line import/prefer-default-export
export {getFormView, postService, deleteService};
