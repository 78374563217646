import {
  destroyIncompleteDataItemRequestAction,
  destroyIncompleteDataItemSuccessAction,
  destroyIncompleteDataItemFailureAction,
} from '../actions/delete.actions';
import {destroyIncompleteInvoice} from '../../../infra/incompleteInvoices.service';
import {ThunkActionType} from '../../store';
import {getIncompleteDataRequest} from './read.requests';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseExtraAction,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const destroyIncompleteDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch, getState) => {
    const {currentPageTable, pageSizeTable} = getState().standardizedReducer;
    const idxSlash = slug.indexOf('/');
    const slugGetAllItem = slug.substring(0, idxSlash);
    const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

    dispatch(destroyIncompleteDataItemRequestAction());
    return destroyIncompleteInvoice(slug)
      .then((response) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: destroyIncompleteDataItemSuccessAction,
          failureAction: destroyIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getIncompleteDataRequest,
          extraRequestArg: slugPagination,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: destroyIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
