import {useEffect, useState} from 'react';
import {
  IFormValues,
  IFormViewRes,
  IHandleValueChangeArgs,
  ISendFormArgs,
} from '../../../state/FormConstructor/formConstructor.types';
import {checkRequiredFields, omitEmptyValue} from '../../../utils';

interface IActionsMap {
  sendFormAction: (args: ISendFormArgs) => void; // eslint-disable-line
  deleteRequestAction: (args: ISendFormArgs) => void; // eslint-disable-line
}

interface IUseFormHandler {
  apiUrl: string | undefined;
  formViewMap: IFormViewRes | null;
  sendFormRes?: IFormValues | null;
  actions: IActionsMap;
}

// eslint-disable-next-line import/prefer-default-export
export const useFormHandler = (argsHook: IUseFormHandler) => {
  const {apiUrl, formViewMap, sendFormRes, actions} = argsHook;
  const {sendFormAction, deleteRequestAction} = actions;
  const [formValues, setFormValues] = useState<IFormValues>({});
  const [formHasError, setFormHasError] = useState(false);

  const handleValueChange = (args: IHandleValueChangeArgs): void => {
    const {keyName, value} = args;
    setFormValues({...formValues, [keyName]: value});
  };

  const submitForm = (): void => {
    if (!formViewMap) return;

    const someRequiredFieldEmpty = checkRequiredFields({
      formValues,
      formItemList: formViewMap.data,
    });

    setFormHasError(someRequiredFieldEmpty);

    if (someRequiredFieldEmpty) return;

    const {expired_month, expired_year, ...rest} = omitEmptyValue(formValues);

    const expired_date =
      expired_month && expired_year
        ? `${expired_month}/${expired_year}`
        : false;

    const resultFormValues = expired_date ? {...rest, expired_date} : rest;

    const sendFormValues = {
      ...resultFormValues,
      type: formViewMap.type_output,
      name: formViewMap.name,
      url_form: formViewMap.url_form,
    };

    if (formViewMap.url_form) {
      if (!formViewMap.method) {
        sendFormAction({slugUrl: formViewMap.url_form, body: sendFormValues});
        return;
      }

      if (formViewMap.url_form.includes('$') && apiUrl) {
        const dynamicUrl = formViewMap.url_form.replace('$', apiUrl);
        if (formViewMap.method === 'delete') {
          deleteRequestAction({slugUrl: dynamicUrl, body: sendFormValues});
          return;
        }
        return;
      }
      return;
    }

    const submitBtn = formViewMap.data.find(
      (itm) => itm.type === 'button' && itm.url,
    );

    if (submitBtn && submitBtn.url) {
      if (!formViewMap.method) {
        sendFormAction({slugUrl: submitBtn.url, body: sendFormValues});
        return;
      }
      if (submitBtn.url.includes('$') && apiUrl) {
        const dynamicUrl = submitBtn.url.replace('$', apiUrl);
        if (formViewMap.method === 'delete') {
          deleteRequestAction({slugUrl: dynamicUrl, body: sendFormValues});
        }
      }
    }
  };

  useEffect(() => {
    if (!formViewMap) return;
    const initFormValues = formViewMap.data.reduce((acc: IFormValues, itm) => {
      if (itm.type === 'button') return acc;

      if (itm.name === 'expired_date' && !itm.value) {
        acc.expired_month = '';
        acc.expired_year = '';
        return acc;
      }

      if (itm.name === 'expired_date' && itm.value) {
        const [month, year] = itm.value.split('/');
        acc.expired_month = month;
        acc.expired_year = year;
        return acc;
      }

      if (itm.type === 'radio' && itm.radio_items && !itm.value) {
        acc[itm.name] = itm.radio_items[0].value;
        return acc;
      }

      acc[itm.name] = itm.value || '';
      return acc;
    }, {});

    setFormValues(initFormValues);
  }, [formViewMap]);

  useEffect(() => {
    if (!sendFormRes) return;

    const resettedFormValues = Object.keys(formValues).reduce(
      (acc: IFormValues, itm) => {
        acc[itm] = '';
        return acc;
      },
      {},
    );

    setFormValues(resettedFormValues);
  }, [sendFormRes]);

  return {formValues, formHasError, handleValueChange, submitForm};
};
