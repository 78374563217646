import {FC} from 'react';
import CollapseList from '../CollapseList';
import {PeriodDivider} from './TransactionsList.styles';
import {ITransactionsListProps} from './TransactionsList.types';

const TransactionsList: FC<ITransactionsListProps> = ({
  listItems,
}: ITransactionsListProps) => (
  <div>
    {listItems.length > 0
      ? listItems.map((listItem) => (
          <div key={listItem.id}>
            <PeriodDivider>{listItem.period}</PeriodDivider>
            <div>
              {listItem.activitiesList.map((activityItem: any) => (
                <CollapseList
                  key={activityItem.id}
                  id={activityItem.id}
                  currency={
                    activityItem.currency
                      ? activityItem.currency.toUpperCase()
                      : null
                  }
                  contractorInfo={activityItem.contractorInfo}
                  paymentPeriod={activityItem.paymentPeriod}
                  paymentAmount={activityItem.paymentAmount}
                  transactionId={activityItem.transactionId}
                  paymentMethod={activityItem.paymentMethod}
                  paymentStatus={activityItem.paymentStatus}
                  isPaymentReceived={activityItem.isPaymentReceived}
                />
              ))}
            </div>
          </div>
        ))
      : null}
  </div>
);

export default TransactionsList;
