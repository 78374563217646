import styled from 'styled-components';

export const PopoverTitle = styled.p`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 10px;
`;

export const PopoverButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const PopoverButton = styled.button<{bgColor: string}>`
  padding: 5px 15px;
  border-radius: 2px;
  border: 1px solid #e3e1ff;
  background-color: ${(p) => p.bgColor};
  transition: background 0.3s linear;

  &:hover {
    background-color: #c7c2ff;
  }
`;

export const PopoverButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
`;
