import {AxiosResponse} from 'axios';
import {filterStandardData} from '../../../infra/standardized.service';
import {ThunkActionType} from '../../store';
import {
  filterStandardDataFailureAction,
  filterStandardDataRequestAction,
  filterStandardDataSuccessAction,
} from '../actions/misc.actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const filterStandardDataRequest =
  (slugUrl: string): ThunkActionType =>
  (dispatch) => {
    dispatch(filterStandardDataRequestAction());
    return filterStandardData(slugUrl)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: filterStandardDataSuccessAction,
          failureAction: filterStandardDataFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: filterStandardDataFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
