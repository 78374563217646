import {
  IFormItem,
  IFormValues,
} from '../../state/FormConstructor/formConstructor.types';

interface ICheckRequiredFieldsArgs {
  formValues: IFormValues;
  formItemList: IFormItem[];
}

// eslint-disable-next-line import/prefer-default-export
export const checkRequiredFields = (
  args: ICheckRequiredFieldsArgs,
): boolean => {
  const {formValues, formItemList} = args;

  // eslint-disable-next-line array-callback-return, consistent-return
  return Object.entries(formValues).some((formItem) => {
    const foundRequiredField = formItemList.find(
      (el) => el.name === formItem[0] && el.required,
    );

    if (
      foundRequiredField &&
      typeof formItem[1] === 'string' &&
      formItem[1].length === 0
    ) {
      return true;
    }
  });
};
