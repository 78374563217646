import {FC} from 'react';
import {Form} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useLocalValidation} from '../../../../hooks';
import {
  FormItem,
  FormItemButton,
  InputWrapper,
  InputPasswordWrapper,
  ButtonWrapper,
  ForgotPasswordText,
} from '../Auth.styles';
import {ILoginUserData} from '../Auth.types';
import {RootState} from '../../../../../state/store';
import {IUserRegisterState} from '../../../../../state/Auth/auth.types';
import {loginUser} from '../../../../../state';

const LoginForm: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const {formatMessage} = useIntl();
  const {emailRules} = useLocalValidation(formatMessage);
  const dispatch = useDispatch();
  const {loading} = useSelector(
    (state: RootState): IUserRegisterState => state.authReducer,
  );

  const loginUserHandler = (values: ILoginUserData): void => {
    dispatch(loginUser(values.email, values.password));
  };

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={loginUserHandler}
      initialValues={{email: '', password: ''}}
    >
      <FormItem
        label={formatMessage({id: 'EMAIL_INPUT_FIELD'})}
        name='email'
        rules={emailRules}
      >
        <InputWrapper
          type='email'
          autoComplete='off'
          $borderRadius='8px'
          placeholder={formatMessage({id: 'EMAIL_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <FormItem
        label={formatMessage({id: 'PASSWORD_INPUT_FIELD'})}
        name='password'
      >
        <InputPasswordWrapper
          $borderRadius='8px'
          placeholder={formatMessage({id: 'PASSWORD_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <ForgotPasswordText to='/forgot-password'>
        {formatMessage({id: 'FORGOT_PASSWORD_LINK'})}
      </ForgotPasswordText>
      <FormItemButton>
        <ButtonWrapper
          type='primary'
          htmlType='submit'
          $borderRadius='8px'
          loading={loading}
        >
          {formatMessage({id: 'LOGIN_BUTTON'})}
        </ButtonWrapper>
      </FormItemButton>
    </Form>
  );
};

export default LoginForm;
