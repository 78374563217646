// eslint-disable-next-line import/prefer-default-export
export const OpenedLetterColored = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M39 18V20M20 2L14.1538 6M1 20V37C1 38.1046 1.89543 39 3 39H37C38.1046 39 39 38.1046 39 37V20M1 20V16.0549C1 15.3947 1.32578 14.7771 1.87063 14.4043L6 11.5789M1 20L6 21.8421M39 20L20 27L6 21.8421M5 31H10M5 35H14M19 6H14.1538M6 21.8421V11.5789M10 10H19M10 14H19M10 18H19M14.1538 6H8C6.89543 6 6 6.89543 6 8V11.5789M27 9L30 12L35 7M39 9C39 13.4183 35.4183 17 31 17C26.5817 17 23 13.4183 23 9C23 4.58172 26.5817 1 31 1C35.4183 1 39 4.58172 39 9Z'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
