import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Delete: FC<IProps> = ({fillColor = '#74828A'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0C6.62123 0 6.27497 0.214002 6.10557 0.552786L5.38197 2H2C1.44772 2 1 2.44772 1 3C1 3.55228 1.44772 4 2 4L2 14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V4C14.5523 4 15 3.55228 15 3C15 2.44772 14.5523 2 14 2H10.618L9.89443 0.552786C9.72504 0.214002 9.37877 0 9 0H7ZM5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6V12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12V6ZM10 5C9.44771 5 9 5.44772 9 6V12C9 12.5523 9.44771 13 10 13C10.5523 13 11 12.5523 11 12V6C11 5.44772 10.5523 5 10 5Z'
      fill={fillColor}
    />
  </svg>
);
