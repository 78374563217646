import {AxiosResponse} from 'axios';
import {
  copyIncompleteInvoice,
  getIncompleteInvoices,
  getIncompleteInvoice,
} from '../../../infra/incompleteInvoices.service';
import {ThunkActionType} from '../../store';
import {
  getIncompleteDataSuccessAction,
  getIncompleteDataFailureAction,
  getIncompleteDataItemRequestAction,
  getIncompleteDataItemSuccessAction,
  getIncompleteDataItemFailureAction,
  copyIncompleteDataItemFailureAction,
  copyIncompleteDataItemRequestAction,
  copyIncompleteDataItemSuccessAction,
  getIncompleteDataRequestAction,
} from '../actions/read.actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';

export const getIncompleteDataRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getIncompleteDataRequestAction());
  return getIncompleteInvoices()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getIncompleteDataSuccessAction,
        failureAction: getIncompleteDataFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getIncompleteDataFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const getIncompleteDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(getIncompleteDataItemRequestAction());
    return getIncompleteInvoice(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getIncompleteDataItemSuccessAction,
          failureAction: getIncompleteDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const copyIncompleteDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(copyIncompleteDataItemRequestAction());
    return copyIncompleteInvoice(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: copyIncompleteDataItemSuccessAction,
          failureAction: copyIncompleteDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: copyIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
