import {publicRoutes} from '../view/static/data/publicRoutes';
import {clearVerificationUrl} from './verification.service';

interface IUserAuth {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

const getLocalAccessToken = (): string => {
  const user: IUserAuth = JSON.parse(localStorage.getItem('user') as string);
  return user?.access_token;
};

const getCurrentUser = (): IUserAuth => {
  const user: IUserAuth = JSON.parse(localStorage.getItem('user') as string);
  return user;
};

const setUser = (user: IUserAuth): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = (): void => localStorage.removeItem('user');

const removeVerifyStateUser = (): void => {
  localStorage.removeItem('isUserVerified');
};

const checkOpenTabSecurePage = (): string => {
  const isSecurePage = publicRoutes.indexOf(window.location.pathname) === -1;

  if (window.location.pathname.includes('/auth')) {
    return 'unauthenticated';
  }

  if (window.location.pathname.includes('/payment-method')) {
    return 'unauthenticated';
  }

  if (window.location.pathname.includes('/verification-email')) {
    return 'unauthenticated';
  }

  if (window.location.pathname === '/incomplite' && getLocalAccessToken()) {
    return 'unauthenticated';
  }

  if (
    (window.location.pathname === '/success-payment' ||
      window.location.pathname === '/reject-payment') &&
    getLocalAccessToken()
  ) {
    return 'authenticated';
  }

  if (!isSecurePage && getLocalAccessToken()) {
    removeUser();
    removeVerifyStateUser();
    clearVerificationUrl();
    return 'unauthenticated';
  }

  if (isSecurePage && !window.history.state) {
    localStorage.setItem('pageTryToOpen', window.location.pathname);
    removeUser();
    removeVerifyStateUser();
    clearVerificationUrl();
    return 'redirect to login';
  }

  if (getLocalAccessToken()) return 'authenticated';

  return 'unauthenticated';
};

export {
  getCurrentUser,
  getLocalAccessToken,
  setUser,
  removeUser,
  checkOpenTabSecurePage,
  removeVerifyStateUser,
};
