import styled from 'styled-components';
import {Input} from 'antd';

const {TextArea} = Input;

// eslint-disable-next-line import/prefer-default-export
export const TextAreaWrapper = styled(TextArea)`
  ${(p) => p.theme.main.typography.body}
  color: ${(p) => p.theme.main.colors.black};

  width: 100%;
  min-width: 340px;
  height: 140px !important;
  padding: 12px 16px;

  resize: none;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${(p) => p.theme.main.colors.grey};
    ${(p) => p.theme.main.typography.bodyMedium}
  }

  @media (max-width: 850px) {
    min-width: 300px;
  }

  @media (max-width: 720px) {
    min-width: 260px;
  }

  @media (max-width: 650px) {
    min-width: 340px;
  }

  @media (max-width: 400px) {
    min-width: 300px;
  }

  @media (max-width: 370px) {
    min-width: 260px;
  }
`;
