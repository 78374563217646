import {IFormValues} from '../../state/FormConstructor/formConstructor.types';

// eslint-disable-next-line import/prefer-default-export, arrow-body-style
export const omitEmptyValue = (formValues: IFormValues): IFormValues => {
  return Object.entries(formValues).reduce((acc: IFormValues, itm) => {
    const [key, value] = itm;

    if (typeof value === 'string' && value.length === 0) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
};
