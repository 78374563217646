// VERIFY_EMAIL TYPES & ACTIONS
export const VERIFY_EMAIL_REQUEST: string = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS: string = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE: string = 'VERIFY_EMAIL_FAILURE';

export const verifyEmailRequestAction = (): {type: string} => ({
  type: VERIFY_EMAIL_REQUEST,
});

export const verifyEmailSuccessAction = (payload: any) => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload,
});

export const verifyEmailFailureAction = (
  payload: string,
): {type: string; payload: string} => ({type: VERIFY_EMAIL_FAILURE, payload});

export const CLEAR_VERIFY_EMAIL_RES: string = 'CLEAR_VERIFY_EMAIL_RES';

export const clearVerifyEmailRes = (): {type: string} => ({
  type: CLEAR_VERIFY_EMAIL_RES,
});
