import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Withdraw: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V8C16 6.89543 15.1046 6 14 6H13C12.4477 6 12 6.44771 12 7C12 7.55228 12.4477 8 13 8H14V14H2V8H3C3.55228 8 4 7.55228 4 7C4 6.44771 3.55228 6 3 6H2C0.895431 6 0 6.89543 0 8V14Z'
      fill={fillColor}
    />
    <path
      d='M9 7C9 7.55229 8.55229 8 8 8C7.44772 8 7 7.55229 7 7V4H5.60087C5.10662 4 4.8245 3.48686 5.12105 3.12727L7.52017 0.218182C7.76009 -0.0727272 8.23991 -0.0727272 8.47983 0.218182L10.879 3.12727C11.1755 3.48686 10.8934 4 10.3991 4H9V7Z'
      fill={fillColor}
    />
    <path
      d='M8 9C9.10457 9 10 9.89543 10 11C10 12.1046 9.10457 13 8 13C6.89543 13 6 12.1046 6 11C6 9.89543 6.89543 9 8 9Z'
      fill={fillColor}
    />
  </svg>
);
