import styled from 'styled-components';

export const Container = styled.div`
  max-width: 596px;
  height: 328px;
  margin-bottom: 100px;
  background-color: ${(p) => p.theme.main.colors.brand};
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 16px;
    background-color: ${(p) => p.theme.main.colors.white};
    border-radius: 50% 0 0 50%;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border: 1px solid #fff;
    position: absolute;
    top: 200px;
    z-index: 1;
  }

  &:before {
    border-radius: 0 50% 50% 0;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    left: -1px;
  }

  &:after {
    border-radius: 50% 0 0 50%;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    right: -1px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 400px) {
    height: 400px;

    &:before,
    &:after {
      top: 232px;
    }
  }
`;

export const LogoIco = styled.img`
  align-self: flex-start;
`;

export const ColoredStripes = styled.img`
  width: 568px;

  @media (max-width: 400px) {
    margin-bottom: 40px;
  }
`;

export const DashedLine = styled.img`
  position: absolute;
  top: 206px;
  left: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 400px) {
    top: 240px;
  }
`;

export const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const Content = styled.div`
  padding: 32px 40px;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    bottom: 20px;
  }
`;

export const Button = styled.button`
  background-color: ${(p) => p.theme.main.colors.brandDark};
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding: 16px 28px;
  border-radius: 8px;
  position: relative;
  z-index: 1;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 400px) {
    width: 300px;
    margin-right: 0;
    margin-bottom: 16px;
    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 380px) {
    padding: 16px;
  }
`;

export const ButtonText = styled.span`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.white};
  margin-left: 10px;

  @media (max-width: 400px) {
    ${(p) => p.theme.main.typography.captionMedium};
    margin-left: 6px;
  }
`;

export const VoucherTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 400px) {
    margin-bottom: 30px;
  }
`;

export const Balance = styled.div`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.white};
  align-self: flex-end;
`;

export const VoucherNumbers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.main.colors.white};

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const CardNumbers = styled.div`
  ${(p) => p.theme.main.typography.subHeading};

  @media (max-width: 400px) {
    display: none;
  }
`;

export const CardNumbersMobile = styled.div`
  ${(p) => p.theme.main.typography.subHeading};
  display: none;
  align-self: flex-start;

  @media (max-width: 400px) {
    display: block;
  }
`;

export const VoucherAmount = styled.div`
  ${(p) => p.theme.main.typography.title};
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.main.colors.white};
    opacity: 0.5;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 400px) {
    margin-bottom: 40px;
  }
`;

export const IconWrapper = styled.div`
  height: 16px;
  transform: rotate(180deg);
`;
