import {
  IFilterItem,
  IFormValues,
} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';

interface IFormFilterValuesArgs {
  filterValues: IFormValues;
  formValues: IFormValues;
  filterTemplate: IFilterItem[];
}

// eslint-disable-next-line import/prefer-default-export
export const formFilterValues = (args: IFormFilterValuesArgs): IFormValues => {
  const {filterValues, formValues, filterTemplate} = args;

  let resultValues;

  if (Object.keys(filterValues).length > 0) {
    const filterArrayValues: {[key: string]: string[]} = Object.entries(
      filterValues,
    ).reduce((acc: IFormValues, itm) => {
      const [key, value] = itm;
      if (Array.isArray(value)) acc[key] = value;
      return acc;
    }, {});

    const entriesFilterValues = Object.entries(filterArrayValues).map((itm) => {
      let arr;
      const foundFilterItem = filterTemplate.find((el) => el.name === itm[0]);

      if (foundFilterItem && foundFilterItem.options) {
        const entries = Object.entries(foundFilterItem.options);

        const foundFilterOption = entries.filter((elem) =>
          itm[1].some((elm) => elm === elem[1]),
        );

        const flattenFoundFilterOption = foundFilterOption.map(
          (unit) => unit[0],
        );

        arr = flattenFoundFilterOption || [];
      }

      return [itm[0], arr];
    });

    const filterArrayValuesConverted = entriesFilterValues.reduce(
      (acc: IFormValues, item) => {
        const [key, value] = item;
        if (typeof key === 'string') acc[key] = value;
        return acc;
      },
      {},
    );

    const intermediateValues = {...filterValues, ...filterArrayValuesConverted};

    resultValues = intermediateValues || {};
  } else {
    resultValues = formValues || {};
  }

  return resultValues;
};
