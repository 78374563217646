// DESTROY_STANDARD_DATA_ITEM TYPES & ACTIONS
export const DESTROY_STANDARD_DATA_ITEM_REQUEST: string =
  'DESTROY_STANDARD_DATA_ITEM_REQUEST';
export const DESTROY_STANDARD_DATA_ITEM_SUCCESS: string =
  'DESTROY_STANDARD_DATA_ITEM_SUCCESS';
export const DESTROY_STANDARD_DATA_ITEM_FAILURE: string =
  'DESTROY_STANDARD_DATA_ITEM_FAILURE';

export const destroyStandardDataItemRequestAction = (): {type: string} => ({
  type: DESTROY_STANDARD_DATA_ITEM_REQUEST,
});

export const destroyStandardDataItemSuccessAction = (): {type: string} => ({
  type: DESTROY_STANDARD_DATA_ITEM_SUCCESS,
});

export const destroyStandardDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DESTROY_STANDARD_DATA_ITEM_FAILURE,
  payload,
});
