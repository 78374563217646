// eslint-disable-next-line import/prefer-default-export
export const GreenArrowInclined = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.00002 10.2428H1.75738M1.75738 6.00019L1.75738 10.2428M1.75738 10.2428L10.2427 1.75755'
      stroke='#10B981'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
