import {IFieldItems} from '../../state/Standardized/standardized.types';
import {IFormValues} from '../../view/pages/MemberArea/Standardized/FiltersDrawer/FiltersDrawer.types';
import {
  IErrorMap,
  IGeoLocationMap,
  IPhoneCodesRes,
} from '../../state/PhoneCodes/phoneCodes.types';
import {formatePersonalInformation} from './formatePersonalInformation';

interface IErrorsMap {
  errorGeoLocation: IErrorMap | null;
  errorPhoneCodes: IErrorMap | null;
}

interface IFormValuesArgs {
  phoneCodes: IPhoneCodesRes | null;
  geoLocation: IGeoLocationMap | null;
  initialFormFields: IFieldItems;
  // eslint-disable-next-line no-unused-vars
  setStateFunction: (value: React.SetStateAction<IFormValues>) => void;
  errorsMap: IErrorsMap;
}

// eslint-disable-next-line import/prefer-default-export
export const formValuesVerificationForm = (args: IFormValuesArgs): void => {
  const {
    phoneCodes,
    geoLocation,
    initialFormFields,
    setStateFunction,
    errorsMap,
  } = args;

  const {errorGeoLocation, errorPhoneCodes} = errorsMap;

  if (phoneCodes && geoLocation) {
    const phoneCodeMapEntries = Object.entries(phoneCodes.phoneCodeMap);

    const phoneCodeEntry = phoneCodeMapEntries.find(
      (itm) => itm[1] === geoLocation.country || geoLocation.country_code,
    );

    if (phoneCodeEntry) {
      const initialFormValues = formatePersonalInformation({
        ...initialFormFields,
        phoneCode: phoneCodeEntry[0] || '44',
      });

      setStateFunction(initialFormValues);
      return;
    }
    setStateFunction(formatePersonalInformation(initialFormFields));
  }

  if (errorGeoLocation || errorPhoneCodes) {
    setStateFunction(formatePersonalInformation(initialFormFields));
  }
};
