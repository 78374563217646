import styled from 'styled-components';
import {Spin} from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 334px;
  height: 360px;
  margin-left: 60px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);

  @media (max-width: 1500px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: 16px;
    ${(p) => p.theme.main.typography.paragraph};
    color: ${(p) => p.theme.main.colors.brand};
  }
`;
