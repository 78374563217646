import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Spin} from 'antd';
import Currencies from './CurrenciesList';
import TransactionsPart from './TransactionsPart';
import {RootState} from '../../../../state/store';
import {
  getDashboardDataRequest,
  setPageTitleAction,
  getBadgeCountRequest,
} from '../../../../state';

const Dashboard: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {timestampReload} = useSelector((state: RootState) => state.appReducer);

  const {data, loading, error} = useSelector(
    (state: RootState) => state.dashboardReducer,
  );

  useEffect(() => {
    if (data) {
      dispatch(setPageTitleAction(data.pageTitle));
    }

    const pathnameOmitSlash = location.pathname.substring(
      1,
      location.pathname.length,
    );

    dispatch(setPageTitleAction(pathnameOmitSlash));
  }, [data]);

  useEffect(() => {
    dispatch(getDashboardDataRequest());
    dispatch(getBadgeCountRequest());
  }, [timestampReload]);

  if (loading) return <Spin tip='Loading...' spinning={loading} size='large' />;

  if (!data) return <div>{error || 'No Data'}</div>;

  return (
    <>
      <Currencies list={data.list} />
      <TransactionsPart table={data.table} />
    </>
  );
};

export default Dashboard;
