import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Deposit: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.0798 1.10582C11.4746 0.298904 10.3633 0.0689047 9.48751 0.569334L3.48456 3.9996H2.75012C1.64555 3.9996 0.750122 4.89503 0.750122 5.9996V13.9996C0.750122 15.1042 1.64555 15.9996 2.75012 15.9996H14.7501C15.8547 15.9996 16.7501 15.1042 16.7501 13.9996V9.9996V5.9996C16.7501 4.89503 15.8547 3.9996 14.7501 3.9996H14.2501L12.0798 1.10582ZM11.7501 3.9996L10.4798 2.30582L7.51569 3.9996H11.7501ZM2.75012 5.9996L14.7501 5.9996V8.9996H12.7501C12.1978 8.9996 11.7501 9.44731 11.7501 9.9996C11.7501 10.5519 12.1978 10.9996 12.7501 10.9996H14.7501V13.9996H2.75012V5.9996Z'
      fill={fillColor}
    />
  </svg>
);
