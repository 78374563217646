import {AxiosResponse} from 'axios';
import {setUser} from '../../../infra/token.service';
import {socialAuth, socialAuthCallback} from '../../../infra/auth.service';
import {ThunkActionType} from '../../store';
import {
  socialAuthRequestAction,
  socialAuthSuccessAction,
  socialAuthFailureAction,
  socialAuthCallbackRequestAction,
  socialAuthCallbackSuccessAction,
  socialAuthCallbackFailureAction,
} from '../actions/auth.socials';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../../utils';
import {ISocialAuthCallbackArgs} from '../auth.types';

export const socialAuthRequest =
  (authProvider: string): ThunkActionType =>
  (dispatch) => {
    dispatch(socialAuthRequestAction());

    return socialAuth(authProvider)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: socialAuthSuccessAction,
          failureAction: socialAuthFailureAction,
          notificationAction: setNotificationState,
        });
        setUser(response.data);
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: socialAuthFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const socialAuthCallbackRequest =
  (socialAuthCallbackArgs: ISocialAuthCallbackArgs): ThunkActionType =>
  (dispatch) => {
    dispatch(socialAuthCallbackRequestAction());

    return socialAuthCallback(socialAuthCallbackArgs)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: socialAuthCallbackSuccessAction,
          failureAction: socialAuthCallbackFailureAction,
          notificationAction: setNotificationState,
        });
        setUser(response.data);
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: socialAuthCallbackFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
