import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {List} from 'antd';
import {setVerifyNotificationState} from '../../../../../state';
import CurrenciesItem from '../CurrenciesItem';
import {IListGrid} from '../CurrenciesItem/CurrenciesItem.types';
import {ICurrenciesListProps} from './CurrenciesList.types';
import {ListWrapper} from './CurrenciesList.styles';

const CurrenciesList = ({list}: ICurrenciesListProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  const screenXXL = useMediaQuery({minWidth: 1600});
  const screenXL = useMediaQuery({maxWidth: 1599, minWidth: 1200});
  const screenLG = useMediaQuery({maxWidth: 1199, minWidth: 992});
  const screenMD = useMediaQuery({maxWidth: 991, minWidth: 768});
  const screenSM = useMediaQuery({maxWidth: 767, minWidth: 576});
  const screenXS = useMediaQuery({maxWidth: 575});

  const gridBreakpointMap = {
    xxl: screenXXL,
    xl: screenXL,
    lg: screenLG,
    md: screenMD,
    sm: screenSM,
    xs: screenXS,
  };

  const listGridMap = {
    gutter: 16,
    xs: 1,
    sm: 1,
    md: 1,
    lg: list.length < 2 ? list.length : 2,
    xl: list.length < 3 ? list.length : 3,
    xxl: list.length < 3 ? list.length : 3,
  };

  const [isScreenIncreasing, setScreenIncreasing] = useState(false);

  const [widthColDecreaseScreen, setWidthColDecreaseScreen] = useState<
    number | false
  >(false);
  const [widthColIncreaseScreen, setWidthColIncreaseScreen] = useState<
    number | false
  >(false);

  const [keyBreakpointChanges, setKeyBreakpointChanges] = useState<
    keyof IListGrid | null
  >(null);

  const handleScreenIncreasing = (boolIncreasing: boolean) => {
    setScreenIncreasing(boolIncreasing);
  };

  const handleKeyBreakpointChanges = (breakpointKey: keyof IListGrid) => {
    setKeyBreakpointChanges(breakpointKey);
  };

  const goToTopUp =
    (voucherId: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (userNotVerified) {
        dispatch(setVerifyNotificationState(true));
        return;
      }

      history.push(`/top-up/${voucherId}`);
    };

  const handleColumnWidth = (
    keyBreakpointChanged: keyof IListGrid | null,
    currentKey: any,
  ) => {
    if (!keyBreakpointChanged) return false;

    if (!listGridMap[keyBreakpointChanged]) return false;

    const breakpointKey = listGridMap[keyBreakpointChanged];
    const columnsCountDown = breakpointKey - 1;

    if (currentKey && currentKey === 'xxl') return 33.3333;

    if (columnsCountDown < 1) return false;

    const percentColumnWidth = 100 / columnsCountDown;

    const roundedPercentColumnWidth =
      Math.floor(percentColumnWidth * 10000) / 10000;

    return roundedPercentColumnWidth;
  };

  useEffect(() => {
    const breakpointEntry = Object.entries(gridBreakpointMap).find(
      (itm) => itm[1],
    );
    if (!breakpointEntry) return;
    const breakpointKey = breakpointEntry[0] as keyof IListGrid;
    if (!breakpointKey) return;

    const percentColumnWidth = handleColumnWidth(
      keyBreakpointChanges,
      breakpointKey,
    );
    setWidthColIncreaseScreen(percentColumnWidth);
  }, [keyBreakpointChanges, isScreenIncreasing]);

  useEffect(() => {
    if (!isScreenIncreasing) return;
    const breakpointEntry = Object.entries(gridBreakpointMap).find(
      (itm) => itm[1],
    );
    if (!breakpointEntry) return;
    const breakpointKey = breakpointEntry[0] as keyof IListGrid;
    if (!breakpointKey) return;

    const columnsCount = listGridMap[breakpointKey];

    const percentColumnWidth = 100 / columnsCount;

    const roundedPercentColumnWidth =
      Math.floor(percentColumnWidth * 10000) / 10000;

    setWidthColDecreaseScreen(roundedPercentColumnWidth);
  }, [isScreenIncreasing, gridBreakpointMap]);

  if (!list) return <div>Accounts</div>;

  return (
    <ListWrapper
      widthColIncreaseScreen={widthColIncreaseScreen}
      widthColDecreaseScreen={widthColDecreaseScreen}
    >
      <List
        grid={listGridMap}
        dataSource={list}
        renderItem={(item): JSX.Element => (
          <CurrenciesItem
            currenciesItem={item}
            goToTopUp={goToTopUp}
            handleKeyBreakpointChanges={handleKeyBreakpointChanges}
            handleScreenIncreasing={handleScreenIncreasing}
          />
        )}
      />
    </ListWrapper>
  );
};

export default CurrenciesList;
