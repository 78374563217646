// CHANGE_APP_LANGUAGE TYPES & ACTIONS
export const CHANGE_APP_LANGUAGE: string = 'CHANGE_APP_LANGUAGE';

export const changeAppLanguageAction = (
  payload: string,
): {type: string; payload: string} => ({type: CHANGE_APP_LANGUAGE, payload});

// SET_AUTHENTICATION_STATE TYPES & ACTIONS
export const SET_AUTHENTICATION_STATE: string = 'SET_AUTHENTICATION_STATE';

export const setAuthenticationState = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_AUTHENTICATION_STATE,
  payload,
});

// SET_IS_AUTHENTICATED TYPES & ACTIONS
export const SET_IS_AUTHENTICATED: string = 'SET_IS_AUTHENTICATED';

export const setIsAuthenticated = (
  payload: boolean,
): {type: string; payload: boolean} => ({
  type: SET_IS_AUTHENTICATED,
  payload,
});
