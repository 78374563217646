import {WalletColored} from '../../../../static/images';
import coloredStripes from '../../../../styles/img/colored-stripes.png';
import {
  BackgroundImage,
  Container,
  Inner,
  Text,
  Wrapper,
} from './EmptyCard.styles';

const EmptyCard = () => (
  <Wrapper marginTop='180px'>
    <Container>
      <BackgroundImage src={coloredStripes} alt='background' />
      <Inner>
        <WalletColored />
        <Text>This is where you’ll see your activity and transactions.</Text>
      </Inner>
    </Container>
  </Wrapper>
);

export default EmptyCard;
