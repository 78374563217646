import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Logout: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9717 7V5.99C11.9717 5.451 12.3117 5.292 12.7317 5.651L14.7117 7.351C15.1357 7.714 15.1317 8.293 14.7117 8.652L12.7317 10.349C12.3077 10.713 11.9717 10.556 11.9717 10.009V9H5.97168C5.70646 9 5.45211 8.89464 5.26457 8.70711C5.07704 8.51957 4.97168 8.26522 4.97168 8C4.97168 7.73478 5.07704 7.48043 5.26457 7.29289C5.45211 7.10536 5.70646 7 5.97168 7H11.9717ZM8.48068 13C8.54553 13.0001 8.60977 12.9874 8.66966 12.9625C8.72956 12.9376 8.78392 12.9011 8.82959 12.8551C8.87526 12.809 8.91134 12.7544 8.93573 12.6943C8.96012 12.6342 8.97234 12.5699 8.97168 12.505V10.999C8.97142 10.8677 8.99711 10.7375 9.04728 10.6162C9.09746 10.4948 9.17112 10.3845 9.26404 10.2917C9.35697 10.1988 9.46732 10.1253 9.58876 10.0752C9.7102 10.0252 9.84033 9.9996 9.97168 10C10.5237 10 10.9717 10.444 10.9717 11V12.995C10.9727 13.2582 10.9218 13.519 10.8217 13.7624C10.7216 14.0058 10.5744 14.227 10.3885 14.4133C10.2027 14.5997 9.98183 14.7474 9.73867 14.8481C9.49552 14.9488 9.23486 15.0004 8.97168 15H2.97168C1.86668 15 0.97168 14.103 0.97168 12.994V3.006C0.97168 1.898 1.85868 1 2.97168 1H8.97168C9.23475 0.999999 9.49523 1.0519 9.7382 1.15272C9.98118 1.25354 10.2019 1.40131 10.3877 1.58755C10.5734 1.7738 10.7207 1.99487 10.8209 2.2381C10.9211 2.48133 10.9723 2.74194 10.9717 3.005V5C10.9717 5.552 10.5277 6 9.97168 6C9.41968 6 8.97168 5.557 8.97168 5.001V3.495C8.97091 3.36465 8.91905 3.23979 8.82725 3.14724C8.73545 3.05469 8.61102 3.00183 8.48068 3H3.46268C3.39693 3.00117 3.33206 3.0153 3.27178 3.04158C3.21149 3.06786 3.15699 3.10577 3.11138 3.15314C3.06577 3.20051 3.02995 3.25642 3.00598 3.31765C2.98201 3.37889 2.97035 3.44425 2.97168 3.51V12.49C2.97168 12.772 3.19968 13 3.46268 13H8.48068Z'
      fill={fillColor}
    />
  </svg>
);
