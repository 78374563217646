import {FC} from 'react';
import {Select} from 'antd';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import FormItem from '../../FormItem';
import {Down} from '../../../../../static/images';
import {ISelectComponentProps} from './SelectComponent.types';
import {SelectWrapper} from './SelectComponent.styles';

const {Option} = Select;

const SelectComponent: FC<ISelectComponentProps> = ({
  name,
  label,
  value,
  options,
  required,
  handleValueChange,
}: ISelectComponentProps) => {
  const handleSelectChange = (option: OptionData | OptionGroupData) => {
    handleValueChange({keyName: name, value: option.value});
  };

  return (
    <FormItem label={label} required={required}>
      <SelectWrapper
        defaultValue={value || undefined}
        placeholder={label}
        suffixIcon={<Down />}
        onSelect={(selectVal, option) => handleSelectChange(option)}
      >
        {options.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </SelectWrapper>
    </FormItem>
  );
};

export default SelectComponent;
