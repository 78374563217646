import {AxiosResponse} from 'axios';
import {Dispatch, ActionCreator} from 'redux';

interface IHandleResponseSuccessAndFailNotification {
  response: AxiosResponse;
  dispatch: Dispatch;
  successAction: ActionCreator<any>;
  failureAction: ActionCreator<any>;
  notificationAction: ActionCreator<any>;
  extraRequest?: ActionCreator<any>;
  extraRequestArg?: string;
  isModalViewNotification?: boolean;
  textMessage?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const handleResponseSuccessAndFailNotification = ({
  response,
  dispatch,
  successAction,
  failureAction,
  notificationAction,
  isModalViewNotification,
  textMessage,
}: IHandleResponseSuccessAndFailNotification) => {
  let successMessage = '';

  if ('error' in response.data && response.data.error) {
    if (response.data.message) {
      dispatch(failureAction(response.data.message));

      if (notificationAction) {
        dispatch(
          notificationAction({
            isShow: true,
            type: 'error',
            message: response.data.message || 'Error',
          }),
        );
      }

      return;
    }

    dispatch(failureAction('Something went wrong, try later'));

    if (notificationAction) {
      dispatch(
        notificationAction({
          isShow: true,
          type: 'error',
          message: response.data.message || 'Error',
        }),
      );
    }
    return;
  }

  if (textMessage) {
    successMessage = textMessage;
  } else if (response.data.message) {
    successMessage = response.data.message;
  } else {
    successMessage = 'Success';
  }

  if (!response.data.type) {
    dispatch(
      notificationAction({
        isShow: !isModalViewNotification,
        type: 'success',
        message: successMessage,
        isModalView: !!isModalViewNotification,
      }),
    );
    dispatch(successAction(response.data));
    return;
  }

  if (response.data.type === 'final') {
    dispatch(
      notificationAction({
        isShow: !isModalViewNotification,
        type: 'success',
        message: successMessage,
        isModalView: !!isModalViewNotification,
      }),
    );
    dispatch(successAction(response.data));
    return;
  }

  dispatch(successAction(response.data));
};
