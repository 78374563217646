import styled, {keyframes} from 'styled-components';
import {Button, Divider, Form, Input, Typography, Radio} from 'antd';
import {Link} from 'react-router-dom';

const fadeIn = keyframes`
  from {
    transform: translateX(0);
    opacity: 0;
  }

  to {
    transform: translateX(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateX(1);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FadeFormItem = styled(Form.Item)<{
  $isRegister?: boolean | undefined;
}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  display: inline-block;
  visibility: ${(p) => (!p.$isRegister ? 'hidden' : 'visible')};
  animation: ${(p) => (!p.$isRegister ? fadeOut : fadeIn)} 0.5s linear;
  transition: visibility 1s linear;
  font-size: 16px;
  width: 100%;
`;

export const TitlesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0 32px 0;
  text-align: center;

  @media only screen and (max-width: 375px) {
    margin: 0 0 32px 0;
  }
`;

export const TitleWrapper = styled(Typography.Title)`
  ${(p) => p.theme.main.typography.h2}
`;

export const SecondaryTextWrapper = styled(Typography.Text)`
  ${(p) => p.theme.main.typography.paragraph}
`;

export const LinkWrapper = styled(Link)`
  color: ${(p) => p.theme.main.colors.brand} !important;
  display: inline;
  font-weight: 600;
`;

export const DividerWrapper = styled(Divider)`
  color: ${(p) => p.theme.main.colors.grey} !important;
  font-size: 14px;
  padding: 32px 0;
  margin: 0 !important;
  border-top-color: #ebebeb !important;
`;

export const SocialsButtonWrapper = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 8px 14px 12px;
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: 4px;
  border: none;
  width: 48%;
  height: 48px;
`;

export const TextWrapper = styled(Typography.Text)`
  display: flex;
  justify-content: center;
  color: #74828a;
  font-size: 12px;
  text-align: center;
  margin-top: 32px;

  @media only screen and (max-width: 500px) {
    display: block;
  }
`;

export const FormItem = styled(Form.Item)`
  font-weight: 500;
  color: #192b3b;
  font-size: 16px;

  label {
    ${(p) => p.theme.main.typography.bodyMedium};
    color: ${(p) => p.theme.main.colors.black};
    text-transform: capitalize;
    line-height: 20px;
  }
`;

export const FormItemFullWidth = styled(Form.Item)`
  width: 100%;
  font-weight: 500;
  color: #192b3b;
  font-size: 16px;

  label {
    ${(p) => p.theme.main.typography.bodyMedium};
    color: ${(p) => p.theme.main.colors.black};
    text-transform: capitalize;
    line-height: 20px;
  }
`;

export const FormItemRow = styled(Form.Item)`
  font-weight: 500;
  color: #192b3b;
  font-size: 16px;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;

  .ant-form-item-label {
    width: 20%;
  }

  label {
    ${(p) => p.theme.main.typography.bodyMedium};
    color: ${(p) => p.theme.main.colors.black};
    line-height: 20px;
  }

  .ant-form-item-control {
    width: 80%;
    max-width: 500px;
  }

  @media only screen and (max-width: 1200px) {
    .ant-form-item-label,
    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-control {
      max-width: 100%;
    }
  }
`;

export const FormItemRowTextArea = styled(Form.Item)`
  font-weight: 500;
  color: #192b3b;
  font-size: 16px;
  flex-direction: row !important;
  justify-content: space-between;

  .ant-form-item-label {
    width: 20%;
  }

  label {
    ${(p) => p.theme.main.typography.bodyMedium};
    color: ${(p) => p.theme.main.colors.black};
    line-height: 20px;
  }

  .ant-form-item-control {
    width: 80%;
    max-width: 500px;
  }

  @media only screen and (max-width: 1200px) {
    .ant-form-item-label,
    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-control {
      max-width: 100%;
    }
  }
`;

export const FormItemRowAlignSelf = styled(Form.Item)`
  font-weight: 500;
  color: #192b3b;
  font-size: 16px;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;

  .ant-form-item-label {
    width: 20%;
  }

  label {
    ${(p) => p.theme.main.typography.bodyMedium};
    color: ${(p) => p.theme.main.colors.black};
    line-height: 20px;
  }

  .ant-form-item-control {
    width: 80%;
    max-width: 500px;
  }

  @media only screen and (max-width: 1200px) {
    .ant-form-item-label,
    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-control {
      max-width: 100%;
    }
  }
`;

export const FormItemButton = styled(Form.Item)`
  @media only screen and (max-width: 375px) {
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled(Input)<{$borderRadius?: string}>`
  ${(p) => p.theme.main.typography.body}
  color: ${(p) => p.theme.main.colors.black};

  padding: 12px 16px;

  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${(p) => p.theme.main.colors.grey};
    ${(p) => p.theme.main.typography.bodyMedium}
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputWrapperDisabled = styled(Input)<{$borderRadius?: string}>`
  ${(p) => p.theme.main.typography.body}
  color: ${(p) => p.theme.main.colors.black};

  padding: 12px 16px;

  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${(p) => p.theme.main.colors.grey};
    ${(p) => p.theme.main.typography.bodyMedium}
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.ant-input:disabled {
    color: ${(p) => p.theme.main.colors.black};
    border-color: ${(p) => p.theme.main.colors.light};
  }
`;

export const InputWrapperPassword = styled(Input)<{
  $borderRadius?: string;
  $inFocus: boolean;
}>`
  ${(p) =>
    p.$inFocus
      ? p.theme.main.typography.passwordSettingsEmail
      : p.theme.main.typography.body};

  color: ${(p) => p.theme.main.colors.black};

  padding: 12px 16px;

  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${(p) => p.theme.main.colors.grey};
    ${(p) => p.theme.main.typography.bodyMedium}
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputPasswordWrapper = styled(Input.Password)<{
  $borderRadius?: string;
}>`
  padding: 12px 16px;
  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
  border: 1px solid ${(p) => p.theme.main.colors.lightGrey};
  box-shadow: ${(p) => p.theme.main.shadow.default};
`;

export const ButtonWrapper = styled(Button)<{$borderRadius?: string}>`
  padding: 12px 24px;
  background-color: ${(p) => p.theme.main.colors.brand};
  color: ${(p) => p.theme.main.colors.white};
  height: 48px;
  width: 100%;
  font-style: normal;
  ${(p) => p.theme.main.typography.bodyMedium}
  box-shadow: ${(p) => p.theme.main.shadow.default};
  border-radius: ${({$borderRadius}) =>
    // eslint-disable-next-line no-unneeded-ternary
    $borderRadius ? $borderRadius : '2px'};
`;

export const ForgotPasswordText = styled(Link)`
  display: flex;
  justify-content: end;
  color: ${(p) => p.theme.main.colors.grey};
  ${(p) => p.theme.main.typography.bodyMedium}
  margin: -15px 0 12px 0;
`;

export const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #171533 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #171533;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #171533;
  }
`;
