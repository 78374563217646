import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../store';
import {getDashboardData} from '../../infra/dashboard.service';
import {
  getDashboardDataRequestAction,
  getDashboardDataSuccessAction,
  getDashboardDataFailureAction,
} from './dashboard.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';

// eslint-disable-next-line import/prefer-default-export
export const getDashboardDataRequest = (): ThunkActionType => (dispatch) => {
  dispatch(getDashboardDataRequestAction());
  return getDashboardData()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: getDashboardDataSuccessAction,
        failureAction: getDashboardDataFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: getDashboardDataFailureAction,
        notificationAction: setNotificationState,
      });
    });
};
