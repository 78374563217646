// eslint-disable-next-line import/prefer-default-export
export const RedArrowInclined = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.99998 1.75717H10.2426M10.2426 5.99981L10.2426 1.75717M10.2426 1.75717L1.75734 10.2424'
      stroke='#EF4444'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
