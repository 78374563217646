import styled from 'styled-components';

export const PresetsWrapper = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'block' : 'none')};
  width: 160px;
  height: 375px;
  padding: 24px 20px;
  background-color: ${(p) => p.theme.main.colors.white};
  border: 1px solid #ebebeb;
  border-bottom: 0;
  border-radius: 8px 0 0 0;
  position: absolute;
  top: 67px;
  left: 0;
  z-index: 2;

  @media (max-width: 1200px) {
    display: none;
    border-radius: 8px;
  }
`;

export const ULWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const LIWrapper = styled.li<{selected: boolean}>`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  padding: 6px 16px;
  background-color: ${({selected}) => (selected ? '#f6f5ff' : '#fff')};
  border-radius: 8px;
  cursor: pointer;
`;
