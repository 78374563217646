import styled from 'styled-components';
import {List} from 'antd';

const {Item} = List;

export const CurrenciesListItem = styled(Item)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 82px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
`;

export const BalancePart = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

export const IconWrapper = styled.div`
  min-width: 48px;
`;

export const BalanceText = styled.div`
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h4`
  ${(p) => p.theme.main.typography.subHeading};
  color: ${(p) => p.theme.main.colors.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BalanceTitle = styled.span`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.darkGrey};
`;

export const SubTitle = styled.p`
  ${(p) => p.theme.main.typography.semiHeading};
  color: ${(p) => p.theme.main.colors.black};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 400px) {
    ${(p) => p.theme.main.typography.subHeading};
  }
`;

export const CurrencySymbol = styled.span`
  margin-left: 4px;
`;

export const AddMoneyButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.blackSecondary};
  background-color: ${(p) => p.theme.main.colors.lightPurple};
  border-radius: 8px;
  white-space: nowrap;
`;

export const ButtonText = styled.span`
  margin-left: 8px;

  @media (max-width: 380px) {
    display: none;
  }
`;
