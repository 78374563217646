import {logout} from '../../../infra/auth.service';
import {ThunkActionType} from '../../store';
import {clearVerificationDocumentsData} from '../../Verification/verification.actions';
import {resetChatReducer} from '../../Chat/misc/actions';
import {
  userLogoutRequestAction,
  userLogoutSuccessAction,
} from '../actions/logout';

// eslint-disable-next-line import/prefer-default-export
export const logoutUser = (): ThunkActionType => (dispatch) => {
  dispatch(userLogoutRequestAction());
  logout();
  dispatch(userLogoutSuccessAction());
  dispatch(clearVerificationDocumentsData());
  dispatch(resetChatReducer());
  localStorage.removeItem('formConstructorUrl');
};
