import styled from 'styled-components';
import {Progress} from 'antd';

export const Container = styled.div`
  text-align: center;
`;

export const Paragraph = styled.p`
  ${(p) => p.theme.main.typography.heading};
  margin-bottom: 16px;
`;

export const StyledProgress = styled(Progress)`
  & .ant-progress-text {
    ${(p) => p.theme.main.typography.paragraph};
  }

  &.ant-progress-status-success .ant-progress-text {
    color: ${(p) => p.theme.main.colors.black};
  }
`;
