import {FC} from 'react';

interface IProps {
  fillColor?: string; //eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Plus: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 14C7.44772 14 7 13.5523 7 13L6.99999 9L3 9C2.44771 9 2 8.55228 2 8C2 7.44772 2.44771 7 3 7H6.99999L6.99999 3C6.99999 2.44771 7.44771 2 8 2C8.55228 2 8.99999 2.44772 8.99999 3L8.99999 7H13C13.5523 7 14 7.44771 14 8C14 8.55228 13.5523 9 13 9H8.99999L9 13C9 13.5523 8.55229 14 8 14Z'
      fill={fillColor}
    />
  </svg>
);
