import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
  display?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Close: FC<IProps> = ({
  fillColor = '#192B3B',
  display = 'initial',
}: IProps) => (
  <svg
    style={{display}}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 12L12 4M4 4L12 12'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
