import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.label<{required?: boolean}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  text-transform: capitalize;
  line-height: 20px;

  &:before {
    content: '*';
    display: ${({required}) => (required ? 'inline-block' : 'none')};
    margin-right: 4px;
    color: ${(p) => p.theme.main.colors.red};
    font-size: 14px;
    font-family: SimSun, sans-serif;
  }
`;

export const InputInner = styled.div`
  margin-top: 8px;
`;
