import {AxiosResponse} from 'axios';
import {
  getProfileInformation,
  postProfileInformation,
} from '../../../infra/profile.service';
import {ThunkActionType} from '../../store';
import {IProfileMap} from '../profile.types';
import {
  getProfileInfoRequestAction,
  getProfileInfoSuccessAction,
  getProfileInfoFailureAction,
  updateProfileSuccessAction,
  updateProfileFailureAction,
  updateProfileRequestAction,
} from './actions';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseSuccessAndFailNotification,
} from '../../../utils';

export const getProfileInformationRequest =
  (): ThunkActionType => (dispatch) => {
    dispatch(getProfileInfoRequestAction());

    return getProfileInformation()
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: getProfileInfoSuccessAction,
          failureAction: getProfileInfoFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: getProfileInfoFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const updateProfileRequest =
  (updatedProfile: IProfileMap): ThunkActionType =>
  (dispatch) => {
    dispatch(updateProfileRequestAction());

    return postProfileInformation(updatedProfile)
      .then((response: AxiosResponse) => {
        handleResponseSuccessAndFailNotification({
          response,
          dispatch,
          successAction: updateProfileSuccessAction,
          failureAction: updateProfileFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: updateProfileFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
