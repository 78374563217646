import PulseLoader from 'react-spinners/PulseLoader';
import {LoaderWrapper, LoaderInner, Title, Paragraph} from './Loader.styles';

const Loader = () => (
  <LoaderWrapper>
    <PulseLoader color='#7367FE' loading size={15} />
    <LoaderInner>
      <Title>Please wait</Title>
      <Paragraph>We are processing your order</Paragraph>
    </LoaderInner>
  </LoaderWrapper>
);

export default Loader;
