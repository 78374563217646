import {FC, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {RootState} from '../../../../state/store';
import {getClientListRequest} from '../../../../state';
import LoadingMask from '../LoadingMask';
import MessagesList from '../MessagesList';
import ChatSkeleton from '../ChatSkeleton';
import {UserCircle} from '../../../static/images';
import {IChatComponentProps} from './ChatComponent.types';
import {
  Container,
  Title,
  ChatStatus,
  ChatStatusText,
  AvatarImg,
  SenderMessage,
  AvatarReply,
  TextController,
  TextareaMessage,
  ButtonWrapper,
  IconWrapper,
  ButtonControl,
  ButtonContainer,
} from './ChatComponent.styles';

const ChatComponent: FC<IChatComponentProps> = ({
  chatName,
  messageValue,
  switchChatStatus,
  removeChat,
  handleChange,
  formateDateTime,
  removeMessage,
  replyMessage,
}: IChatComponentProps) => {
  const dispatch = useDispatch();
  const {data} = useSelector((state: RootState) => state.appReducer);
  const {imageUrl} = data.navbar.user;
  const {
    oneChat,
    clientsMap,
    loading,
    loadingSendMessage,
    loadingDeleteMessage,
    loadingChangeChatStatus,
  } = useSelector((state: RootState) => state.chatReducer);

  useEffect(() => {
    dispatch(getClientListRequest());
  }, []);

  if (loading) return <ChatSkeleton />;

  return (
    <Container>
      {loadingDeleteMessage ? <LoadingMask /> : null}
      {oneChat && clientsMap && !Array.isArray(clientsMap) ? (
        <Title>{chatName}</Title>
      ) : null}
      {oneChat ? (
        <ChatStatus>
          <ChatStatusText>Current chat status:</ChatStatusText>
          <>
            {loadingChangeChatStatus ? (
              <Spin
                spinning={loadingChangeChatStatus}
                size='small'
                indicator={
                  <LoadingOutlined
                    spin
                    style={{fontSize: 20, color: '#7367FE'}}
                  />
                }
              />
            ) : (
              <span>{oneChat?.status}</span>
            )}
          </>
        </ChatStatus>
      ) : null}
      <div>
        <MessagesList
          messagesList={oneChat?.messages || []}
          formateDateTime={formateDateTime}
          removeMessage={removeMessage}
        />
        <SenderMessage>
          <AvatarReply>
            {imageUrl ? (
              <AvatarImg src={imageUrl} alt='avatar' />
            ) : (
              <UserCircle />
            )}
          </AvatarReply>
          <TextController>
            <TextareaMessage
              placeholder='Enter your message here'
              value={messageValue}
              onChange={handleChange}
            />
            <ButtonControl>
              <ButtonWrapper onClick={replyMessage}>
                {loadingSendMessage ? (
                  <IconWrapper>
                    <Spin
                      size='small'
                      spinning={loadingSendMessage}
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{fontSize: 20, color: '#fff'}}
                        />
                      }
                    />
                  </IconWrapper>
                ) : null}
                <span>Reply</span>
              </ButtonWrapper>
              <ButtonContainer>
                <ButtonWrapper onClick={switchChatStatus}>
                  <span>{oneChat?.status === 'open' ? 'close' : 'open'}</span>
                </ButtonWrapper>
                <ButtonWrapper onClick={removeChat}>
                  <span>Delete chat</span>
                </ButtonWrapper>
              </ButtonContainer>
            </ButtonControl>
          </TextController>
        </SenderMessage>
      </div>
    </Container>
  );
};

export default ChatComponent;
