import styled from 'styled-components';

export const ActionButtons = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ButtonText = styled.span<{color?: string}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) =>
    p.color ? p.theme.main.colors.red : p.theme.main.colors.black};
  margin-left: 8px;
  text-transform: capitalize;

  transition: color 0.3s linear;

  &:hover {
    color: ${(p) => (p.color ? '#F87171' : p.theme.main.colors.darkGrey)};
  }
`;
