import {ComponentType, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {notification, Modal} from 'antd';
import {NotificationInstance} from 'antd/lib/notification';
import {RootState} from '../../state/store';
import {setNotificationState} from '../../state';
import MainCard from '../components/MainCard';
import {OpenedLetterColored} from '../static/images';
import {
  MESSAGE_MODAL_OPTIONS,
  MESSAGE_NOTIFICATION_OPTIONS,
} from '../static/data/notifications';

function WithNotification<T>(Component: ComponentType<T>) {
  function HOC(props: T) {
    const dispatch = useDispatch();

    const {notificationMap} = useSelector(
      (state: RootState) => state.notificationReducer,
    );

    const openNotificationWithIcon = (
      type: keyof NotificationInstance,
      message: string,
    ) => {
      notification[type]({
        message,
        duration: 3,
        onClose: () =>
          dispatch(setNotificationState(MESSAGE_NOTIFICATION_OPTIONS)),
      });
    };

    useEffect(() => {
      if (notificationMap.isShow) {
        openNotificationWithIcon(
          notificationMap.type as keyof NotificationInstance,
          notificationMap.message,
        );
      }
    }, [notificationMap]);

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...(props as T)} />
        <Modal
          wrapClassName='verification-notify-modal'
          visible={notificationMap.isModalView}
          centered
          footer={null}
          closable={false}
          bodyStyle={{padding: 0}}
        >
          <MainCard
            icon={<OpenedLetterColored />}
            textButton='Close'
            title={notificationMap.message}
            handleClick={() =>
              dispatch(setNotificationState(MESSAGE_MODAL_OPTIONS))
            }
          />
        </Modal>
      </>
    );
  }
  return HOC;
}

export default WithNotification;
