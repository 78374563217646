import styled, {keyframes} from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
`;

export const DateRangePickerWrapper = styled.div<{focusedInput: boolean}>`
  position: relative;

  & .DateRangePicker {
    width: 100%;
  }

  & .DayPicker__withBorder {
    box-shadow: none;
    border-radius: 0 8px 0 0;
    border-top: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;

    @media (max-width: 1200px) {
      border: 0;
      border-radius: 8px;
      left: 0px !important;
    }
  }

  & .DateRangePicker .DateRangePicker_picker {
    width: 100%;
    border-radius: 0 8px 0 0;
    left: 159px !important;
    z-index: 2;

    @media (max-width: 1200px) {
      width: 102%;
      padding-top: 70px;
      border: 1px solid #ebebeb;
      border-radius: 8px;
      top: 64px !important;
      left: -2px !important;
    }
  }

  & .DateRangePickerInput {
    width: 100%;
    border: 1px solid #ebebeb;
    outline: ${({focusedInput}) => (focusedInput ? '2px solid #171533;' : '0')};
    box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
    border-radius: 8px;
    position: relative;
    z-index: 3;
  }

  & .DateInput {
    width: 102px;
    border-radius: 8px;
  }

  & .DateInput_input {
    ${(p) => p.theme.main.typography.body};
    color: ${(p) => p.theme.main.colors.black};
    padding: 14px 16px;
    line-height: 1;
    font-weight: 400;
    border-bottom: 0;
    border-radius: 8px;
  }

  & .DateInput_input::placeholder {
    ${(p) => p.theme.main.typography.body};
    color: ${(p) => p.theme.main.colors.grey};
  }

  & .DateInput_input__focused {
    border-bottom: 0;
  }

  & .DateInput_fang {
    display: none;
  }

  & .DayPicker_transitionContainer {
    margin-bottom: 24px;
    border-radius: 0 8px 0 0;
    animation-name: ${fadeIn};
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    @media (max-width: 1200px) {
      border-radius: 8px;
    }
  }

  & .DateRangePickerInput_calendarIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 1;
  }

  & .DateRangePickerInput__showClearDates {
    padding-right: 0;
  }

  & .DateRangePickerInput_clearDates {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 100%;
    padding: 0;
    margin: 0 10px;
    right: 25px;
  }

  & .CalendarMonth_caption {
    ${(p) => p.theme.main.typography.subHeading}
    color: ${(p) => p.theme.main.colors.black}
  }

  & .CalendarDay__default {
    border: 0;
  }

  & .DayPicker_weekHeader_li {
    small {
      ${(p) => p.theme.main.typography.bodyMedium}
      color: ${(p) => p.theme.main.colors.black}
    }
  }

  & .CalendarMonth_table {
    margin-top: 20px;
  }

  & .CalendarMonth_table td {
    ${(p) => p.theme.main.typography.caption}
    color: ${(p) => p.theme.main.colors.black};
    line-height: 39px;
  }

  & .CalendarMonth_table tbody tr:first-child td {
    color: ${(p) => p.theme.main.colors.grey};
  }

  & .CalendarDay__selected,
  & .CalendarDay__selected:hover,
  & .CalendarDay__selected:active {
    background: ${(p) => p.theme.main.colors.brand};
    color: ${(p) => p.theme.main.colors.white} !important;
    border-radius: 8px 0 0 8px;
  }

  & .CalendarDay__hovered_span,
  & .CalendarDay__hovered_span:hover {
    background: ${(p) => p.theme.main.colors.signUp};
  }

  & .CalendarDay__default:hover {
    background: ${(p) => p.theme.main.colors.signUp};
    border: 0;
  }

  & .CalendarDay__default.CalendarDay__selected:hover {
    background: ${(p) => p.theme.main.colors.brand};
  }

  & .CalendarDay__selected.CalendarDay__selected_end {
    border-radius: 0 8px 8px 0;
  }

  & .CalendarDay__selected_span {
    background: ${(p) => p.theme.main.colors.signUp};
  }
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  &:hover {
    background-color: ${(p) => p.theme.main.colors.lightGrey};
  }
`;

export const PrevButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  top: 18px;
  left: 22px;
  position: absolute;

  @media (max-width: 1200px) {
    transform: rotate(90deg);
  }
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  top: 18px;
  right: 22px;
  position: absolute;

  @media (max-width: 1200px) {
    transform: rotate(90deg);
  }
`;
