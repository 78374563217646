import {AxiosResponse} from 'axios';
import {ThunkActionType} from '../store';
import {IPaymentDetailsMap} from './topUp.types';
import {
  initializeTopUp,
  initializeTopUpId,
  sendTopUp,
} from '../../infra/topUp.service';
import {
  initTopUpRequestAction,
  initTopUpSuccessAction,
  initTopUpFailureAction,
  initTopUpIdRequestAction,
  initTopUpIdSuccessAction,
  initTopUpIdFailureAction,
  sendTopUpRequestAction,
  sendTopUpSuccessAction,
  sendTopUpFailureAction,
} from './topUp.actions';
import {setNotificationState} from '../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
} from '../../utils';

export const initTopUpRequest = (): ThunkActionType => (dispatch) => {
  dispatch(initTopUpRequestAction());

  return initializeTopUp()
    .then((response: AxiosResponse) => {
      handleResponseFailNotification({
        response,
        dispatch,
        successAction: initTopUpSuccessAction,
        failureAction: initTopUpFailureAction,
      });
    })
    .catch((error) => {
      handleErrorNotification({
        error,
        dispatch,
        failureAction: initTopUpFailureAction,
        notificationAction: setNotificationState,
      });
    });
};

export const initTopUpIdRequest =
  (id: string): ThunkActionType =>
  (dispatch) => {
    dispatch(initTopUpIdRequestAction());

    return initializeTopUpId(id)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: initTopUpIdSuccessAction,
          failureAction: initTopUpIdFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: initTopUpIdFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const sendTopUpRequest =
  (slugUrl: string, topUpData: IPaymentDetailsMap): ThunkActionType =>
  (dispatch) => {
    dispatch(sendTopUpRequestAction());

    return sendTopUp(slugUrl, topUpData)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: sendTopUpSuccessAction,
          failureAction: sendTopUpFailureAction,
          notificationAction: setNotificationState,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: sendTopUpFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
