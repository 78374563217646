import {FC, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {OptionData, OptionGroupData} from 'rc-select/lib/interface';
import TopUpFormComponent from '../TopUpFormComponent';
import {
  IFormValues,
  ITopUpFormContainerProps,
} from './TopUpFormContainer.types';
import {ITopUpFieldItem} from '../../../../../state/TopUp/topUp.types';
import {RootState} from '../../../../../state/store';
import {
  sendTopUpRequest,
  setPaymentDetailsTopUpAction,
  clearTopUpResponse,
} from '../../../../../state';
import {
  convertStringValueToNumber,
  createInitialFormValues,
  reorderTopUpFieldsList,
} from '../../../../../utils';

const TopUpFormContainer: FC<ITopUpFormContainerProps> = ({
  topUpList,
}: ITopUpFormContainerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {topUpSendSuccess} = useSelector(
    (state: RootState) => state.topUpReducer,
  );

  const [formFieldsList, setFormFieldsList] = useState<ITopUpFieldItem[]>([]);
  const [formValues, setFormValues] = useState<IFormValues>({});
  const [isDisabledCheckoutButton, setDisabledCheckoutButton] = useState(true);
  const [currencyMap, setCurrencyMap] = useState<IFormValues>({});
  const [currentCurrency, setCurrentCurrency] = useState('');

  const goPrevPage = () => history.goBack();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePaymentMethod = (key: string, paymentMethod: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: paymentMethod,
    }));
  };

  const handleSelectChange = (
    key: string,
    option: OptionData | OptionGroupData,
  ) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: option.value,
    }));
  };

  const sendTopUpPaymentDetails = () => {
    const formNumberValues = convertStringValueToNumber(formValues);
    dispatch(setPaymentDetailsTopUpAction(formValues));

    if (topUpList.slug) {
      dispatch(sendTopUpRequest(topUpList.slug, formNumberValues));
    }
  };

  const handleBlurWindow = () => {
    if (topUpSendSuccess) {
      dispatch(clearTopUpResponse());
    }
  };

  useEffect(() => {
    const isDisabled = Object.entries(formValues).some(
      (itm: [string, string]) => !itm[1],
    );
    setDisabledCheckoutButton(isDisabled);
  }, [formValues]);

  useEffect(() => {
    if (!topUpSendSuccess) return;
    if (topUpSendSuccess?.redirect) {
      window.open(topUpSendSuccess.redirect, 'noopener noreferrer');
    }
  }, [topUpSendSuccess]);

  useEffect(() => {
    if (!formValues.voucher) return;
    setCurrentCurrency(currencyMap[formValues.voucher]);
  }, [formValues.voucher]);

  useEffect(() => {
    const {currency, ...restFields} = topUpList.fields;

    if (currency.options) {
      setCurrencyMap(currency.options);
    }

    setFormFieldsList(reorderTopUpFieldsList(Object.values(restFields)));
    setFormValues(createInitialFormValues(restFields));
  }, []);

  useEffect(() => {
    window.addEventListener('blur', handleBlurWindow);
    return () => window.removeEventListener('blur', handleBlurWindow);
  }, []);

  return (
    <TopUpFormComponent
      description={topUpList.description}
      formFieldsList={formFieldsList}
      formValues={formValues}
      currentCurrency={currentCurrency}
      isDisabledCheckoutButton={isDisabledCheckoutButton}
      goPrevPage={goPrevPage}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      handlePaymentMethod={handlePaymentMethod}
      sendTopUpPaymentDetails={sendTopUpPaymentDetails}
    />
  );
};

export default TopUpFormContainer;
