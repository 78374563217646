// eslint-disable-next-line no-shadow
export enum TypeView {
  Popup = 'popup', // eslint-disable-line no-unused-vars
  Page = 'page', // eslint-disable-line no-unused-vars
}

export interface IFormViewRes {
  data: IFormItem[];
  url_form: null | string;
  type: string;
  name: string;
  method: null | string;
  type_output: null | string;
}

export interface IRadioItem {
  value: string;
  label: string;
}

export interface IOptionItem {
  label: string;
  value: string;
}

export interface IFormItem {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  multi?: boolean;
  value?: string;
  tooltip?: string;
  url?: string;
  radio_items?: IRadioItem[];
  content?: string;
  options?: IOptionItem[];
}

export interface IFormValues {
  [key: string]: any;
}

export interface IHandleValueChangeArgs {
  keyName: string;
  value: any;
}

export interface ISendFormArgs {
  slugUrl: string;
  body: IFormValues;
}

export interface IFormFields {
  fields: IFormItem[][];
  buttons: IFormItem[];
}

export interface IGetFormViewRequestArgs {
  slugUrl: string;
  apiUrl?: string;
}

export interface IFormConstructorState {
  loading: boolean;
  formViewMap: IFormViewRes | null;
  sendFormRes: IFormValues | null;
  apiUrl: string | undefined;
  error: any | null;
}
