/* eslint-disable react/no-array-index-key */
import {FC} from 'react';
import {useCardNumber} from '../../../../hooks';
import InputComponent from '../FormControls/InputComponent';
import DateComponent from '../FormControls/DateComponent';
import DateTimeComponent from '../FormControls/DateTimeComponent';
import SelectComponent from '../FormControls/SelectComponent';
import MultiSelectComponent from '../FormControls/MultiSelectComponent';
import TextAreaComponent from '../FormControls/TextAreaComponent';
import CardNumberInput from '../FormControls/CardNumberInput';
import ExpiredDate from '../FormControls/ExpiredDate';
import RadioComponent from '../FormControls/RadioComponent';
import SwitchComponent from '../FormControls/SwitchComponent';
import Paragraph from '../FormControls/Paragraph';
import SubmitButton from '../FormControls/SubmitButton';
import CancelButton from '../FormControls/CancelButton';
import {IFormCollectionProps} from './FormCollection.types';
import {IFormItem} from '../../../../../state/FormConstructor/formConstructor.types';
import {
  ErrorMessage,
  Container,
  Inner,
  FieldsWrapper,
  FieldsColumn,
  ButtonsGroup,
} from './FormCollection.styles';

const FormCollection: FC<IFormCollectionProps> = ({
  typeView,
  formFields,
  formValues,
  formHasError,
  submitForm,
  handleValueChange,
  handleCancelButton,
}: IFormCollectionProps) => {
  const cardNumberHook = useCardNumber({
    keyName: 'cardnumber',
    initialValue: formValues.cardnumber,
    handleValueChange,
  });

  return (
    <Container typeView={typeView}>
      <Inner>
        <FieldsWrapper typeView={typeView}>
          {formFields.fields.map((formArr, idx) => (
            <FieldsColumn key={`form-item-${idx}`} typeView={typeView}>
              {formArr.map((formItem: IFormItem, index) => {
                if (
                  formItem.type === 'input' &&
                  formItem.name !== 'expired_date'
                ) {
                  return (
                    <InputComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      value={formValues[formItem.name] || ''}
                      required={formItem.required}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'date') {
                  return (
                    <DateComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      value={formValues[formItem.name] || ''}
                      required={formItem.required}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'datetime') {
                  return (
                    <DateTimeComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      value={formValues[formItem.name] || ''}
                      required={formItem.required}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (
                  formItem.type === 'select' &&
                  formItem.options &&
                  formItem.multi
                ) {
                  return (
                    <MultiSelectComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      options={formItem.options}
                      required={formItem.required}
                      value={formValues[formItem.name] || ''}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'select' && formItem.options) {
                  return (
                    <SelectComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      options={formItem.options}
                      value={formValues[formItem.name] || ''}
                      required={formItem.required}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'textarea') {
                  return (
                    <TextAreaComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      required={formItem.required}
                      value={formValues[formItem.name] || ''}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'radio' && formItem.radio_items) {
                  return (
                    <RadioComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      radioItems={formItem.radio_items}
                      required={formItem.required}
                      value={formValues[formItem.name] || ''}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'toggle_switch') {
                  return (
                    <SwitchComponent
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      name={formItem.name}
                      required={formItem.required}
                      isChecked={formValues[formItem.name] || false}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (
                  formItem.type === 'input' &&
                  formItem.name === 'expired_date'
                ) {
                  return (
                    <ExpiredDate
                      key={`${formItem.name}-${index}`}
                      label={formItem.label}
                      required={formItem.required}
                      expiredMonth={formValues.expired_month || ''}
                      expiredYear={formValues.expired_year || ''}
                      handleValueChange={handleValueChange}
                    />
                  );
                }

                if (formItem.type === 'cardnumber') {
                  return (
                    <CardNumberInput
                      key={`${formItem.name}-${index}`}
                      name={formItem.name}
                      label={formItem.label}
                      required={formItem.required}
                      cardNumberMasked={cardNumberHook.cardNumberMasked}
                      handleEnterKey={cardNumberHook.handleEnterKey}
                      handlePasteCardNumber={
                        cardNumberHook.handlePasteCardNumber
                      }
                      handleChangeCardNumber={
                        cardNumberHook.handleChangeCardNumber
                      }
                    />
                  );
                }

                if (formItem.type === 'paragraph' && formItem.content) {
                  return (
                    <Paragraph
                      key={`${formItem.name}-${index}`}
                      content={formItem.content}
                    />
                  );
                }

                return (
                  <div key={`${formItem.name}-${index}`}>{formItem.type}</div>
                );
              })}
            </FieldsColumn>
          ))}
        </FieldsWrapper>
        <ButtonsGroup typeView={typeView}>
          {formFields.buttons.map((btn, index) => {
            if (btn.name === 'submit') {
              return (
                <SubmitButton
                  key={`${btn.name}-${index}`}
                  name={btn.name}
                  label={btn.label}
                  submitForm={submitForm}
                />
              );
            }

            if (btn.name === 'cancel') {
              return (
                <CancelButton
                  key={`${btn.name}-${index}`}
                  name={btn.name}
                  label={btn.label}
                  handleCancelButton={handleCancelButton}
                />
              );
            }

            return <div key={`${btn.name}-${index}`}>{btn.type}</div>;
          })}
        </ButtonsGroup>
        {formHasError ? (
          <ErrorMessage>Please fill all reqired fields</ErrorMessage>
        ) : null}
      </Inner>
    </Container>
  );
};

export default FormCollection;
