import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setVerifyNotificationState} from '../../../../../state';
import {Plus} from '../../../../static/images';
import coloredStripes from '../../../../styles/img/colored-stripes.png';
import dashedLine from '../../../../styles/img/dashed-line.png';
import logoIco from '../../../../styles/img/logo-transparent.png';
import {
  ColoredStripes,
  Button,
  ButtonGroup,
  Container,
  Inner,
  DashedLine,
  ButtonText,
  LogoIco,
  Content,
} from './EmptyVoucher.styles';
import {IEmptyVoucherProps} from './EmptyVoucher.types';

const EmptyVoucher: FC<IEmptyVoucherProps> = ({
  voucherId,
}: IEmptyVoucherProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  const buyVoucher = () => {
    if (userNotVerified) {
      dispatch(setVerifyNotificationState(true));
      return;
    }
    history.push(`/top-up/${voucherId}`);
  };

  return (
    <Container>
      <Inner>
        <Content>
          <LogoIco src={logoIco} alt='logo' />
        </Content>
        <ColoredStripes src={coloredStripes} alt='background' />
        <ButtonGroup>
          <Button onClick={buyVoucher}>
            <Plus fillColor='#fff' />
            <ButtonText>Buy a Voucher</ButtonText>
          </Button>
        </ButtonGroup>
      </Inner>
      <DashedLine src={dashedLine} alt='line' />
    </Container>
  );
};

export default EmptyVoucher;
