import {FC} from 'react';
import {Form} from 'antd';
import {useIntl} from 'react-intl';
import {useLocalValidation} from '../../../../hooks';
import {FormItem, InputWrapper, ButtonWrapper} from '../Auth.styles';

const ForgotPasswordForm: FC = (): JSX.Element => {
  const {formatMessage} = useIntl();
  const {emailRules} = useLocalValidation(formatMessage);
  const sendEmail = (values: {email: string}) => values;

  return (
    <Form
      layout='vertical'
      onFinish={sendEmail}
      initialValues={{
        email: '',
      }}
    >
      <FormItem
        label={formatMessage({id: 'EMAIL_INPUT_FIELD'})}
        name='email'
        rules={emailRules}
      >
        <InputWrapper
          type='email'
          autoComplete='off'
          placeholder={formatMessage({id: 'EMAIL_INPUT_FIELD_PLACEHOLDER'})}
        />
      </FormItem>
      <FormItem>
        <ButtonWrapper type='primary' htmlType='submit'>
          {formatMessage({id: 'FORGOT_PASSWORD_BUTTON'})}
        </ButtonWrapper>
      </FormItem>
    </Form>
  );
};

export default ForgotPasswordForm;
