import {FC, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Form, Input} from 'antd';
import {FormProps} from 'antd/lib/form';
import styled from 'styled-components';
import {RootState} from '../../../../state/store';
import {INotificationMap} from '../../../../state/Notification/notification.types';
import {sendContactRequest} from '../../../../state';
import {
  FormItemRow,
  FormItemRowTextArea,
  FormItemRowAlignSelf,
  InputWrapper,
  ButtonWrapper,
} from '../../MemberArea/Auth/Auth.styles';

const {TextArea} = Input;

const ContactForm: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {notificationMap}: {notificationMap: INotificationMap} = useSelector(
    (state: RootState) => state.notificationReducer,
  );

  const sendContactHandler = ({
    name,
    email,
    phone,
    comment,
  }: {
    name: string;
    email: string;
    phone: string;
    comment: string;
  }): void => {
    dispatch(sendContactRequest(name, email, phone, comment));
  };

  useEffect(() => {
    if (notificationMap.isShow && notificationMap.type === 'success') {
      form.resetFields();
    }
  }, [notificationMap]);

  return (
    <StyledForm
      layout='vertical'
      form={form}
      onFinish={sendContactHandler}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        comment: '',
      }}
    >
      <FormItemRow label='Name' name='name'>
        <InputWrapper placeholder='Name' />
      </FormItemRow>
      <FormItemRow label='Email address' name='email'>
        <InputWrapper
          autoComplete='off'
          placeholder='Enter your email address'
        />
      </FormItemRow>
      <FormItemRow label='Phone number' name='phone'>
        <InputWrapper placeholder='Enter your phone number' />
      </FormItemRow>
      <FormItemRowTextArea label='Comment' name='comment'>
        <TextArea
          rows={4}
          autoSize={{minRows: 3, maxRows: 4}}
          placeholder='Enter your question or comment'
        />
      </FormItemRowTextArea>
      <FormItemRowAlignSelf>
        <ButtonWrapper type='primary' htmlType='submit'>
          Send
        </ButtonWrapper>
      </FormItemRowAlignSelf>
    </StyledForm>
  );
};

export default ContactForm;

const StyledForm: FC<FormProps> = styled(Form)`
  display: flex;
  flex-direction: column;
`;
