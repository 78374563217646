import axios, {AxiosResponse, AxiosPromise, AxiosRequestConfig} from 'axios';
import {AUTHORIZATION} from './constants';
import {getLocalAccessToken, setUser, getCurrentUser} from './token.service';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token: string = getLocalAccessToken();
    if (token && config && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: Error): Promise<Error> => Promise.reject(error),
);

instance.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => res,
  async (error): Promise<AxiosPromise> => {
    const originalConfig = error.config;

    if (originalConfig.url !== AUTHORIZATION.LOGIN && error.response) {
      // Token is not valid
      // eslint-disable-next-line no-underscore-dangle
      if (error.response.status === 401 && !originalConfig._retry) {
        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = true;
        const {refresh_token} = getCurrentUser();

        try {
          const rs = await instance.post(AUTHORIZATION.REFRESH_TOKEN, {
            refresh_token,
          });
          setUser(rs.data);
          return instance(originalConfig);
        } catch (_error) {
          if (axios.isAxiosError(_error)) {
            if (_error.response) return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error.response.data);
  },
);

export default instance;
