import {FC} from 'react';
import FormItem from '../../FormItem';
import {ISwitchComponentProps} from './SwitchComponent.types';
import {SwitchWrapper} from './SwitchComponent.styles';

const SwitchComponent: FC<ISwitchComponentProps> = ({
  name,
  label,
  required,
  isChecked,
  handleValueChange,
}: ISwitchComponentProps) => (
  <FormItem label={label} required={required}>
    <SwitchWrapper
      defaultChecked={isChecked}
      onChange={(checked) => handleValueChange({keyName: name, value: checked})}
    />
  </FormItem>
);

export default SwitchComponent;
