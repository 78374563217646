import {Dispatch, ActionCreator} from 'redux';
import {handleErrorFirstStep} from './handleErrorFirstStep';

interface IHandleError {
  error: any;
  dispatch: Dispatch;
  failureAction: ActionCreator<any>;
  notificationAction: ActionCreator<any>;
}

// eslint-disable-next-line import/prefer-default-export
export const handleErrorNotification = ({
  error,
  dispatch,
  failureAction,
  notificationAction,
}: IHandleError) => {
  handleErrorFirstStep({error, dispatch, failureAction});

  if (!error.response) {
    dispatch(
      notificationAction({
        isShow: true,
        type: 'error',
        message: 'Something went wrong, try later',
      }),
    );
    return dispatch(failureAction('Something went wrong, try later'));
  }

  //! Fix when message will be string
  if (typeof error.response.data.message !== 'string') {
    return dispatch(
      notificationAction({
        isShow: true,
        type: 'error',
        message: JSON.stringify(error.response.data.message),
      }),
    );
  }

  return dispatch(
    notificationAction({
      isShow: true,
      type: 'error',
      message: error.response.data.message,
    }),
  );
};
