import {AxiosResponse} from 'axios';
import {
  createIncompleteDataItemRequestAction,
  createIncompleteDataItemSuccessAction,
  createIncompleteDataItemFailureAction,
  storeIncompleteDataItemRequestAction,
  storeIncompleteDataItemSuccessAction,
  storeIncompleteDataItemFailureAction,
} from '../actions/create.actions';
import {
  createIncompleteInvoice,
  storeIncompleteInvoice,
} from '../../../infra/incompleteInvoices.service';
import {ThunkActionType} from '../../store';
import {getIncompleteDataRequest} from './read.requests';
import {ItemDataCreate} from '../incompleteInvoices.types';
import {setNotificationState} from '../../Notification/notification.actions';
import {
  handleErrorNotification,
  handleResponseFailNotification,
  handleResponseExtraAction,
} from '../../../utils';

export const createIncompleteDataItemRequest =
  (slug: string): ThunkActionType =>
  (dispatch) => {
    dispatch(createIncompleteDataItemRequestAction());
    return createIncompleteInvoice(slug)
      .then((response: AxiosResponse) => {
        handleResponseFailNotification({
          response,
          dispatch,
          successAction: createIncompleteDataItemSuccessAction,
          failureAction: createIncompleteDataItemFailureAction,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: createIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };

export const storeIncompleteDataItemRequest =
  (slug: string, dataCreate: ItemDataCreate): ThunkActionType =>
  (dispatch, getState) => {
    const {currentPageTable, pageSizeTable} = getState().standardizedReducer;
    const idxSlash = slug.indexOf('/');
    const slugGetAllItem = slug.substring(0, idxSlash);
    const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

    dispatch(storeIncompleteDataItemRequestAction());
    return storeIncompleteInvoice(slug, dataCreate)
      .then((response: AxiosResponse) => {
        handleResponseExtraAction({
          response,
          dispatch,
          successAction: storeIncompleteDataItemSuccessAction,
          failureAction: storeIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
          extraRequest: getIncompleteDataRequest,
          extraRequestArg: slugPagination,
        });
      })
      .catch((error) => {
        handleErrorNotification({
          error,
          dispatch,
          failureAction: storeIncompleteDataItemFailureAction,
          notificationAction: setNotificationState,
        });
      });
  };
