import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Partners: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.99999 6C9.10456 6 9.99999 5.10457 9.99999 4C9.99999 2.89543 9.10456 2 7.99999 2C6.89542 2 5.99999 2.89543 5.99999 4C5.99999 5.10457 6.89542 6 7.99999 6ZM12 11.24C12 8.16 10.3704 7.28 7.99999 7.28C5.62962 7.28 3.99999 8.16 3.99999 11.24C3.99999 12.168 4.88888 13 7.99999 13C11.1111 13 12 12.072 12 11.24ZM14.5455 6.45455C14.5455 7.25787 13.8942 7.90909 13.0909 7.90909C12.2876 7.90909 11.6364 7.25787 11.6364 6.45455C11.6364 5.65122 12.2876 5 13.0909 5C13.8942 5 14.5455 5.65122 14.5455 6.45455ZM13.0909 8.84C14.8148 8.84 16 9.48 16 11.72C16 12.3251 15.3535 13 13.0909 13C12.7965 13 12.5294 12.9898 12.2875 12.9704C12.8038 12.5403 13 12.0275 13 11.5385C13 10.4619 12.8728 9.57753 12.6357 8.85651C12.7827 8.84526 12.9346 8.84 13.0909 8.84ZM2.90909 7.90909C2.10577 7.90909 1.45455 7.25787 1.45455 6.45455C1.45455 5.65122 2.10577 5 2.90909 5C3.71241 5 4.36364 5.65122 4.36364 6.45455C4.36364 7.25787 3.71241 7.90909 2.90909 7.90909ZM0 11.72C0 9.48 1.18518 8.84 2.90909 8.84C3.06543 8.84 3.21735 8.84526 3.36435 8.85651C3.12723 9.57753 3.00001 10.4619 3.00001 11.5385C3.00001 12.0275 3.19626 12.5403 3.71248 12.9704C3.47063 12.9898 3.20354 13 2.90909 13C0.646464 13 0 12.3251 0 11.72Z'
      fill={fillColor}
    />
  </svg>
);
