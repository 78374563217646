import {FC} from 'react';
import {IPaymentMethodItemProps} from './PaymentMethodItem.types';
import {StyledRadio} from '../../Auth/Auth.styles';
import {Container, Header, Heading} from './PaymentMethodItem.styles';
import {defineIcon} from './defineIcon';

const PaymentMethodItem: FC<IPaymentMethodItemProps> = ({
  keyProp,
  checked,
  paymentMethodKey,
  paymentMethodName,
  paymentMethodIcon,
  handlePaymentMethod,
}: IPaymentMethodItemProps) => (
  <Container checked={checked}>
    <Header>
      <StyledRadio
        checked={checked}
        onChange={() => handlePaymentMethod(keyProp, paymentMethodKey)}
      />
      {defineIcon(paymentMethodIcon)}
      <Heading>{paymentMethodName}</Heading>
    </Header>
  </Container>
);

export default PaymentMethodItem;
