import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Spin} from 'antd';
import {useIntl} from 'react-intl';
import {RootState} from '../../../../../state/store';
import {changePasswordRequest} from '../../../../../state';
import {useLocalValidation} from '../../../../hooks';
import {IPasswordFormFields} from './PasswordForm.types';
import {FormItem, InputWrapperPassword} from '../../Auth/Auth.styles';
import {FormWrapper, ButtonGroup, ButtonWrapper} from './PasswordForm.styles';

const PasswordForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();

  const {passwordProfileRules, passwordRules, confirmPasswordRules} =
    useLocalValidation(formatMessage, 'new_password');

  const {loading, changedPasswordResponse} = useSelector(
    (state: RootState) => state.profileReducer,
  );

  const [inFocusPasswordInput, setInFocusPasswordInput] = useState({
    current_password: false,
    new_password: false,
    new_confirm_password: false,
  });

  const [valuePasswordInput, setValuePasswordInput] = useState<{
    [key: string]: string;
  }>({
    current_password: '',
    new_password: '',
    new_confirm_password: '',
  });

  const handleFocusPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInFocusPasswordInput({
      ...inFocusPasswordInput,
      [event.target.id]: true,
    });
  };

  const handleBlurPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (valuePasswordInput[event.target.id].length > 0) {
      setInFocusPasswordInput({
        ...inFocusPasswordInput,
        [event.target.id]: true,
      });
      return;
    }

    setInFocusPasswordInput({
      ...inFocusPasswordInput,
      [event.target.id]: false,
    });
  };

  const handleChangePasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValuePasswordInput({
      ...valuePasswordInput,
      [event.target.id]: event.target.value,
    });
  };

  const onReset = () => form.resetFields();
  const passwordFormHandler = (values: IPasswordFormFields) => {
    dispatch(changePasswordRequest(values));
  };

  useEffect(() => {
    if (!changedPasswordResponse) return;
    onReset();
  }, [changedPasswordResponse]);

  if (loading) return <Spin tip='Loading...' spinning={loading} size='large' />;

  return (
    <FormWrapper>
      <Form
        layout='vertical'
        form={form}
        onFinish={passwordFormHandler}
        initialValues={{
          current_password: '',
          new_password: '',
          new_confirm_password: '',
        }}
      >
        <FormItem
          label='Current Password'
          name='current_password'
          rules={passwordProfileRules}
        >
          <InputWrapperPassword
            spellCheck='false'
            onFocus={handleFocusPasswordInput}
            onBlur={handleBlurPasswordInput}
            onChange={handleChangePasswordInput}
            placeholder={
              inFocusPasswordInput.current_password
                ? ''
                : 'Confirm your current password'
            }
            $borderRadius='8px'
            type='text'
            $inFocus={inFocusPasswordInput.current_password}
          />
        </FormItem>
        <FormItem
          label='New password'
          name='new_password'
          rules={passwordRules}
        >
          <InputWrapperPassword
            spellCheck='false'
            onFocus={handleFocusPasswordInput}
            onBlur={handleBlurPasswordInput}
            onChange={handleChangePasswordInput}
            placeholder={
              inFocusPasswordInput.new_password
                ? ''
                : 'Enter your your new password'
            }
            $borderRadius='8px'
            type='text'
            $inFocus={inFocusPasswordInput.new_password}
          />
        </FormItem>
        <FormItem
          label='Confirm new password'
          name='new_confirm_password'
          rules={confirmPasswordRules}
        >
          <InputWrapperPassword
            spellCheck='false'
            onFocus={handleFocusPasswordInput}
            onBlur={handleBlurPasswordInput}
            onChange={handleChangePasswordInput}
            placeholder={
              inFocusPasswordInput.new_confirm_password
                ? ''
                : 'Confirm new password'
            }
            $borderRadius='8px'
            type='text'
            $inFocus={inFocusPasswordInput.new_confirm_password}
          />
        </FormItem>
        <FormItem>
          <ButtonGroup>
            <ButtonWrapper type='primary' htmlType='submit' $borderRadius='8px'>
              Change password
            </ButtonWrapper>
            <ButtonWrapper
              type='primary'
              htmlType='button'
              $borderRadius='8px'
              onClick={onReset}
            >
              Cancel
            </ButtonWrapper>
          </ButtonGroup>
        </FormItem>
      </Form>
    </FormWrapper>
  );
};

export default PasswordForm;
