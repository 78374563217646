import {AnyAction} from 'redux';
import {
  UPLOAD_VERIFICATION_FILE_REQUEST,
  UPLOAD_VERIFICATION_FILE_SUCCESS,
  UPLOAD_VERIFICATION_FILE_FAILURE,
  GET_VERIFICATION_DOCUMENTS_REQUEST,
  GET_VERIFICATION_DOCUMENTS_SUCCESS,
  GET_VERIFICATION_DOCUMENTS_FAILURE,
  DELETE_VERIFICATION_DOCUMENT_REQUEST,
  DELETE_VERIFICATION_DOCUMENT_SUCCESS,
  DELETE_VERIFICATION_DOCUMENT_FAILURE,
  STORE_PERSONAL_INFO_REQUEST,
  STORE_PERSONAL_INFO_SUCCESS,
  STORE_PERSONAL_INFO_FAILURE,
  VERIFY_CLIENT_REQUEST,
  VERIFY_CLIENT_SUCCESS,
  VERIFY_CLIENT_FAILURE,
  CLEAR_VERIFICATION_RESPONSE,
  SET_UPLOAD_FILE_URL,
  SET_SEND_FORM_URL,
  CLEAR_VERIFICATION_DOCUMENTS_DATA,
} from './verification.actions';
import {IVerificationState} from './verification.types';

const initialState: IVerificationState = {
  loading: false,
  loadingsUploadFiles: null,
  loadingStorePersonalInfo: false,
  loadingVerifyClient: false,
  responseVerification: null,
  verificationDocumentsData: null,
  responseDeleteFile: null,
  responseSendPersonalInfo: null,
  responseVerifyClient: null,
  uploadFileUrl: '',
  sendFormUrl: '',
  error: null,
};

const verificationReducer = (
  state: IVerificationState = initialState,
  action: AnyAction,
): IVerificationState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_VERIFICATION_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case STORE_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loadingStorePersonalInfo: true,
        error: null,
      };

    case DELETE_VERIFICATION_DOCUMENT_REQUEST:
    case UPLOAD_VERIFICATION_FILE_REQUEST:
      return {
        ...state,
        loadingsUploadFiles: {
          [action.payload]: true,
        },
      };

    case VERIFY_CLIENT_REQUEST:
      return {
        ...state,
        loadingVerifyClient: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_VERIFICATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        verificationDocumentsData: action.payload,
        error: null,
      };

    case UPLOAD_VERIFICATION_FILE_SUCCESS:
      return {
        ...state,
        loadingsUploadFiles: null,
        responseVerification: action.payload,
        error: null,
      };

    case DELETE_VERIFICATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingsUploadFiles: null,
        responseDeleteFile: action.payload,
        error: null,
      };

    case STORE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        loadingStorePersonalInfo: false,
        responseSendPersonalInfo: action.payload,
      };

    case VERIFY_CLIENT_SUCCESS:
      return {
        ...state,
        loadingVerifyClient: false,
        error: null,
        responseVerifyClient: action.payload,
      };

    // MISC ACTIONS
    case CLEAR_VERIFICATION_RESPONSE:
      return {
        ...state,
        responseVerification: null,
      };

    case SET_UPLOAD_FILE_URL:
      return {
        ...state,
        uploadFileUrl: action.payload,
      };

    case SET_SEND_FORM_URL:
      return {
        ...state,
        sendFormUrl: action.payload,
      };

    case CLEAR_VERIFICATION_DOCUMENTS_DATA:
      return {
        ...state,
        verificationDocumentsData: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_VERIFICATION_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STORE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loadingStorePersonalInfo: false,
        error: action.payload,
      };

    case UPLOAD_VERIFICATION_FILE_FAILURE:
    case DELETE_VERIFICATION_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingsUploadFiles: null,
        error: action.payload,
      };

    case VERIFY_CLIENT_FAILURE:
      return {
        ...state,
        loadingVerifyClient: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default verificationReducer;
