import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {Spin} from 'antd';
import TableViewComponent from './TableViewComponent';
import {ColumnItem, formColumns} from './dataTable';
import {RootState} from '../../../../../state/store';
import {
  editStandardDataItemRequest,
  destroyStandardDataItemRequest,
  copyStandardDataItemRequest,
  getStandardDataPerPageRequest,
  setCurrentTablePageAction,
  setPageSizeTableAction,
  getFormViewRequest,
  changeStatusNotificationRequest,
} from '../../../../../state';
import {IHandlerMenuItemArgs} from '../DropDownMenu/DropDownMenu.types';
import {IBodyTableItem, IHeaderTableItem, ITableProps} from './TableView.types';
import {
  handleColumnsCount,
  stringifyObjectContent,
  whetherExpandRow,
} from '../../../../../utils';
import {Menu} from '../../../../static/images';
import {DefinitionItem, ExpandedRowData, TermItem} from './TableView.styles';

const TableView: FC<ITableProps> = ({data, pagination}: ITableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {tableName} = useParams<{tableName: string}>();

  const minWidth401PX = useMediaQuery({query: '(min-width: 401px)'});
  const maxWidth400PX = useMediaQuery({query: '(max-width: 400px)'});

  const [isExpandRow, setExpandRow] = useState(false);
  const [tableHeaderSortable, setTableHeaderSortable] = useState<ColumnItem[]>(
    [],
  );

  const {
    loading,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
    itemDataEdit,
    itemDataCopied,
    currentSlug,
    currentPageTable,
    pageSizeTable,
  } = useSelector((state: RootState) => state.standardizedReducer);

  const goToVerificationPage = (path: string): void => {
    const userId = path.replace('/verification/', '');

    history.push(path, {
      from: 'notifications',
      userId,
    });
  };

  const switchStatusNotification = (id: string): void => {
    dispatch(changeStatusNotificationRequest(id));
  };

  const actionsHandler = (args: IHandlerMenuItemArgs): void => {
    const {slugUrl, altApiUrl} = args;

    if (slugUrl.indexOf('edit') >= 0) {
      if (altApiUrl) {
        dispatch(getFormViewRequest({slugUrl: altApiUrl, apiUrl: slugUrl}));
        return;
      }

      dispatch(editStandardDataItemRequest(slugUrl));
      return;
    }

    if (slugUrl.indexOf('copy') >= 0) {
      if (altApiUrl) {
        dispatch(getFormViewRequest({slugUrl: altApiUrl, apiUrl: slugUrl}));
        return;
      }

      dispatch(copyStandardDataItemRequest(slugUrl));
      return;
    }

    if (slugUrl.indexOf('destroy') >= 0) {
      if (altApiUrl) {
        dispatch(getFormViewRequest({slugUrl: altApiUrl, apiUrl: slugUrl}));
        return;
      }

      dispatch(destroyStandardDataItemRequest(slugUrl));
      return;
    }

    if (slugUrl.indexOf('/api/display') >= 0) {
      dispatch(getFormViewRequest({slugUrl, apiUrl: slugUrl}));
      return;
    }

    history.push(`/s/${slugUrl}`);
  };

  const onRow = (record: {[key: string]: any}) => ({
    onDoubleClick: () => {
      const id = record.id || record.iD || record.Id || record.ID;

      if (id) {
        history.push(`/s/${tableName}/${id}`);
      }
    },
  });

  useEffect(() => {
    if (!data) return;
    if (!data.sortable) return;

    const headerSortable = data.header.map((item: IHeaderTableItem) => {
      if (item.dataIndex.toLowerCase() === 'actions') return item;

      return {
        ...item,
        defaultSortOrder: 'descend',
        sorter: (a: IBodyTableItem, b: IBodyTableItem) => {
          const notValidDateType = Number.isNaN(
            new Date(a[item.key]).getTime(),
          );
          const stringType = typeof a[item.key] === 'string';
          const stringLength = a[item.key].length >= 8;

          if (notValidDateType) {
            return a[item.key] - b[item.key];
          }

          if (stringType && stringLength) {
            return (
              new Date(a[item.key]).getTime() - new Date(b[item.key]).getTime()
            );
          }

          return a[item.key] - b[item.key];
        },
      };
    });

    const actionColumn = data.header.find(
      (column) => column.dataIndex === 'actions',
    );

    const formattedColumns = handleColumnsCount(
      minWidth401PX,
      actionColumn,
      headerSortable,
      actionsHandler,
      goToVerificationPage,
      switchStatusNotification,
      formColumns,
    );

    const columnsActionsIcon = formattedColumns.map((col) => {
      if (col.dataIndex.toLowerCase() === 'actions') {
        return {...col, title: <Menu />, width: 20};
      }

      return col;
    });

    setTableHeaderSortable(columnsActionsIcon);
  }, [data, minWidth401PX]);

  useEffect(() => {
    setExpandRow(whetherExpandRow(maxWidth400PX, data.header));
  }, [tableHeaderSortable]);

  const renderRow = (record: IBodyTableItem) => {
    const entries = Object.entries(record);

    const dataTableOutColumnsRaw = entries.filter((itm) => {
      const dataTableItemInColumns = tableHeaderSortable.find(
        (el) => el.dataIndex === itm[0],
      );

      // eslint-disable-next-line no-unneeded-ternary
      return dataTableItemInColumns ? false : true;
    });

    const dataTableOutColumns = dataTableOutColumnsRaw.filter(
      (itm) => itm[0] !== 'key',
    );

    return (
      <div>
        {dataTableOutColumns.map((itm) => (
          <ExpandedRowData key={itm[0]}>
            <TermItem>{itm[0]}</TermItem>
            <DefinitionItem>{itm[1]}</DefinitionItem>
          </ExpandedRowData>
        ))}
      </div>
    );
  };

  const loadings = {loading, loadingDataItemEdit};
  const itemData = {itemDataEdit, itemDataCopied};

  const formattedBody = data.body.map((item) => {
    const newItem = stringifyObjectContent(item);
    return newItem;
  });

  const isPaginationExist = pagination ? pagination.total : false;
  const isPerPageExist = isPaginationExist && isPaginationExist > pageSizeTable;

  const paginationMap = {
    pageSize: pageSizeTable,
    total: pagination?.total,
    current: currentPageTable,
    showSizeChanger: !!isPerPageExist,
    onChange: (page: number, pageSize?: number | undefined) => {
      dispatch(setCurrentTablePageAction(page));
      if (pageSize) {
        dispatch(setPageSizeTableAction(pageSize));
        dispatch(
          getStandardDataPerPageRequest(
            tableName,
            `page=${page}&perPage=${pageSize}`,
          ),
        );
      }
    },
  };

  if (loadingDataItemEdit || loadingDataItemCopy || loadingDataItemView) {
    return (
      <Spin
        tip='Loading...'
        spinning={
          loadingDataItemEdit || loadingDataItemCopy || loadingDataItemView
        }
        size='large'
      />
    );
  }

  return (
    <TableViewComponent
      onRow={onRow}
      expandable={isExpandRow ? {expandedRowRender: renderRow} : {}}
      dataSource={formattedBody}
      columns={tableHeaderSortable}
      pagination={paginationMap}
      loadings={loadings}
      itemData={itemData}
      currentSlug={currentSlug}
    />
  );
};

export default TableView;
