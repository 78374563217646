import api from './config';
import {removeUser, removeVerifyStateUser} from './token.service';
import {AUTHORIZATION} from './constants';
import {
  clearFirstStepAuth,
  clearRedirectUrlPayment,
  clearVerificationUrl,
} from './verification.service';
import {ISocialAuthCallbackArgs} from '../state/Auth/auth.types';

const register = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  password_confirmation: string,
) =>
  api.post(AUTHORIZATION.REGISTER, {
    firstName,
    lastName,
    email,
    password,
    password_confirmation,
  });

const registerPayment = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  password_confirmation: string,
  short: string,
  inv_id: string,
) =>
  api.post(AUTHORIZATION.REGISTER, {
    firstName,
    lastName,
    email,
    password,
    password_confirmation,
    short,
    inv_id,
  });

const login = (email: string, password: string) =>
  api.post(AUTHORIZATION.LOGIN, {email, password});

const logout = (): void => {
  removeUser();
  removeVerifyStateUser();
  clearVerificationUrl();
  clearFirstStepAuth();
  clearRedirectUrlPayment();
};

const redirectPaymentRegister = (url: string) => api.get(url);

const getCurrentUser = (): void =>
  JSON.parse(localStorage.getItem('user') as string);

const verifyEmail = (verifyUrl: string) => api.get(verifyUrl);

const socialAuth = (authProvider: string) =>
  api.get(`${AUTHORIZATION.SOCAIL_AUTH}/${authProvider}`);

const socialAuthCallback = (
  socialAuthCallbackArgs: ISocialAuthCallbackArgs,
) => {
  const {authProvider, params} = socialAuthCallbackArgs;
  return api.get(
    `${AUTHORIZATION.SOCAIL_AUTH}/${authProvider}${AUTHORIZATION.CALLBACK}${params}`,
  );
};

export {
  register,
  registerPayment,
  login,
  logout,
  getCurrentUser,
  redirectPaymentRegister,
  verifyEmail,
  socialAuth,
  socialAuthCallback,
};
