import {AnyAction} from 'redux';
import {
  CREATE_INCOMPLETE_DATA_ITEM_REQUEST,
  CREATE_INCOMPLETE_DATA_ITEM_SUCCESS,
  CREATE_INCOMPLETE_DATA_ITEM_FAILURE,
  STORE_INCOMPLETE_DATA_ITEM_REQUEST,
  STORE_INCOMPLETE_DATA_ITEM_SUCCESS,
  STORE_INCOMPLETE_DATA_ITEM_FAILURE,
} from './actions/create.actions';
import {
  GET_INCOMPLETE_DATA_REQUEST,
  GET_INCOMPLETE_DATA_SUCCESS,
  GET_INCOMPLETE_DATA_FAILURE,
  GET_INCOMPLETE_DATA_ITEM_REQUEST,
  GET_INCOMPLETE_DATA_ITEM_SUCCESS,
  GET_INCOMPLETE_DATA_ITEM_FAILURE,
  COPY_INCOMPLETE_DATA_ITEM_REQUEST,
  COPY_INCOMPLETE_DATA_ITEM_SUCCESS,
  COPY_INCOMPLETE_DATA_ITEM_FAILURE,
} from './actions/read.actions';
import {
  EDIT_INCOMPLETE_DATA_ITEM_REQUEST,
  EDIT_INCOMPLETE_DATA_ITEM_SUCCESS,
  EDIT_INCOMPLETE_DATA_ITEM_FAILURE,
  UPDATE_INCOMPLETE_DATA_ITEM_REQUEST,
  UPDATE_INCOMPLETE_DATA_ITEM_SUCCESS,
  UPDATE_INCOMPLETE_DATA_ITEM_FAILURE,
} from './actions/update.actions';
import {
  DESTROY_INCOMPLETE_DATA_ITEM_REQUEST,
  DESTROY_INCOMPLETE_DATA_ITEM_SUCCESS,
  DESTROY_INCOMPLETE_DATA_ITEM_FAILURE,
} from './actions/delete.actions';
import {
  CLEAR_INCOMPLETE_DATA_ITEM,
  CLEAR_INCOMPLETE_DATA_ITEM_COPY,
  CLEAR_INCOMPLETE_DATA_ITEM_CREATE,
  CLEAR_INCOMPLETE_PAYMENT_FIELDS,
  SET_CURRENT_SLUG,
  CLEAR_PAY_SUCCESS_RES,
  CLEAR_ERROR_STATE,
} from './actions/misc.actions';
import {
  PAY_INCOMPLETE_INVOICE_INIT_REQUEST,
  PAY_INCOMPLETE_INVOICE_INIT_SUCCESS,
  PAY_INCOMPLETE_INVOICE_INIT_FAILURE,
  PAY_INCOMPLETE_INVOICE_SEND_REQUEST,
  PAY_INCOMPLETE_INVOICE_SEND_SUCCESS,
  PAY_INCOMPLETE_INVOICE_SEND_FAILURE,
  DECLINE_INCOMPLETE_INVOICE_REQUEST,
  DECLINE_INCOMPLETE_INVOICE_SUCCESS,
  DECLINE_INCOMPLETE_INVOICE_FAILURE,
} from './actions/payment.actions';
import {IIncompleteState} from './incompleteInvoices.types';

const initialState: IIncompleteState = {
  loading: false,
  loadingDataItemView: false,
  loadingDataItemEdit: false,
  loadingDataItemCopy: false,
  incompleteData: null,
  currentSlug: '',
  itemDataView: null,
  itemDataEdit: null,
  itemDataCopied: null,
  fieldsCreateItem: null,
  payList: null,
  paySuccessRes: null,
  error: null,
};

const incompleteInvoicesReducer = (
  state: IIncompleteState = initialState,
  action: AnyAction,
): IIncompleteState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_INCOMPLETE_DATA_REQUEST:
    case CREATE_INCOMPLETE_DATA_ITEM_REQUEST:
    case STORE_INCOMPLETE_DATA_ITEM_REQUEST:
    case UPDATE_INCOMPLETE_DATA_ITEM_REQUEST:
    case DESTROY_INCOMPLETE_DATA_ITEM_REQUEST:
    case PAY_INCOMPLETE_INVOICE_INIT_REQUEST:
    case PAY_INCOMPLETE_INVOICE_SEND_REQUEST:
    case DECLINE_INCOMPLETE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_INCOMPLETE_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemView: true,
        error: null,
      };

    case COPY_INCOMPLETE_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemCopy: true,
        error: null,
      };

    case EDIT_INCOMPLETE_DATA_ITEM_REQUEST:
      return {
        ...state,
        loadingDataItemEdit: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_INCOMPLETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        incompleteData: action.payload,
        error: null,
      };

    case GET_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemView: false,
        itemDataView: action.payload,
        error: null,
      };

    case COPY_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemCopy: false,
        currentSlug: action.payload.slug,
        itemDataCopied: action.payload.fields,
        error: null,
      };

    case CREATE_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: action.payload.slug,
        fieldsCreateItem: action.payload.fields,
        error: null,
      };

    case STORE_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        fieldsCreateItem: null,
        itemDataCopied: null,
        error: null,
      };

    case EDIT_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loadingDataItemEdit: false,
        currentSlug: action.payload.slug,
        itemDataEdit: action.payload.fields,
        error: null,
      };

    case UPDATE_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: null,
      };

    case DESTROY_INCOMPLETE_DATA_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: null,
      };

    case PAY_INCOMPLETE_INVOICE_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        payList: action.payload,
        error: null,
      };

    case PAY_INCOMPLETE_INVOICE_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        payList: null,
        paySuccessRes: action.payload,
        error: null,
      };

    case DECLINE_INCOMPLETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    // MISC ACTIONS
    case CLEAR_INCOMPLETE_DATA_ITEM:
      return {
        ...state,
        itemDataEdit: null,
      };

    case CLEAR_INCOMPLETE_DATA_ITEM_COPY:
      return {
        ...state,
        itemDataCopied: null,
      };

    case CLEAR_INCOMPLETE_DATA_ITEM_CREATE:
      return {
        ...state,
        currentSlug: '',
        fieldsCreateItem: null,
      };

    case CLEAR_INCOMPLETE_PAYMENT_FIELDS:
      return {
        ...state,
        payList: null,
      };

    case SET_CURRENT_SLUG:
      return {
        ...state,
        currentSlug: action.payload,
      };

    case CLEAR_PAY_SUCCESS_RES:
      return {
        ...state,
        paySuccessRes: null,
      };

    case CLEAR_ERROR_STATE:
      return {
        ...state,
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case COPY_INCOMPLETE_DATA_ITEM_FAILURE:
      return {
        ...state,
        loadingDataItemCopy: false,
        error: action.payload,
      };

    case CREATE_INCOMPLETE_DATA_ITEM_FAILURE:
    case STORE_INCOMPLETE_DATA_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        fieldsCreateItem: null,
        itemDataCopied: null,
        error: action.payload,
      };

    case EDIT_INCOMPLETE_DATA_ITEM_FAILURE:
    case UPDATE_INCOMPLETE_DATA_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        currentSlug: '',
        itemDataEdit: null,
        error: action.payload,
      };

    case GET_INCOMPLETE_DATA_ITEM_FAILURE:
      return {
        ...state,
        loadingDataItemView: false,
        error: action.payload,
      };

    case GET_INCOMPLETE_DATA_FAILURE:
    case DESTROY_INCOMPLETE_DATA_ITEM_FAILURE:
    case PAY_INCOMPLETE_INVOICE_INIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAY_INCOMPLETE_INVOICE_SEND_FAILURE:
      return {
        ...state,
        loading: false,
        payList: null,
        error: action.payload,
      };

    case DECLINE_INCOMPLETE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default incompleteInvoicesReducer;
