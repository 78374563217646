import {FC} from 'react';
import {TextAreaComponentProps} from './TextAreaComponent.types';
import {TextAreaWrapper} from './TextAreaComponent.styles';
import FormItem from '../../FormItem';

const TextAreaComponent: FC<TextAreaComponentProps> = ({
  name,
  label,
  value,
  required,
  handleValueChange,
}: TextAreaComponentProps) => (
  <FormItem label={label} required={required}>
    <TextAreaWrapper
      value={value}
      placeholder={label}
      onChange={(event) =>
        handleValueChange({keyName: name, value: event.target.value})
      }
    />
  </FormItem>
);

export default TextAreaComponent;
