import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const Send: FC<IProps> = ({fillColor = '#192B3B'}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2049_3512)'>
      <path
        d='M15.4473 8.89444C15.786 8.72505 16 8.37878 16 8.00001C16 7.62124 15.786 7.27498 15.4473 7.10558L1.44726 0.105584C1.09256 -0.0717665 0.666568 -0.0227601 0.361406 0.230502C0.0562439 0.483764 -0.0704267 0.893423 0.0385181 1.27473L1.46709 6.27472C1.58974 6.70402 1.98213 7 2.42861 7L7.00004 7C7.55233 7 8.00004 7.44772 8.00004 8C8.00004 8.55229 7.55233 9 7.00004 9H2.42862C1.98213 9 1.58975 9.29598 1.46709 9.72528L0.0385184 14.7253C-0.0704265 15.1066 0.0562433 15.5163 0.361406 15.7695C0.666567 16.0228 1.09256 16.0718 1.44726 15.8944L15.4473 8.89444Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_2049_3512'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
