import {FC} from 'react';
import TransactionsList from '../TransactionsList';
import {IListItem} from '../CollapseList/CollapseList.types';
import EmptyCard from '../EmptyCard';
import {ITransactionsPartProps} from './TransactionsPart.types';
import {TopTableInfo, Title} from './TransactionsPart.styles';
import VerifyAccount from '../../../../components/VerifyAccount';

const TransactionsPart: FC<ITransactionsPartProps> = ({
  table,
}: ITransactionsPartProps): JSX.Element => {
  const tableTitle = table ? table.tableTitle : '';
  const listItems: IListItem[] = table ? Object.values(table.body) : [];
  const isUserVerified = localStorage.getItem('isUserVerified');
  const userNotVerified = isUserVerified && isUserVerified === 'unverified';

  return (
    <>
      {userNotVerified ? (
        <VerifyAccount />
      ) : (
        <>
          <TopTableInfo>
            <Title>{tableTitle}</Title>
          </TopTableInfo>
          {listItems.length > 0 ? (
            <TransactionsList listItems={listItems} />
          ) : (
            <EmptyCard />
          )}
        </>
      )}
    </>
  );
};

export default TransactionsPart;
