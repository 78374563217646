import {ITopUpFieldItem} from '../../state/TopUp/topUp.types';

// eslint-disable-next-line import/prefer-default-export
export const reorderInputAmount = (rawFieldsList: ITopUpFieldItem[]) => {
  const inputAmountIdx = rawFieldsList.findIndex(
    (itm: ITopUpFieldItem) =>
      itm.type === 'input' && itm.name.toLowerCase() === 'amount',
  );

  const tmp = rawFieldsList[0];
  rawFieldsList[0] = rawFieldsList[inputAmountIdx]; // eslint-disable-line
  rawFieldsList[inputAmountIdx] = tmp; // eslint-disable-line

  return rawFieldsList;
};
