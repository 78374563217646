import {FC} from 'react';

interface IProps {
  width?: number; // eslint-disable-line
  height?: number; // eslint-disable-line
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const CreditCard: FC<IProps> = ({
  width = 16,
  height = 16,
  fillColor = '#192B3B',
}: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2 2C0.895431 2 0 2.89543 0 4V5H16V4C16 2.89543 15.1046 2 14 2H2Z'
      fill={fillColor}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 7H0V12C0 13.1046 0.895431 14 2 14H14C15.1046 14 16 13.1046 16 12V7ZM2 11C2 10.4477 2.44772 10 3 10H4C4.55228 10 5 10.4477 5 11C5 11.5523 4.55228 12 4 12H3C2.44772 12 2 11.5523 2 11ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H8C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10H7Z'
      fill={fillColor}
    />
  </svg>
);
