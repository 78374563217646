import styled from 'styled-components';

export const CalendarPanelInfo = styled.div`
  width: 778px;
  margin-left: -160px;
  padding: 24px 24px 24px 160px;
  border: 1px solid #ebebeb;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  background: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px -1px 0px rgb(255 255 255), 0px 0px 1px rgb(9 30 66 / 31%),
    0px 8px 12px rgb(9 30 66 / 15%), 0px 0px 1px rgb(9 30 66 / 31%);
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: ${(p) => p.theme.main.colors.lightGrey};
    position: absolute;
    top: 0;
    left: 158px;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 618px;
    height: 1px;
    background-color: ${(p) => p.theme.main.colors.lightGrey};
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  @media (max-width: 1200px) {
    width: auto;
    margin-left: 0;
    padding: 24px;
    border-radius: 0 0 8px 8px;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #ebebeb;

    &:before,
    &:after {
      display: none;
    }
  }

  @media (max-width: 400px) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledButton = styled.button`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-left: 24px;
  padding: 10px 24px;
  background-color: ${(p) => p.theme.main.colors.signUp};
  border-radius: 8px;
  transition: all 0.3s linear;

  &:hover {
    color: ${(p) => p.theme.main.colors.white};
    background-color: ${(p) => p.theme.main.colors.brand};
  }

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PresetsWrapper = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'block' : 'none')};
  width: 160px;
  height: 375px;
  padding: 24px 20px;
  background-color: ${(p) => p.theme.main.colors.white};
  border: 1px solid #ebebeb;
  border-bottom: 0;
  border-radius: 8px 0 0 0;
  position: absolute;
  top: 67px;
  left: 0;
  z-index: 2;

  @media (max-width: 1200px) {
    display: none;
    border-radius: 8px;
  }
`;

export const ULWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const LIWrapper = styled.li<{selected: boolean}>`
  ${(p) => p.theme.main.typography.body};
  color: ${(p) => p.theme.main.colors.black};
  padding: 6px 16px;
  background-color: ${({selected}) => (selected ? '#f6f5ff' : '#fff')};
  border-radius: 8px;
  cursor: pointer;
`;
