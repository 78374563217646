import {createStore, applyMiddleware, combineReducers, AnyAction} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import authReducer from './Auth/auth.reducer';
import transactionsReducer from './Transactions/transactions.reducer';
import vouchersReducer from './Vouchers/vouchers.reducer';
import appReducer from './App/app.reducer';
import dashboardReducer from './Dashboard/dashboard.reducer';
import standardizedReducer from './Standardized/standardized.reducer';
import topUpReducer from './TopUp/topUp.reducer';
import incompleteInvoicesReducer from './IncompleteInvoices/incompleteInvoices.reducer';
import notificationReducer from './Notification/notification.reducer';
import profileReducer from './Profile/profile.reducer';
import verificationReducer from './Verification/verification.reducer';
import paymentReducer from './Payment/payment.reducer';
import badgeCountReducer from './BadgeCount/badgeCount.reducer';
import exportFileReducer from './ExportFile/exportFile.reducer';
import phoneCodesReducer from './PhoneCodes/phoneCodes.reducer';
import chatReducer from './Chat/chat.reducer';
import formConstructorReducer from './FormConstructor/formConstructor.reducer';

export type ThunkActionType = ThunkAction<void, RootState, unknown, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const {createLogger} = require('redux-logger');
  const logger = createLogger({collapsed: true});
  middlewares.push(logger);
}

const store = createStore(
  combineReducers({
    authReducer,
    appReducer,
    dashboardReducer,
    vouchersReducer,
    transactionsReducer,
    standardizedReducer,
    topUpReducer,
    incompleteInvoicesReducer,
    notificationReducer,
    profileReducer,
    verificationReducer,
    paymentReducer,
    badgeCountReducer,
    exportFileReducer,
    phoneCodesReducer,
    chatReducer,
    formConstructorReducer,
  }),
  applyMiddleware(...middlewares),
);

export default store;
