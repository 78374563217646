import {
  IFieldItems,
  IFormValuesInitial,
  OptionField,
} from '../../state/Standardized/standardized.types';

interface ICheckboxMap {
  [key: string]: boolean;
}

interface IFormFiledsValuesArgs {
  formValues: IFormValuesInitial;
  formFields: IFieldItems | null;
}

interface ILoopFormFieldsArgs {
  formValues: IFormValuesInitial;
  formFields: IFieldItems;
  queryMapRaw: IFormValuesInitial;
}

interface ILoopOptionsArgs {
  formField: [string, OptionField];
  formValues: IFormValuesInitial;
  queryMapRaw: IFormValuesInitial;
}

interface IInsertKeyIntoOptionArgs {
  formField: [string, OptionField];
  option: [OptionField];
  queryMapRaw: IFormValuesInitial;
}

const omitFalsyValueFromMap = (obj: IFormValuesInitial) => {
  const entries = Object.entries(obj);
  return entries.reduce((acc: ICheckboxMap, item: any) => {
    const [keyName, value] = item;
    if (value) acc[keyName] = value;
    return acc;
  }, {});
};

const omitBoolValueFromMap = (obj: IFormValuesInitial) => {
  const entries = Object.entries(obj);
  return entries.reduce((acc: IFormValuesInitial, item) => {
    const [keyName, value] = item;
    if (typeof value !== 'boolean') acc[keyName] = value;
    return acc;
  }, {});
};

const insertKeyIntoOption = (args: IInsertKeyIntoOptionArgs) => {
  const {formField, option, queryMapRaw} = args;
  const [optionKey] = option;

  const splittedOption = formField[1].name.split('');
  const idxFirstSquareBracket = splittedOption.indexOf('[');
  splittedOption.splice(idxFirstSquareBracket, 1, '[', optionKey);

  const formattedOption = splittedOption.join('');
  queryMapRaw[formattedOption] = optionKey;
};

const loopOptions = (args: ILoopOptionsArgs) => {
  const {formField, formValues, queryMapRaw} = args;

  Object.entries(formField[1].options).forEach((option: any) => {
    const [optionKey, optionValue] = option;

    if (formValues[optionValue]) {
      const idxFirstSquareBracketOut = formField[1].name.indexOf('[');
      if (idxFirstSquareBracketOut >= 0) {
        insertKeyIntoOption({formField, option, queryMapRaw});
        return;
      }

      queryMapRaw[`${formField[1].name}[${optionKey}]`] = optionKey;
    }
  });
};

const loopFormFields = (args: ILoopFormFieldsArgs) => {
  const {formFields, formValues, queryMapRaw} = args;

  Object.entries(formFields).forEach((formField: any) => {
    if (formField[1].type === 'checkbox' && formField[1].options) {
      loopOptions({formField, formValues, queryMapRaw});
    }
  });
};

const createRawQueryStringMap = (args: IFormFiledsValuesArgs) => {
  const {formFields, formValues} = args;
  const queryMapRaw: IFormValuesInitial = {};

  if (formFields) {
    loopFormFields({formFields, formValues, queryMapRaw});
  }

  return queryMapRaw;
};

// eslint-disable-next-line import/prefer-default-export
export const convertFormValuesWithCheckbox = (args: IFormFiledsValuesArgs) => {
  const {formFields, formValues} = args;
  const formattedFormValues = omitFalsyValueFromMap(formValues);

  const queryMapRaw = createRawQueryStringMap({
    formFields,
    formValues: formattedFormValues,
  });

  const resultFormValues = omitBoolValueFromMap(formattedFormValues);

  return {...resultFormValues, ...queryMapRaw};
};
