import {FC} from 'react';
import FormItem from '../../FormItem';
import {CreditCard} from '../../../../../static/images';
import {ICardNumberInputProps} from './CardNumberInput.types';
import {InputWrapper, IconWrapper, Input} from './CardNumberInput.styles';

const CardNumberInput: FC<ICardNumberInputProps> = ({
  name,
  label,
  required,
  cardNumberMasked,
  handleEnterKey,
  handlePasteCardNumber,
  handleChangeCardNumber,
}: ICardNumberInputProps) => (
  <FormItem label={label} required={required}>
    <InputWrapper>
      <IconWrapper>
        <CreditCard fillColor='#BAC0C5' />
      </IconWrapper>
      <Input
        id='cardNumber'
        value={cardNumberMasked}
        name={name}
        placeholder={label}
        onChange={handleChangeCardNumber}
        onKeyDown={handleEnterKey}
        onPaste={handlePasteCardNumber}
      />
    </InputWrapper>
  </FormItem>
);

export default CardNumberInput;
