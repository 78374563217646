import {FC} from 'react';

interface IProps {
  fillColor?: string; // eslint-disable-line
}

// eslint-disable-next-line import/prefer-default-export
export const ChevronDoubleRight: FC<IProps> = ({
  fillColor = '#192B3B',
}: IProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.70711 4.29289C9.31658 3.90237 8.68342 3.90237 8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711L10.5858 8L8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289L9.70711 4.29289ZM4.70711 4.29289C4.31658 3.90237 3.68342 3.90237 3.29289 4.29289C2.90237 4.68342 2.90237 5.31658 3.29289 5.70711L5.58579 8L3.29289 10.2929C2.90237 10.6834 2.90237 11.3166 3.29289 11.7071C3.68342 12.0976 4.31658 12.0976 4.70711 11.7071L7.70711 8.70711C8.09763 8.31658 8.09763 7.68342 7.70711 7.29289L4.70711 4.29289Z'
      fill={fillColor}
    />
  </svg>
);
