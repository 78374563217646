import {ISuccessRes, IVerificationDocsRes} from './verification.types';

// GET_VERIFICATION_DOCUMENTS TYPES & ACTIONS
export const GET_VERIFICATION_DOCUMENTS_REQUEST: string =
  'GET_VERIFICATION_DOCUMENTS_REQUEST';
export const GET_VERIFICATION_DOCUMENTS_SUCCESS: string =
  'GET_VERIFICATION_DOCUMENTS_SUCCESS';
export const GET_VERIFICATION_DOCUMENTS_FAILURE: string =
  'GET_VERIFICATION_DOCUMENTS_FAILURE';

export const getVerificationDocumentsRequestAction = (): {type: string} => ({
  type: GET_VERIFICATION_DOCUMENTS_REQUEST,
});

export const getVerificationDocumentsSuccessAction = (
  payload: IVerificationDocsRes,
) => ({
  type: GET_VERIFICATION_DOCUMENTS_SUCCESS,
  payload,
});

export const getVerificationDocumentsFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_VERIFICATION_DOCUMENTS_FAILURE,
  payload,
});

// UPLOAD_VERIFICATION_FILE TYPES & ACTIONS
export const UPLOAD_VERIFICATION_FILE_REQUEST: string =
  'UPLOAD_VERIFICATION_FILE_REQUEST';
export const UPLOAD_VERIFICATION_FILE_SUCCESS: string =
  'UPLOAD_VERIFICATION_FILE_SUCCESS';
export const UPLOAD_VERIFICATION_FILE_FAILURE: string =
  'UPLOAD_VERIFICATION_FILE_FAILURE';

export const uploadVerificationFileRequestAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: UPLOAD_VERIFICATION_FILE_REQUEST,
  payload,
});

export const uploadVerificationFileSuccessAction = (payload: any) => ({
  type: UPLOAD_VERIFICATION_FILE_SUCCESS,
  payload,
});

export const uploadVerificationFileFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: UPLOAD_VERIFICATION_FILE_FAILURE,
  payload,
});

// DELETE_VERIFICATION_DOCUMENT TYPES & ACTIONS
export const DELETE_VERIFICATION_DOCUMENT_REQUEST: string =
  'DELETE_VERIFICATION_DOCUMENT_REQUEST';
export const DELETE_VERIFICATION_DOCUMENT_SUCCESS: string =
  'DELETE_VERIFICATION_DOCUMENT_SUCCESS';
export const DELETE_VERIFICATION_DOCUMENT_FAILURE: string =
  'DELETE_VERIFICATION_DOCUMENT_FAILURE';

export const deleteVerificationDocumentRequestAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DELETE_VERIFICATION_DOCUMENT_REQUEST,
  payload,
});

export const deleteVerificationDocumentSuccessAction = (payload: any) => ({
  type: DELETE_VERIFICATION_DOCUMENT_SUCCESS,
  payload,
});

export const deleteVerificationDocumentFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: DELETE_VERIFICATION_DOCUMENT_FAILURE,
  payload,
});

// STORE_PERSONAL_INFO TYPES & ACTIONS
export const STORE_PERSONAL_INFO_REQUEST: string =
  'STORE_PERSONAL_INFO_REQUEST';
export const STORE_PERSONAL_INFO_SUCCESS: string =
  'STORE_PERSONAL_INFO_SUCCESS';
export const STORE_PERSONAL_INFO_FAILURE: string =
  'STORE_PERSONAL_INFO_FAILURE';

export const storePersonalInfoRequestAction = (): {type: string} => ({
  type: STORE_PERSONAL_INFO_REQUEST,
});

export const storePersonalInfoSuccessAction = (payload: ISuccessRes) => ({
  type: STORE_PERSONAL_INFO_SUCCESS,
  payload,
});

export const storePersonalInfoFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: STORE_PERSONAL_INFO_FAILURE,
  payload,
});

// VERIFY_CLIENT TYPES & ACTIONS
export const VERIFY_CLIENT_REQUEST: string = 'VERIFY_CLIENT_REQUEST';
export const VERIFY_CLIENT_SUCCESS: string = 'VERIFY_CLIENT_SUCCESS';
export const VERIFY_CLIENT_FAILURE: string = 'VERIFY_CLIENT_FAILURE';

export const verifyClientRequestAction = (): {type: string} => ({
  type: VERIFY_CLIENT_REQUEST,
});

export const verifyClientSuccessAction = (payload: ISuccessRes) => ({
  type: VERIFY_CLIENT_SUCCESS,
  payload,
});

export const verifyClientFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: VERIFY_CLIENT_FAILURE,
  payload,
});

// SET_UPLOAD_FILE_URL TYPES & ACTIONS
export const SET_UPLOAD_FILE_URL: string = 'SET_UPLOAD_FILE_URL';

export const setUploadFileUrl = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_UPLOAD_FILE_URL,
  payload,
});

// SET_SEND_FORM_URL TYPES & ACTIONS
export const SET_SEND_FORM_URL: string = 'SET_SEND_FORM_URL';

export const setSendFormUrl = (
  payload: string,
): {type: string; payload: string} => ({
  type: SET_SEND_FORM_URL,
  payload,
});

// CLEAR_VERIFICATION_RESPONSE TYPES & ACTIONS
export const CLEAR_VERIFICATION_RESPONSE: string =
  'CLEAR_VERIFICATION_RESPONSE';

export const clearVerificationResponse = (): {type: string} => ({
  type: CLEAR_VERIFICATION_RESPONSE,
});

// CLEAR_VERIFICATION_DOCUMENTS_DATA TYPES & ACTIONS
export const CLEAR_VERIFICATION_DOCUMENTS_DATA: string =
  'CLEAR_VERIFICATION_DOCUMENTS_DATA';

export const clearVerificationDocumentsData = (): {type: string} => ({
  type: CLEAR_VERIFICATION_DOCUMENTS_DATA,
});
