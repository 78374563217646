import {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setAuthenticationState} from '../../../../../state';
import {useCalculateProgress} from '../../../../hooks';
import ProgressComponent from '../ProgressComponent';

const SuccessfulPayment: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {inProcessing} = useCalculateProgress();

  useEffect(() => {
    if (!inProcessing) {
      localStorage.setItem('pageTryToOpen', '/incomplete');
      dispatch(setAuthenticationState('authenticated'));
    }
  }, [inProcessing]);

  return <ProgressComponent />;
};

export default SuccessfulPayment;
