import {IIncompletedDataResponse} from '../incompleteInvoices.types';

// GET_INCOMPLETE_DATA TYPES & ACTIONS
export const GET_INCOMPLETE_DATA_REQUEST: string =
  'GET_INCOMPLETE_DATA_REQUEST';
export const GET_INCOMPLETE_DATA_SUCCESS: string =
  'GET_INCOMPLETE_DATA_SUCCESS';
export const GET_INCOMPLETE_DATA_FAILURE: string =
  'GET_INCOMPLETE_DATA_FAILURE';

export const getIncompleteDataRequestAction = (): {type: string} => ({
  type: GET_INCOMPLETE_DATA_REQUEST,
});

export const getIncompleteDataSuccessAction = (
  payload: IIncompletedDataResponse,
): {type: string; payload: IIncompletedDataResponse} => ({
  type: GET_INCOMPLETE_DATA_SUCCESS,
  payload,
});

export const getIncompleteDataFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_INCOMPLETE_DATA_FAILURE,
  payload,
});

// GET_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const GET_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'GET_INCOMPLETE_DATA_ITEM_REQUEST';
export const GET_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'GET_INCOMPLETE_DATA_ITEM_SUCCESS';
export const GET_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'GET_INCOMPLETE_DATA_ITEM_FAILURE';

export const getIncompleteDataItemRequestAction = (): {type: string} => ({
  type: GET_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const getIncompleteDataItemSuccessAction = (payload: any) => ({
  type: GET_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const getIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: GET_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});

// COPY_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const COPY_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'COPY_INCOMPLETE_DATA_ITEM_REQUEST';
export const COPY_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'COPY_INCOMPLETE_DATA_ITEM_SUCCESS';
export const COPY_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'COPY_INCOMPLETE_DATA_ITEM_FAILURE';

export const copyIncompleteDataItemRequestAction = (): {type: string} => ({
  type: COPY_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const copyIncompleteDataItemSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: COPY_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const copyIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: COPY_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});
