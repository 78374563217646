import {IIncompleteDataItemCreate} from '../incompleteInvoices.types';

// CREATE_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const CREATE_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'CREATE_INCOMPLETE_DATA_ITEM_REQUEST';
export const CREATE_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'CREATE_INCOMPLETE_DATA_ITEM_SUCCESS';
export const CREATE_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'CREATE_INCOMPLETE_DATA_ITEM_FAILURE';

export const createIncompleteDataItemRequestAction = (): {type: string} => ({
  type: CREATE_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const createIncompleteDataItemSuccessAction = (
  payload: IIncompleteDataItemCreate,
): {type: string; payload: IIncompleteDataItemCreate} => ({
  type: CREATE_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const createIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CREATE_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});

// STORE_INCOMPLETE_DATA_ITEM TYPES & ACTIONS
export const STORE_INCOMPLETE_DATA_ITEM_REQUEST: string =
  'STORE_INCOMPLETE_DATA_ITEM_REQUEST';
export const STORE_INCOMPLETE_DATA_ITEM_SUCCESS: string =
  'STORE_INCOMPLETE_DATA_ITEM_SUCCESS';
export const STORE_INCOMPLETE_DATA_ITEM_FAILURE: string =
  'STORE_INCOMPLETE_DATA_ITEM_FAILURE';

export const storeIncompleteDataItemRequestAction = (): {type: string} => ({
  type: STORE_INCOMPLETE_DATA_ITEM_REQUEST,
});

export const storeIncompleteDataItemSuccessAction = (
  payload: any,
): {type: string; payload: any} => ({
  type: STORE_INCOMPLETE_DATA_ITEM_SUCCESS,
  payload,
});

export const storeIncompleteDataItemFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: STORE_INCOMPLETE_DATA_ITEM_FAILURE,
  payload,
});
