import {createQueryString} from './queryString';
import {IBodyTableItem} from '../view/pages/MemberArea/Standardized/TableView/TableView.types';
import {IFilterDataMap} from '../state/Standardized/standardized.types';

interface IFormFilteredUrlArgs {
  slugUrl: string;
  currentPageTable: number;
  pageSizeTable: number;
  filterDataMap: IFilterDataMap | null;
}

interface ObjectItem {
  [key: string]: any;
}

interface TitleValueObject {
  title: string;
  value: any;
}

type TupleItem = [string, any];

export const stringifyObjectContent = (obj: IBodyTableItem) => {
  const newObj: IBodyTableItem = {};
  Object.entries(obj).forEach((itm) => {
    if (typeof itm[1] === 'object') {
      newObj[itm[0]] = JSON.stringify(itm[1], null, '  ');
      return;
    }
    newObj[itm[0]] = itm[1]; // eslint-disable-line
  });
  return newObj;
};

export const formateStandardizedItem = (rawData: ObjectItem) => {
  if (!('data' in rawData)) {
    return [];
  }

  if ('data' in rawData && !rawData.data) {
    return [];
  }

  if (typeof rawData.data === 'object' && Array.isArray(rawData.data)) {
    return rawData.data.map((item: ObjectItem) => {
      const newItem = stringifyObjectContent(item);
      return newItem;
    });
  }

  if (typeof rawData.data === 'object' && !Array.isArray(rawData.data)) {
    const entriesObject = Object.entries(rawData.data);

    const titleValueRawList: TitleValueObject[] = entriesObject.map(
      (entryItem: TupleItem) => ({title: entryItem[0], value: entryItem[1]}),
    );

    const titleValueFormattedList = titleValueRawList.map(
      (item: ObjectItem) => {
        const newItem = stringifyObjectContent(item);
        return newItem;
      },
    );

    return titleValueFormattedList;
  }

  return [];
};

export const formFilteredUrl = (args: IFormFilteredUrlArgs): string => {
  const {slugUrl, currentPageTable, pageSizeTable, filterDataMap} = args;

  const idxApiPrefix = slugUrl.indexOf('/api/');
  const formattedSlugUrl =
    idxApiPrefix >= 0 ? slugUrl.replace('/api/', '') : slugUrl;

  const idxSlash = formattedSlugUrl.indexOf('/');
  const slugGetAllItem = formattedSlugUrl.substring(0, idxSlash);

  const slugPagination = `${slugGetAllItem}?page=${currentPageTable}&perPage=${pageSizeTable}`;

  const filterQueryString = filterDataMap
    ? createQueryString(filterDataMap)
    : false;

  const slugFilter = filterQueryString
    ? `${slugPagination}&${filterQueryString}`
    : slugPagination;

  return slugFilter;
};
