// CHANGE_PASSWORD TYPES & ACTIONS
export const CHANGE_PASSWORD_REQUEST: string = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS: string = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE: string = 'CHANGE_PASSWORD_FAILURE';

export const changePasswordRequestAction = (): {type: string} => ({
  type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccessAction = (payload: any) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFailureAction = (
  payload: string,
): {type: string; payload: string} => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});
