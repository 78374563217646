const linksList = [
  {title: "Chrome", link: "https://support.google.com/chrome/answer/95647"}, 
  {title: "Firefox", link: "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"}, 
  {title: "Opera", link: "https://help.opera.com/en/latest/security-and-privacy/"}, 
  {title: "Internet Explorer", link: "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"}, 
  {title: "Safari", link: "https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"},
  {title: "Edge", link: "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"}
]

const PrivacyPolicy = () => (
  <section className="section">
    <div className="container-terms">
      <div>
        <h1 className="main-title terms-title terms-title--privacy-policy">Our privacy policy</h1>
        <h3 className="section__middle-title terms-title">Collection of your personal data by third parties</h3> 
        <p className="terms-description">This Privacy Policy does not apply to any third-party service or website which we connect to, and which may also collect and use information about you.  We are not responsible for any of the information collected by any third party.</p>
        <p className="terms-description terms-description--uppercase">IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS OUR WEBSITE, USE OUR SOFTWARE OR SERVICES OR PROVIDE ANY INFORMATION ABOUT YOURSELF TO US.</p>
      </div>  
      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Introduction</h3>
        <p className="terms-description">Here at Blixi, a business operated under the laws of in New Zealand (we, us or our) protecting your privacy and treating your personal data with care is of paramount importance to us. This Privacy Policy also applies to our related bodies corporate.  This Privacy Policy explains what personal data we collect, why we collect personal data and how we collect, use, disclose, store and protect your personal data when you visit our website, use our services or products, provide us with information yourself (such as when you sign up to our service or use our services) or when you accept services from us.</p>
        <p className="terms-description">It also explains how to contact us to correct, update or delete any personal data provided to us, or make a complaint if you have concerns. We are compliant with the Privacy Act 2020 New Zealand and General Data Protection Regulation (EU) 2016/679 (GDPR).</p>
        <p className="terms-description">We will only collect and process personal data about you where we have a lawful basis to do so. Lawful basis includes consent (where you have given consent), use of our service (where processing is necessary for the delivery of services to you ) and legitimate interests (including security threats or frauds, risk of harm to self or others, compliance with applicable laws, and enabling us to administer our service).</p>
        <p className="terms-description">You expressly and voluntarily grant your informed consent to us to deal with your personal data in accordance with the terms and conditions of this Privacy Policy. You have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object.</p>
        <p className="terms-description">Unless otherwise indicated by the context words importing the singular include the plural and vice versa.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Children’s privacy</h3>
        <p className="terms-description">Our website and service is available to persons under the age of 18 (Children).  We do not knowingly collect personal data from Children without the express consent of a parent or legal guardian.</p>
        <p className="terms-description">If you are a parent or guardian and you are aware that your Children have provided us with personal data, please contact us.  If we become aware that we have collected personal data from Children without verification of parental or guardian consent, we will take steps to remove that information from our servers.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Which entities does this privacy policy cover?</h3>
        <p className="terms-description">This Privacy Policy applies to us with respect to content on our websites, our products, services and information you provide to us about yourself.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">What is personal data?</h3>
        <p className="terms-description">Personal data is defined as data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which the organisation has or is likely to have access.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">When and how do we collect your personal data?</h3>
        <p className="terms-description">We collect most personal data directly from you when you consent to use our products or services or receive communications from us. Your consent may be express (e.g. you agree to the use of your information by ticking a box) or implied by an action you take or do not take (i.e. because you have agreed to terms and conditions that contain information about the use or disclosure of your information).</p>
        <p className="terms-description">You provide us your information when you use our products, services or you use our website generally or you deal with us.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">What personal data do we collect?</h3>
        <h4 className="terms-subtitle">Personal data</h4>
        <p className="terms-description">We collect demographic and personally identifiable information.  That personally identifiable information may include (but not be limited to):</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">full name;</p></li>
          <li className="terms-list__item"><p className="terms-description">mailing or street address;</p></li>
          <li className="terms-list__item"><p className="terms-description">date of birth;</p></li>
          <li className="terms-list__item"><p className="terms-description">email address;</p></li>
          <li className="terms-list__item"><p className="terms-description">telephone number and other contact details;</p></li>
          <li className="terms-list__item"><p className="terms-description">age or date of birth;</p></li>
          <li className="terms-list__item"><p className="terms-description">photographic representations of you;</p></li>
          <li className="terms-list__item"><p className="terms-description">credit card and other bank account information information and other information for billing;</p></li>
          <li className="terms-list__item"><p className="terms-description">identifying data about yourself that is uploaded onto our website (from documents such as passports or licences);</p></li>
          <li className="terms-list__item"><p className="terms-description">your device ID, browser, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information;</p></li>
          <li className="terms-list__item"><p className="terms-description">details of the services we have provided to you or that you have enquired about, including any additional information necessary to deliver those services and respond to your enquiries;</p></li>
          <li className="terms-list__item"><p className="terms-description">any additional information relating to you that you provide to us directly through our website or use of our services or indirectly through your use of our website or use of our services or online presence or through other websites or accounts from which you permit us to collect information;</p></li>
          <li className="terms-list__item"><p className="terms-description">information you provide about yourself when you communicate to us or others when you use the services;</p></li>
          <li className="terms-list__item"><p className="terms-description">information you provide to us through surveys; or</p></li>
          <li className="terms-list__item"><p className="terms-description">any other personal data that may be required in order to facilitate your dealings with us. </p></li>
        </ul>
        <p className="terms-description">We may collect these types of personal data either directly from you, or from third parties. We may collect this information when you: </p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">register on our website;</p></li>
          <li className="terms-list__item"><p className="terms-description">communicate with us through correspondence, chats, email, or when you share information with us from other social applications, services or websites;</p></li>
          <li className="terms-list__item"><p className="terms-description">interact with our site, products, services, content and advertising; or</p></li>
          <li className="terms-list__item"><p className="terms-description">invest in our business or enquire as to a potential purchase from our business.</p></li>
        </ul>
        <p className="terms-description">You can choose not to provide us with your personal data, but then you may not be able to take advantage of some of the features of our services or our website.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Why do we collect your personal data?</h3>
        <p className="terms-description">We may collect your personal data when required by law but generally we collect personal data from you (or about you) to allow us to:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">create and manage user accounts;</p></li>
          <li className="terms-list__item"><p className="terms-description">undertake transaction monitoring;</p></li>
          <li className="terms-list__item"><p className="terms-description">minimise fraudulent activities;</p></li>
          <li className="terms-list__item"><p className="terms-description">comply with our AML/CFT obligations;</p></li>
          <li className="terms-list__item"><p className="terms-description">supply you with information about our software and services;</p></li>
          <li className="terms-list__item"><p className="terms-description">supply you with tailored service offerings that may benefit you;</p></li>
          <li className="terms-list__item"><p className="terms-description">provide payment processing services to you;</p></li>
          <li className="terms-list__item"><p className="terms-description">ensure your use of our services and product is safe and secure;</p></li>
          <li className="terms-list__item"><p className="terms-description">send administrative information;</p></li>
          <li className="terms-list__item"><p className="terms-description">marketing and advertising to you;</p></li>
          <li className="terms-list__item"><p className="terms-description">respond to inquiries and offer support;</p></li>
          <li className="terms-list__item"><p className="terms-description">request user feedback;</p></li>
          <li className="terms-list__item"><p className="terms-description">improve user experience;</p></li>
          <li className="terms-list__item"><p className="terms-description">post customer testimonials;</p></li>
          <li className="terms-list__item"><p className="terms-description">enforce terms and conditions and policies;</p></li>
          <li className="terms-list__item"><p className="terms-description">protect from abuse and malicious users;</p></li>
          <li className="terms-list__item"><p className="terms-description">respond to legal requests and prevent harm;</p></li>
          <li className="terms-list__item"><p className="terms-description">communicate more effectively with you about our services and your care; and</p></li>
          <li className="terms-list__item"><p className="terms-description">ensure your experience with us is a positive one.</p></li>
        </ul>
        <p className="terms-description">Personal data collected or received by us will only be used for the stated purpose for which it was provided.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">When do we disclose your personal data?</h3>
        <p className="terms-description">We may collect, hold, use and disclose your personal data for the following purposes:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">to interact with third party financiers and banks or other payment gateways to enable payment processing;</p></li>
          <li className="terms-list__item"><p className="terms-description">to enable you to access and use our website or services;</p></li>
          <li className="terms-list__item"><p className="terms-description">to operate, protect, improve and optimise our website or services, business and our users’ experience, such as to perform analytics, conduct research and for advertising and marketing;</p></li>
          <li className="terms-list__item"><p className="terms-description">to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you;</p></li>
          <li className="terms-list__item"><p className="terms-description">to send you marketing and promotional messages and other information that may be of interest to you, including information sent by, or on behalf of, our business partners that we think you may find interesting;</p></li>
          <li className="terms-list__item"><p className="terms-description">to administer rewards, surveys, contests, or other promotional activities or events sponsored or managed by us or our business partners;</p></li>
          <li className="terms-list__item"><p className="terms-description">to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties; and</p></li>
          <li className="terms-list__item"><p className="terms-description">to consider your employment application.</p></li>
        </ul>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">To whom do we disclose your personal data?</h3>
        <p className="terms-description">We may disclose personal data for the purposes described in this privacy policy to:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">our employees, agents and contractors and related bodies corporate;</p></li>
          <li className="terms-list__item"><p className="terms-description">third party suppliers and service providers (including providers for the operation of our website and/or our business or in connection with providing our services to you) including banks and payment processors;</p></li>
          <li className="terms-list__item"><p className="terms-description">businesses whom you interact with via our services;</p></li>
          <li className="terms-list__item"><p className="terms-description">professional advisers, dealers and agents;</p></li>
          <li className="terms-list__item"><p className="terms-description">payment systems operators (eg merchants receiving card payments);</p></li>
          <li className="terms-list__item"><p className="terms-description">our existing or potential agents, business partners or partners;</p></li>
          <li className="terms-list__item"><p className="terms-description">our sponsors or promoters of any competition that we conduct via our services;</p></li>
          <li className="terms-list__item"><p className="terms-description">anyone to whom our assets or businesses (or any part of them) are transferred;</p></li>
          <li className="terms-list__item"><p className="terms-description">specific third parties authorised by you to receive information held by us; and/or</p></li>
          <li className="terms-list__item"><p className="terms-description">other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law.</p></li>
        </ul>
        <p className="terms-description">If personal data is disclosed to a third party, we are required to take all reasonable steps to ensure your personal data is treated in accordance with the laws that apply to personal data in that country. We may also disclose your personal data to a trusted third party who also holds other information about you. This third party may combine that information in order to enable it and us to develop anonymised consumer insights so that we can better understand your preferences and interests, personalise your experience and enhance the products and services that you receive.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Aggregated information & direct marketing</h3>
        <p className="terms-description">We do not sell your personal data. We may aggregate the information you and others make available to us and share it with third parties. </p>
        <p className="terms-description">We may use, sell, license, and share this aggregated information with third parties for research or other purposes such as to improve our services or to help our partners understand more about the users of our service issues.</p>
        <p className="terms-description">We and/or our carefully selected business partners may send you direct marketing communications and information about our service and products. This may take the form of emails, SMS, mail or other forms of communication, in accordance with the laws of your country. You may opt-out of receiving marketing materials from us by contacting us using the details set out below or by using the opt-out facilities provided (eg an unsubscribe link).</p>
        <p className="terms-description">You can object to us using your information for these purposes.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">What if you don’t want us to collect your personal data?</h3>
        <p className="terms-description">You are not obligated to provide us with your personal data. You may choose whether you receive communications from us. Whilst it is your choice not to provide your personal data to us this may impede our ability to provide you with all the functionality of our services and website.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">What if you don’t want to receive further communications from us?</h3>
        <p className="terms-description">Should you wish to remove yourself from our database you may do so at any time by contacting us by emailing us at <a className="terms-link" href="mailto:info@peratera.com">info@peratera.com </a>.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">How can I access, correct and/ or update personal data you have collected?</h3>
        <p className="terms-description">At any time, you may contact us and request your personal data be modified. We will make all efforts to correct data once we have proved your identity.</p>
        <p className="terms-description">We will deal with all requests for access to personal data as quickly as possible, but no later than the prescribed time required by law (unless any complexities arise). Requests for a large amount of information, or information which is not currently in use, may require further time before a response can be given.</p>
        <p className="terms-description">We will provide you your personal data in a structured, commonly used, machine-readable format.</p>
        <p className="terms-description">In some cases, we will refuse to give you access to personal data we hold about you. This includes, but is not limited to, circumstances where giving you access would: be unlawful; have an unreasonable impact on other people’s privacy; prejudice an investigation of unlawful activity; reveal our intentions in relation to negotiations with you so as to prejudice those negotiations; prejudice enforcement related activities conducted by, or on behalf of, an enforcement body; reveal evaluative information generated within our business in connection with a commercially sensitive decision-making process.</p>
        <p className="terms-description">We will also refuse access where the personal data relates to existing or anticipated legal proceedings, and the information would not be accessible by the process of discovery in those proceedings. Further, we will refuse access where your request is frivolous or vexatious, and where we reasonably believe that unlawful activity, or misconduct of a serious nature, is being or may be engaged in against us and giving access would be likely to prejudice the taking of appropriate action in relation to that matter.</p>
        <p className="terms-description">If we refuse to give you access we will provide you with reasons for our refusal, unless doing so would be unreasonable in the circumstances. We will also take reasonable steps to give you access in a way that meets your needs without giving rise to the reasons of our refusal. Further, we will provide details of how you may make a complaint about our decision.</p>
        <p className="terms-description">Please note that the access and correction requirements under this Privacy Policy operates alongside and do not replace other informal or legal procedures by which an individual can be provided access to, or correction of, their personal data.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">How do we store and protect your personal data?</h3>
        <p className="terms-description">For us to provide excellent service we are required to store some personal data and take the greatest of care to ensure this information is treated as private and confidential. Transmitting personal data via the internet does have inherent risks associated with it. We will however take all reasonable steps to ensure the security of this data.</p>
        <p className="terms-description">We have taken the necessary measures to ensure the personal data we hold is not compromised. In accordance with and as permitted by applicable law and regulations we will retain your information as long necessary to serve you, to maintain your account or as otherwise required to operate our service.</p>
        <p className="terms-description">Our third party data storage centre is equipped with the latest technology, infrastructure and dedicated technical staff to ensure our working environment has reliability and security for your data.  However, we cannot be held liable for events outside our control particularly with respect to third parties who act as repositories of your information.</p>
        <p className="terms-description">Our website is protected by security certificates and are built considering all modern security stands where possible. We will take reasonable steps to maintain the integrity and security of any personal data we have stored, including taking reasonable steps to prevent interference and loss, misuse, unauthorised access, modification or disclosure of such personal data.</p>
        <p className="terms-description">Note that no information transmitted over the Internet can be guaranteed to be completely secure. While we will endeavour to protect your personal data as best as possible we cannot guarantee the security of any information that you transmit to us or receive from us. The transmission and exchange of information is carried out at your own risk.</p>
        <p className="terms-description">It is important that you protect your privacy by ensuring that no one obtains your personal data, and you must contact us directly if your details change. Should your information be erroneously provided to us or no longer remain valid within the constraints of this Privacy Policy we will securely destroy or de-identify it as soon as practicable, as long as it is lawful to do so.</p>
        <p className="terms-description">We have obligations to notify you if you are affected by a data breach. We will take all reasonable precautions to take remedial action to prevent such an event. However, as we cannot guarantee that remedial action will be sufficient to prevent all instances of a breach, we will take steps to notify you of an eligible data breach as soon as practicable, and provide recommendations as to what steps you should take to mitigate any serious issues.</p>
        <p className="terms-description">For EU residents, where we employ data processors to process personal data on our behalf, we only do so on the basis that such data processors comply with the requirements under the GDPR and that have adequate technical measures in place to protect personal data against unauthorised use, loss and theft.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Log data</h3>
        <p className="terms-description">Whenever you use our website, or in a case of an error within the website, we collect data and information (through third party products) called Log Data. This Log Data may include information such as your device, Internet Protocol address, device name, operating system version, the configuration of the device when utilizing our website, the time and date of your use of our website and other statistics.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Transfer out</h3>
        <p className="terms-description">We may transfer data we receive about you, including all personal data, to our hosting service providers and data centres located overseas, such as an Amazon Web Services node in countries such as Australia, India and the United States of America. You hereby expressly and voluntarily grant your informed consent to such transfers.  Transfers out of your country will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website and our own Data Protection Agreement.</p>
        <p className="terms-description">You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>
      </section>

       <section className="terms-section">
        <h3 className="section__middle-title terms-title">Changes to this privacy policy</h3>
        <p className="terms-description">We reserve the right to modify or amend this Privacy Policy at any time.</p>
        <p className="terms-description">If you object to any changes, you may cease using our website and/or our services. You acknowledge and agree that your continued use of our website means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Cookies</h3>
        <p className="terms-description">Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our website, we may collect information from you automatically through cookies or similar technology.  While we do not use browsing information to identify you personally, we may record certain information about your use of our website, such as which pages you visit, the time and date of your visit and the internet protocol address assigned to your computer.</p>
        <p className="terms-description">Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.  We may also use &prime;cookies&prime; or other similar tracking technologies on our website that help us track your website usage and remember your preferences. Cookies are small files that store information on your computer, TV, mobile phone or other device. They enable the entity that put the cookie on your device to recognise you across different websites, services, devices and/or browsing sessions. You can disable cookies through your internet browser but our websites may not work as intended for you if you do so.</p>
        <p className="terms-description">Cookies may be either &quot;persistent&quot; cookies or &quot;session&quot; cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Cookies that we may use</h3>
        <p className="terms-description">We use cookies for the following purposes:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">authentication and status - we use cookies to identify you when you visit our website and as you navigate our website, and to determine if you are logged into the website</p></li>
          <li className="terms-list__item"><p className="terms-description">personalisation - we use cookies to store information about your preferences and to personalise the website for you</p></li>
          <li className="terms-list__item"><p className="terms-description">security - we use cookies s an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally</p></li>
          <li className="terms-list__item"><p className="terms-description">analysis - we use cookies to help us to analyse the use and performance of our website and services</p></li>
          <li className="terms-list__item"><p className="terms-description">cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally.</p></li>
        </ul>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Cookies used by our service providers</h3>
        <p className="terms-description">Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Managing cookies</h3>
        <p className="terms-description">Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
        <div className="terms-link-block">
          {linksList.map(linkItem => (
            <div className="terms-link-element" key={linkItem.title}>
              <div>
                <p className="terms-description terms-description--link">{linkItem.title}</p>
                <a className="section__small-description" href={linkItem.link}>{linkItem.link}</a>
              </div>
              <div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.01263 12.0357C4.0187 12.0357 4.02075 12.0351 4.02137 12.0357H4.01263ZM11.9787 9.53574C11.9787 8.98346 12.4264 8.53574 12.9787 8.53574C13.531 8.53574 13.9787 8.98346 13.9787 9.53574V12.0421C13.9787 13.1323 13.0855 14.0357 11.9876 14.0357H4.01263C2.92006 14.0357 2.02148 13.1395 2.02148 12.0403V4.03114C2.02148 2.93591 2.92065 2.03574 4.02267 2.03574H5.97873C6.53101 2.03574 6.97873 2.48346 6.97873 3.03574C6.97873 3.58803 6.53101 4.03574 5.97873 4.03574C5.97873 4.03574 4.02148 4.03998 4.02148 4.03114C4.02148 4.03114 4.02142 12.0358 4.02137 12.0357C4.02137 12.0357 11.9795 12.035 11.9787 12.0421V9.53574ZM11.3689 6.0312L8.83499 8.56513C8.36863 9.03149 7.61568 9.03467 7.14598 8.56496C6.67952 8.09851 6.67615 7.34562 7.14581 6.87595L9.67974 4.34202L8.82736 3.48965C8.37145 3.03373 8.51985 2.57723 9.17276 2.48396L12.7205 1.97714C13.378 1.88321 13.8271 2.33755 13.7338 2.99045L13.227 6.53818C13.133 7.19572 12.6834 7.34573 12.2213 6.88358L11.3689 6.0312Z" fill="#74828A"/>
                </svg>
              </div>
            </div>
          ))}
        </div>
        <p className="terms-description">Blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use all the features on our website.</p>
      </section>
      <section className="terms-section">
        <h3 className="section__middle-title terms-title">General data protection regulation (GDPR) for the European Union (EU)</h3>
        <p className="terms-description">We will comply with the principles of data protection set out in the GDPR for the purpose of fairness, transparency and lawful data collection and use.</p>
        <p className="terms-description">We process your personal data as a Processor and/or to the extent that we are a Controller as defined in the GDPR.</p>
        <p className="terms-description">We must establish a lawful basis for processing your personal data. The legal basis for which we collect your personal data depends on the data that we collect and how we use it.</p>
        <p className="terms-description">We will only collect your personal data with your express consent for a specific purpose and any data collected will be to the extent necessary and not excessive for its purpose. We will keep your data safe and secure.</p>
        <p className="terms-description">We will also process your personal data if it is necessary for our legitimate interests, or to fulfil a contractual or legal obligation.</p>
        <p className="terms-description">We process your personal data if it is necessary to protect your life or in a medical situation, it is necessary to carry out a public function, a task of public interest or if the function has a clear basis in law.</p>
        <p className="terms-description">We do not collect or process any personal data from you that is considered &quot;Sensitive personal data&quot; under the GDPR, such as personal data relating to your sexual orientation or ethnic origin unless we have obtained your explicit consent, or if it is being collected subject to and in accordance with the GDPR.</p>
        <p className="terms-description">You must not provide us with your personal data if you are under the age of 16 without the consent of your parent or someone who has parental authority for you. We do not knowingly collect or process the personal data of children.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Your rights under the GDPR</h3>
        <p className="terms-description">If you are an individual residing in the EU, you have certain rights as to how your personal data is obtained and used. We comply with your rights under the GDPR as to how your personal data is used and controlled if you are an individual residing in the EU.</p>
        <p className="terms-description">Except as otherwise provided in the GDPR, you have the following rights:</p>
        <ul className="terms-list">
          <li className="terms-list__item"><p className="terms-description">to be informed how your personal data is being used;</p></li>
          <li className="terms-list__item"><p className="terms-description">access your personal data (we will provide you with a free copy of it);</p></li>
          <li className="terms-list__item"><p className="terms-description">to correct your personal data if it is inaccurate or incomplete;</p></li>
          <li className="terms-list__item"><p className="terms-description">to delete your personal data (also known as &quot;the right to be forgotten&quot;);</p></li>
          <li className="terms-list__item"><p className="terms-description">to restrict processing of your personal data;</p></li>
          <li className="terms-list__item"><p className="terms-description">to retain and reuse your personal data for your own purposes;</p></li>
          <li className="terms-list__item"><p className="terms-description">to object to your personal data being used; and</p></li>
          <li className="terms-list__item"><p className="terms-description">to object against automated decision making and profiling.</p></li>
        </ul>
        <p className="terms-description">Please contact us at any time to exercise your rights under the GDPR at the contact details in this Privacy Policy.</p>
        <p className="terms-description">We may ask you to verify your identity before acting on any of your requests.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Changes that we make to our privacy policy</h3>
        <p className="terms-description">We will notify you about any changes to our Privacy Policy by updating the “Last Updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of our website or services after the date such revised Privacy Policy is posted.</p>
      </section>

      <section className="terms-section">
        <h3 className="section__middle-title terms-title">Enquiries, requests & complaints</h3>
        <p className="terms-description">Enquiries regarding this Privacy Policy or the personal data we may hold on you, should be addressed with the Privacy Officer, at 
        <a className="terms-link" href="mailto:info@blixti.com">info@blixti.com</a></p>
        <p className="terms-description">If you think your personal data, held by us, may have been compromised in any way or you have any other Privacy related complaints or issues, you should also raise the matter with the Privacy Officer.</p>
        <p className="terms-description">We will ensure your claims are investigated and a formal response will be provided to you, within a reasonable time, considering the circumstances of your claims. If any corrective action is determined to be required, as a result of that investigation, we will take all reasonable steps to rectify the situation and advise you of such, again within a reasonable time considering the circumstances.</p>
        <p className="terms-description">If we do not resolve your enquiry, concern or complaint to your satisfaction or you require further information in relation to any privacy matters, please contact the Privacy Commissioner New Zealand, whose contact details are below.</p>
      </section>

      <section className="terms-section">
        <dl>
          <dt className="terms-description terms-description--term-list">
            <span>Telephone:</span>
          </dt>
          <dd className="terms-list-item-definition">
            <a className="terms-link terms-link--list" href="tel:+64 0800 803 909">+64 0800 803 909</a>
          </dd>
          <dt className="terms-description terms-description--term-list">
            <span>Email:</span>
          </dt>
          <dd className="terms-list-item-definition">
            <a className="terms-link terms-link--list" href="mailto:oia@privacy.org.nz">oia@privacy.org.nz</a>
          </dd>
          <dt className="terms-description terms-description--term-list">
            <span>Office Address:</span>
          </dt>
          <dd className="terms-list-item-definition">
            <span className="terms-definition-text">PO Box 10 094, Wellington 6143</span>
          </dd>
          <dt className="terms-description terms-description--term-list">
            <span>Website:</span>
          </dt>
          <dd className="terms-list-item-definition">
            <a className="terms-link terms-link--list" href="https://privacy.org.nz/about-us/contact/">
              https://privacy.org.nz/about-us/contact/
            </a>
          </dd>
          <dt className="terms-description terms-description--term-list">
            <span>Date of current version:</span>
          </dt>
          <dd className="terms-list-item-definition">
            <span className="terms-definition-text">05.10.2021</span>
          </dd>
        </dl>
      </section>
    </div>
  </section>
);

export default PrivacyPolicy;