import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {Spin} from 'antd';
import {ArrowLeft} from '../../../../static/images';
import ActionsList from '../../../../components/ActionsList';
import FormModal from '../../../../components/FormModal';
import {RootState} from '../../../../../state/store';
import {
  // CRUD REQUESTS
  storeStandardDataItemRequest,
  getStandardDataItemRequest,
  copyStandardDataItemRequest,
  updateStandardDataItemRequest,
  editStandardDataItemRequest,
  destroyStandardDataItemRequest,
  // MISC ACTIONS
  clearStandardDataItem,
  clearStandardDataItemCopy,
} from '../../../../../state';
import {
  createInitialFormValues,
  formateStandardizedItem,
} from '../../../../../utils';
import {IRolesViewItem, IRolesViewTuple} from './SingleItem.types';
import {IBodyTableItem} from '../TableView/TableView.types';
import {
  FlexWrapper,
  TermItem,
  BackButton,
  BackButtonText,
  DefinitionItem,
  StyledTag,
  DetailedInfoList,
  StyledCheckbox,
  TermItemRole,
  DefinitionItemRole,
  RoleItemGroup,
  CheckboxGroup,
  CheckboxItem,
  RoleDetails,
} from './SingleItem.styles';

const SingleItem = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {tableName, id} = useParams<{tableName: string; id: string}>();
  const {
    data,
    error,
    loading,
    loadingDataItemEdit,
    loadingDataItemCopy,
    loadingDataItemView,
    itemDataView,
    itemDataEdit,
    itemDataCopied,
    currentSlug,
  } = useSelector((state: RootState) => state.standardizedReducer);

  const [detailedView, setDetailedView] = useState<IBodyTableItem[]>([]);
  const [pageTitle, setPageTitle] = useState('');

  const [isModalEditVisible, setIsModaEditlVisible] = useState(
    !!(!loadingDataItemEdit && itemDataEdit),
  );

  const [isModalCopyVisible, setIsModaCopylVisible] = useState(
    !!(!loadingDataItemCopy && itemDataCopied),
  );

  const onCloseEditModal = () => setIsModaEditlVisible(false);
  const onCloseCopyModal = () => setIsModaCopylVisible(false);

  useEffect(() => {
    setIsModaEditlVisible(!!(!loadingDataItemEdit && itemDataEdit));
  }, [loadingDataItemEdit, itemDataEdit]);

  useEffect(() => {
    setIsModaCopylVisible(!!(!loading && itemDataCopied));
  }, [loading, itemDataCopied]);

  useEffect(() => {
    const currentItem = data.table.body.find(
      (bodyItem: IBodyTableItem) =>
        bodyItem.id === id ||
        bodyItem.iD === id ||
        bodyItem.Id === id ||
        bodyItem.ID === id,
    );

    if (!currentItem) {
      history.push(`/s/${tableName}`);
    }
  }, [data]);

  useEffect(() => {
    if (!itemDataView) return;
    setPageTitle(itemDataView.pageTitle);
    const formattedData = formateStandardizedItem(itemDataView);
    setDetailedView(formattedData);
  }, [itemDataView]);

  useEffect(() => {
    dispatch(getStandardDataItemRequest(`${tableName}/${id}`));
  }, []);

  const defineTagBgColor = (tagStatus: string): string => {
    switch (tagStatus) {
      case 'open':
        return '#d1fae5';
      case 'open_pay':
        return '#ffedd5';
      default:
        return '#ffe2e2';
    }
  };

  const goBackToTable = () => {
    history.push(`/s/${tableName}`);
  };

  if (error) return <div>{error}</div>;

  if (
    loading ||
    loadingDataItemView ||
    !itemDataView ||
    loadingDataItemEdit ||
    loadingDataItemCopy
  )
    return (
      <Spin
        tip='Loading...'
        spinning={
          loading ||
          loadingDataItemView ||
          !itemDataView ||
          loadingDataItemEdit ||
          loadingDataItemCopy
        }
        size='large'
      />
    );

  const dispatchActionsMapProps = {
    copyDataItemAction: copyStandardDataItemRequest,
    editDataItemAction: editStandardDataItemRequest,
    deleteDataItemAction: destroyStandardDataItemRequest,
  };

  return (
    <div>
      <BackButton type='button' onClick={goBackToTable}>
        <ArrowLeft />
        <BackButtonText>Back</BackButtonText>
      </BackButton>
      <DetailedInfoList>
        {pageTitle === 'Role' ? (
          <RoleDetails>
            {Object.entries<IRolesViewItem>(itemDataView.data).map(
              (item: IRolesViewTuple) => (
                <RoleItemGroup key={item[0]}>
                  <TermItemRole>{item[1].label}</TermItemRole>
                  <div>
                    {item[1].type === 'checkbox' ? (
                      <CheckboxGroup>
                        {Object.entries<string>(item[1].options).map(
                          (option: [string, string]) => {
                            const value = item[1].value ? item[1].value : false;

                            const isChecked = value
                              ? value.some((val) => val === +option[0])
                              : false;

                            return (
                              <CheckboxItem key={option[1]}>
                                <StyledCheckbox checked={isChecked} />
                                <DefinitionItemRole htmlFor={option[1]}>
                                  {option[1]}
                                </DefinitionItemRole>
                              </CheckboxItem>
                            );
                          },
                        )}
                      </CheckboxGroup>
                    ) : null}
                  </div>
                </RoleItemGroup>
              ),
            )}
          </RoleDetails>
        ) : (
          <>
            {detailedView.map((dataItem: IBodyTableItem) => {
              const keyValueTuple: string[] = Object.values(dataItem);
              if (dataItem.title.toLowerCase() === 'status') {
                return (
                  <FlexWrapper key={uuidv4()}>
                    <TermItem>{keyValueTuple[0]}</TermItem>
                    <StyledTag
                      color={defineTagBgColor(dataItem.value.toLowerCase())}
                      status={dataItem.value.toLowerCase()}
                    >
                      {dataItem.value}
                    </StyledTag>
                  </FlexWrapper>
                );
              }
              return (
                <FlexWrapper key={uuidv4()}>
                  <TermItem>{keyValueTuple[0]}</TermItem>
                  <DefinitionItem>{keyValueTuple[1]}</DefinitionItem>
                </FlexWrapper>
              );
            })}
          </>
        )}
      </DetailedInfoList>
      <ActionsList
        actionsList={itemDataView.buttons}
        dispatchActionsMap={dispatchActionsMapProps}
      />
      {isModalEditVisible ? (
        <FormModal
          titleModal='Edit'
          visible={isModalEditVisible}
          formFields={itemDataEdit}
          currentSlug={currentSlug}
          onClose={onCloseEditModal}
          clearFormAction={clearStandardDataItem}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={updateStandardDataItemRequest}
        />
      ) : null}
      {isModalCopyVisible ? (
        <FormModal
          titleModal='Copy'
          visible={isModalCopyVisible}
          formFields={itemDataCopied}
          currentSlug={currentSlug}
          onClose={onCloseCopyModal}
          clearFormAction={clearStandardDataItemCopy}
          createInitialFormValues={createInitialFormValues}
          sendFormAction={storeStandardDataItemRequest}
        />
      ) : null}
    </div>
  );
};

export default SingleItem;
