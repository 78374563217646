import {AnyAction} from 'redux';
import {
  GET_VOUCHER_DATA_REQUEST,
  GET_VOUCHER_DATA_SUCCESS,
  GET_VOUCHER_DATA_FAILURE,
} from './vouchers.actions';
import {IVouchersState} from './vouchers.types';

const initialState: IVouchersState = {
  loading: false,
  data: null,
  error: null,
};

const vouchersReducer = (
  state: IVouchersState = initialState,
  action: AnyAction,
): IVouchersState => {
  switch (action.type) {
    // START REQUEST ACTIONS
    case GET_VOUCHER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    // SUCCESS REQUEST ACTIONS
    case GET_VOUCHER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    // FAILURE REQUEST ACTIONS
    case GET_VOUCHER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default vouchersReducer;
