import {FC, useState} from 'react';
import {Popover} from 'antd';
import {
  NavItem,
  NavItemText,
  SubNavItem,
} from '../BottomNavigation/BottomNavigation.styles';
import {ISubNavigationProps} from './SubNavigation.types';
import {defineMenuItemIcon} from '../../../utils';

const SubNavigation: FC<ISubNavigationProps> = ({
  title,
  subNav,
  icon,
  goToPage,
}: ISubNavigationProps) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setShowPopover(visible);
  };

  return (
    <Popover
      visible={showPopover}
      key={title}
      overlayClassName='bottom-navigation'
      trigger='click'
      onVisibleChange={handleVisibleChange}
      content={
        <div>
          {subNav.map((subMenuItem) => (
            <SubNavItem
              key={subMenuItem.title}
              onTouchEnd={() => {
                setShowPopover(false);
                goToPage(subMenuItem.slug);
              }}
            >
              {defineMenuItemIcon(subMenuItem.icon?.toLowerCase() || 'default')}
              <NavItemText>{subMenuItem.title}</NavItemText>
            </SubNavItem>
          ))}
        </div>
      }
    >
      <NavItem key={title}>
        {defineMenuItemIcon(icon?.toLowerCase() || 'default')}
        <NavItemText>{title}</NavItemText>
      </NavItem>
    </Popover>
  );
};

export default SubNavigation;
