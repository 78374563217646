import styled from 'styled-components';
import {Select} from 'antd';

export const FormInner = styled.div`
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const HalfPart = styled.div`
  width: 50%;
  &:first-child {
    margin-right: 40px;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelDate = styled.p<{isShowRequiredMark: boolean | undefined}>`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 8px;

  &:before {
    content: '*';
    display: ${({isShowRequiredMark}) =>
      isShowRequiredMark ? 'inline-block' : 'none'};
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
`;

export const FlexItemDate = styled.div`
  width: 30%;

  @media (max-width: 400px) {
    width: 22%;
  }
`;

export const FlexItemMonth = styled.div`
  width: 30%;

  @media (max-width: 400px) {
    width: 44%;
  }
`;

export const FlexItemYear = styled.div`
  width: 30%;

  @media (max-width: 400px) {
    width: 28%;
  }
`;

export const StyledSelect = styled(Select)`
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);

  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 48px !important;
  }

  .ant-select-selection-item {
    line-height: 48px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    height: 48px !important;
  }

  .ant-select-arrow {
    width: auto;
    height: auto;
  }
`;

export const PhoneCodeLabel = styled.div`
  ${(p) => p.theme.main.typography.bodyMedium};
  color: ${(p) => p.theme.main.colors.black};
  margin-bottom: 8px;

  &:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
`;

export const PhoneCodeSelectWrapper = styled.div`
  position: relative;
  margin-right: 20px;
`;

export const PhoneCodeSelect = styled.select`
  width: 60px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);
`;

export const CountryCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 44px;
  background-color: ${(p) => p.theme.main.colors.white};
  border-radius: 8px 0 0 8px;
  position: absolute;
  top: 31px;
  left: 1px;
  z-index: 1;
`;

export const PhoneNumberInput = styled.input`
  width: 100%;
  height: 46px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background-color: ${(p) => p.theme.main.colors.white};
  box-shadow: 0px 4px 8px rgb(44 39 56 / 4%);
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
