import {FC} from 'react';
import {v4 as uuidv4} from 'uuid';
import AmountCurrency from '../AmountCurrency';
import PaymentProvider from '../PaymentProvider';
import PreferredPaymentProvider from '../PreferredPaymentProvider';
import RestPaymentProviders from '../RestPaymentProviders';
import CheckoutButton from '../CheckoutButton';
import {IPaymentMethodsComponentProps} from './PaymentMethodsComponent.types';
import {
  FormWrapper,
  Input,
  PageWrapper,
  PaymentMethods,
  Heading,
  ButtonGroup,
  Button,
  ButtonText,
} from './PaymentMethodsComponent.styles';

const PaymentMethodsComponent: FC<IPaymentMethodsComponentProps> = ({
  formFields,
  formValues,
  preferMethod,
  goPrevPage,
  handleSelectChange,
  handleInputChange,
  handlePaymentMethod,
  handleRestPaymentOptions,
}: IPaymentMethodsComponentProps) => (
  <PageWrapper>
    <FormWrapper>
      {formFields.map((formField) => {
        if (formField.type === 'input' && formField.name === 'amount') {
          return (
            <AmountCurrency
              key={uuidv4()}
              name={formField.name}
              label={formField.label || formField.name}
              value={formField.value || formValues[formField.name]}
              handleChange={handleInputChange}
            />
          );
        }

        if (
          formField.type === 'radiobutton' &&
          formField.options &&
          formField.name === 'method'
        ) {
          const entriesOptionProviders = Object.entries(formField.options);

          const primaryProviders = preferMethod
            ? entriesOptionProviders.slice(0, 3)
            : entriesOptionProviders.slice(0, 4);

          const additionalProviders = preferMethod
            ? entriesOptionProviders.slice(3)
            : entriesOptionProviders.slice(4);

          return (
            <PaymentMethods key={formField.name}>
              <Heading>Payment method</Heading>
              {preferMethod ? (
                <PreferredPaymentProvider
                  key={uuidv4()}
                  keyProp='preferred'
                  paymentMethodKey={preferMethod.value}
                  paymentMethodName={preferMethod.label as string}
                  handlePaymentMethod={handlePaymentMethod}
                  checked={formValues.preferred === preferMethod.value}
                />
              ) : null}
              {primaryProviders.map((paymentMethod) => (
                <PaymentProvider
                  key={uuidv4()}
                  keyProp={formField.name}
                  paymentMethodKey={paymentMethod[0]}
                  paymentMethodName={paymentMethod[1]}
                  handlePaymentMethod={handlePaymentMethod}
                  checked={formValues[formField.name] === paymentMethod[0]}
                />
              ))}
              {additionalProviders.length > 0 ? (
                <RestPaymentProviders
                  paymentMethodKey={formValues.method}
                  checked={formValues.restPaymentOptionsChecked}
                  additionalProviders={additionalProviders}
                  handleSelectChange={handleSelectChange}
                  handleRestPaymentOptions={handleRestPaymentOptions}
                />
              ) : null}
            </PaymentMethods>
          );
        }

        if (formField.type === 'hidden') {
          return (
            <Input
              key={uuidv4()}
              type='hidden'
              name={formField.name}
              value={formField.value || formValues[formField.name]}
              onChange={handleInputChange}
              placeholder={formField.label}
            />
          );
        }

        return (
          <Input
            key={uuidv4()}
            type='text'
            name={formField.name}
            value={formField.value || formValues[formField.name]}
            onChange={handleInputChange}
            placeholder={formField.label}
          />
        );
      })}
      <ButtonGroup>
        <Button onClick={goPrevPage}>
          <ButtonText>Cancel</ButtonText>
        </Button>
        <CheckoutButton
          isDisabled={!(formValues.preferred || formValues.method)}
        />
      </ButtonGroup>
    </FormWrapper>
  </PageWrapper>
);

export default PaymentMethodsComponent;
