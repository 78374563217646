// eslint-disable-next-line import/prefer-default-export
export const NationalIDCircle = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='24' cy='24' r='24' fill='#E3E1FF' />
    <path
      d='M22 18V17C22 15.8954 22.8954 15 24 15C25.1046 15 26 15.8954 26 17V18M22 18C22 19.1046 22.8954 20 24 20C25.1046 20 26 19.1046 26 18M22 18C21.2577 18 17.7492 18 15.9985 18C15.4462 18 15 18.4468 15 18.9991C15 21.4303 15 27.5902 15 30.0061C15 30.5584 15.4477 31 16 31H32C32.5523 31 33 30.5523 33 30V19C33 18.4477 32.5538 18 32.0015 18C30.2508 18 26.7423 18 26 18M21 26C22.1046 26 23 25.1046 23 24C23 22.8954 22.1046 22 21 22C19.8954 22 19 22.8954 19 24C19 25.1046 19.8954 26 21 26ZM21 26C22.3062 26 23.4174 26.8348 23.8292 28M21 26C19.6938 26 18.5825 26.8348 18.1707 28M27 23H30M27 27H29'
      stroke='#7367FE'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
